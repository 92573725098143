import axios from "axios";

import { BASE_API_URL } from "../constants/dropdown";
import {
  APPROVE_REJECT_OVERDRAFT_FAIL,
  APPROVE_REJECT_OVERDRAFT_REQUEST,
  APPROVE_REJECT_OVERDRAFT_SUCCESS,
  GET_OVERDRAFTS_FAIL,
  GET_OVERDRAFTS_REQUEST,
  GET_OVERDRAFTS_SUCCESS,
  GET_OVERDRAFT_SCHEDULES_FAIL,
  GET_OVERDRAFT_SCHEDULES_REQUEST,
  GET_OVERDRAFT_SCHEDULES_SUCCESS,
  UPDATE_INVESTMENT_RATES_SUCCESS,
  UPDATE_OVERDRAFT_LIMIT_FAIL,
  UPDATE_OVERDRAFT_LIMIT_REQUEST,
  UPDATE_OVERDRAFT_LIMIT_SUCCESS,
} from "../constants/adminConstants";

export const fetchOverDraftRequest =
  (pageNumber, filterdata, status) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_OVERDRAFTS_REQUEST,
      });

      const {
        admin2faLogin: { userInfo },
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const page = isNaN(pageNumber) ? 1 : pageNumber;

      if (filterdata === undefined) {
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/overdraft/all-request?size=500&page=${page}&export=true`,
          config
        );
        dispatch({
          type: GET_OVERDRAFTS_SUCCESS,
          payload: data,
        });
      } else {
        const { initiated, approved, rejected } = filterdata;

        if (initiated) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/overdraft/all-request?size=500&page=${page}&export=true&statusid=1`,
            config
          );
          dispatch({
            type: GET_OVERDRAFTS_SUCCESS,
            payload: data,
          });
        } else if (approved) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/overdraft/all-request?size=500&page=${page}&export=true&statusid=5`,
            config
          );
          dispatch({
            type: GET_OVERDRAFTS_SUCCESS,
            payload: data,
          });
        } else if (rejected) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/overdraft/all-request?size=500&page=${page}&export=true&statusid=7`,
            config
          );
          dispatch({
            type: GET_OVERDRAFTS_SUCCESS,
            payload: data,
          });
        } else {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/overdraft/all-request?size=500&page=${page}&export=true`,
            config
          );
          dispatch({
            type: GET_OVERDRAFTS_SUCCESS,
            payload: data,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: GET_OVERDRAFTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const setApproveRejectOverDraft =
  (postData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: APPROVE_REJECT_OVERDRAFT_REQUEST,
      });

      const {
        admin2faLogin: { userInfo },
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const overdraftData = {
        request_id: Number(postData?.request_id),
        is_approved: postData?.is_approved,
        approved_amount: postData?.approved_amount,
        interest_rate: postData?.interest_rate,
        penal_rate: postData?.penal_rate,
      };

      console.log(overdraftData);

      const { data } = await axios.post(
        `${BASE_API_URL}/admin/overdraft/approve/reject-overdraft-request`,
        overdraftData,
        config
      );
      dispatch({
        type: APPROVE_REJECT_OVERDRAFT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVE_REJECT_OVERDRAFT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const setUpdateOverdraftLimit =
  (postData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_OVERDRAFT_LIMIT_REQUEST,
      });

      const {
        admin2faLogin: { userInfo },
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const overdraftData = {
        wallet_tag: postData.wallet_tag,
        over_draft_amount: postData?.overdraftAmount,
      };

      console.log(overdraftData);

      const { data } = await axios.post(
        `${BASE_API_URL}/admin/overdraft/update-overdraft-limit`,
        overdraftData,
        config
      );
      dispatch({
        type: UPDATE_OVERDRAFT_LIMIT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_OVERDRAFT_LIMIT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchOverDraftSchedule = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_OVERDRAFT_SCHEDULES_REQUEST,
    });

    const {
      admin2faLogin: { userInfo },
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    console.log(id);

    if (id) {
      const { data } = await axios.get(
        `${BASE_API_URL}/admin/overdraft/all-schedules?businessid=${id}&size=500&export=true`,
        config
      );
      console.log(data);
      dispatch({
        type: GET_OVERDRAFT_SCHEDULES_SUCCESS,
        payload: data,
      });
    } else {
      const { data } = await axios.get(
        `${BASE_API_URL}/admin/overdraft/all-schedules`,
        config
      );
      console.log(data);
      dispatch({
        type: GET_OVERDRAFT_SCHEDULES_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_OVERDRAFT_SCHEDULES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCustomerInvestments } from "../actions/userActions";
import { format, formatDate } from "../main";
import Loader from "./Loader";
import Paginate from "./Paginate";

const PersonalVaultBalances = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const year = new Date().getFullYear();
  const [startDate, setStartDate] = useState(new Date(year, 0, 1));
  const [endDate, setEndDate] = useState(new Date());
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tag, setTag] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [byBalance, setByBalance] = useState(false);
  const customerRef = useRef(true);

  const [styles, setStyles] = useState({
    navContainer: {
      display: "none",
    },
  });

  const customerInvestments = useSelector((state) => state.customerInvestments);
  const { loading, success, investments, error } = customerInvestments;

  useEffect(() => {
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    if (customerRef.current) {
      dispatch(getCustomerInvestments(currPage));
      customerRef.current = false;
    }
    if (success && investments) {
      setData(
        investments?.items?.map?.((inv) => ({
          vault_name: inv?.investment_name,
          customer_name: inv?.full_name,
          penntag: inv?.user_name,
          amount: format(inv?.amount),
          duration: inv?.duration,
          interest_rate: inv?.interest_rate,
          interest_amount: format(inv?.interest_accrued),
          status: inv?.status,
          start_date: moment(inv?.start_date).format("LLL"),
          withdrawal_date: moment(inv?.end_date).format("LLL"),
          topup_amount: inv?.topup_amount,
          rollover_duration: inv?.roll_over_duration,
        }))
      );
      setHeaders([
        {
          label: "Vault Name",
          key: "vault_name",
        },

        {
          label: "Customer Name",
          key: "customer_name",
        },
        {
          label: "PennTag",
          key: "penntag",
        },
        {
          label: "Amouunt",
          key: "amount",
        },
        {
          label: "Duration",
          key: "duration",
        },
        {
          label: "Interest Rate",
          key: "interest_rate",
        },
        {
          label: "Interest Amount",
          key: "interest_amount",
        },
        {
          label: "Status",
          key: "status",
        },
        {
          label: "Start Date",
          key: "start_date",
        },
        {
          label: "Withdrawal Date",
          key: "withdrawal_date",
        },
        {
          label: "Top up Amount",
          key: "topup_amount",
        },
        {
          label: "Rollover Duration",
          key: "rollover_duration",
        },
      ]);
    }
  }, [dispatch, currPage, error, loading, investments, success]);

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none",
        },
      });
    } else {
      setStyles({
        navContainer: {
          display: "block",
        },
      });
    }
  };

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none",
      },
    });

    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      tag,
      name,
      accountNumber,
      byBalance,
    };

    dispatch(getCustomerInvestments(currPage, filterData));
  };

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: "none",
      },
    });
    setEmail("");
    setTag("");
    setName("");
    setAccountNumber("");

    dispatch(getCustomerInvestments(currPage));
  };

  const afterPageClicked = (page_number) => {
    if (page_number === currPage) {
      setCurrPage(page_number + 1);
    } else {
      setCurrPage(page_number);
    }

    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      tag,
      name,
      accountNumber,
      byBalance,
    };

    dispatch(getCustomerInvestments(currPage, filterData));
  };

  return (
    <>
      <div className="filter-box">
        <div className="filter">
          <>
            <div className="filter-container">
              <p style={{ cursor: "pointer" }} onClick={handleClick}>
                Filter
              </p>
              <div className="navContainer" style={styles.navContainer}>
                <div className="date-filter">
                  <div className="date-title">Date</div>
                  <div className="date-options">
                    <div className="custom-filter__item custom-filter__item--pad"></div>
                    <div className="date-content">
                      <div className="date-items">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="date-title">Status</div>
                <div className="checkbox checkbox--flex">
                  <div className="checkbox__item">
                    <input
                      id="successful_wz3gl9i3d"
                      type="checkbox"
                      name="succwz3gl9i3d"
                      className="checkbox__input"
                      value="successful"
                      checked={byBalance}
                      onChange={() => setByBalance(!byBalance)}
                    />{" "}
                    <label
                      htmlFor="successful_wz3gl9i3d"
                      className="checkbox__label"
                    >
                      Sort By Balance
                    </label>
                  </div>
                </div>

                <div className="status-container">
                  <div className="date-title">Customer</div>
                  <div className="checkbox checkbox--flex">
                    <div className="checkbox__item full-width ">
                      <input
                        type="email"
                        className="email-input checkbox-item-styled"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter customer Email"
                      />{" "}
                    </div>
                    <div className="checkbox__item full-width ">
                      <input
                        type="email"
                        className="email-input checkbox-item-styled"
                        value={tag}
                        onChange={(e) => setTag(e.target.value)}
                        placeholder="Enter customer Tag"
                      />{" "}
                    </div>
                    <div className="checkbox__item full-width ">
                      <input
                        type="email"
                        className="email-input checkbox-item-styled"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter customer Name"
                      />{" "}
                    </div>
                    <div className="checkbox__item full-width ">
                      <input
                        type="email"
                        className="email-input checkbox-item-styled"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        placeholder="Enter customer Account No"
                      />{" "}
                    </div>
                  </div>
                </div>
                <div
                  className="status-container"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <button className="button" onClick={handleClear}>
                    <p>Clear</p>
                  </button>
                  <button className="button" onClick={handleFilter}>
                    <p>Filter</p>
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
        <div className="filter">
          <CSVLink data={data} headers={headers}>
            Export
          </CSVLink>
        </div>
      </div>
      <>
        <MDBTable
          responsive
          style={{
            marginTop: "20px",
          }}
        >
          <MDBTableHead>
            <tr className="font-reduce">
              <th scope="col">S/N</th>
              <th scope="col">Vault Name</th>
              <th scope="col">Name</th>
              <th scope="col">PennTag</th>
              <th cope="col">Duration</th>
              <th scope="col">Start Amount</th>
              <th scope="col">Total Amount</th>
              <th scope="col">Interest Rate</th>
              <th scope="col">Start Date</th>
              <th scope="col">Withdrawal Date</th>
              <th scope="col">Vault Status</th>
              <th scope="col">Rollover Status</th>
              <th scope="col">Top up Status</th>
              <th scope="col">Rollover Duration</th>
            </tr>
          </MDBTableHead>
          {loading && <Loader />}
          <MDBTableBody>
            {!loading &&
              success &&
              investments?.items?.map((inv, key) => (
                <tr className="font-reduce">
                  <td>{key + 1}</td>
                  <td>{inv?.investment_name}</td>
                  <td>{inv?.full_name}</td>
                  <td>{inv?.user_name}</td>
                  <td>{inv?.duration}</td>
                  <td>{format(inv?.amount)}</td>
                  <td>{format(inv?.current_amount)}</td>
                  <td>{inv?.interest_rate}</td>
                  <td>{moment(inv?.start_date).format("LLL")}</td>
                  <td>{moment(inv?.end_date).format("LLL")}</td>
                  <td>{inv?.status}</td>
                  <td>{inv?.roll_over_status}</td>
                  <td>{inv?.topup_status}</td>
                  <td>{inv?.roll_over_duration}</td>
                </tr>
              ))}
          </MDBTableBody>
        </MDBTable>
        <Paginate
          totPages={investments?.page?.total_page}
          currentPage={String(investments?.page?.current_page)}
          page={String(investments?.page?.current_page)}
          pageClicked={(ele) => {
            afterPageClicked(ele);
          }}
        />
      </>
    </>
  );
};

export default PersonalVaultBalances;

import axios from "axios"
import {
  BILLS_FAIL,
  BILLS_REQUEST,
  BILLS_SUCCESS,
  BUSINESS_METRICS_FAIL,
  BUSINESS_METRICS_REQUEST,
  BUSINESS_METRICS_SUCCESS,
  INVESTORS_COUNT_FAIL,
  INVESTORS_COUNT_REQUEST,
  INVESTORS_COUNT_SUCCESS,
  LOCKED_FUNDS_FAIL,
  LOCKED_FUNDS_REQUEST,
  LOCKED_FUNDS_SUCCESS,
  TOTAL_COMMISSION_FAIL,
  TOTAL_COMMISSION_REQUEST,
  TOTAL_COMMISSION_SUCCESS,
  TOTAL_INTEREST_FAIL,
  TOTAL_INTEREST_REQUEST,
  TOTAL_INTEREST_SUCCESS,
  USER_COUNT_FAIL,
  USER_COUNT_REQUEST,
  USER_COUNT_SUCCESS,
  TRANSFER_TOTAL_FAIL,
  TRANSFER_TOTAL_REQUEST,
  TRANSFER_TOTAL_SUCCESS,
  TARGET_SAVINGS_METRICS_REQUEST,
  TARGET_SAVINGS_METRICS_SUCCESS,
  TARGET_SAVINGS_METRICS_FAIL,
  GET_POS_METRICS_REQUEST,
  GET_POS_METRICS_SUCCESS,
  GET_POS_METRICS_FAIL,
  GET_INTEREST_REQUEST,
  GET_INTEREST_SUCCESS,
  GET_INTEREST_FAIL,
  GET_TRANSACTION_COUNT_REQUEST,
  GET_TRANSACTION_COUNT_SUCCESS,
  GET_TRANSACTION_COUNT_FAIL,
  TOTAL_COMMISSION_BREAKDOWN_REQUEST,
  TOTAL_COMMISSION_BREAKDOWN_SUCCESS,
  TOTAL_COMMISSION_BREAKDOWN_FAIL,
  GET_TOTAL_BALANCE_REQUEST,
  GET_TOTAL_BALANCE_SUCCESS,
  GET_TOTAL_BALANCE_FAIL,
  GET_BALANCE_HISTORY_REQUEST,
  GET_BALANCE_HISTORY_SUCCESS,
  GET_BALANCE_HISTORY_FAIL
} from "../constants/adminConstants"
import { BASE_API_URL } from "../constants/dropdown"

export const fetchCommisssions = (filterData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOTAL_COMMISSION_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    if (filterData === undefined) {
      // getting our response as we set it in our userController in the backend
      const { data } = await axios.get(
        `${BASE_API_URL}/admin/dashboard/commissions/total`,
        config
      )

      dispatch({
        type: TOTAL_COMMISSION_SUCCESS,
        payload: data
      })
    } else {
      const year = new Date().getFullYear()
      const date = new Date(year, 0, 1)
      const { endDate } = filterData

      const startDate =
        filterData.startDate === undefined ? date : filterData.startDate
      const { data } = await axios.get(
        `${BASE_API_URL}/admin/dashboard/commissions/total?fromdate=${startDate}&todate=${endDate}`,
        config
      )

      console.log(filterData)

      dispatch({
        type: TOTAL_COMMISSION_SUCCESS,
        payload: data
      })
    }
  } catch (error) {
    console.log(error)
    dispatch({
      type: TOTAL_COMMISSION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const fetchBills = (filterData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BILLS_REQUEST
    })

    const {
      admin2faLogin: { userInfo }
    } = getState()
    const token = userInfo?.token?.access_token
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    if (filterData === undefined) {
      const { data } = await axios.get(
        `${BASE_API_URL}/admin/dashboard/transaction/bills`,
        config
      )

      dispatch({
        type: BILLS_SUCCESS,
        payload: data
      })
    } else {
      const year = new Date().getFullYear()
      const date = new Date(year, 0, 1)
      const { endDate } = filterData

      const startDate =
        filterData.startDate === undefined ? date : filterData.startDate
      const { data } = await axios.get(
        `${BASE_API_URL}/admin/dashboard/transaction/bills?fromdate=${startDate}&todate=${endDate}`,
        config
      )

      dispatch({
        type: BILLS_SUCCESS,
        payload: data
      })
    }
  } catch (error) {
    console.log(error)
    dispatch({
      type: BILLS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const fetchBusinessMetrics =
  (filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BUSINESS_METRICS_REQUEST
      })

      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      console.log(config)

      if (filterData === undefined) {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/dashboard/business/metrics`,
          config
        )

        dispatch({
          type: BUSINESS_METRICS_SUCCESS,
          payload: data
        })
      } else {
        const year = new Date().getFullYear()
        const date = new Date(year, 0, 1)
        const { endDate } = filterData

        const startDate =
          filterData.startDate === undefined ? date : filterData.startDate

        const { data } = await axios.get(
          `${BASE_API_URL}/admin/dashboard/business/metrics?fromdate=${startDate}&todate=${endDate}`,
          config
        )

        dispatch({
          type: BUSINESS_METRICS_SUCCESS,
          payload: data
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: BUSINESS_METRICS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const fetchTotalInterest =
  (filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TOTAL_INTEREST_REQUEST
      })

      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      if (filterData === undefined) {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/dashboard/interests/total`,
          config
        )

        dispatch({
          type: TOTAL_INTEREST_SUCCESS,
          payload: data
        })
      } else {
        const year = new Date().getFullYear()
        const date = new Date(year, 0, 1)
        const { endDate } = filterData

        const startDate =
          filterData.startDate === undefined ? date : filterData.startDate
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/dashboard/interests/total?fromdate=${startDate}&todate=${endDate}`,
          config
        )

        console.log(filterData)

        dispatch({
          type: TOTAL_INTEREST_SUCCESS,
          payload: data
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: TOTAL_INTEREST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const countUser = (filterData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_COUNT_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    if (filterData === undefined) {
      // getting our response as we set it in our userController in the backend
      const { data } = await axios.get(
        `${BASE_API_URL}/admin/dashboard/users/count`,
        config
      )
      dispatch({
        type: USER_COUNT_SUCCESS,
        payload: data
      })
    } else {
      const year = new Date().getFullYear()
      const date = new Date(year, 0, 1)
      const { endDate } = filterData

      const startDate =
        filterData.startDate === undefined ? date : filterData.startDate

      const { data } = await axios.get(
        `${BASE_API_URL}/admin/dashboard/users/count?fromdate=${startDate}&todate=${endDate}`,
        config
      )
      dispatch({
        type: USER_COUNT_SUCCESS,
        payload: data
      })
    }
  } catch (error) {
    dispatch({
      type: USER_COUNT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const countInvestors = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: INVESTORS_COUNT_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/dashboard/investors/count`,
      config
    )
    dispatch({
      type: INVESTORS_COUNT_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: INVESTORS_COUNT_FAIL,
      payload: error?.response?.data
    })
  }
}

export const fundsLocked = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOCKED_FUNDS_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/dashboard/locked/funds`,
      config
    )
    dispatch({
      type: LOCKED_FUNDS_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: LOCKED_FUNDS_FAIL,
      payload: error?.response?.data
    })
  }
}

export const totalTransfer = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRANSFER_TOTAL_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/dashboard/funds/transfer/total?&isbusiness=false&transfertype=P2B`,
      config
    )
    dispatch({
      type: TRANSFER_TOTAL_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: TRANSFER_TOTAL_FAIL,
      payload: error?.response?.data
    })
  }
}

export const fetchTargetSavingsMetrics =
  (filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TARGET_SAVINGS_METRICS_REQUEST
      })

      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      if (filterData === undefined) {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/dashboard/target-savings/metrics`,
          config
        )

        dispatch({
          type: TARGET_SAVINGS_METRICS_SUCCESS,
          payload: data
        })
      } else {
        const year = new Date().getFullYear()
        const date = new Date(year, 0, 1)
        const { endDate } = filterData

        const startDate =
          filterData.startDate === undefined ? date : filterData.startDate

        const { data } = await axios.get(
          `${BASE_API_URL}/admin/dashboard/target-savings/metrics?fromdate=${startDate}&todate=${endDate}`,
          config
        )

        dispatch({
          type: TARGET_SAVINGS_METRICS_SUCCESS,
          payload: data
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: TARGET_SAVINGS_METRICS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const fetchPosMetrics = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_POS_METRICS_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/dashboard/pos/metrics`,
      config
    )
    dispatch({
      type: GET_POS_METRICS_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_POS_METRICS_FAIL,
      payload: error?.response?.data
    })
  }
}

export const fetchInterestMetrics = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_INTEREST_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/dashboard/interest/metrics`,
      config
    )
    dispatch({
      type: GET_INTEREST_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_INTEREST_FAIL,
      payload: error?.response?.data
    })
  }
}

export const fetchTransactionMetrics =
  (filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_TRANSACTION_COUNT_REQUEST
      })

      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      if (filterData === undefined) {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/dashboard/count/metrics`,
          config
        )
        dispatch({
          type: GET_TRANSACTION_COUNT_SUCCESS,
          payload: data
        })
      } else {
        const year = new Date().getFullYear()
        const date = new Date(year, 0, 1)
        const { endDate } = filterData

        const startDate =
          filterData.startDate === undefined ? date : filterData.startDate

        const { data } = await axios.get(
          `${BASE_API_URL}/admin/dashboard/count/metrics?fromdate=${startDate}&todate=${endDate}`,
          config
        )

        dispatch({
          type: GET_TRANSACTION_COUNT_SUCCESS,
          payload: data
        })
      }
    } catch (error) {
      dispatch({
        type: GET_TRANSACTION_COUNT_FAIL,
        payload: error?.response?.data
      })
    }
  }

export const fetchCommisssionsBreakdown =
  (filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TOTAL_COMMISSION_BREAKDOWN_REQUEST
      })

      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      const year = new Date().getFullYear()
      const date = new Date(year, 0, 1)
      const { endDate } = filterData

      const startDate =
        filterData.startDate === undefined ? date : filterData.startDate
      const { data } = await axios.get(
        `${BASE_API_URL}/admin/dashboard/commissions/total?Fromdate=${startDate}&Todate=${endDate}&export=true`,
        config
      )

      console.log(data)

      dispatch({
        type: TOTAL_COMMISSION_BREAKDOWN_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: TOTAL_COMMISSION_BREAKDOWN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const fetchTotalBalance = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_TOTAL_BALANCE_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/dashboard/balance/update`,
      config
    )
    dispatch({
      type: GET_TOTAL_BALANCE_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_TOTAL_BALANCE_FAIL,
      payload: error?.response?.data
    })
  }
}

export const fetchBalanceHistory = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_BALANCE_HISTORY_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/dashboard/balance/history`,
      config
    )
    dispatch({
      type: GET_BALANCE_HISTORY_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_BALANCE_HISTORY_FAIL,
      payload: error?.response?.data
    })
  }
}

import React, { useEffect, useRef, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit"
import { format } from "../main"
import moment from "moment"
import { fetchAllCustomerTransactions } from "../actions/userActions"
import Loader from "./Loader"
import Paginate from "./Paginate"
import { CSVLink } from "react-csv"

const CustomerTransactions = ({ customeremail }) => {
  const [currPage, setCurrPage] = useState(1)
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  // FILTER states starts

  // filter states emd
  const dispatch = useDispatch()
  const transRef = useRef(true)

  const customerTransactions = useSelector(
    (state) => state.customerTransactions
  )
  const {
    loading,
    success,
    error,
    customerTrans: transactions
  } = customerTransactions

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  useEffect(() => {
    if (!userInfo || userInfo === null) {
      navigate("/", { replace: true })
    }
    if (transRef.current) {
      dispatch(fetchAllCustomerTransactions(customeremail))
      transRef.current = false
    }
    if (success && transactions) {
      setData(
        transactions?.items?.map((tran) => ({
          product: tran?.product,
          transactionAmount: tran?.transaction_amount,
          fee: tran?.fee,
          foreign_amount: tran?.foreign_amount,
          transaction_reference: tran?.transaction_reference,
          payment_reference: tran?.payment_reference,
          remarks: tran?.remarks,
          date_created: tran?.date_created,
          status: tran?.status,
          batch_reference: tran?.batch_reference,
          customer_email: tran?.customer_email,
          currency: tran?.currency,
          foreign_currency: tran?.foreign_currency,
          wallet_name: tran?.wallet_name,
          direction: tran?.direction,
          customer_full_name: tran?.customer_full_name,
          user_name: tran?.username
        }))
      )

      setHeaders([
        { label: "Product", key: "product" },
        { label: "Transaction Amount", key: "transactionAmount" },
        { label: "Fee", key: "fee" },
        { label: "ForeignAmount", key: "foreign_amount" },
        { label: "Transaction Reference", key: "transaction_reference" },
        { label: "Payment Reference", key: "payment_reference" },
        { label: "Remarks", key: "remarks" },
        { label: "Date Created", key: "date_created" },
        { label: "Status", key: "status" },
        { label: "Batch Reference", key: "batch_reference" },
        { label: "Customer Email", key: "customer_email" },
        { label: "Currency", key: "currency" },
        { label: "Foreign Currency", key: "foreign_currency" },
        { label: "Wallet Name", key: "wallet_name" },
        { label: "Direction", key: "direction" },
        { label: "Customer Full Name", key: "customer_full_name" },
        { label: "UserName", key: "user_name" }
      ])
    }
  }, [
    dispatch,
    userInfo,
    navigate,
    transactions,
    success,
    customeremail,
    error,
    loading
  ])

  const afterPageClicked = (page_number) => {
    console.log(page_number)
    if (page_number === currPage) {
      dispatch(fetchAllCustomerTransactions(customeremail, page_number + 1))
    } else {
      dispatch(fetchAllCustomerTransactions(customeremail, page_number))
    }

    if (page_number === "undefined1") {
      dispatch(fetchAllCustomerTransactions(customeremail, 1))
    }
  }

  const handleExport = (e, done) => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })

    dispatch(fetchAllCustomerTransactions(customeremail))

    if (success && transactions) {
      setData(
        transactions?.items?.map((tran) => ({
          product: tran?.product,
          transactionAmount: tran?.transaction_amount,
          fee: tran?.fee,
          recipient: tran?.recipient === "0" ? "---" : tran?.recipient,
          foreign_amount: tran?.foreign_amount,
          transaction_reference: tran?.transaction_reference,
          payment_reference: tran?.payment_reference,
          remarks: tran?.remarks,
          date_created: tran?.date_created,
          status: tran?.status,
          batch_reference: tran?.batch_reference,
          customer_email: tran?.customer_email,
          currency: tran?.currency,
          foreign_currency: tran?.foreign_currency,
          wallet_name: tran?.wallet_name,
          balance_after: tran?.balance_after,
          balance_before: tran?.balance_before,
          direction: tran?.direction,
          customer_full_name: tran?.customer_full_name,
          user_name: tran?.username
        }))
      )

      setHeaders([
        { label: "Product", key: "product" },
        { label: "Transaction Amount", key: "transactionAmount" },
        { label: "Fee", key: "fee" },
        { label: "Recipient", key: "recipient" },
        { label: "ForeignAmount", key: "foreign_amount" },
        { label: "Transaction Reference", key: "transaction_reference" },
        { label: "Payment Reference", key: "payment_reference" },
        { label: "Remarks", key: "remarks" },
        { label: "Date Created", key: "date_created" },
        { label: "Status", key: "status" },
        { label: "Batch Reference", key: "batch_reference" },
        { label: "Customer Email", key: "customer_email" },
        { label: "Currency", key: "currency" },
        { label: "Foreign Currency", key: "foreign_currency" },
        { label: "Wallet Name", key: "wallet_name" },
        { label: "Balance After", key: "balance_after" },
        { label: "Balance Before", key: "balance_before" },
        { label: "Direction", key: "direction" },
        { label: "Customer Full Name", key: "customer_full_name" },
        { label: "UserName", key: "user_name" }
      ])
      done(true)
    } else {
      done(false)
    }
  }

  return (
    <>
      <div
        className="filter"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100px",
          fontSize: "16px",
          marginBottom: "10px"
        }}
      >
        <CSVLink
          data={data}
          headers={headers}
          filename={"transaction.csv"}
          asyncOnClick={true}
          onClick={handleExport}
        >
          Export
        </CSVLink>
      </div>
      {loading ? (
        <Loader />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          {/* <MDBTable responsive style={{ 'width': '100%', fontSize: '14px' }}>
            <MDBTableHead>
              <tr className='font-reduce'>
                <th scope='col'>S/N</th>
                <th scope='col'>Product</th>
                <th scope='col'>Transaction Amount</th>
                <th scope='col'>Fee</th>
                <th scope='col'>Transaction Reference</th>
                <th scope='col'>Payment Reference</th>
                <th scope='col'>Customer Full Name</th>
                <th scope='col'>User Tag</th>
                <th scope='col'>Account number</th>
                <th scope='col'>Remarks</th>
                <th scope='col'>Date Created</th>
                <th scope='col'>Batch Reference</th>
                <th scope='col'>Customer Email</th>
                <th scope='col'>Wallet Balance</th>
                <th scope='col'>Merchant Tag</th>
                <th scope='col'>Direction</th>

                <th scope='col'>Status</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {success &&
                transactions?.items?.length > 0 &&
                transactions?.items?.map((tran, key) => (
                  <tr key={key} className='font-reduce'>
                    <td>{key + 1}</td>
                    <td style={{ 'width': '100%' }}>{tran?.product}</td>
                    <td>{format(tran?.transaction_amount)}</td>
                    <td>{tran?.fee}</td>
                    <td>{tran?.transaction_reference}</td>
                    <td>{tran?.payment_reference}</td>
                    <td>
                      {' '}
                      <a
                        href={`/${tran?.customer_id}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {tran?.customer_full_name}
                      </a>
                    </td>
                    <td>
                      {' '}
                      <a
                        href={`/${tran?.customer_id}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {tran?.username}
                      </a>
                    </td>
                    <td>{tran?.account_number}</td>
                    <td>{tran?.remarks.substring(0, 15)}</td>
                    <td>{moment(tran?.date_created).format('LLL')}</td>
                    <td>{tran?.batch_reference}</td>
                    <td>{tran?.customer_email}</td>
                    <td>{format(tran?.wallet_balance)}</td>
                    <td>{tran?.is_merchant ? 'true' : 'false'}</td>
                    <td>{tran?.direction}</td>

                    <td>{tran?.status}</td>
                  </tr>
                ))}
            </MDBTableBody>
          </MDBTable> */}
          <MDBTable responsive style={{ "width": "100%", fontSize: "14px" }}>
            <MDBTableHead>
              <tr className="font-reduce">
                <th scope="col">S/N</th>
                <th scope="col">Product</th>
                <th scope="col">Transaction Amount</th>
                <th scope="col">Direction</th>
                <th scope="col">Status</th>
                <th scope="col">Fee</th>
                <th scope="col">Recipient</th>
                <th scope="col">Transaction Reference</th>
                <th scope="col">Payment Reference</th>
                <th scope="col">Customer Full Name</th>
                <th scope="col">User Tag</th>
                <th scope="col">Account number</th>
                <th scope="col">Remarks</th>
                <th scope="col">Date Created</th>
                <th scope="col">Batch Reference</th>
                <th scope="col">Customer Email</th>
                <th scope="col">Balance Before</th>
                <th scope="col">Balance After</th>
                <th scope="col">Merchant Tag</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {success &&
                transactions?.items?.length > 0 &&
                transactions?.items?.map((tran, key) => (
                  <tr key={key} className="font-reduce">
                    <td>{key + 1 + transactions?.page?.total_skipped}</td>
                    <td style={{ "width": "100%" }}>{tran?.product}</td>
                    <td>{format(tran?.transaction_amount)}</td>
                    <td>{tran?.direction}</td>

                    <td>{tran?.status}</td>

                    <td>{tran?.fee}</td>
                    <td>{tran?.recipient === "0" ? "---" : tran?.recipient}</td>
                    <td>{tran?.transaction_reference}</td>
                    <td>{tran?.payment_reference}</td>
                    <td>
                      {" "}
                      <a
                        href={`/${tran?.customer_id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {tran?.customer_full_name}
                      </a>
                    </td>
                    <td>
                      {" "}
                      <a
                        href={`/${tran?.customer_id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {tran?.username}
                      </a>
                    </td>
                    <td>{tran?.account_number}</td>
                    <td>{tran?.remarks}</td>
                    <td>{moment(tran?.date_created).format("LLL")}</td>
                    <td>{tran?.batch_reference}</td>
                    <td>{tran?.customer_email}</td>
                    <td>
                      {format(
                        tran?.status === "Initiated"
                          ? 0.0
                          : tran?.direction === "Credit" &&
                            tran?.status === "Successful"
                          ? tran?.balance_after - tran?.transaction_amount
                          : tran?.balance_after + tran?.transaction_amount
                      )}
                    </td>
                    <td>
                      {format(
                        tran?.status === "Initiated"
                          ? 0.0
                          : tran?.balance_after - tran?.fee
                      )}
                    </td>
                    <td>{tran?.is_merchant ? "true" : "false"}</td>
                  </tr>
                ))}
            </MDBTableBody>
          </MDBTable>
          <Paginate
            totPages={transactions?.page?.total_page}
            currentPage={String(transactions?.page?.current_page)}
            page={String(transactions?.page?.current_page)}
            pageClicked={(ele) => {
              afterPageClicked(ele)
            }}
          />
        </>
      )}
    </>
  )
}

export default CustomerTransactions

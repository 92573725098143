import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { format, formatDate } from "../main"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Loader from "./Loader"
import { CSVLink } from "react-csv"
import { fetchBusinessMetrics } from "../actions/dashboardActions"
import { Link } from "react-router-dom"

const BusinessMetrics = () => {
  const metricsRef = useRef(true)
  const dispatch = useDispatch()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const businessMetrics = useSelector((state) => state.businessMetrics)
  const { loading, success, metrics, error } = businessMetrics

  useEffect(() => {
    if (metricsRef.current) {
      dispatch(fetchBusinessMetrics())
      metricsRef.current = false
    }
    if (
      (!loading && error?.message === "Please login again") ||
      error?.message === "You have previously logged out. Please login again"
    ) {
      localStorage.removeItem("userInfo")
    }
    if (success && metrics) {
      setData([
        {
          individual_balance: format(metrics.individual_balance),
          personal_vault: format(metrics.personal_vault),
          merchant_balance: format(metrics.merchant_balance),
          investment: format(metrics.investment),
          total_inflow: format(metrics.total_inflow),
          total_transfer: format(metrics.total_transfer),
          estimated_revenue: format(metrics.estimated_revenue),
          total_bills: format(metrics.total_bills)
        }
      ])

      setHeaders([
        { label: "Individual Balance", key: "individual_balance" },
        { label: "Personal Vault", key: "personal_vault" },
        { label: "Merchant Balance", key: "merchant_balance" },
        { label: "Investment", key: "investment" },
        { label: "Total inflow", key: "total_inflow" },
        { label: "Total Transfer", key: "total_transfer" },
        { label: "Estimated Revenue", key: "estimated_revenue" },
        { label: "Total Bills", key: "total_bills" }
      ])
    }
  }, [metrics, success, dispatch, error, loading])

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
    dispatch(fetchBusinessMetrics(filterData))
  }

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    dispatch(fetchBusinessMetrics())
  }
  return (
    <>
      <div className="filter-box">
        <div className="filter">
          <>
            <div className="filter-container">
              <p style={{ cursor: "pointer" }} onClick={handleClick}>
                Filter
              </p>
              <div className="navContainer" style={styles.navContainer}>
                <div className="date-filter">
                  <div className="date-title">Date</div>
                  <div className="date-options">
                    <div className="date-content">
                      <div className="date-items">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="status-container">
                  <div className="checkbox checkbox--flex"></div>
                </div>
                <div
                  className="status-container"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                  }}
                >
                  <button className="button" onClick={handleClear}>
                    <p>Clear</p>
                  </button>
                  <button className="button" onClick={handleFilter}>
                    <p>Filter</p>
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
        <div className="filter">
          <CSVLink data={data} headers={headers}>
            Export
          </CSVLink>
        </div>
      </div>
      <div className="accordion-item">
        <h3 className="accordion-header" id="h1">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#business"
            aria-expanded="true"
            aria-controls="business"
          >
            Business Metrics
          </button>
        </h3>
        <div
          id="business"
          className="accordion-collapse collapse show"
          aria-labelledby="h1"
        >
          <div className="accordion-body">
            {loading ? (
              <Loader />
            ) : (
              <div className="row">
                <p className="error">{error && error}</p>

                <div className="col-lg-3">
                  <Link
                    to="/transactions"
                    state={{ prdID: 1, start: endDate }}
                    className="text"
                  >
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>
                      <div className="info-box-count">
                        {metrics === null ? 0 : format(metrics?.inflow_today)}
                      </div>
                      <p>Inflow Today</p>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3">
                  <Link
                    to="/transactions"
                    state={{ prdID: 4, start: endDate }}
                    className="text"
                  >
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>
                      <div className="info-box-count">
                        {metrics === null ? 0 : format(metrics?.out_flow_today)}
                      </div>
                      <p>OutFlow Today</p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link
                    to="/transactions"
                    state={{ prdID: 3, start: endDate }}
                    className="text"
                  >
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>
                      <div className="info-box-count">
                        {metrics === null
                          ? 0
                          : format(metrics?.p2_p_inflow_today)}
                      </div>
                      <p>P2P Inflow Today</p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link
                    to="/transactions"
                    state={{ prdID: 2, start: endDate }}
                    className="text"
                  >
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>
                      <div className="info-box-count">
                        {metrics === null
                          ? 0
                          : format(metrics?.p2_p_outflow_today)}
                      </div>
                      <p>P2P OutFlow Today</p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link
                    to="/transactions"
                    state={{ start: endDate }}
                    className="text"
                  >
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>
                      <div className="info-box-count">
                        {metrics === null
                          ? 0
                          : metrics?.daily_transaction_count}
                      </div>
                      <p>Daily Transaction count</p>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3">
                  <Link to="/balances" className="text">
                    <div className="info-box">
                      <i className="fa fa-users blue-icon"></i>
                      <div className="info-box-count">
                        {metrics === null
                          ? 0
                          : format(metrics?.individual_balance)}
                      </div>

                      <p>Total Individual Balance</p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/balances" className="text">
                    <div className="info-box">
                      <i className="fa fa-users green-icon"></i>
                      <div className="info-box-count">
                        {metrics === null ? 0 : format(metrics?.personal_vault)}
                      </div>
                      <p>Total Personal Vault</p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/balances" className="text">
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>
                      <div className="info-box-count">
                        {metrics === null
                          ? 0
                          : format(metrics?.merchant_balance)}
                      </div>
                      <p>Total Merchant Balance</p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : format(metrics?.investment)}
                    </div>
                    <p>Total Investment</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <Link to="/transactions" className="text">
                    <div className="info-box">
                      <i className="fa fa-users green-icon"></i>
                      <div className="info-box-count">
                        {metrics === null ? 0 : format(metrics?.total_inflow)}
                      </div>
                      <p>Total Inflow</p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/transactions" className="text">
                    <div className="info-box">
                      <i className="fa fa-users"></i>
                      <div className="info-box-count">
                        {metrics === null ? 0 : format(metrics?.total_transfer)}
                      </div>
                      <p>Total Transfer</p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : format(metrics?.estimated_revenue)}
                    </div>
                    <p>Estimated Revenue</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <Link to="/transactions" className="text">
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>
                      <div className="info-box-count">
                        {metrics === null ? 0 : format(metrics?.total_bills)}
                      </div>
                      <p>Total Bills Paid</p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/transactions" className="text">
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>
                      <div className="info-box-count">
                        {metrics === null
                          ? 0
                          : format(metrics?.active_target_savings)}
                      </div>
                      <p>Active Target Savings</p>
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default BusinessMetrics

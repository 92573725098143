import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInvestmentRate } from '../actions/investmentActions'
import Loader from './Loader'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { formatDate } from '../main'
import { customerDeactivate } from '../actions/userActions'

const CustomerDeactivateModal = ({ setIsOpen, id }) => {
  const dispatch = useDispatch()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date())

  const deactivateCustomer = useSelector((state) => state.deactivateCustomer)
  const { loading, success } = deactivateCustomer
  useEffect(() => {
    if (success) {
      setIsOpen(false)
      window.location.reload()
    }
  }, [success, dispatch, setIsOpen])

  const updateRate = () => {
    const postData = {
      customer_id: id,
      account_locked_until: formatDate(startDate)
    }

    dispatch(customerDeactivate(postData))
  }

  return (
    <>
      <div className='darkBG' onClick={() => setIsOpen(false)} />
      <div
        className='centered'
        style={{
          backgroundColor: 'grey'
        }}
      >
        <div
          className='modal'
          style={{
            backgroundColor: '#181a1b',
            height: '450px'
          }}
        >
          <div className='modalHeader' style={{ background: 'grey' }}>
            <h5 className='heading'>DeActivate Customer</h5>
          </div>
          <button className='closeBtn' onClick={() => setIsOpen(false)}>
            {/* <RiCloseLine style={{ marginBottom: '-3px' }} /> */}
          </button>
          <div className='modalContent login-form'>
            <h5>Lock Account Until:</h5>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div className='modalActions'>
            <div className='actionsContainer'>
              {loading || loading ? (
                <Loader />
              ) : (
                <>
                  <button
                    className='deleteBtn'
                    onClick={updateRate}
                    style={{
                      backgroundColor: 'green'
                    }}
                  >
                    Update
                  </button>
                  <button
                    className='cancelBtn'
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomerDeactivateModal

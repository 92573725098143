import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setApproveRejectBusiness, setMemo } from '../actions/merchantActions'
import Loader from './Loader'

const Modal = ({ setIsOpen, id, customer_id }) => {
  const dispatch = useDispatch()
  const [content, setContent] = useState('')

  const approveBusiness = useSelector((state) => state.approveBusiness)
  const {
    loading: approveRejectLoading,
    success: approveRejectSucess,
    error: approveRejectError
  } = approveBusiness

  const addMemos = useSelector((state) => state.addMemos)
  const { loading, success, error } = addMemos

  useEffect(() => {
    if (success) {
      const postData2 = {
        'id': id,
        'is_approved': false
      }
      dispatch(setApproveRejectBusiness(postData2))
    }
    if (approveRejectSucess) {
      setIsOpen(false)
    }
  }, [approveRejectSucess, success, dispatch])

  const reject = () => {
    const postData = {
      customer_id,
      remark: content
    }
    dispatch(setMemo(postData))
  }
  return (
    <>
      <div className='darkBG' onClick={() => setIsOpen(false)} />
      <div className='centered'>
        <div className='modal'>
          <div className='modalHeader'>
            <h5 className='heading'>Reject Business</h5>
          </div>
          <button className='closeBtn' onClick={() => setIsOpen(false)}>
            {/* <RiCloseLine style={{ marginBottom: '-3px' }} /> */}
          </button>
          <div className='modalContent'>
            <p> Plese add reason for rejection </p>
            <textarea
              cols={30}
              rows={10}
              className='area'
              value={content}
              onChange={(e) => setContent(e.target.value)}
            ></textarea>
          </div>
          <div className='modalActions'>
            <div className='actionsContainer'>
              {loading || approveRejectLoading ? (
                <Loader />
              ) : (
                <>
                  <button className='deleteBtn' onClick={reject}>
                    Reject
                  </button>
                  <button
                    className='cancelBtn'
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal

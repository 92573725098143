import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { format, formatDate } from "../main"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Loader from "./Loader"
import { CSVLink } from "react-csv"
import {
  fetchBusinessMetrics,
  fetchTotalBalance
} from "../actions/dashboardActions"
import { Link } from "react-router-dom"

const BalanceMetrics = () => {
  const metricsRef = useRef(true)
  const dispatch = useDispatch()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const getTotalBalance = useSelector((state) => state.getTotalBalance)
  const { loading, success, balance, error } = getTotalBalance

  console.log(balance)

  useEffect(() => {
    if (metricsRef.current) {
      dispatch(fetchTotalBalance())
      metricsRef.current = false
    }
    if (
      (!loading && error?.message === "Please login again") ||
      error?.message === "You have previously logged out. Please login again"
    ) {
      localStorage.removeItem("userInfo")
    }
    if (success && balance) {
      setData([
        {
          individual_balance: format(balance.individual_wallet_balance),
          personal_vault: format(balance.personal_vault_balance),
          merchant_balance: format(balance.merchant_wallet_balance),
          target_savings: format(balance.target_savings_balance)
        }
      ])

      setHeaders([
        { label: "Individual Balance", key: "individual_balance" },
        { label: "Personal Vault", key: "personal_vault" },
        { label: "Merchant Balance", key: "merchant_balance" },
        { label: "Target savings", key: "target_savings" }
      ])
    }
  }, [balance, success, dispatch, error, loading])

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
    dispatch(fetchBusinessMetrics(filterData))
  }

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    dispatch(fetchBusinessMetrics())
  }
  return (
    <>
      <div className="filter-box">
        <div className="filter">
          <>
            <div className="filter-container">
              <p style={{ cursor: "pointer" }} onClick={handleClick}>
                Filter
              </p>
              <div className="navContainer" style={styles.navContainer}>
                <div className="date-filter">
                  <div className="date-title">Date</div>
                  <div className="date-options">
                    <div className="date-content">
                      <div className="date-items">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="status-container">
                  <div className="checkbox checkbox--flex"></div>
                </div>
                <div
                  className="status-container"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                  }}
                >
                  <button className="button" onClick={handleClear}>
                    <p>Clear</p>
                  </button>
                  <button className="button" onClick={handleFilter}>
                    <p>Filter</p>
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
        <div className="filter">
          <div className="filter">
            <Link to="/history">Export</Link>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h3 className="accordion-header" id="h1">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#business"
            aria-expanded="true"
            aria-controls="business"
          >
            Balance Metrics(Updated daily at 11:59pm)
          </button>
        </h3>
        <div
          id="business"
          className="accordion-collapse collapse show"
          aria-labelledby="h1"
        >
          <div className="accordion-body">
            {loading ? (
              <Loader />
            ) : (
              <div className="row">
                <p className="error">{error && error}</p>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {balance === null
                        ? 0
                        : format(balance?.data?.individual_wallet_balance)}
                    </div>
                    <p>Individual Wallet Balance</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {balance === null
                        ? 0
                        : format(balance?.data?.merchant_wallet_balance)}
                    </div>
                    <p>Merchant Wallet Balance</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {balance === null
                        ? 0
                        : format(balance?.data?.personal_vault_balance)}
                    </div>
                    <p>Personal Vault</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {balance === null
                        ? 0
                        : format(balance?.data?.target_savings_balance)}
                    </div>
                    <p>Target savings Balance</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default BalanceMetrics

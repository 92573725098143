import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  fetchDepartments,
  fetchPermissions,
  fetchRolePermission,
  fetchRoles,
  fetchTeamMembers,
  newMember
} from '../actions/adminActions'
import DeleteAdminModal from '../components/DeleteAdminModal'
import Header from '../components/Header'
import Paginate from '../components/Paginate'
import SideNav from '../components/SideNav'
import UpdateAdminRoleModal from '../components/UpdateAdminRoleModal'

const AdminScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const teamRef = useRef(true)
  const [profileTab, setProfileTab] = useState(true)
  const [teamTab, setTeamTab] = useState(false)
  const [permissionTab, setPermissionTab] = useState(false)
  const [inviteTab, setInviteTab] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [role_id, setRoleId] = useState('')
  const [department_id, setDepartmentId] = useState('')
  const [supervisor_id, setSupervisorId] = useState('')
  const [emailInvite, setEmailInvite] = useState('')
  const [referal_code, setReferalCode] = useState('')
  const [id, setId] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const teamMembers = useSelector((state) => state.teamMembers)
  const { loading, success, members } = teamMembers

  const getRoles = useSelector((state) => state.getRoles)
  const { success: roleSuccess, roles } = getRoles

  const getDepartments = useSelector((state) => state.getDepartments)
  const { success: departmentSuccess, departments } = getDepartments

  const createMember = useSelector((state) => state.createMember)
  const { success: memberSuccess, member, error } = createMember

  const getPermissions = useSelector((state) => state.getPermissions)
  const { success: permissionSuccess, permissions } = getPermissions

  const rolesPermission = useSelector((state) => state.rolesPermission)
  const { success: rolPerSuccess, rolPer } = rolesPermission

  const setTabs = (param) => {
    if (param === 'profile') {
      setProfileTab(true)
      setPermissionTab(false)
      setTeamTab(false)
    } else if (param === 'team') {
      setProfileTab(false)
      setPermissionTab(false)
      setTeamTab(true)
    } else if (param === 'security') {
      setProfileTab(false)
      setPermissionTab(true)
      setTeamTab(false)
    }
  }

  useEffect(() => {
    if (userInfo?.user?.role === 'Account Officer') {
      navigate('/performance', { replace: true })
    }
    if (teamRef.current) {
      dispatch(fetchTeamMembers())
      dispatch(fetchRoles())
      dispatch(fetchPermissions())
      dispatch(fetchDepartments())
      dispatch(fetchRolePermission())
      teamRef.current = false
    }
    if (memberSuccess) {
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  }, [dispatch, memberSuccess])

  const submitHandler = (e) => {
    e.preventDefault()
  }

  const afterPageClicked = () => {}

  const setMemberInvite = () => {
    const memeberData = {
      email,
      role_id: Number(role_id),
      department_id: Number(department_id),
      supervisor_id: Number(supervisor_id),
      referal_code
    }
    dispatch(newMember(memeberData))

    // setProfileTab(false)
    // setPermissionTab(false)
    // setTeamTab(true)
  }

  const setInvite = () => {
    setInviteTab(true)
  }

  const submitTeamMemberHandler = (e) => {
    e.preventDefault()
  }

  const setPermissionInvite = () => {
    setProfileTab(false)
    setPermissionTab(false)
    setTeamTab(true)
    setInviteTab(true)
  }

  const revealModal = (id) => {
    setIsOpen(true)
    setId(id)
  }

  const revealModal2 = (id) => {
    setIsOpen2(true)
    setId(id)
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Admin | PennyTree - Admin</title>
      </Helmet>
      <section className='page-container'>
        <div className='page-content'>
          <Header />
          <div className='page-body'>
            <ul className='tab-header'>
              <li onClick={() => setTabs('profile')}>Profile</li>
              {userInfo?.user?.role === 'Admin' && (
                <li onClick={() => setTabs('team')}>Team Members</li>
              )}

              {userInfo?.user?.role === 'Admin' && (
                <li onClick={() => setTabs('security')}>
                  Permissions and Security
                </li>
              )}
            </ul>

            <div className='tab-body'>
              <div
                className='profileBody'
                style={{
                  display: profileTab ? 'block' : 'none'
                }}
              >
                <h2 className='tab-title'>Profile</h2>
                <div
                  className='investment-form'
                  style={{ width: '70%', margin: '0 auto', marginTop: '70px' }}
                >
                  <form action='' method='post' onSubmit={submitHandler}>
                    <div className='form-group'>
                      <label htmlFor='email' style={{ color: '#ffffff' }}>
                        full name
                      </label>
                      <input
                        className='invest-input'
                        type='text'
                        name='investment name'
                        value={`${userInfo?.user?.first_name} ${userInfo?.user?.last_name}`}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='email' style={{ color: '#ffffff' }}>
                        Email
                      </label>
                      <input
                        className='invest-input'
                        type='text'
                        name='name of issuer'
                        value={userInfo?.user?.email_address}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='email' style={{ color: '#ffffff' }}>
                        Phone number
                      </label>
                      <input
                        className='invest-input'
                        type='text'
                        name='issue amount'
                        value={userInfo?.user?.mobile_number}
                      />
                    </div>

                    <div className='form-group'>
                      <label htmlFor='email' style={{ color: '#ffffff' }}>
                        Department
                      </label>
                      <input
                        className='invest-input'
                        type='text'
                        name='issue amount'
                        value={userInfo?.user?.department}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div
                className='team-body'
                style={{
                  display: teamTab ? 'block' : 'none'
                }}
              >
                <h2
                  className='tab-title'
                  style={{
                    display: inviteTab ? 'none' : 'block'
                  }}
                >
                  Team Members
                </h2>
                {userInfo?.user?.role === 'Admin' && (
                  <p
                    style={{
                      marginLeft: '20px',
                      color: 'green',
                      letterSpacing: '1.3px',
                      fontWeight: 'bold',
                      display: inviteTab ? 'none' : 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={setInvite}
                  >
                    Invite
                  </p>
                )}

                <p
                  style={{
                    marginLeft: '20px',
                    color: 'green',
                    letterSpacing: '1.3px',
                    fontWeight: 'bold',
                    display: inviteTab ? 'flex' : 'none',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    cursor: 'pointer',
                    marginTop: '30px'
                  }}
                  onClick={() => setInviteTab(!inviteTab)}
                >
                  Close
                </p>
                {isOpen && <DeleteAdminModal setIsOpen={setIsOpen} id={id} />}
                {isOpen2 && (
                  <UpdateAdminRoleModal setIsOpen={setIsOpen2} id={id} />
                )}
                <div style={{ display: inviteTab ? 'none' : 'block' }}>
                  <MDBTable
                    responsive
                    style={{
                      'width': '100%',
                      fontSize: '14px',
                      margin: '0 auto',
                      marginTop: '70px'
                    }}
                  >
                    <MDBTableHead>
                      <tr>
                        <th scope='col'>Name</th>
                        <th scope='col'>Email</th>
                        <th scope='col'>Role </th>
                        <th scope='col'>Status</th>
                        <th scope='col'>Department</th>
                        <th scope='col'>Staff Id</th>
                        <th scope='col'></th>
                        <th scope='col'></th>
                        <th scope='col'></th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {success &&
                        members?.items?.map((mem, key) => (
                          <tr key={key}>
                            <td>{mem?.first_name}</td>
                            <td>{mem?.email_address}</td>
                            <td>{mem?.role}</td>
                            <td>{mem?.status}</td>
                            <td>{mem?.department}</td>
                            <td>{mem?.referal_code}</td>
                            <td>
                              <button
                                className='withdrawal-btn'
                                onClick={() => revealModal(mem?.id)}
                              >
                                {' '}
                                Remove
                              </button>
                            </td>
                            {/* <td>
                              <button
                                className='withdrawal-btn'
                                onClick={() => revealModal2(mem?.id)}
                              >
                                {' '}
                                Update
                              </button>
                            </td> */}
                          </tr>
                        ))}
                    </MDBTableBody>
                  </MDBTable>
                  <Paginate
                    totPages={members?.page?.total_page}
                    currentPage={String(members?.page?.currentPage)}
                    page={String(members?.page?.currentPage)}
                    pageClicked={(ele) => {
                      afterPageClicked(ele)
                    }}
                  />
                </div>
                <div
                  style={{
                    display: inviteTab ? 'block' : 'none'
                  }}
                >
                  <h2 className='tab-title'>Invite Team Member</h2>
                  <form
                    action=''
                    method='post'
                    onSubmit={submitTeamMemberHandler}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <div className='form-group'>
                      <label htmlFor='password' style={{ color: '#ffffff' }}>
                        Email Address
                      </label>
                      <input
                        id='password'
                        type='text'
                        name='otp'
                        placeholder='Enter email address'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                          background: '#1a1a1a',
                          border: 'none',
                          padding: '20px',
                          width: '500px',
                          borderRadius: '10px',
                          color: '#fff'
                        }}
                      />
                    </div>

                    <div className='form-group'>
                      <label htmlFor='password' style={{ color: '#ffffff' }}>
                        Department
                      </label>
                      <select
                        style={{
                          background: '#1a1a1a',
                          border: 'none',
                          padding: '20px',
                          width: '500px',
                          borderRadius: '10px'
                        }}
                        onChange={(e) => setDepartmentId(e.target.value)}
                      >
                        <option>Select Department</option>
                        {departmentSuccess &&
                          departments &&
                          departments?.data?.map((d) => (
                            <option value={d?.id}>{d?.name}</option>
                          ))}
                      </select>
                    </div>

                    <div className='form-group'>
                      <label htmlFor='roles' style={{ color: '#ffffff' }}>
                        Roles
                      </label>
                      <select
                        style={{
                          background: '#1a1a1a',
                          border: 'none',
                          padding: '20px',
                          width: '500px',
                          borderRadius: '10px'
                        }}
                        onChange={(e) => setRoleId(e.target.value)}
                      >
                        <option>Select Roles</option>
                        {roleSuccess &&
                          roles &&
                          roles?.data?.map((d) => (
                            <option value={d?.id}>{d?.name}</option>
                          ))}
                      </select>
                    </div>

                    <div className='form-group'>
                      <label htmlFor='password' style={{ color: '#ffffff' }}>
                        Supervisor
                      </label>
                      <select
                        style={{
                          background: '#1a1a1a',
                          border: 'none',
                          padding: '20px',
                          width: '500px',
                          borderRadius: '10px'
                        }}
                        onChange={(e) => setSupervisorId(e.target.value)}
                      >
                        <option>Select Supervisor</option>
                        {success &&
                          members &&
                          members?.items?.map((d) => (
                            <option value={d?.id}>
                              {d?.first_name} {d?.last_name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className='form-group'>
                      <label htmlFor='password' style={{ color: '#ffffff' }}>
                        STAFF ID
                      </label>
                      <input
                        id='password'
                        type='text'
                        name='otp'
                        placeholder='Enter staff ID'
                        value={referal_code}
                        onChange={(e) => setReferalCode(e.target.value)}
                        style={{
                          background: '#1a1a1a',
                          border: 'none',
                          padding: '20px',
                          width: '500px',
                          borderRadius: '10px',
                          color: '#fff'
                        }}
                      />
                    </div>

                    <p
                      style={{
                        marginLeft: '20px',
                        color: 'green',
                        letterSpacing: '1.3px',
                        fontWeight: 'bold',
                        alignItems: 'center',
                        cursor: 'pointer',
                        textAlign: 'end'
                      }}
                      onClick={setMemberInvite}
                    >
                      Invite
                    </p>
                    <p
                      style={{
                        color: 'green',
                        letterSpacing: '1.3px',
                        fontWeight: 'bold',
                        alignItems: 'center',
                        fontSize: '16px'
                      }}
                    >
                      {member?.message}
                    </p>
                    <p
                      style={{
                        color: 'red',
                        letterSpacing: '1.3px',
                        fontWeight: 'bold',
                        alignItems: 'center',
                        fontSize: '16px'
                      }}
                    >
                      {error && error}
                    </p>
                  </form>
                </div>
              </div>
              <div
                className='permission-body'
                style={{
                  display: permissionTab ? 'block' : 'none'
                }}
              >
                <form>
                  {userInfo?.user?.role === 'Admin' && (
                    <div className='form-group'>
                      <label htmlFor='email' style={{ color: '#ffffff' }}>
                        Enter Email
                      </label>
                      <input
                        className='invest-input'
                        type='text'
                        name='investment name'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ color: 'white' }}
                      />
                      <p
                        style={{
                          marginLeft: '20px',
                          color: 'green',
                          letterSpacing: '1.3px',
                          fontWeight: 'bold',
                          cursor: 'pointer'
                        }}
                        onClick={setPermissionInvite}
                      >
                        Invite
                      </p>
                    </div>
                  )}
                </form>
                <div className='permission-flex'>
                  {rolPerSuccess &&
                    rolPer.data?.map((p, key) => (
                      <div className='developer-flex flex-item'>
                        <h3 className='flex-title'>
                          {p?.name} ({p?.description})
                        </h3>
                        <ul>
                          {p?.permissions?.map((perm) => (
                            <li>{perm?.name}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AdminScreen

import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { format, formatDate } from "../main"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Loader from "./Loader"
import { CSVLink } from "react-csv"
import {
  fetchBusinessMetrics,
  fetchTargetSavingsMetrics
} from "../actions/dashboardActions"
import { Link } from "react-router-dom"

const TargetSavingsMetrics = () => {
  const metricsRef = useRef(true)
  const dispatch = useDispatch()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const targetSavingsMetrics = useSelector(
    (state) => state.targetSavingsMetrics
  )
  const { loading, success, metrics, error } = targetSavingsMetrics

  useEffect(() => {
    if (metricsRef.current) {
      dispatch(fetchTargetSavingsMetrics())
      metricsRef.current = false
    }
    // if (
    //   (!loading && error?.message === 'Please login again') ||
    //   error?.message === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    // }
  }, [metrics, success, dispatch, error, loading])

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
    dispatch(fetchTargetSavingsMetrics(filterData))
  }

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    dispatch(fetchTargetSavingsMetrics())
  }
  return (
    <>
      <div className="filter-box">
        <div className="filter">
          <>
            <div className="filter-container">
              <p style={{ cursor: "pointer" }} onClick={handleClick}>
                Filter
              </p>
              <div className="navContainer" style={styles.navContainer}>
                <div className="date-filter">
                  <div className="date-title">Date</div>
                  <div className="date-options">
                    <div className="date-content">
                      <div className="date-items">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="status-container">
                  <div className="checkbox checkbox--flex"></div>
                </div>
                <div
                  className="status-container"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                  }}
                >
                  <button className="button" onClick={handleClear}>
                    <p>Clear</p>
                  </button>
                  <button className="button" onClick={handleFilter}>
                    <p>Filter</p>
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
      <div className="accordion-item">
        <h3 className="accordion-header" id="h1">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#business"
            aria-expanded="true"
            aria-controls="business"
          >
            Target Savings Metrics
          </button>
        </h3>
        <div
          id="business"
          className="accordion-collapse collapse show"
          aria-labelledby="h1"
        >
          <div className="accordion-body">
            {loading ? (
              <Loader />
            ) : (
              <div className="row">
                <p className="error">{error && error}</p>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : format(metrics?.daily_target_savings_amount)}
                    </div>
                    <p>Daily Target Savings</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : format(metrics?.daily_average_amount_saved)}
                    </div>
                    <p>Daily Average Amount Saved</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : format(metrics?.weekly_average_amount_saved)}
                    </div>
                    <p>Weekly Average Amount Saved</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : format(metrics?.monthly_average_amount_saved)}
                    </div>
                    <p>Monthly Average Amount Saved</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : format(
                            metrics?.daily_average_amount_saved +
                              metrics?.weekly_average_amount_saved +
                              metrics?.monthly_average_amount_saved
                          )}
                    </div>
                    <p>Total Average Amount Saved</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <Link to="/target-savings/all/2">
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>

                      <div className="info-box-count">
                        {metrics === null
                          ? 0
                          : format(metrics?.active_target_savings_amount)}
                      </div>

                      <p>Active Target Savings</p>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3">
                  <Link to="/target-savings/all/4">
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>
                      <div className="info-box-count">
                        {metrics === null
                          ? 0
                          : format(metrics?.completed_target_savings_amount)}
                      </div>
                      <p>Completed Target Savings</p>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3">
                  <Link to="/target-savings/all/3">
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>
                      <div className="info-box-count">
                        {metrics === null
                          ? 0
                          : format(metrics?.cancelled_target_savings_amount)}
                      </div>
                      <p>Cancelled Target Savings</p>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3">
                  <Link to="/target-savings/all/5">
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>
                      <div className="info-box-count">
                        {metrics === null
                          ? 0
                          : format(metrics?.closed_target_savings_amount)}
                      </div>
                      <p>Closed Target Savings</p>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3">
                  <Link to="/target-savings/all">
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>
                      <div className="info-box-count">
                        {metrics === null
                          ? 0
                          : format(metrics?.all_target_savings_amount)}
                      </div>
                      <p>All Target Savings</p>
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TargetSavingsMetrics

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBusinessWallet } from "../actions/merchantActions";
import { format } from "../main";

const BusinessWallet = ({ customerid, businessid }) => {
  console.log(customerid, businessid);
  const dispatch = useDispatch();
  const [newWallet, setWallet] = useState();
  const getBusinessWallet = useSelector((state) => state.getBusinessWallet);
  const { loading, success, wallets } = getBusinessWallet;

  const ref = useRef(true);
  useEffect(() => {
    if (ref.current) {
      dispatch(fetchBusinessWallet(customerid, businessid));
      ref.current = false;
    }
  }, [dispatch, businessid, customerid]);

  useEffect(() => {
    if (success && wallets[businessid]) {
      setWallet(wallets[businessid]);
    }
  }, [wallets, success, businessid]);

  return (
    <>
      {success && (
        <div>
          <p>
            Available Balance:{" "}
            <span style={{ fontWeight: "bold" }}>
              {format(newWallet?.data?.available_balance)}
            </span>
          </p>
          <p>
            Account Number: <span>{newWallet?.data?.account_number}</span>
          </p>
        </div>
      )}
    </>
  );
};

export default BusinessWallet;

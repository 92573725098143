import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Header from "../components/Header"
import Loader from "../components/Loader"
import SideNav from "../components/SideNav"

import {
  setTerminal,
  fetchCustomerBusinesses,
  getTerminalBanks
} from "../actions/terminalActions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const AddTerminalScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [terminal_id, setTerminalId] = useState("")
  const [serial_no, setSerialNo] = useState("")
  const [terminal_bank, setBanks] = useState({})
  const banksRef = useRef(true)

  const customerLimit = useSelector((state) => state.customerLimit)
  const { loading, success, error, limit } = customerLimit

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const fetchBanks = useSelector((state) => state.fetchBanks)
  const {
    loading: bankLoading,
    success: bankSuccess,
    banks,
    error: banksError
  } = fetchBanks

  const addTerminal = useSelector((state) => state.addTerminal)
  const {
    loading: terminalLoading,
    success: terminalSuccess,
    terminal,
    error: terminalError
  } = addTerminal

  const submitHandler = (e) => {
    e.preventDefault()
    const postBody = {
      terminal_id: terminal_id,
      serial_number: serial_no,
      bank_id: terminal_bank
    }

    console.log(postBody.terminal_id.length)
    if (postBody.terminal_id.length < 7 || postBody.serial_number.length < 10) {
      toast.error("Invalid Terminal Id or Serial Number", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true
      })
    } else {
      dispatch(setTerminal(postBody))
    }
  }

  useEffect(() => {
    if (userInfo?.user?.role !== "Admin") {
      navigate("/performance", { replace: true })
    }
    if (banksRef.current) {
      dispatch(getTerminalBanks())
      banksRef.current = false
    }
    if (terminalError) {
      toast.error(terminalError, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true
      })
    }
    if (terminalSuccess) {
      toast.success("Terminal Added SuccessFully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true
      })
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
    if (banksError) {
      toast.error(banksError, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true
      })
    }
  }, [
    terminal_id,
    userInfo,
    navigate,
    dispatch,
    error,
    loading,
    terminalError,
    terminalSuccess,
    banksError
  ])

  console.log(terminalSuccess)

  return (
    <>
      <SideNav />
      <Helmet>
        <title>Terminal Creation | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <ToastContainer />
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Add a new Terminal</h1>
            <div
              className="status-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <div className="button">
                <FontAwesomeIcon />
                <Link to="/terminal/unassigned"> UnAssigned Terminals</Link>
              </div>
            </div>
            {/* <div className='page-flexed'> */}
            <div>
              <div className="investment-form">
                <form action="" method="post" onSubmit={submitHandler}>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                      fontSize: "20px",
                      letterSpacing: "1.5px",
                      marginBottom: "10px"
                    }}
                  >
                    {error && error}
                  </p>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "green",
                      fontSize: "20px",
                      letterSpacing: "1.5px"
                    }}
                  >
                    {success && limit?.message}
                  </p>
                  <div className="form-group">
                    <label htmlFor="email" style={{ color: "#ffffff" }}>
                      Terminal ID:
                    </label>

                    <input
                      className="invest-input"
                      type="text"
                      placeholder="Input Terminal Identity Number"
                      value={terminal_id}
                      onChange={(e) => setTerminalId(e.target.value)}
                      min={0}
                      max={9}
                      //   style={{ display: "block", width: "400px" }}
                    />

                    <br />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email" style={{ color: "#ffffff" }}>
                      Serial Number:
                    </label>

                    <input
                      className="invest-input"
                      type="text"
                      placeholder="Input Serial Number"
                      value={serial_no}
                      onChange={(e) => setSerialNo(e.target.value)}
                      min={0}
                      max={9}
                      //   style={{ display: "block", width: "400px" }}
                    />

                    <br />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email" style={{ color: "#ffffff" }}>
                      Select Terminal Bank
                    </label>

                    <div className="checkbox checkbox--flex">
                      <div className="checkbox__item">
                        <div className="form-group">
                          {bankLoading ? (
                            <Loader />
                          ) : (
                            <select
                              style={{
                                background: "#1a1a1a",
                                color: "#fff",
                                border: "none",
                                padding: "15px",
                                width: "700px",
                                borderRadius: "10px"
                              }}
                              className="invest-input"
                              onChange={(e) => setBanks(e.target.value)}
                            >
                              <option>Select Bank</option>

                              {bankSuccess &&
                                banks &&
                                banks?.data?.map((d) => (
                                  <option value={`${d?.id}`}>
                                    {d?.bank_name}
                                  </option>
                                ))}
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <button type="submit" className="login-btn">
                        Add Terminal
                      </button>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AddTerminalScreen

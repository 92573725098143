export const StatusConstants = {
  STATUS_SUCCESS: "Successful",
  STATUS_INITIATED: "Initiated",
  STATUS_PROCESSING: "Processing",
  STATUS_REVERSED: "Reversed",
  STATUS_FAILED: "Failed",
  STATUS_PENDING: "Pending"
}

export const BASE_API_URL = "https://api.mypennytree.com/api/v2"

export const PAYAZA_AUTH =
  "Payaza UFo3OC1QS0xJVkUtRkQ1Mjc3RkMtREYwOS00RkQyLUI2QkQtMDZBMDIwQ0E1NUI2"
export const PAYAZA_URL =
  "https://router-live.78financials.com/api/request/secure/payloadhandler"
export const X_PUSH_MESSAGE_KEY = "This is a secret value"
export const x_api_key = 1124468
export const SEERBIT_URL = "https://seerbitapi.com"
export const SEERBIT_KEY = "SBPUBK_XPRXSWP3VN4LSPDMQA9PAEZS82SUCBAQ"
export const SEERBIT_POCKET = "SBP0000090"
export const SEERBIT_EMAIL = "tech@mypennytree.com"
export const SEERBIT_PASS = "tech"
export const VFD_KEY = "b3ee4766-8729-32c9-ad2c-8ebd686856c2"
export const VFD_WALLET_CREDS =
  "NndwcmVIdFNLNmgxaDlYNkpvUGZZcHMxZE5VYTpZSzl1alc0SXlVME55d0lhZkE2VDNwdTVmWnNh"
export const FLW_KEY = "FLWSECK-21e53d846d170f20e7f7ee3680c57baa-X"
export const PAYSTACK_SECRET =
  "sk_live_92b22a3320d4623db0732c7207b79b872bac2262"

// Grabbing the parameter from the url
//e.g http://localhost:3000/setup/#access_token=BQCFv8_mHptkarE1d0p8N1cXTkU5iwQuaSe_Dlsezw9T2m59sff.

import validator from 'validator'

export const getHashParams = () => {
  const hashParams = {}
  let e
  const r = /([^&;=]+)=?([^&;]*)/g
  const q = window.location.hash.substring(1)
  while ((e = r.exec(q))) {
    hashParams[e[1]] = decodeURIComponent(e[2])
  }
  console.log(hashParams)
  return hashParams
}

export const validateTelephone = (telephone) => {
  return validator.isMobilePhone(telephone, 'en-NG', { strictMode: true })
}

export const isEmpty = (str) => {
  return validator.isEmpty(str, { ignore_whitespace: true })
}

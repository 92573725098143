import axios from 'axios'
import {
  GET_INTEREST_INFO_FAIL,
  GET_INTEREST_INFO_REQUEST,
  GET_INTEREST_INFO_SUCCESS
} from '../constants/adminConstants'
import { BASE_API_URL } from '../constants/dropdown'

export const fetchInterestInfo = (filterData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_INTEREST_INFO_REQUEST
    })

    const {
      admin2faLogin: { userInfo }
    } = getState()
    const token = userInfo?.token?.access_token
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    if (filterData === undefined) {
      dispatch({
        type: GET_INTEREST_INFO_FAIL,
        payload: 'Please select all necessary fields'
      })
    } else {
      const { year, productId, isVault } = filterData

      const { data } = await axios.get(
        `${BASE_API_URL}/admin/dashboard/interest/details?productid=${productId}&year=${year}&IsVault=${isVault}`,
        config
      )

      dispatch({
        type: GET_INTEREST_INFO_SUCCESS,
        payload: data
      })
    }
  } catch (error) {
    console.log(error)
    dispatch({
      type: GET_INTEREST_INFO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

import axios from "axios"
import {
  ADD_TERMINAL_FAIL,
  ADD_TERMINAL_REQUEST,
  ADD_TERMINAL_SUCCESS,
  ASSIGN_TERMINAL_FAIL,
  ASSIGN_TERMINAL_REQUEST,
  ASSIGN_TERMINAL_SUCCESS,
  FETCH_BANKS_FAIL,
  FETCH_BANKS_REQUEST,
  FETCH_BANKS_SUCCESS,
  FETCH_BUSINESS_FAIL,
  FETCH_BUSINESS_REQUEST,
  FETCH_BUSINESS_SUCCESS,
  FETCH_UNASSIGNED_TERMINALS_FAIL,
  FETCH_UNASSIGNED_TERMINALS_REQUEST,
  FETCH_UNASSIGNED_TERMINALS_SUCCESS
} from "../constants/adminConstants"
import { BASE_API_URL } from "../constants/dropdown"

export const fetchCustomerBusinesses =
  (customerId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_BUSINESS_REQUEST
      })

      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      const { data } = await axios.get(
        `${BASE_API_URL}/admin/terminal/businesses?Customerid=${customerId}`,
        config
      )

      dispatch({
        type: FETCH_BUSINESS_SUCCESS,
        payload: data
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: FETCH_BUSINESS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const getTerminalBanks = (customerId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_BANKS_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    const { data } = await axios.get(
      `${BASE_API_URL}/admin/terminal/banks`,
      config
    )

    dispatch({
      type: FETCH_BANKS_SUCCESS,
      payload: data
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: FETCH_BANKS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const setTerminal = (postData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_TERMINAL_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    const { terminal_id, serial_number, bank_id } = postData

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.post(
      `${BASE_API_URL}/admin/terminal/add/new`,
      { terminal_id, serial_number, bank_id },
      config
    )

    dispatch({
      type: ADD_TERMINAL_SUCCESS,
      payload: data
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: ADD_TERMINAL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const fetchUnassignedTerminals =
  (statusid) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_UNASSIGNED_TERMINALS_REQUEST
      })

      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      const { data } = await axios.get(
        `${BASE_API_URL}/admin/terminal/unassigned?statusid=${statusid}`,
        config
      )

      dispatch({
        type: FETCH_UNASSIGNED_TERMINALS_SUCCESS,
        payload: data
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: FETCH_UNASSIGNED_TERMINALS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const assignTerminalToCustomer =
  (postData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ASSIGN_TERMINAL_REQUEST
      })

      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      const { terminal_id, customer_id, business_id, terminal_action } =
        postData

      // getting our response as we set it in our userController in the backend
      const { data } = await axios.post(
        `${BASE_API_URL}/admin/terminal/assign/new`,
        { terminal_id, customer_id, business_id, terminal_action },
        config
      )

      dispatch({
        type: ASSIGN_TERMINAL_SUCCESS,
        payload: data
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: ASSIGN_TERMINAL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import React, { useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  fetchOfficers,
  fetchSingleOfficerDetails,
  fetchTargetSavingsOfficers
} from "../actions/adminActions"
import Header from "../components/Header"
import Loader from "../components/Loader"
import Paginate from "../components/Paginate"
import SideNav from "../components/SideNav"
import { calculatePercentage, format } from "../main"

const TargetSavingsOfficerScreen = () => {
  const dispatch = useDispatch()
  const performanceRef = useRef(true)
  const navigate = useNavigate()

  const getTargetSavingsOfficer = useSelector(
    (state) => state.getTargetSavingsOfficer
  )
  const { loading, success, error, savingsOfficer } = getTargetSavingsOfficer

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  useEffect(() => {
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    if (!userInfo || userInfo === null) {
      navigate("/", { replace: true })
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate(`/officer/${userInfo?.user?.id}`, { replace: true })
    }
    if (performanceRef.current) {
      dispatch(fetchTargetSavingsOfficers())
      performanceRef.current = false
    }
  }, [loading, success, error, userInfo, navigate, dispatch])
  const afterPageClicked = (page_number) => {}
  return (
    <>
      <SideNav />
      <Helmet>
        <title> Target Savings Performance | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Target Savings Performance</h1>
            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                >
                  <MDBTableHead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col">Account Officer Name</th>
                      <th scope="col">Staff Id</th>
                      <th scope="col">No. Of Accounts</th>
                      <th scope="col">Target Amount</th>
                      <th scope="col">Actual Inflow Amount</th>
                      <th scope="col">Leaderboard</th>
                      <th scope="col">Action</th>
                      <th scope="col"></th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {console.log(
                      savingsOfficer?.items?.sort(
                        (a, b) => a.leader_board - b.leader_board
                      )
                    )}
                    {success &&
                      savingsOfficer?.items?.map((tran, key) => (
                        <tr key={key}>
                          <td>{key}</td>
                          <td>
                            <a href={`/officer/${tran?.id}`}>{tran?.name}</a>
                          </td>
                          <td>{tran?.referal_code}</td>
                          <td>{tran?.no_of_accounts}</td>
                          <td>{format(tran?.target_amount)}</td>
                          <td>{format(tran?.target_inflow_amount)}</td>

                          <td>{tran?.leader_board}</td>
                          <td>
                            <button className="withdrawal-btn">
                              <a href={`/target-savings/officer/${tran?.id}`}>
                                View
                              </a>
                            </button>
                          </td>
                          <td></td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
                <Paginate
                  totPages={savingsOfficer?.page?.total_page}
                  currentPage={String(savingsOfficer?.page?.currentPage)}
                  page={String(savingsOfficer?.page?.currentPage)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default TargetSavingsOfficerScreen

import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { fetchInvestmentRates } from "../actions/investmentActions"
import Header from "../components/Header"
import Loader from "../components/Loader"
import SideNav from "../components/SideNav"
import UpdateRateModal from "../components/UpdateRateModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

const InvestmentRatesScreen = () => {
  const dispatch = useDispatch()
  const ratesRef = useRef(true)
  const [isOpen, setIsOpen] = useState(false)
  const [id, setId] = useState("")
  useEffect(() => {
    if (ratesRef.current) {
      dispatch(fetchInvestmentRates())
      ratesRef.current = false
    }
    setId(id)
  }, [dispatch, id])

  const investmentRates = useSelector((state) => state.investmentRates)
  const { loading, success, error, rates } = investmentRates

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const revealModal = (id) => {
    setIsOpen(true)
    setId(id)
  }
  return (
    <>
      <SideNav />
      <Helmet>
        <title> PennyTree - Investment Rates</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Investment Rates</h1>
            {userInfo?.user?.role === "Admin" && (
              <div
                className="status-container"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "10px"
                }}
              >
                <div className="button">
                  <FontAwesomeIcon />
                  <Link to="/rates/all"> Product Rates</Link>
                </div>
              </div>
            )}
            {error && <p>{error}</p>}
            <>
              {isOpen && <UpdateRateModal setIsOpen={setIsOpen} id={id} />}
              {loading ? (
                <Loader />
              ) : (
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                >
                  <MDBTableHead>
                    <tr className="font-reduce">
                      <th scope="col">S/N</th>
                      <th scope="col"> Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Duration</th>
                      <th scope="col">Rate</th>
                      <th scope="col">Action</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      rates &&
                      rates?.items?.map((r, key) => (
                        <tr>
                          <td>{key + 1}</td>
                          <td>{r?.name}</td>
                          <td>{r?.description}</td>
                          <td>{r?.duration}</td>
                          <td>{r?.rate}.0%</td>
                          <td>
                            <button
                              className="withdrawal-btn"
                              onClick={() => revealModal(r?.id)}
                            >
                              Update
                            </button>
                          </td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
              )}
              {/* <Paginate
                  totPages={investments?.page?.total_page}
                  currentPage={String(investments?.page?.currentPage)}
                  page={String(investments?.page?.currentPage)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
              /> */}
            </>
          </div>
        </div>
      </section>
    </>
  )
}

export default InvestmentRatesScreen

import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import SideNav from '../components/SideNav'
import { format, formatDate } from '../main'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { CSVLink } from 'react-csv'

import {
  setApproveRejectWithdrawal,
  fetchAllPayouts,
  fetchAllBills
} from '../actions/transactionActions'

const BillsPaymentScreen = () => {
  const dispatch = useDispatch()
  const withdrawalRef = useRef(true)
  const [currPage, setCurrPage] = useState(1)
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const navigate = useNavigate()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [email, setEmail] = useState('')
  const [transactionReference, setTransactionReference] = useState('')
  const [successful, setSuccess] = useState(false)
  const [pending, setPending] = useState(false)
  const [initiated, setInitiated] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [reversed, setReversed] = useState(false)
  const [failed, setFailed] = useState(false)
  const [styles, setStyles] = useState({
    navContainer: {
      display: 'none'
    }
  })

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const getAllBills = useSelector((state) => state.getAllBills)
  const { loading, success, error, bills } = getAllBills

  useEffect(() => {
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    // if (!userInfo || userInfo === null) {
    //   navigate('/', { replace: true })
    // }
    if (error) {
      navigate('/dashboard', { replace: true })
    }
    if (userInfo?.user?.role === 'Account Officer') {
      navigate(`/officer/${userInfo?.user?.id}`, { replace: true })
    }
    if (withdrawalRef.current) {
      dispatch(fetchAllBills(currPage))
      withdrawalRef.current = false
    }
    if (userInfo?.user?.role === 'Account Officer') {
      navigate('/performance', { replace: true })
    }
  }, [dispatch, loading, error, navigate, userInfo, currPage, bills, success])

  // filter data

  const handleClick = () => {
    if (styles.navContainer.display === 'block') {
      setStyles({
        navContainer: {
          display: 'none'
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: 'block'
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: 'none'
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      transactionReference,
      successful,
      pending,
      initiated,
      processing,
      reversed,
      failed
    }

    dispatch(fetchAllBills(currPage, filterData))
  }

  const handleClear = () => {
    setEmail('')

    setStyles({
      navContainer: {
        display: 'none'
      }
    })

    dispatch(fetchAllPayouts(currPage))
  }

  const afterPageClicked = (page_number) => {
    if (page_number === currPage) {
      setCurrPage(page_number + 1)
    } else {
      setCurrPage(page_number)
    }

    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      transactionReference,
      successful,
      pending,
      initiated,
      processing,
      reversed,
      failed
    }
    if (page_number === 'undefined1') {
      setCurrPage(1)
    }
    dispatch(fetchAllBills(currPage, filterData))
  }

  const handleExport = (e, done) => {
    setStyles({
      navContainer: {
        display: 'none'
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      transactionReference,
      exported: true
    }

    dispatch(fetchAllPayouts(currPage, filterData))
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> All Bills | PennyTree - Admin</title>
      </Helmet>
      <section className='page-container'>
        <div className='page-content'>
          <Header />
          <div className='page-body'>
            <h1>All Bills</h1>
            <p
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'red',
                fontSize: '20px',
                letterSpacing: '1.5px'
              }}
            >
              {error && error}
            </p>
            <div className='filter-box'>
              <div className='filter'>
                <>
                  <div className='filter-container'>
                    <p style={{ cursor: 'pointer' }} onClick={handleClick}>
                      Filter
                    </p>
                    <div className='navContainer' style={styles.navContainer}>
                      <div className='date-filter'>
                        <div className='date-title'>Date</div>
                        <div className='date-options'>
                          <div className='date-content'>
                            <div className='date-items'>
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                              />

                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className='date-title'>Status</div>
                      <div className='checkbox checkbox--flex'>
                        <div className='checkbox__item'>
                          <input
                            id='successful_wz3gl9i2d'
                            type='checkbox'
                            name='successful_wz3gl9i2d'
                            className='checkbox__input'
                            value='successful'
                            checked={successful}
                            onChange={() => setSuccess(!successful)}
                          />{' '}
                          <label
                            htmlFor='successful_wz3gl9i2d'
                            className='checkbox__label'
                          >
                            Successful
                          </label>
                        </div>
                        <div className='checkbox__item'>
                          <input
                            id='new_wz3gl9i2d'
                            type='checkbox'
                            name='new_wz3gl9i2d'
                            className='checkbox__input'
                            checked={pending}
                            onChange={() => setPending(!pending)}
                          />{' '}
                          <label
                            htmlFor='new_wz3gl9i2d'
                            className='checkbox__label'
                          >
                            Pending
                          </label>
                        </div>
                        <div className='checkbox__item'>
                          <input
                            id='failed_wz3gl9i2d'
                            type='checkbox'
                            name='failed_wz3gl9i2d'
                            className='checkbox__input'
                            checked={failed}
                            onChange={() => setFailed(!failed)}
                          />{' '}
                          <label
                            htmlFor='failed_wz3gl9i2d'
                            className='checkbox__label'
                          >
                            Failed
                          </label>
                        </div>
                        <div className='checkbox__item'>
                          <input
                            id='reversed_wz3gl9i2d'
                            type='checkbox'
                            name='reversed_wz3gl9i2d'
                            className='checkbox__input'
                            checked={reversed}
                            onChange={() => setReversed(!reversed)}
                          />{' '}
                          <label
                            htmlFor='reversed_wz3gl9i2d'
                            className='checkbox__label'
                          >
                            Reversed
                          </label>
                        </div>
                        <div className='checkbox__item'>
                          <input
                            id='initiated_wz3gl9i2d'
                            type='checkbox'
                            name='initiated_wz3gl9i2d'
                            className='checkbox__input'
                            checked={initiated}
                            onChange={() => setInitiated(!initiated)}
                          />{' '}
                          <label
                            htmlFor='initiated_wz3gl9i2d'
                            className='checkbox__label'
                          >
                            Initiated
                          </label>
                        </div>
                        <div className='checkbox__item'>
                          <input
                            id='processing_wz3gl9i2d'
                            type='checkbox'
                            name='processing_wz3gl9i2d'
                            className='checkbox__input'
                            checked={processing}
                            onChange={() => setProcessing(!processing)}
                          />{' '}
                          <label
                            htmlFor='processing_wz3gl9i2d'
                            className='checkbox__label'
                          >
                            Processing
                          </label>
                        </div>
                      </div> */}
                      <div className='status-container'>
                        <div className='date-title'>Other Filters</div>
                        <div className='checkbox checkbox--flex'>
                          <div
                            className='checkbox__item'
                            style={{
                              width: '100%'
                            }}
                          >
                            <input
                              style={{
                                width: '100%',
                                borderRadius: '10px',
                                border: '1px solid grey',
                                fontSize: '14px',
                                display:
                                  transactionReference ||
                                  successful ||
                                  pending ||
                                  failed ||
                                  initiated ||
                                  processing ||
                                  reversed
                                    ? 'none'
                                    : 'block'
                              }}
                              type='email'
                              className='email-input'
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder='Enter customer Email'
                            />{' '}
                          </div>
                          <div
                            className='checkbox__item'
                            style={{
                              width: '100%'
                            }}
                          >
                            <input
                              style={{
                                width: '100%',
                                borderRadius: '10px',
                                border: '1px solid grey',
                                fontSize: '14px',
                                display:
                                  email ||
                                  successful ||
                                  pending ||
                                  failed ||
                                  initiated ||
                                  processing ||
                                  reversed
                                    ? 'none'
                                    : 'block'
                              }}
                              type='email'
                              className='email-input'
                              value={transactionReference}
                              onChange={(e) =>
                                setTransactionReference(e.target.value)
                              }
                              placeholder='Enter Transaction Reference'
                            />{' '}
                          </div>
                        </div>
                      </div>
                      <div
                        className='status-container'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center'
                        }}
                      >
                        <button className='button' onClick={handleClear}>
                          <p>Clear</p>
                        </button>
                        <button className='button' onClick={handleFilter}>
                          <p>Filter</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
              <div className='filter'>
                <CSVLink
                  data={data}
                  headers={headers}
                  filename={'transaction.csv'}
                  asyncOnClick={true}
                  onClick={handleExport}
                >
                  Export
                </CSVLink>
              </div>
            </div>
            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ 'width': '100%', fontSize: '14px' }}
                >
                  <MDBTableHead>
                    <tr className='font-reduce'>
                      <th scope='col'>S/N</th>
                      <th scope='email'>Customer Email</th>
                      <th scope='col'>Transaction Reference</th>
                      <th scope='col'>Transaction ID</th>
                      <th scope='col'>Pay Reference</th>
                      <th scope='col'>Number</th>
                      <th scope='col'>Amount</th>
                      <th scope='col'>Provider Reference</th>
                      <th scope='col'>Bill Token</th>
                      <th scope='col'>Date Created</th>
                      <th scope='col'>Status</th>
                      <th scope='col'>Remark</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      bills?.items?.map((item, key) => (
                        <tr key={key} className='font-reduce'>
                          <td>{key + 1 + bills?.page?.total_skipped}</td>
                          <td>{item?.customer_email}</td>
                          <td>{item?.transaction_reference}</td>
                          <td>{item?.transaction_id}</td>
                          <td>{item?.pay_reference}</td>
                          <td>{item?.number}</td>
                          <td>{item?.amount}</td>
                          <td>{item?.provider_reference}</td>
                          <td>{item?.bill_token}</td>
                          <td>{moment(item?.date_created).format('LLL')}</td>
                          <td>{item?.status}</td>
                          <td>{item?.remarks}</td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
                <Paginate
                  totPages={bills?.page?.total_page}
                  currentPage={bills?.page?.currentPage}
                  page={bills?.page?.currentPage}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default BillsPaymentScreen

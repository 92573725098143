import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Header from "../components/Header"
import Loader from "../components/Loader"
import Paginate from "../components/Paginate"
import SideNav from "../components/SideNav"
import { format, formatDate } from "../main"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import { CSVLink } from "react-csv"
import { fetchCustomerFailedVerfications } from "../actions/userActions"

const FailedVerificationsScreen = () => {
  const dispatch = useDispatch()
  const withdrawalRef = useRef(true)
  const [currPage, setCurrPage] = useState(1)
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const navigate = useNavigate()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [customertag, setCustomerTag] = useState("")
  const [type, setType] = useState("")
  const [status, setStatus] = useState("")
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const getFailedVerfications = useSelector(
    (state) => state.getFailedVerfications
  )
  const { loading, success, error, verifications } = getFailedVerfications

  useEffect(() => {
    if (userInfo?.user?.role === "Account Officer") {
      navigate(`/officer/${userInfo?.user?.id}`, { replace: true })
    }
    if (withdrawalRef.current) {
      dispatch(fetchCustomerFailedVerfications(currPage))
      withdrawalRef.current = false
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate("/performance", { replace: true })
    }
  }, [dispatch, loading, error, navigate, userInfo, currPage, success])

  // filter data

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      type,
      status,
      customertag
    }

    dispatch(fetchCustomerFailedVerfications(currPage, filterData))
  }

  const handleClear = () => {
    setType("")
    setStatus("")

    setStyles({
      navContainer: {
        display: "none"
      }
    })

    dispatch(fetchCustomerFailedVerfications(currPage))
  }

  const afterPageClicked = (page_number) => {
    if (page_number === currPage) {
      setCurrPage(page_number + 1)
    } else {
      setCurrPage(page_number)
    }

    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      type,
      status
    }
    if (page_number === "undefined1") {
      setCurrPage(1)
    }
    dispatch(fetchCustomerFailedVerfications(currPage, filterData))
  }

  const handleExport = (e, done) => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      type,
      status,
      customertag
    }
    dispatch(fetchCustomerFailedVerfications(currPage, filterData))
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> User Verifications | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Customer Verification(s)</h1>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "red",
                fontSize: "20px",
                letterSpacing: "1.5px"
              }}
            >
              {error && error}
            </p>
            <div className="filter-box">
              <div className="filter">
                <>
                  <div className="filter-container">
                    <p style={{ cursor: "pointer" }} onClick={handleClick}>
                      Filter
                    </p>
                    <div className="navContainer" style={styles.navContainer}>
                      <div className="date-filter">
                        <div className="date-title">Date</div>
                        <div className="date-options">
                          <div className="date-content">
                            <div className="date-items">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                              />

                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="status-container">
                        <div className="date-title">Other Filters</div>
                        <div className="checkbox checkbox--flex">
                          <div className="form-group">
                            <select
                              style={{
                                background: "#f4f6f8",
                                color: "#1a1a1a",
                                border: "none",
                                padding: "20px",
                                width: "380px",
                                borderRadius: "10px"
                              }}
                              onChange={(e) => setType(e.target.value)}
                            >
                              <option value="BVN,NIN,EMAIL">
                                Select Verfication Type
                              </option>

                              <option value="BVN">BVN</option>
                              <option value="NIN">NIN</option>
                              <option value="EMAIL">EMAIL</option>
                            </select>
                          </div>

                          <div className="form-group">
                            <select
                              style={{
                                background: "#f4f6f8",
                                color: "#1a1a1a",
                                border: "none",
                                padding: "20px",
                                width: "380px",
                                borderRadius: "10px"
                              }}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="1,2,3">
                                Select Verfication Status
                              </option>

                              <option value="I">Initiated</option>
                              <option value="C">Completed</option>
                              <option value="F">Failed</option>
                            </select>
                          </div>
                          <div
                            className="form-group"
                            style={{
                              width: "100%"
                            }}
                          >
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "1px solid grey",
                                fontSize: "14px"
                              }}
                              type="email"
                              className="email-input"
                              value={customertag}
                              onChange={(e) => setCustomerTag(e.target.value)}
                              placeholder="Enter pennTag or Merchanttag"
                            />{" "}
                          </div>
                        </div>
                      </div>
                      <div
                        className="status-container"
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center"
                        }}
                      >
                        <button className="button" onClick={handleClear}>
                          <p>Clear</p>
                        </button>
                        <button className="button" onClick={handleFilter}>
                          <p>Filter</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
              {/* <div className="filter">
                <CSVLink
                  data={data}
                  headers={headers}
                  filename={"transaction.csv"}
                  asyncOnClick={true}
                  onClick={handleExport}
                >
                  Export
                </CSVLink>
              </div> */}
            </div>
            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                >
                  <MDBTableHead>
                    <tr className="font-reduce">
                      <th scope="col">S/N</th>
                      <th scope="col">Type</th>
                      <th scope="col">Value</th>
                      <th scope="col">Status</th>
                      <th scope="col">FirstName</th>
                      <th scope="col">LastName</th>
                      <th scope="col">EmailAddress</th>
                      <th scope="col">PennTag</th>
                      <th scope="col">Mobile Number</th>
                      <th scope="col">Date Created</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      verifications?.items?.map((item, key) => (
                        <tr key={key} className="font-reduce">
                          <td>
                            {key + 1 + verifications?.page?.total_skipped}
                          </td>
                          <td>{item?.type}</td>
                          <td>{item?.value}</td>
                          <td>
                            {item?.status === "F"
                              ? "FAILED"
                              : item?.status === "I"
                              ? "INITIATED"
                              : item?.status === "C"
                              ? "COMPLETED"
                              : null}
                          </td>
                          <td>{item?.firstname}</td>
                          <td>{item?.lastname}</td>
                          <td>{item?.email_address}</td>
                          <td>{item?.username}</td>
                          <td>{item?.mobile_number}</td>

                          <td>{moment(item?.date_created).format("LLL")}</td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
                <Paginate
                  totPages={verifications?.page?.total_page}
                  currentPage={verifications?.page?.current_page}
                  page={verifications?.page?.current_page}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default FailedVerificationsScreen

import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Header from "../components/Header"
import Loader from "../components/Loader"
import Paginate from "../components/Paginate"
import SideNav from "../components/SideNav"
import { format, formatDate } from "../main"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"

import {
  setApproveRejectWithdrawal,
  fetchPendingWithdrawals,
  fetchFailedWithdrawals
} from "../actions/transactionActions"

const FailedPayoutsScreen = () => {
  const dispatch = useDispatch()
  const withdrawalRef = useRef(true)
  const [currPage, setCurrPage] = useState(1)
  const navigate = useNavigate()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [email, setEmail] = useState("")
  const [transactionReference, setTransactionReference] = useState("")
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const failedWithdrawal = useSelector((state) => state.failedWithdrawal)
  const { loading, success, error, withdrawals } = failedWithdrawal

  const approveRejectWithdrawal = useSelector(
    (state) => state.approveRejectWithdrawal
  )
  const {
    loading: approveRejectLoading,
    success: approveRejectSucess,
    error: approveRejectError
  } = approveRejectWithdrawal

  useEffect(() => {
    if (approveRejectSucess) {
      window.location.reload()
    }
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    if (!userInfo || userInfo === null) {
      navigate("/", { replace: true })
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate(`/officer/${userInfo?.user?.id}`, { replace: true })
    }
    if (withdrawalRef.current) {
      dispatch(fetchFailedWithdrawals(currPage))
      withdrawalRef.current = false
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate("/performance", { replace: true })
    }
  }, [
    dispatch,
    loading,
    error,
    navigate,
    userInfo,
    currPage,
    approveRejectSucess
  ])

  // filter data

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      transactionReference
    }

    dispatch(fetchFailedWithdrawals(currPage, filterData))
  }

  const handleClear = () => {
    setEmail("")

    setStyles({
      navContainer: {
        display: "none"
      }
    })

    dispatch(fetchFailedWithdrawals(currPage))
  }

  const afterPageClicked = (page_number) => {
    if (page_number === currPage) {
      setCurrPage(page_number + 1)
    } else {
      setCurrPage(page_number)
    }

    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,

      transactionReference
    }
    if (page_number === "undefined1") {
      setCurrPage(1)
    }
    dispatch(fetchFailedWithdrawals(currPage, filterData))
  }

  const approve = (id) => {
    const postData = {
      "payout_id": id,
      "is_approved": true,
      "failed": true
    }
    dispatch(setApproveRejectWithdrawal(postData))
  }

  const reject = (id) => {
    const postData = {
      "payout_id": id,
      "is_approved": false,
      "failed": true
    }
    dispatch(setApproveRejectWithdrawal(postData))
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Failed Withdrawals | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Failed Withdrawals</h1>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "red",
                fontSize: "20px",
                letterSpacing: "1.5px"
              }}
            >
              {error && error}
              {approveRejectError && approveRejectError}
            </p>
            <div className="filter-box">
              <div className="filter">
                <>
                  <div className="filter-container">
                    <p style={{ cursor: "pointer" }} onClick={handleClick}>
                      Filter
                    </p>
                    <div className="navContainer" style={styles.navContainer}>
                      <div className="date-filter">
                        <div className="date-title">Date</div>
                        <div className="date-options">
                          <div className="date-content">
                            <div className="date-items">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                              />

                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="status-container" style={{}}></div>

                      <div className="status-container">
                        <div className="date-title">Other Filters</div>
                        <div className="checkbox checkbox--flex">
                          <div
                            className="checkbox__item"
                            style={{
                              width: "100%"
                            }}
                          >
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "1px solid grey",
                                fontSize: "14px",
                                display: transactionReference ? "none" : "block"
                              }}
                              type="email"
                              className="email-input"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter customer Email"
                            />{" "}
                          </div>
                          <div
                            className="checkbox__item"
                            style={{
                              width: "100%"
                            }}
                          >
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "1px solid grey",
                                fontSize: "14px",
                                display: email ? "none" : "block"
                              }}
                              type="email"
                              className="email-input"
                              value={transactionReference}
                              onChange={(e) =>
                                setTransactionReference(e.target.value)
                              }
                              placeholder="Enter Transaction Reference"
                            />{" "}
                          </div>
                        </div>
                      </div>
                      <div
                        className="status-container"
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center"
                        }}
                      >
                        <button className="button" onClick={handleClear}>
                          <p>Clear</p>
                        </button>
                        <button className="button" onClick={handleFilter}>
                          <p>Filter</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                >
                  <MDBTableHead>
                    <tr className="font-reduce">
                      <th scope="col">S/N</th>
                      <th scope="col">Name</th>
                      <th scope="email">Customer Email</th>
                      <th scope="col">PennTag/Merchant ID</th>
                      <th scope="col">A/C Number</th>
                      <th scope="col">Pending Amount</th>
                      <th scope="col">Receipient</th>
                      <th scope="col">Account Balance</th>
                      <th scope="col">Merchant Balance</th>
                      <th scope="col">Current Balance</th>
                      <th scope="col">Transaction Reference</th>
                      <th scope="col">Date Created</th>
                      <th scope="col">Status</th>
                      <th scope="col">Remark</th>
                      <th scope="col">Action</th>
                      <th scope="col"></th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      withdrawals?.items?.map((item, key) => (
                        <tr key={key} className="font-reduce">
                          <td>{key}</td>
                          <td>{item?.customer_full_name}</td>
                          <td>{item?.customer_email}</td>
                          <td>{item?.username}</td>
                          <td>{item?.account_number}</td>
                          <td>{format(item?.amount)}</td>
                          <td>{item?.recipient}</td>
                          <td>{format(item?.wallet_balance)}</td>
                          <td>{format(item?.merchant_wallet_balance)}</td>
                          <td>{format(item?.current_balance)}</td>
                          <td>{item?.transaction_reference}</td>
                          <td>{moment(item?.date_created).format("LLL")}</td>
                          <td>{item?.status}</td>
                          <td>{item?.remarks}</td>

                          {(userInfo?.user?.role === "Admin" ||
                            userInfo?.user?.role === "Operations") &&
                          approveRejectLoading ? (
                            <Loader />
                          ) : (
                            <>
                              <td>
                                <button
                                  className="withdrawal-btn"
                                  onClick={() => approve(item?.id)}
                                >
                                  Retry
                                </button>
                              </td>
                              <td>
                                <button
                                  className="withdrawal-btn withdrawal-red"
                                  onClick={() => reject(item?.id)}
                                >
                                  {" "}
                                  Reject
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
                <Paginate
                  totPages={withdrawals?.page?.total_page}
                  currentPage={String(withdrawals?.page?.currentPage)}
                  page={String(withdrawals?.page?.currentPage)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default FailedPayoutsScreen

import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import React, { useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  fetchOfficers,
  fetchSingleOfficerDetails
} from "../actions/adminActions"
import Header from "../components/Header"
import Loader from "../components/Loader"
import Paginate from "../components/Paginate"
import SideNav from "../components/SideNav"
import { calculatePercentage, format } from "../main"

const PerformanceScreen = () => {
  const dispatch = useDispatch()
  const performanceRef = useRef(true)
  const navigate = useNavigate()

  const getOfficers = useSelector((state) => state.getOfficers)
  const { loading, success, error, officers } = getOfficers

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  useEffect(() => {
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    if (!userInfo || userInfo === null) {
      navigate("/", { replace: true })
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate(`/officer/${userInfo?.user?.id}`, { replace: true })
    }
    if (performanceRef.current) {
      dispatch(fetchOfficers())
      dispatch(fetchSingleOfficerDetails())
      performanceRef.current = false
    }
  }, [loading, success, error, userInfo, navigate, dispatch])
  const afterPageClicked = (page_number) => {}
  return (
    <>
      <SideNav />
      <Helmet>
        <title> Performance | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Performance</h1>
            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                >
                  <MDBTableHead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col">Account Officer Name</th>
                      <th scope="col">Staff Id</th>
                      <th scope="col">No. Of Customers</th>
                      <th scope="col">No. Of Merchants</th>
                      <th scope="col">No. Of Unfunded Accounts</th>
                      <th scope="col">No. Of Funded Accounts</th>
                      <th scope="col">% Of Unfunded Accounts</th>
                      <th scope="col">Individual Account Float</th>
                      <th scope="col">Merchant Float</th>
                      <th scope="col">Personal Vault Float</th>
                      <th scope="col">Total Float</th>
                      <th scope="col">Average Float</th>
                      <th scope="col">Leaderboard</th>
                      <th scope="col">Action</th>
                      <th scope="col"></th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {console.log(
                      officers?.items?.sort(
                        (a, b) => a.leader_board - b.leader_board
                      )
                    )}
                    {success &&
                      officers?.items?.map((tran, key) => (
                        <tr key={key}>
                          <td>{key}</td>
                          <td>
                            <a href={`/officer/${tran?.id}`}>{tran?.name}</a>
                          </td>
                          <td>{tran?.referal_code}</td>
                          <td>{tran?.no_of_accounts}</td>
                          <td>{tran?.no_of_merchants}</td>
                          <td>{tran?.no_of_unfunded_accounts}</td>
                          <td>
                            {tran?.no_of_accounts -
                              tran?.no_of_unfunded_accounts}
                          </td>
                          <td>
                            {calculatePercentage(
                              tran?.no_of_accounts,
                              tran?.no_of_unfunded_accounts
                            )}
                            %
                          </td>
                          <td>{format(tran?.individual_account_float)}</td>
                          <td>{format(tran?.merchant_float)}</td>
                          <td>{format(tran?.personal_vault_float)}</td>
                          <td>{format(tran?.total_float)}</td>
                          <td>{format(tran?.avg_float)}</td>
                          <td>{tran?.leader_board}</td>
                          <td>
                            <button className="withdrawal-btn">
                              <a href={`/officer/${tran?.id}`}>View</a>
                            </button>
                          </td>
                          <td></td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
                <Paginate
                  totPages={officers?.page?.total_page}
                  currentPage={String(officers?.page?.currentPage)}
                  page={String(officers?.page?.currentPage)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default PerformanceScreen

import axios from "axios";
import {
  ADD_MEMOS_FAIL,
  ADD_MEMOS_REQUEST,
  ADD_MEMOS_SUCCESS,
  APPROVE_BUSINESS_FAIL,
  APPROVE_BUSINESS_REQUEST,
  APPROVE_BUSINESS_SUCCESS,
  BUSINESS_DOCUMENT_FAIL,
  BUSINESS_DOCUMENT_REQUEST,
  BUSINESS_DOCUMENT_SUCCESS,
  GET_BUSINESS_WALLET_FAIL,
  GET_BUSINESS_WALLET_REQUEST,
  GET_BUSINESS_WALLET_SUCCESS,
  GET_CUSTOMER_BUSINESS_FAIL,
  GET_CUSTOMER_BUSINESS_REQUEST,
  GET_CUSTOMER_BUSINESS_SUCCESS,
  GET_MEMOS_FAIL,
  GET_MEMOS_REQUEST,
  GET_MEMOS_SUCCESS,
  MERCHANT_BALANCES_FAIL,
  MERCHANT_BALANCES_REQUEST,
  MERCHANT_BALANCES_SUCCESS,
  PENDING_BUSINESS_FAIL,
  PENDING_BUSINESS_REQUEST,
  PENDING_BUSINESS_SUCCESS,
  VIEW_DOCUMENTS_FAIL,
  VIEW_DOCUMENTS_REQUEST,
  VIEW_DOCUMENTS_SUCCESS,
} from "../constants/adminConstants";
import { BASE_API_URL } from "../constants/dropdown";

export const fetchPendingBusiness =
  (pageNumber, filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PENDING_BUSINESS_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const page = isNaN(pageNumber) ? 1 : pageNumber;

      if (filterData === undefined) {
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/customer/management/approvals?size=${400}&page=${page}`,
          config
        );

        dispatch({
          type: PENDING_BUSINESS_SUCCESS,
          payload: data,
        });
      } else {
        const year = new Date().getFullYear();
        const date = new Date(year, 0, 1);
        const { endDate, customeremail, penntag } = filterData;

        console.log(filterData);

        const startDate =
          filterData.startDate === undefined ? date : filterData.startDate;

        const checkboxArray = [
          {
            name: filterData.verified,
            status: "verified",
          },
          {
            name: filterData.New,
            status: "New",
          },
          {
            name: filterData.rejected,
            status: "Rejected",
          },
        ];
        const filtered = checkboxArray.filter((c) => c.name === true);
        const mapped = filtered.map((m) => m.status);
        const statusVal = mapped.toString();

        if (filtered.length > 0) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/approvals?status=${statusVal}`,
            config
          );

          dispatch({
            type: PENDING_BUSINESS_SUCCESS,
            payload: data,
          });
        } else if (customeremail) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/approvals?customeremail=${customeremail}`,
            config
          );
          console.log("here");
          console.log(data);

          dispatch({
            type: PENDING_BUSINESS_SUCCESS,
            payload: data,
          });
        } else if (penntag) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/approvals?penntag=${penntag}`,
            config
          );

          dispatch({
            type: PENDING_BUSINESS_SUCCESS,
            payload: data,
          });
        } else {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/approvals?size=300&page=${page}&fromdate=${startDate}&todate=${endDate}`,
            config
          );

          dispatch({
            type: PENDING_BUSINESS_SUCCESS,
            payload: data,
          });
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: PENDING_BUSINESS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchBusinessDocument =
  (customerId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BUSINESS_DOCUMENT_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BASE_API_URL}/admin/customer/management/documents/${customerId}`,
        config
      );

      dispatch({
        type: BUSINESS_DOCUMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: BUSINESS_DOCUMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const setApproveRejectBusiness =
  (postData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: APPROVE_BUSINESS_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { id, is_approved } = postData;

      // getting our response as we set it in our userController in the backend
      const { data } = await axios.post(
        `${BASE_API_URL}/admin/customer/management/approve/business`,
        { id, is_approved },
        config
      );

      dispatch({
        type: APPROVE_BUSINESS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: APPROVE_BUSINESS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchSingleDocument =
  (field, fileaccess) => async (dispatch, getState) => {
    try {
      dispatch({
        type: VIEW_DOCUMENTS_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.file_token;

      console.log(token);

      const { data } = await axios.get(
        `${BASE_API_URL}/admin/customer/documents/verification-documents/download?fileId=${field}&fileaccess=${token}`
      );

      dispatch({
        type: VIEW_DOCUMENTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: VIEW_DOCUMENTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const setMemo = (postData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_MEMOS_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { customer_id, remark } = postData;

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.post(
      `${BASE_API_URL}/admin/customer/management/memos/add`,
      { customer_id, remark },
      config
    );

    dispatch({
      type: ADD_MEMOS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ADD_MEMOS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchCustomerMemos =
  (customerId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_MEMOS_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BASE_API_URL}/admin/customer/management/memos/${customerId}`,
        config
      );

      dispatch({
        type: GET_MEMOS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_MEMOS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message ?? error.response,
      });
    }
  };

export const fetchMerchantBalances =
  (pageNumber, filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MERCHANT_BALANCES_REQUEST,
      });

      const {
        admin2faLogin: { userInfo },
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const page = isNaN(pageNumber) ? 1 : pageNumber;

      if (filterData === undefined) {
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/transaction/all/merchant/balances?size=300&page=${page}&balance=false`,
          config
        );

        dispatch({
          type: MERCHANT_BALANCES_SUCCESS,
          payload: data,
        });
      } else {
        const year = new Date().getFullYear();
        const date = new Date(year, 0, 1);
        const { endDate, email, penntag, exported, byBalance } = filterData;

        const startDate =
          filterData.startDate === undefined ? date : filterData.startDate;

        if (email) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all/merchant/balances?customeremail=${email}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: MERCHANT_BALANCES_SUCCESS,
            payload: data,
          });
        } else if (penntag) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all/merchant/balances?penntag=${penntag}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: MERCHANT_BALANCES_SUCCESS,
            payload: data,
          });
        } else if (byBalance) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all/merchant/balances?balance=${byBalance}`,
            config
          );

          dispatch({
            type: MERCHANT_BALANCES_SUCCESS,
            payload: data,
          });
        } else if (exported === true) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all/merchant/balances?fromdate=${startDate}&todate=${endDate}&export=${exported}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: MERCHANT_BALANCES_SUCCESS,
            payload: data,
          });
        } else {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all/merchant/balances?size=300&page=${page}&fromdate=${startDate}&todate=${endDate}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: MERCHANT_BALANCES_SUCCESS,
            payload: data,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: MERCHANT_BALANCES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message ?? error.response,
      });
    }
  };

export const fetchCustomerBusiness =
  (customerId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_CUSTOMER_BUSINESS_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BASE_API_URL}/admin/customer/businesses?customerid=${customerId}`,
        config
      );

      dispatch({
        type: GET_CUSTOMER_BUSINESS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOMER_BUSINESS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message ?? error.response,
      });
    }
  };

export const fetchBusinessWallet =
  (customerId, businessId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_BUSINESS_WALLET_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BASE_API_URL}/admin/customer/business/wallet?customerid=${customerId}&businessid=${businessId}`,
        config
      );

      dispatch({
        type: GET_BUSINESS_WALLET_SUCCESS,
        payload: { businessid: businessId, wallet: data },
      });
    } catch (error) {
      dispatch({
        type: GET_BUSINESS_WALLET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message ?? error.response,
      });
    }
  };

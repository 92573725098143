import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Header from "../components/Header"
import Loader from "../components/Loader"
import Paginate from "../components/Paginate"
import SideNav from "../components/SideNav"
import { format, formatDate } from "../main"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"

import { setApproveRejectWithdrawal } from "../actions/transactionActions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import {
  fetchPendingTreegarInvestmentAssest,
  setApproveRejectInvestment
} from "../actions/adminActions"

const TreegarPendingInvestmentScreen = () => {
  const dispatch = useDispatch()
  const withdrawalRef = useRef(true)
  const [currPage, setCurrPage] = useState(1)
  const navigate = useNavigate()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [email, setEmail] = useState("")
  const [transactionReference, setTransactionReference] = useState("")
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const treegarPendingInvestment = useSelector(
    (state) => state.treegarPendingInvestment
  )
  const { loading, success, error, pending } = treegarPendingInvestment

  const approveRejectInvestment = useSelector(
    (state) => state.approveRejectInvestment
  )
  const {
    loading: approveRejectLoading,
    success: approveRejectSucess,
    error: approveRejectError
  } = approveRejectInvestment

  useEffect(() => {
    if (approveRejectSucess) {
      window.location.reload()
    }
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    if (!userInfo || userInfo === null) {
      navigate("/", { replace: true })
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate(`/officer/${userInfo?.user?.id}`, { replace: true })
    }
    if (withdrawalRef.current) {
      dispatch(fetchPendingTreegarInvestmentAssest(currPage))
      withdrawalRef.current = false
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate("/performance", { replace: true })
    }
  }, [
    dispatch,
    loading,
    error,
    navigate,
    userInfo,
    currPage,
    approveRejectSucess
  ])

  const afterPageClicked = (page_number) => {
    if (page_number === currPage) {
      setCurrPage(page_number + 1)
    } else {
      setCurrPage(page_number)
    }

    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,

      transactionReference
    }
    if (page_number === "undefined1") {
      setCurrPage(1)
    }
    dispatch(fetchPendingTreegarInvestmentAssest(currPage, filterData))
  }

  const approve = (id) => {
    const postData = {
      "id": id,
      "approved_by": userInfo?.user?.id,
      "approve": true
    }
    dispatch(setApproveRejectInvestment(postData))
  }

  const reject = (id) => {
    const postData = {
      "id": id,
      "approved_by": userInfo?.user?.id,
      "approve": false
    }
    dispatch(setApproveRejectInvestment(postData))
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Pending Investments | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Pending Investments</h1>
            <div
              className="status-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            ></div>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "red",
                fontSize: "20px",
                letterSpacing: "1.5px"
              }}
            >
              {error && error}
              {approveRejectError && approveRejectError}
            </p>

            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                  align="middle"
                >
                  <MDBTableHead>
                    <tr className="font-reduce">
                      <th scope="col">Investment Id</th>
                      <th scope="col">Status</th>
                      <th scope="col">Customer Name</th>
                      <th scope="col">Inflow</th>
                      <th scope="col">Effective Date</th>
                      <th scope="col">Tenor(days)</th>
                      <th scope="col">Maturity Date</th>
                      <th scope="col">Interest Expense</th>
                      <th scope="col">Accrued Interest Expense</th>
                      <th scope="col">Gross Interest Payout At Maturity</th>
                      <th scope="col">WHT Deduction</th>
                      <th scope="col">Charges</th>
                      <th scope="col">Net Interest Payout At Maturity</th>
                      <th scope="col">Withdrawable Amount At Maturity</th>
                      <th scope="col">Action</th>
                      <th scope="col"></th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      pending?.items?.map((i, key) => (
                        <tr key={key} className="font-reduce">
                          <td>{i?.investment_tag}</td>
                          <td>{i?.status}</td>
                          <td>{i?.customer_name}</td>
                          <td>{format(i?.inflow)}</td>
                          <td>{moment(i?.effective_date).format("LLL")}</td>
                          <td>{i?.tenor}days</td>
                          <td>{moment(i?.maturity_date).format("LLL")}</td>

                          <td>{i?.interest_expense}%</td>
                          <td>{format(i?.accrued_interest_expense)}</td>
                          <td>{format(i?.gross_interest_payout)}</td>
                          <td>{format(i?.wht_deduction)}</td>
                          <td>{format(i?.charges)}</td>
                          <td>{format(i?.net_interest_payout)}</td>
                          <td>{format(i?.withdrawal_amount)}</td>
                          {(userInfo?.user?.role === "Admin" ||
                            userInfo?.user?.role === "TREEGAR") &&
                          approveRejectLoading ? (
                            <Loader />
                          ) : (
                            <>
                              <td>
                                <button
                                  className="withdrawal-btn"
                                  onClick={() => approve(i?.id)}
                                >
                                  Approve
                                </button>
                              </td>
                              <td>
                                <button
                                  className="withdrawal-btn withdrawal-red"
                                  onClick={() => reject(i?.id)}
                                >
                                  {" "}
                                  Reject
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
                <Paginate
                  totPages={pending?.page?.total_page}
                  currentPage={String(pending?.page?.currentPage)}
                  page={String(pending?.page?.currentPage)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default TreegarPendingInvestmentScreen

import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { format, formatDate } from "../main"
import { CSVLink } from "react-csv"
import Loader from "./Loader"
import { fetchCommisssions } from "../actions/dashboardActions"
import { Link } from "react-router-dom"

const CommissionReceived = () => {
  const commissionsRef = useRef(true)
  const dispatch = useDispatch()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const totalCommsion = useSelector((state) => state.totalCommsion)
  const { loading, success, error, commissions } = totalCommsion

  useEffect(() => {
    if (commissionsRef.current) {
      dispatch(fetchCommisssions())
      commissionsRef.current = false
    }
    // if (
    //   (!loading && error?.message === 'Please login again') ||
    //   error?.message === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    // }
    if (success && commissions) {
      setData([
        {
          flutterwave_transfer_expenses:
            commissions?.flutterwave_transfer_expenses,
          flutterwave_transfers_revenue:
            commissions?.flutterwave_transfers_revenue,
          flutterwave_transfers_income:
            commissions?.flutterwave_transfers_income,
          airtime_commission: commissions?.flw_airtime_commission,
          electricity_commission: commissions?.electricity_commission,
          tv_commission: commissions?.tv_commission,
          betting_commission: commissions?.betting_commission,
          paystack_transfer_expenses: commissions?.paystack_transfer_expenses,
          paystack_transfers_revenue: commissions?.paystack_transfers_revenue,
          paystack_transfers_income: commissions?.paystack_transfers_income,
          payaza_transfer_expenses: commissions?.payaza_transfer_expenses,
          payaza_transfers_revenue: commissions?.payaza_transfers_revenue,
          payaza_transfers_income: commissions?.payaza_transfers_income,
          vfd_transfer_expenses: commissions?.vfd_transfer_expenses,
          vfd_transfers_revenue: commissions?.vfd_transfers_revenue,
          vfd_transfers_income: commissions?.vfd_transfers_income,
          seerbit_transfer_expenses: commissions?.seerbit_transfer_expenses,
          seerbit_transfers_revenue: commissions?.seerbit_transfers_revenue,
          seerbit_transfers_income: commissions?.seerbit_transfers_income,
          paystack_target_savings_expenses:
            commissions?.paystack_target_savings_expenses,
          seerbit_target_savings_expenses:
            commissions?.seerbit_target_savings_expense
        }
      ])

      setHeaders([
        {
          label: "Flutterwave Transfer Expense",
          key: "flutterwave_transfer_expenses"
        },
        {
          label: "Flutterwave Transfers Revenue",
          key: "flutterwave_transfers_revenue"
        },
        {
          label: "Flutterwave Transfers income",
          key: "flutterwave_transfers_income"
        },
        { label: "Airtime Commission", key: "airtime_commission" },
        { label: "Electricity Commission", key: "electricity_commission" },
        { label: "Tv Commission", key: "tv_commission" },
        { label: "Betting Commsio", key: "betting_commission" },
        {
          label: "paystack Transfer Expense",
          key: "paystack_transfer_expenses"
        },
        {
          label: "paystack Transfers Revenue",
          key: "paystack_transfers_revenue"
        },
        {
          label: "paystack Transfers income",
          key: "paystack_transfers_income"
        },
        {
          label: "payaza Transfer Expense",
          key: "payaza_transfer_expenses"
        },
        {
          label: "payaza Transfers Revenue",
          key: "payaza_transfers_revenue"
        },
        {
          label: "payaza Transfers income",
          key: "payaza_transfers_income"
        },
        {
          label: "vfd Transfer Expense",
          key: "vfd_transfer_expenses"
        },
        {
          label: "vfd Transfers Revenue",
          key: "vfd_transfers_revenue"
        },
        {
          label: "vfd Transfers income",
          key: "vfd_transfers_income"
        },
        {
          label: "seerbit Transfer Expense",
          key: "seerbit_transfer_expenses"
        },
        {
          label: "seerbit Transfers Revenue",
          key: "seerbit_transfers_revenue"
        },
        {
          label: "seerbit Transfers income",
          key: "seerbit_transfers_income"
        },
        {
          label: "paystack target savings expenses",
          key: "paystack_target_savings_expenses"
        },
        {
          label: "seerbit target savings expenses",
          key: "seerbit_target_savings_expenses"
        }
      ])
    }
  }, [commissions, success, dispatch, loading, error])

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
    dispatch(fetchCommisssions(filterData))
  }

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    dispatch(fetchCommisssions())
  }

  return (
    <>
      <div className="filter-box">
        <div className="filter">
          <>
            <div className="filter-container">
              <p style={{ cursor: "pointer" }} onClick={handleClick}>
                Filter
              </p>
              <div className="navContainer" style={styles.navContainer}>
                <div className="date-filter">
                  <div className="date-title">Date</div>
                  <div className="date-options">
                    <div className="date-content">
                      <div className="date-items">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="status-container"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                  }}
                >
                  <button className="button" onClick={handleClear}>
                    <p>Clear</p>
                  </button>
                  <button className="button" onClick={handleFilter}>
                    <p>Filter</p>
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
        <div className="filter">
          <Link to="/commissions-breakdown">Export</Link>
        </div>
      </div>
      <div className="accordion-item">
        <h3 className="accordion-header" id="h3">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#commission"
            aria-expanded="true"
            aria-controls="commission"
          >
            Commission Received
          </button>
        </h3>
        <div
          id="commission"
          className="accordion-collapse collapse show"
          aria-labelledby="h3"
        >
          {loading ? (
            <Loader />
          ) : (
            <div className="accordion-body">
              <div className="row">
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.flutterwave_transfer_expenses)}
                    </div>
                    <p>Flutterwave Transfer Expense</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.flutterwave_transfers_revenue)}
                    </div>
                    <p>Flutterwave Transfer Revenue</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.flutterwave_transfers_income)}
                    </div>
                    <p>Flutterwave Transfer Income</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.flw_airtime_commission)}
                    </div>
                    <p>Flutterwave Airtime Commission</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.electricity_commission)}
                    </div>
                    <p>Electricity Commission</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.tv_commission)}
                    </div>
                    <p>TV Subs. Commission</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {" "}
                      {format(commissions?.betting_commission)}
                    </div>
                    <p>Betting Commission</p>
                  </div>
                </div>
                {/* <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {success &&
                        commissions &&
                        format(
                          Object.values(commissions).reduce(
                            (t, value) => t + value,
                            0
                          )
                        )}
                    </div>
                    <p>Total Commission</p>
                  </div>
                </div> */}
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.vfd_transfer_expenses)}
                    </div>
                    <p>VFD Transfer Expenses</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.vfd_transfers_revenue)}
                    </div>
                    <p>VFD Transfer Revenue</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.vfd_transfers_income)}
                    </div>
                    <p>VFD Transfer Income</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.paystack_transfer_expenses)}
                    </div>
                    <p>Paystack Transfer Expense</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.paystack_transfer_revenue)}
                    </div>
                    <p>Paystack Transfer Revenue</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.paystack_transfers_income)}
                    </div>
                    <p>Paystack Transfer Income</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.payaza_transfer_expenses)}
                    </div>
                    <p>Payaza Transfer Expenses</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.payaza_transfer_revenue)}
                    </div>
                    <p>Payaza Transfer Revenue</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.payaza_transfers_income)}
                    </div>
                    <p>Payaza Transfer Income</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.seerbit_transfer_expenses)}
                    </div>
                    <p>Seerbit Transfer Expense</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.seerbit_transfer_revenue)}
                    </div>
                    <p>Seerbit Transfer Revenue</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.seerbit_transfers_income)}
                    </div>
                    <p>Seerbit Transfer Income</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.paystack_target_savings_expenses)}
                    </div>
                    <p>Paystack Target Savings Expense</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {format(commissions?.seerbit_target_savings_expenses)}
                    </div>
                    <p>Seerbit Target Savings Expense</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default CommissionReceived

import {
  ADMIN_2FA_LOGIN_FAIL,
  ADMIN_2FA_LOGIN_REQUEST,
  ADMIN_2FA_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGOUT,
  ALL_TRANSACTIONS_FAIL,
  ALL_TRANSACTIONS_REQUEST,
  ALL_TRANSACTIONS_SUCCESS,
  FAILED_DEBITS_FAIL,
  FAILED_DEBITS_REQUEST,
  FAILED_DEBITS_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  INVESTORS_COUNT_FAIL,
  INVESTORS_COUNT_REQUEST,
  INVESTORS_COUNT_SUCCESS,
  LOCKED_FUNDS_FAIL,
  LOCKED_FUNDS_REQUEST,
  LOCKED_FUNDS_SUCCESS,
  RESEND_OTP_FAIL,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  SINGLE_USER_FAIL,
  SINGLE_USER_REQUEST,
  SINGLE_USER_SUCCESS,
  TRANSFER_TOTAL_FAIL,
  TRANSFER_TOTAL_REQUEST,
  TRANSFER_TOTAL_SUCCESS,
  USER_ACCOUNT_FAIL,
  USER_ACCOUNT_REQUEST,
  USER_ACCOUNT_SUCCESS,
  USER_CARD_FAIL,
  USER_CARD_REQUEST,
  USER_CARD_SUCCESS,
  USER_COUNT_FAIL,
  USER_COUNT_REQUEST,
  USER_COUNT_SUCCESS,
  USER_SAVINGS_REQUEST,
  USER_SAVINGS_SUCCESS,
  USER_SAVINGS_FAIL,
  USER_VAULTS_FAIL,
  USER_VAULTS_REQUEST,
  USER_VAULTS_SUCCESS,
  CUSTOMERS_DETAILS_REQUEST,
  CUSTOMERS_DETAILS_SUCCESS,
  CUSTOMERS_DETAILS_FAIL,
  ALL_INVESTMENTS_REQUEST,
  ALL_INVESTMENTS_SUCCESS,
  ALL_INVESTMENTS_FAIL,
  CREATE_INVESTMENT_REQUEST,
  CREATE_INVESTMENT_SUCCESS,
  CREATE_INVESTMENT_FAIL,
  CUSTOMER_INVESTMENT_REQUEST,
  CUSTOMER_INVESTMENT_SUCCESS,
  CUSTOMER_INVESTMENT_FAIL,
  TOTAL_COMMISSION_REQUEST,
  TOTAL_COMMISSION_SUCCESS,
  TOTAL_COMMISSION_FAIL,
  BILLS_REQUEST,
  BILLS_SUCCESS,
  BILLS_FAIL,
  BUSINESS_METRICS_REQUEST,
  BUSINESS_METRICS_SUCCESS,
  BUSINESS_METRICS_FAIL,
  PENDING_WITHDRAWAL_REQUEST,
  PENDING_WITHDRAWAL_SUCCESS,
  PENDING_WITHDRAWAL_FAIL,
  GET_OFFICERS_REQUEST,
  GET_OFFICERS_SUCCESS,
  GET_OFFICERS_FAIL,
  ACCOUNT_OFFICER_DETAILS_REQUEST,
  ACCOUNT_OFFICER_DETAILS_SUCCESS,
  ACCOUNT_OFFICER_DETAILS_FAIL,
  TEAM_MEMBERS_REQUEST,
  TEAM_MEMBERS_SUCCESS,
  TEAM_MEMBERS_FAIL,
  GET_ROLES_REQUEST,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_FAIL,
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAIL,
  CREATE_MEMBER_REQUEST,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_FAIL,
  ASSIGN_OFFICER_REQUEST,
  ASSIGN_OFFICER_SUCCESS,
  ASSIGN_OFFICER_FAIL,
  DEASSIGN_OFFICER_REQUEST,
  DEASSIGN_OFFICER_SUCCESS,
  DEASSIGN_OFFICER_FAIL,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAIL,
  TRANSACTION_STATUS_REQUEST,
  TRANSACTION_STATUS_SUCCESS,
  TRANSACTION_STATUS_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  APPROVE_REJECT_WITHDRAWAL_REQUEST,
  APPROVE_REJECT_WITHDRAWAL_SUCCESS,
  APPROVE_REJECT_WITHDRAWAL_FAIL,
  PENDING_BUSINESS_REQUEST,
  PENDING_BUSINESS_SUCCESS,
  PENDING_BUSINESS_FAIL,
  TOTAL_INTEREST_REQUEST,
  TOTAL_INTEREST_SUCCESS,
  TOTAL_INTEREST_FAIL,
  BUSINESS_DOCUMENT_REQUEST,
  BUSINESS_DOCUMENT_SUCCESS,
  BUSINESS_DOCUMENT_FAIL,
  APPROVE_BUSINESS_REQUEST,
  APPROVE_BUSINESS_SUCCESS,
  APPROVE_BUSINESS_FAIL,
  PRODUCT_TYPES_REQUEST,
  PRODUCT_TYPES_SUCCESS,
  PRODUCT_TYPES_FAIL,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAIL,
  FETCH_CUSTOMERS_RESET,
  VIEW_DOCUMENTS_REQUEST,
  VIEW_DOCUMENTS_SUCCESS,
  VIEW_DOCUMENTS_FAIL,
  ADD_MEMOS_REQUEST,
  ADD_MEMOS_SUCCESS,
  ADD_MEMOS_FAIL,
  GET_MEMOS_REQUEST,
  GET_MEMOS_SUCCESS,
  GET_MEMOS_FAIL,
  ROLES_PERMISSIONS_REQUEST,
  ROLES_PERMISSIONS_SUCCESS,
  ROLES_PERMISSIONS_FAIL,
  ALL_BALANCES_REQUEST,
  ALL_BALANCES_SUCCESS,
  ALL_BALANCES_FAIL,
  MERCHANT_BALANCES_REQUEST,
  MERCHANT_BALANCES_SUCCESS,
  MERCHANT_BALANCES_FAIL,
  DELETE_INVESTMENT_REQUEST,
  DELETE_INVESTMENT_SUCCESS,
  DELETE_INVESTMENT_FAIL,
  UPDATE_USER_STATUS_REQUEST,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAIL,
  UPDATE_USER_ROLE_REQUEST,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAIL,
  GET_CUSTOMER_TRANSACTION_REQUEST,
  GET_CUSTOMER_TRANSACTION_SUCCESS,
  GET_CUSTOMER_TRANSACTION_FAIL,
  GET_SINGLE_OFFICER_REQUEST,
  GET_SINGLE_OFFICER_SUCCESS,
  GET_SINGLE_OFFICER_FAIL,
  GET_SINGLE_OFFICER_INFO_REQUEST,
  GET_SINGLE_OFFICER_INFO_SUCCESS,
  GET_SINGLE_OFFICER_INFO_FAIL,
  SET_CUSTOMER_LIMIT_REQUEST,
  SET_CUSTOMER_LIMIT_SUCCESS,
  SET_CUSTOMER_LIMIT_FAIL,
  SET_CUSTOMER_AUTOMATION_REQUEST,
  SET_CUSTOMER_AUTOMATION_SUCCESS,
  SET_CUSTOMER_AUTOMATION_FAIL,
  GET_CUSTOMER_LIMIT_REQUEST,
  GET_CUSTOMER_LIMIT_SUCCESS,
  GET_CUSTOMER_LIMIT_FAIL,
  GET_TARGET_SAVINGS_OFFICER_REQUEST,
  GET_TARGET_SAVINGS_OFFICER_SUCCESS,
  GET_TARGET_SAVINGS_OFFICER_FAIL,
  GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_REQUEST,
  GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_SUCCESS,
  GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_FAIL,
  FAILED_WITHDRAWAL_REQUEST,
  FAILED_WITHDRAWAL_SUCCESS,
  FAILED_WITHDRAWAL_FAIL,
  ALL_PAYOUTS_REQUEST,
  ALL_PAYOUTS_SUCCESS,
  ALL_PAYOUTS_FAIL,
  GET_ALL_TARGET_SAVINGS_REQUEST,
  GET_ALL_TARGET_SAVINGS_SUCCESS,
  GET_ALL_TARGET_SAVINGS_FAIL,
  TARGET_SAVINGS_METRICS_REQUEST,
  TARGET_SAVINGS_METRICS_SUCCESS,
  TARGET_SAVINGS_METRICS_FAIL,
  GET_ALL_TARGET_SAVINGS_RESET,
  GET_ALL_BILLS_REQUEST,
  GET_ALL_BILLS_SUCCESS,
  GET_ALL_BILLS_FAIL,
  GET_TRANSACTION_SETTINGS_REQUEST,
  GET_TRANSACTION_SETTINGS_SUCCESS,
  GET_TRANSACTION_SETTINGS_FAIL,
  ENABLE_DISABLE_TRANSACTION_REQUEST,
  ENABLE_DISABLE_TRANSACTION_SUCCESS,
  ENABLE_DISABLE_TRANSACTION_FAIL,
  GET_PROVIDERS_REQUEST,
  GET_PROVIDERS_SUCCESS,
  GET_PROVIDERS_FAIL,
  ENABLE_DISABLE_PROVIDER_REQUEST,
  ENABLE_DISABLE_PROVIDER_SUCCESS,
  ENABLE_DISABLE_PROVIDER_FAIL,
  TARGET_SAVINGS_LOGS_REQUEST,
  TARGET_SAVINGS_LOGS_SUCCESS,
  TARGET_SAVINGS_LOGS_FAIL,
  GET_INVESTMENT_RATES_REQUEST,
  GET_INVESTMENT_RATES_SUCCESS,
  GET_INVESTMENT_RATES_FAIL,
  UPDATE_INVESTMENT_RATES_REQUEST,
  UPDATE_INVESTMENT_RATES_SUCCESS,
  UPDATE_INVESTMENT_RATES_FAIL,
  DEACTIVATE_CUSTOMER_REQUEST,
  DEACTIVATE_CUSTOMER_SUCCESS,
  DEACTIVATE_CUSTOMER_FAIL,
  GET_POS_METRICS_REQUEST,
  GET_POS_METRICS_SUCCESS,
  GET_POS_METRICS_FAIL,
  GET_CUSTOMER_CARDS_REQUEST,
  GET_CUSTOMER_CARDS_SUCCESS,
  GET_CUSTOMER_CARDS_FAIL,
  DELETE_CUSTOMER_CARD_REQUEST,
  DELETE_CUSTOMER_CARD_SUCCESS,
  DELETE_CUSTOMER_CARD_FAIL,
  ALL_TARGET_SAVINGS_LOGS_REQUEST,
  ALL_TARGET_SAVINGS_LOGS_SUCCESS,
  ALL_TARGET_SAVINGS_LOGS_FAIL,
  CUSTOMER_INVESTMENT_EXPIRED_REQUEST,
  CUSTOMER_INVESTMENT_EXPIRED_SUCCESS,
  CUSTOMER_INVESTMENT_EXPIRED_FAIL,
  GET_INTEREST_INFO_REQUEST,
  GET_INTEREST_INFO_SUCCESS,
  GET_INTEREST_INFO_FAIL,
  GET_INTEREST_REQUEST,
  GET_INTEREST_SUCCESS,
  GET_INTEREST_FAIL,
  GET_TRANSACTION_COUNT_REQUEST,
  GET_TRANSACTION_COUNT_SUCCESS,
  GET_TRANSACTION_COUNT_FAIL,
  CREATE_TREEGAR_INVESTMENT_REQUEST,
  CREATE_TREEGAR_INVESTMENT_SUCCESS,
  CREATE_TREEGAR_INVESTMENT_FAIL,
  GET_TREEGAR_INVESTMENT_REQUEST,
  GET_TREEGAR_INVESTMENT_SUCCESS,
  GET_TREEGAR_INVESTMENT_FAIL,
  GET_TREEGAR_INVESTMENT_CONSTANT_REQUEST,
  GET_TREEGAR_INVESTMENT_CONSTANT_SUCCESS,
  GET_TREEGAR_INVESTMENT_CONSTANT_FAIL,
  GET_TREEGAR_INVESTMENT_LIABILITY_REQUEST,
  GET_TREEGAR_INVESTMENT_LIABILITY_SUCCESS,
  GET_TREEGAR_INVESTMENT_LIABILITY_FAIL,
  GET_TREEGAR_PENDING_INVESTMENT_REQUEST,
  GET_TREEGAR_PENDING_INVESTMENT_SUCCESS,
  GET_TREEGAR_PENDING_INVESTMENT_FAIL,
  APPROVE_REJECT_INVESTMENT_REQUEST,
  APPROVE_REJECT_INVESTMENT_SUCCESS,
  APPROVE_REJECT_INVESTMENT_FAIL,
  GET_TERMINALS_REQUEST,
  GET_TERMINALS_SUCCESS,
  GET_TERMINALS_FAIL,
  GET_OVERDRAFTS_REQUEST,
  GET_OVERDRAFTS_SUCCESS,
  GET_OVERDRAFTS_FAIL,
  APPROVE_REJECT_OVERDRAFT_REQUEST,
  APPROVE_REJECT_OVERDRAFT_SUCCESS,
  APPROVE_REJECT_OVERDRAFT_FAIL,
  UPDATE_OVERDRAFT_LIMIT_REQUEST,
  UPDATE_OVERDRAFT_LIMIT_SUCCESS,
  UPDATE_OVERDRAFT_LIMIT_FAIL,
  GET_OVERDRAFT_SCHEDULES_REQUEST,
  GET_OVERDRAFT_SCHEDULES_SUCCESS,
  GET_OVERDRAFT_SCHEDULES_FAIL,
  GET_FAILED_VERIFICATIONS_REQUEST,
  GET_FAILED_VERIFICATIONS_SUCCESS,
  GET_FAILED_VERIFICATIONS_FAIL,
  TOTAL_COMMISSION_BREAKDOWN_REQUEST,
  TOTAL_COMMISSION_BREAKDOWN_SUCCESS,
  TOTAL_COMMISSION_BREAKDOWN_FAIL,
  GET_CUSTOMER_TARGET_SAVINGS_REQUEST,
  GET_CUSTOMER_TARGET_SAVINGS_SUCCESS,
  GET_CUSTOMER_TARGET_SAVINGS_FAIL,
  GET_BALANCE_METRICS_REQUEST,
  GET_BALANCE_METRICS_SUCCESS,
  GET_BALANCE_METRICS_FAIL,
  GET_ALL_RATES_REQUEST,
  GET_ALL_RATES_SUCCESS,
  GET_ALL_RATES_FAIL,
  UPDATE_RATE_REQUEST,
  UPDATE_RATE_SUCCESS,
  UPDATE_RATE_FAIL,
  GET_TOTAL_BALANCE_REQUEST,
  GET_TOTAL_BALANCE_SUCCESS,
  GET_TOTAL_BALANCE_FAIL,
  GET_BALANCE_HISTORY_REQUEST,
  GET_BALANCE_HISTORY_SUCCESS,
  GET_BALANCE_HISTORY_FAIL,
  TRANSACTIONS_EXPORT_REQUEST,
  TRANSACTIONS_EXPORT_SUCCESS,
  TRANSACTIONS_EXPORT_FAIL,
  FETCH_BUSINESS_REQUEST,
  FETCH_BUSINESS_SUCCESS,
  FETCH_BUSINESS_FAIL,
  FETCH_CUSTOMERID_REQUEST,
  FETCH_CUSTOMERID_SUCCESS,
  FETCH_CUSTOMERID_FAIL,
  FETCH_BANKS_REQUEST,
  FETCH_BANKS_SUCCESS,
  FETCH_BANKS_FAIL,
  ASSIGN_TERMINAL_REQUEST,
  ASSIGN_TERMINAL_SUCCESS,
  ASSIGN_TERMINAL_FAIL,
  ADD_TERMINAL_REQUEST,
  ADD_TERMINAL_SUCCESS,
  ADD_TERMINAL_FAIL,
  FETCH_UNASSIGNED_TERMINALS_REQUEST,
  FETCH_UNASSIGNED_TERMINALS_SUCCESS,
  FETCH_UNASSIGNED_TERMINALS_FAIL,
  FETCH_BUSINESS_RESET,
  ASSIGN_TERMINAL_RESET,
  GET_PAYAZA_BALANCE_REQUEST,
  GET_PAYAZA_BALANCE_SUCCESS,
  GET_PAYAZA_BALANCE_FAIL,
  GET_SEERBIT_BALANCE_REQUEST,
  GET_SEERBIT_BALANCE_SUCCESS,
  GET_SEERBIT_BALANCE_FAIL,
  GET_VFD_BALANCE_REQUEST,
  GET_VFD_BALANCE_SUCCESS,
  GET_VFD_BALANCE_FAIL,
  GET_PAYSTACK_BALANCE_REQUEST,
  GET_PAYSTACK_BALANCE_SUCCESS,
  GET_PAYSTACK_BALANCE_FAIL,
  GET_FLW_BALANCE_REQUEST,
  GET_FLW_BALANCE_SUCCESS,
  GET_FLW_BALANCE_FAIL,
  GET_CUSTOMER_BUSINESS_REQUEST,
  GET_CUSTOMER_BUSINESS_SUCCESS,
  GET_CUSTOMER_BUSINESS_FAIL,
  GET_BUSINESS_WALLET_REQUEST,
  GET_BUSINESS_WALLET_SUCCESS,
  GET_BUSINESS_WALLET_FAIL,
} from "../constants/adminConstants";

export const adminLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LOGIN_REQUEST:
      return { loading: true };
    case ADMIN_LOGIN_SUCCESS:
      return { loading: false, success: true, tokenInfo: action.payload };
    case ADMIN_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const admin2faLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_2FA_LOGIN_REQUEST:
      return { loading: true };
    case ADMIN_2FA_LOGIN_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case ADMIN_2FA_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const resendOtpReducer = (state = {}, action) => {
  switch (action.type) {
    case RESEND_OTP_REQUEST:
      return { loading: true };
    case RESEND_OTP_SUCCESS:
      return { loading: false, otp: action.payload };
    case RESEND_OTP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allCustomersReducer = (state = { customers: [] }, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_REQUEST:
      return { loading: true, customers: [] };
    case GET_CUSTOMERS_SUCCESS:
      return { loading: false, success: true, customers: action.payload };
    case GET_CUSTOMERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customersDetailsReducer = (state = { customer: {} }, action) => {
  switch (action.type) {
    case CUSTOMERS_DETAILS_REQUEST:
      return { loading: true };
    case CUSTOMERS_DETAILS_SUCCESS:
      return { loading: false, success: true, customer: action.payload };
    case CUSTOMERS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const singleUserReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case SINGLE_USER_REQUEST:
      return { loading: true };
    case SINGLE_USER_SUCCESS:
      return { loading: false, user: action.payload.data };
    case SINGLE_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getTransactionsReducer = (
  state = { transactions: {} },
  action
) => {
  switch (action.type) {
    case ALL_TRANSACTIONS_REQUEST:
      return { loading: true };
    case ALL_TRANSACTIONS_SUCCESS:
      return { loading: false, success: true, transactions: action.payload };
    case ALL_TRANSACTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const usersCardReducer = (state = { cards: [] }, action) => {
  switch (action.type) {
    case USER_CARD_REQUEST:
      return { loading: true, users: [] };
    case USER_CARD_SUCCESS:
      return { loading: false, cards: action.payload.data };
    case USER_CARD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userAccountReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_ACCOUNT_REQUEST:
      return { loading: true, users: {} };
    case USER_ACCOUNT_SUCCESS:
      return { loading: false, user: action.payload.data };
    case USER_ACCOUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userSavingsReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_SAVINGS_REQUEST:
      return { loading: true, users: [] };
    case USER_SAVINGS_SUCCESS:
      return { loading: false, users: action.payload.data };
    case USER_SAVINGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userVaultsReducer = (state = { vaults: [] }, action) => {
  switch (action.type) {
    case USER_VAULTS_REQUEST:
      return { loading: true, vaults: [] };
    case USER_VAULTS_SUCCESS:
      return { loading: false, vaults: action.payload.data };
    case USER_VAULTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const failedDebitsReducer = (state = { debits: {} }, action) => {
  switch (action.type) {
    case FAILED_DEBITS_REQUEST:
      return { loading: true };
    case FAILED_DEBITS_SUCCESS:
      return { loading: false, debits: action.payload };
    case FAILED_DEBITS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userCountReducer = (state = { count: {} }, action) => {
  switch (action.type) {
    case USER_COUNT_REQUEST:
      return { loading: true };
    case USER_COUNT_SUCCESS:
      return { loading: false, success: true, count: action.payload.data };
    case USER_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const investorsCountReducer = (state = { count: {} }, action) => {
  switch (action.type) {
    case INVESTORS_COUNT_REQUEST:
      return { loading: true };
    case INVESTORS_COUNT_SUCCESS:
      return { loading: false, success: true, count: action.payload.data };
    case INVESTORS_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const lockedFundsReducer = (state = { count: {} }, action) => {
  switch (action.type) {
    case LOCKED_FUNDS_REQUEST:
      return { loading: true };
    case LOCKED_FUNDS_SUCCESS:
      return { loading: false, success: true, count: action.payload.data };
    case LOCKED_FUNDS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const transferTotalReducer = (state = { count: {} }, action) => {
  switch (action.type) {
    case TRANSFER_TOTAL_REQUEST:
      return { loading: true };
    case TRANSFER_TOTAL_SUCCESS:
      return { loading: false, success: true, count: action.payload.data };
    case TRANSFER_TOTAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allInvestmentsReducer = (state = { investments: [] }, action) => {
  switch (action.type) {
    case ALL_INVESTMENTS_REQUEST:
      return { loading: true };
    case ALL_INVESTMENTS_SUCCESS:
      return {
        loading: false,
        success: true,
        investments: action.payload.data,
      };
    case ALL_INVESTMENTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createInvestmentReducer = (state = { investment: {} }, action) => {
  switch (action.type) {
    case CREATE_INVESTMENT_REQUEST:
      return { loading: true };
    case CREATE_INVESTMENT_SUCCESS:
      return { loading: false, success: true, investment: action.payload.data };
    case CREATE_INVESTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customersInvestmentsReducer = (
  state = { investments: [] },
  action
) => {
  switch (action.type) {
    case CUSTOMER_INVESTMENT_REQUEST:
      return { loading: true };
    case CUSTOMER_INVESTMENT_SUCCESS:
      return {
        loading: false,
        success: true,
        investments: action.payload.data,
      };
    case CUSTOMER_INVESTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customersInvestmentsExpiredReducer = (
  state = { investments: [] },
  action
) => {
  switch (action.type) {
    case CUSTOMER_INVESTMENT_EXPIRED_REQUEST:
      return { loading: true };
    case CUSTOMER_INVESTMENT_EXPIRED_SUCCESS:
      return {
        loading: false,
        success: true,
        investments: action.payload.data,
      };
    case CUSTOMER_INVESTMENT_EXPIRED_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const totalCommissionReducer = (state = { commissions: [] }, action) => {
  switch (action.type) {
    case TOTAL_COMMISSION_REQUEST:
      return { loading: true };
    case TOTAL_COMMISSION_SUCCESS:
      return {
        loading: false,
        success: true,
        commissions: action.payload.data,
      };
    case TOTAL_COMMISSION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const totalBillsReducer = (state = { bills: {} }, action) => {
  switch (action.type) {
    case BILLS_REQUEST:
      return { loading: true };
    case BILLS_SUCCESS:
      return { loading: false, success: true, bills: action.payload.data };
    case BILLS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const businessMetricsReducer = (state = { metrics: {} }, action) => {
  switch (action.type) {
    case BUSINESS_METRICS_REQUEST:
      return { loading: true };
    case BUSINESS_METRICS_SUCCESS:
      return { loading: false, success: true, metrics: action.payload.data };
    case BUSINESS_METRICS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pendingWithdrawalReducer = (
  state = { withdrawals: {} },
  action
) => {
  switch (action.type) {
    case PENDING_WITHDRAWAL_REQUEST:
      return { loading: true };
    case PENDING_WITHDRAWAL_SUCCESS:
      return {
        loading: false,
        success: true,
        withdrawals: action.payload.data,
      };
    case PENDING_WITHDRAWAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getOfficersReducer = (state = { officers: {} }, action) => {
  switch (action.type) {
    case GET_OFFICERS_REQUEST:
      return { loading: true };
    case GET_OFFICERS_SUCCESS:
      return { loading: false, success: true, officers: action.payload.data };
    case GET_OFFICERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getAccountOfficerDetailsReducer = (
  state = { officerDetails: {} },
  action
) => {
  switch (action.type) {
    case ACCOUNT_OFFICER_DETAILS_REQUEST:
      return { loading: true };
    case ACCOUNT_OFFICER_DETAILS_SUCCESS:
      return {
        loading: false,
        success: true,
        officerDetails: action.payload.data,
      };
    case ACCOUNT_OFFICER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getTeamMembersReducer = (state = { members: {} }, action) => {
  switch (action.type) {
    case TEAM_MEMBERS_REQUEST:
      return { loading: true };
    case TEAM_MEMBERS_SUCCESS:
      return {
        loading: false,
        success: true,
        members: action.payload,
      };
    case TEAM_MEMBERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getRolesReducer = (state = { roles: {} }, action) => {
  switch (action.type) {
    case GET_ROLES_REQUEST:
      return { loading: true };
    case GET_ROLES_SUCCESS:
      return {
        loading: false,
        success: true,
        roles: action.payload,
      };
    case GET_ROLES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getDepartmentsReducer = (state = { departments: {} }, action) => {
  switch (action.type) {
    case GET_DEPARTMENTS_REQUEST:
      return { loading: true };
    case GET_DEPARTMENTS_SUCCESS:
      return {
        loading: false,
        success: true,
        departments: action.payload,
      };
    case GET_DEPARTMENTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getPermissionsReducer = (state = { permissions: {} }, action) => {
  switch (action.type) {
    case GET_PERMISSIONS_REQUEST:
      return { loading: true };
    case GET_PERMISSIONS_SUCCESS:
      return {
        loading: false,
        success: true,
        permissions: action.payload,
      };
    case GET_PERMISSIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createMemberReducer = (state = { member: {} }, action) => {
  switch (action.type) {
    case CREATE_MEMBER_REQUEST:
      return { loading: true };
    case CREATE_MEMBER_SUCCESS:
      return {
        loading: false,
        success: true,
        member: action.payload,
      };
    case CREATE_MEMBER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const assignOfficerReducer = (state = { assigned: {} }, action) => {
  switch (action.type) {
    case ASSIGN_OFFICER_REQUEST:
      return { loading: true };
    case ASSIGN_OFFICER_SUCCESS:
      return {
        loading: false,
        success: true,
        assigned: action.payload,
      };
    case ASSIGN_OFFICER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deassignOfficerReducer = (state = { deassigned: {} }, action) => {
  switch (action.type) {
    case DEASSIGN_OFFICER_REQUEST:
      return { loading: true };
    case DEASSIGN_OFFICER_SUCCESS:
      return {
        loading: false,
        success: true,
        deassigned: action.payload,
      };
    case DEASSIGN_OFFICER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const verifyUserReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case VERIFY_USER_REQUEST:
      return { loading: true };
    case VERIFY_USER_SUCCESS:
      return {
        loading: false,
        success: true,
        deassigned: action.payload,
      };
    case VERIFY_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const transactionStatusReducer = (state = { status: {} }, action) => {
  switch (action.type) {
    case TRANSACTION_STATUS_REQUEST:
      return { loading: true };
    case TRANSACTION_STATUS_SUCCESS:
      return {
        loading: false,
        success: true,
        status: action.payload,
      };
    case TRANSACTION_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const forgotPasswordReducer = (
  state = { passwordForgot: {} },
  action
) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return { loading: true };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        loading: false,
        success: true,
        passwordForgot: action.payload,
      };
    case FORGOT_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const resetPasswordReducer = (state = { passwordReset: {} }, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { loading: true };
    case RESET_PASSWORD_SUCCESS:
      return {
        loading: false,
        success: true,
        passwordReset: action.payload,
      };
    case RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approveRejectWithdrawaldReducer = (
  state = { approveReject: {} },
  action
) => {
  switch (action.type) {
    case APPROVE_REJECT_WITHDRAWAL_REQUEST:
      return { loading: true };
    case APPROVE_REJECT_WITHDRAWAL_SUCCESS:
      return {
        loading: false,
        success: true,
        approveReject: action.payload,
      };
    case APPROVE_REJECT_WITHDRAWAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pendingBusinessReducer = (state = { business: {} }, action) => {
  switch (action.type) {
    case PENDING_BUSINESS_REQUEST:
      return { loading: true };
    case PENDING_BUSINESS_SUCCESS:
      return {
        loading: false,
        success: true,
        business: action.payload,
      };
    case PENDING_BUSINESS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const businessDocumentReducer = (state = { document: [] }, action) => {
  switch (action.type) {
    case BUSINESS_DOCUMENT_REQUEST:
      return { loading: true };
    case BUSINESS_DOCUMENT_SUCCESS:
      return { loading: false, success: true, document: action.payload.data };
    case BUSINESS_DOCUMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const totalInterestReducer = (state = { interest: {} }, action) => {
  switch (action.type) {
    case TOTAL_INTEREST_REQUEST:
      return { loading: true };
    case TOTAL_INTEREST_SUCCESS:
      return { loading: false, success: true, interest: action.payload };
    case TOTAL_INTEREST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approveBusinessReducer = (
  state = { businessAprove: {} },
  action
) => {
  switch (action.type) {
    case APPROVE_BUSINESS_REQUEST:
      return { loading: true };
    case APPROVE_BUSINESS_SUCCESS:
      return { loading: false, success: true, businessAprove: action.payload };
    case APPROVE_BUSINESS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productTypesReducer = (state = { products: {} }, action) => {
  switch (action.type) {
    case PRODUCT_TYPES_REQUEST:
      return { loading: true };
    case PRODUCT_TYPES_SUCCESS:
      return { loading: false, success: true, products: action.payload };
    case PRODUCT_TYPES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const headerCustomersReducer = (state = { customers: [] }, action) => {
  switch (action.type) {
    case FETCH_CUSTOMERS_REQUEST:
      return { loading: true, customers: [] };
    case FETCH_CUSTOMERS_SUCCESS:
      return { loading: false, success: true, customers: action.payload };
    case FETCH_CUSTOMERS_FAIL:
      return { loading: false, error: action.payload };
    case FETCH_CUSTOMERS_RESET:
      return { customers: [] };
    default:
      return state;
  }
};

export const viewDocumentReducer = (state = { doc: {} }, action) => {
  switch (action.type) {
    case VIEW_DOCUMENTS_REQUEST:
      return { loading: true };
    case VIEW_DOCUMENTS_SUCCESS:
      return { loading: false, success: true, doc: action.payload };
    case VIEW_DOCUMENTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addMemosReducer = (state = { memo: {} }, action) => {
  switch (action.type) {
    case ADD_MEMOS_REQUEST:
      return { loading: true };
    case ADD_MEMOS_SUCCESS:
      return { loading: false, success: true, memo: action.payload };
    case ADD_MEMOS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getMemosReducer = (state = { memos: [] }, action) => {
  switch (action.type) {
    case GET_MEMOS_REQUEST:
      return { loading: true };
    case GET_MEMOS_SUCCESS:
      return { loading: false, success: true, doc: action.payload };
    case GET_MEMOS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const rolesPermissionReducer = (state = { rolPer: [] }, action) => {
  switch (action.type) {
    case ROLES_PERMISSIONS_REQUEST:
      return { loading: true };
    case ROLES_PERMISSIONS_SUCCESS:
      return { loading: false, success: true, rolPer: action.payload };
    case ROLES_PERMISSIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allBalancesReducer = (state = { balances: [] }, action) => {
  switch (action.type) {
    case ALL_BALANCES_REQUEST:
      return { loading: true };
    case ALL_BALANCES_SUCCESS:
      return { loading: false, success: true, balances: action.payload };
    case ALL_BALANCES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const merchantBalancesReducer = (
  state = { merchantBalances: [] },
  action
) => {
  switch (action.type) {
    case MERCHANT_BALANCES_REQUEST:
      return { loading: true };
    case MERCHANT_BALANCES_SUCCESS:
      return {
        loading: false,
        success: true,
        merchantBalances: action.payload,
      };
    case MERCHANT_BALANCES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteInvestmentReducer = (state = { delInv: [] }, action) => {
  switch (action.type) {
    case DELETE_INVESTMENT_REQUEST:
      return { loading: true };
    case DELETE_INVESTMENT_SUCCESS:
      return { loading: false, success: true, delInv: action.payload };
    case DELETE_INVESTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userStatusReducer = (state = { status: {} }, action) => {
  switch (action.type) {
    case UPDATE_USER_STATUS_REQUEST:
      return { loading: true };
    case UPDATE_USER_STATUS_SUCCESS:
      return { loading: false, success: true, status: action.payload };
    case UPDATE_USER_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userRoleReducer = (state = { newRole: {} }, action) => {
  switch (action.type) {
    case UPDATE_USER_ROLE_REQUEST:
      return { loading: true };
    case UPDATE_USER_ROLE_SUCCESS:
      return { loading: false, success: true, status: action.payload };
    case UPDATE_USER_ROLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getCustomerTransactionsReducer = (
  state = { customerTrans: {} },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_TRANSACTION_REQUEST:
      return { loading: true };
    case GET_CUSTOMER_TRANSACTION_SUCCESS:
      return { loading: false, success: true, customerTrans: action.payload };
    case GET_CUSTOMER_TRANSACTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getSingleOfficerDetailsReducer = (
  state = { officerDetails: {} },
  action
) => {
  switch (action.type) {
    case GET_SINGLE_OFFICER_REQUEST:
      return { loading: true };
    case GET_SINGLE_OFFICER_SUCCESS:
      return {
        loading: false,
        success: true,
        singleOfficer: action.payload.data,
      };
    case GET_SINGLE_OFFICER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getSingleOfficerInfoReducer = (
  state = { officerDetails: {} },
  action
) => {
  switch (action.type) {
    case GET_SINGLE_OFFICER_INFO_REQUEST:
      return { loading: true };
    case GET_SINGLE_OFFICER_INFO_SUCCESS:
      return {
        loading: false,
        success: true,
        singleOfficer: action.payload.data,
      };
    case GET_SINGLE_OFFICER_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customerLimitReducer = (state = { limit: {} }, action) => {
  switch (action.type) {
    case SET_CUSTOMER_LIMIT_REQUEST:
      return { loading: true };
    case SET_CUSTOMER_LIMIT_SUCCESS:
      return {
        loading: false,
        success: true,
        limit: action.payload,
      };
    case SET_CUSTOMER_LIMIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customerAutomationReducer = (
  state = { automation: {} },
  action
) => {
  switch (action.type) {
    case SET_CUSTOMER_AUTOMATION_REQUEST:
      return { loading: true };
    case SET_CUSTOMER_AUTOMATION_SUCCESS:
      return {
        loading: false,
        success: true,
        automation: action.payload,
      };
    case SET_CUSTOMER_AUTOMATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getcustomerLimitReducer = (
  state = { customLimit: {} },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_LIMIT_REQUEST:
      return { loading: true };
    case GET_CUSTOMER_LIMIT_SUCCESS:
      return {
        loading: false,
        success: true,
        customLimit: action.payload,
      };
    case GET_CUSTOMER_LIMIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getTargetSavingsOfficerInfoReducer = (
  state = { savingsOfficer: {} },
  action
) => {
  switch (action.type) {
    case GET_TARGET_SAVINGS_OFFICER_REQUEST:
      return { loading: true };
    case GET_TARGET_SAVINGS_OFFICER_SUCCESS:
      return {
        loading: false,
        success: true,
        savingsOfficer: action.payload.data,
      };
    case GET_TARGET_SAVINGS_OFFICER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getSingleTargetSavingsOfficerInfoReducer = (
  state = { singletargetSavingsOfficer: {} },
  action
) => {
  switch (action.type) {
    case GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_REQUEST:
      return { loading: true };
    case GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_SUCCESS:
      return {
        loading: false,
        success: true,
        singletargetSavingsOfficer: action.payload.data,
      };
    case GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const failedWithdrawalReducer = (
  state = { withdrawals: {} },
  action
) => {
  switch (action.type) {
    case FAILED_WITHDRAWAL_REQUEST:
      return { loading: true };
    case FAILED_WITHDRAWAL_SUCCESS:
      return {
        loading: false,
        success: true,
        withdrawals: action.payload.data,
      };
    case FAILED_WITHDRAWAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allPayoutsReducer = (state = { payouts: {} }, action) => {
  switch (action.type) {
    case ALL_PAYOUTS_REQUEST:
      return { loading: true };
    case ALL_PAYOUTS_SUCCESS:
      return { loading: false, success: true, payouts: action.payload.data };
    case ALL_PAYOUTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getAllTargetSavingsReducer = (
  state = { alltargetSavings: {} },
  action
) => {
  switch (action.type) {
    case GET_ALL_TARGET_SAVINGS_REQUEST:
      return { loading: true };
    case GET_ALL_TARGET_SAVINGS_SUCCESS:
      return {
        loading: false,
        success: true,
        alltargetSavings: action.payload.data,
      };
    case GET_ALL_TARGET_SAVINGS_RESET:
      return {
        loading: false,
        success: true,
        alltargetSavings: {},
      };
    case GET_ALL_TARGET_SAVINGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const targetSavingsMetricsReducer = (
  state = { metrics: {} },
  action
) => {
  switch (action.type) {
    case TARGET_SAVINGS_METRICS_REQUEST:
      return { loading: true };
    case TARGET_SAVINGS_METRICS_SUCCESS:
      return { loading: false, success: true, metrics: action.payload.data };
    case TARGET_SAVINGS_METRICS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getAllBillsReducer = (state = { bills: {} }, action) => {
  switch (action.type) {
    case GET_ALL_BILLS_REQUEST:
      return { loading: true };
    case GET_ALL_BILLS_SUCCESS:
      return { loading: false, success: true, bills: action.payload.data };
    case GET_ALL_BILLS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getTransactionSettingsReducer = (
  state = { tranSettings: {} },
  action
) => {
  switch (action.type) {
    case GET_TRANSACTION_SETTINGS_REQUEST:
      return { loading: true };
    case GET_TRANSACTION_SETTINGS_SUCCESS:
      return {
        loading: false,
        success: true,
        tranSettings: action.payload.data,
      };
    case GET_TRANSACTION_SETTINGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const enableDisableTransactionReducer = (
  state = { transaction: {} },
  action
) => {
  switch (action.type) {
    case ENABLE_DISABLE_TRANSACTION_REQUEST:
      return { loading: true };
    case ENABLE_DISABLE_TRANSACTION_SUCCESS:
      return {
        loading: false,
        success: true,
        transaction: action.payload.data,
      };
    case ENABLE_DISABLE_TRANSACTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getProvidersReducer = (state = { providers: {} }, action) => {
  switch (action.type) {
    case GET_PROVIDERS_REQUEST:
      return { loading: true };
    case GET_PROVIDERS_SUCCESS:
      return {
        loading: false,
        success: true,
        providers: action.payload.data,
      };
    case GET_PROVIDERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const enableDisableProviderReducer = (
  state = { provider: {} },
  action
) => {
  switch (action.type) {
    case ENABLE_DISABLE_PROVIDER_REQUEST:
      return { loading: true };
    case ENABLE_DISABLE_PROVIDER_SUCCESS:
      return {
        loading: false,
        success: true,
        provider: action.payload.data,
      };
    case ENABLE_DISABLE_PROVIDER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const targetSavingsLogsReducer = (state = { logs: {} }, action) => {
  switch (action.type) {
    case TARGET_SAVINGS_LOGS_REQUEST:
      return { loading: true };
    case TARGET_SAVINGS_LOGS_SUCCESS:
      return { loading: false, success: true, logs: action.payload.data };
    case TARGET_SAVINGS_LOGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const investmentRatesReducer = (state = { rates: {} }, action) => {
  switch (action.type) {
    case GET_INVESTMENT_RATES_REQUEST:
      return { loading: true };
    case GET_INVESTMENT_RATES_SUCCESS:
      return { loading: false, success: true, rates: action.payload.data };
    case GET_INVESTMENT_RATES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateInvestmentRateReducer = (state = { rates: {} }, action) => {
  switch (action.type) {
    case UPDATE_INVESTMENT_RATES_REQUEST:
      return { loading: true };
    case UPDATE_INVESTMENT_RATES_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_INVESTMENT_RATES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deactivateCustomerReducer = (state = { customer: {} }, action) => {
  switch (action.type) {
    case DEACTIVATE_CUSTOMER_REQUEST:
      return { loading: true };
    case DEACTIVATE_CUSTOMER_SUCCESS:
      return { loading: false, success: true, customer: action.payload };
    case DEACTIVATE_CUSTOMER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const posMetricsReducer = (state = { metrics: {} }, action) => {
  switch (action.type) {
    case GET_POS_METRICS_REQUEST:
      return { loading: true };
    case GET_POS_METRICS_SUCCESS:
      return { loading: false, success: true, metrics: action.payload.data };
    case GET_POS_METRICS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customerCardsReducer = (state = { cards: [] }, action) => {
  switch (action.type) {
    case GET_CUSTOMER_CARDS_REQUEST:
      return { loading: true };
    case GET_CUSTOMER_CARDS_SUCCESS:
      return { loading: false, success: true, cards: action.payload.data };
    case GET_CUSTOMER_CARDS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteCustomerCardReducer = (state = { card: {} }, action) => {
  switch (action.type) {
    case DELETE_CUSTOMER_CARD_REQUEST:
      return { loading: true };
    case DELETE_CUSTOMER_CARD_SUCCESS:
      return { loading: false, success: true, card: action.payload.data };
    case DELETE_CUSTOMER_CARD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allTargetSavingsLogsReducer = (state = { logs: {} }, action) => {
  switch (action.type) {
    case ALL_TARGET_SAVINGS_LOGS_REQUEST:
      return { loading: true };
    case ALL_TARGET_SAVINGS_LOGS_SUCCESS:
      return { loading: false, success: true, logs: action.payload.data };
    case ALL_TARGET_SAVINGS_LOGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const interestInfoReducer = (state = { interest: [] }, action) => {
  switch (action.type) {
    case GET_INTEREST_INFO_REQUEST:
      return { loading: true };
    case GET_INTEREST_INFO_SUCCESS:
      return { loading: false, success: true, interest: action.payload };
    case GET_INTEREST_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const totalinterestReducer = (state = { interest: {} }, action) => {
  switch (action.type) {
    case GET_INTEREST_REQUEST:
      return { loading: true };
    case GET_INTEREST_SUCCESS:
      return { loading: false, success: true, interest: action.payload };
    case GET_INTEREST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const transactionCountReducer = (state = { metrics: {} }, action) => {
  switch (action.type) {
    case GET_TRANSACTION_COUNT_REQUEST:
      return { loading: true };
    case GET_TRANSACTION_COUNT_SUCCESS:
      return { loading: false, success: true, metrics: action.payload.data };
    case GET_TRANSACTION_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const treegarInvestmentReducer = (
  state = { investment: {} },
  action
) => {
  switch (action.type) {
    case CREATE_TREEGAR_INVESTMENT_REQUEST:
      return { loading: true };
    case CREATE_TREEGAR_INVESTMENT_SUCCESS:
      return { loading: false, success: true, investment: action.payload.data };
    case CREATE_TREEGAR_INVESTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const treegarInvestmentGetReducer = (
  state = { investments: {} },
  action
) => {
  switch (action.type) {
    case GET_TREEGAR_INVESTMENT_REQUEST:
      return { loading: true };
    case GET_TREEGAR_INVESTMENT_SUCCESS:
      return { loading: false, success: true, investment: action.payload.data };
    case GET_TREEGAR_INVESTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const treegarInvestmentGetLiabilityReducer = (
  state = { investments: {} },
  action
) => {
  switch (action.type) {
    case GET_TREEGAR_INVESTMENT_LIABILITY_REQUEST:
      return { loading: true };
    case GET_TREEGAR_INVESTMENT_LIABILITY_SUCCESS:
      return { loading: false, success: true, investment: action.payload.data };
    case GET_TREEGAR_INVESTMENT_LIABILITY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const treegarInvestmentConstantReducer = (
  state = { constant: {} },
  action
) => {
  switch (action.type) {
    case GET_TREEGAR_INVESTMENT_CONSTANT_REQUEST:
      return { loading: true };
    case GET_TREEGAR_INVESTMENT_CONSTANT_SUCCESS:
      return { loading: false, success: true, constant: action.payload.data };
    case GET_TREEGAR_INVESTMENT_CONSTANT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const treegarPendingInvestmentReducer = (
  state = { pending: {} },
  action
) => {
  switch (action.type) {
    case GET_TREEGAR_PENDING_INVESTMENT_REQUEST:
      return { loading: true };
    case GET_TREEGAR_PENDING_INVESTMENT_SUCCESS:
      return { loading: false, success: true, pending: action.payload.data };
    case GET_TREEGAR_PENDING_INVESTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approveRejectInvestmentdReducer = (
  state = { approveReject: {} },
  action
) => {
  switch (action.type) {
    case APPROVE_REJECT_INVESTMENT_REQUEST:
      return { loading: true };
    case APPROVE_REJECT_INVESTMENT_SUCCESS:
      return {
        loading: false,
        success: true,
        approveReject: action.payload,
      };
    case APPROVE_REJECT_INVESTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getTerminalsReducer = (state = { terminals: {} }, action) => {
  switch (action.type) {
    case GET_TERMINALS_REQUEST:
      return { loading: true };
    case GET_TERMINALS_SUCCESS:
      return { loading: false, success: true, terminals: action.payload.data };
    case GET_TERMINALS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getOverdraftRequestReducer = (
  state = { overdraftRequest: {} },
  action
) => {
  switch (action.type) {
    case GET_OVERDRAFTS_REQUEST:
      return { loading: true };
    case GET_OVERDRAFTS_SUCCESS:
      return {
        loading: false,
        success: true,
        overdraftRequest: action.payload.data,
      };
    case GET_OVERDRAFTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approveRejectOverdraftReducer = (
  state = { overdraft: {} },
  action
) => {
  switch (action.type) {
    case APPROVE_REJECT_OVERDRAFT_REQUEST:
      return { loading: true };
    case APPROVE_REJECT_OVERDRAFT_SUCCESS:
      return { loading: false, success: true, overdraft: action.payload.data };
    case APPROVE_REJECT_OVERDRAFT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateOverdraftLimitReducer = (
  state = { overdraftLimit: {} },
  action
) => {
  switch (action.type) {
    case UPDATE_OVERDRAFT_LIMIT_REQUEST:
      return { loading: true };
    case UPDATE_OVERDRAFT_LIMIT_SUCCESS:
      return {
        loading: false,
        success: true,
        overdraftLimit: action.payload,
      };
    case UPDATE_OVERDRAFT_LIMIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getOverdraftScheduleReducer = (
  state = { overdraftSchedules: [] },
  action
) => {
  switch (action.type) {
    case GET_OVERDRAFT_SCHEDULES_REQUEST:
      return { loading: true };
    case GET_OVERDRAFT_SCHEDULES_SUCCESS:
      return {
        loading: false,
        success: true,
        overdraftSchedules: action.payload.data,
      };
    case GET_OVERDRAFT_SCHEDULES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getFailedVerificationsReducer = (
  state = { verifications: [] },
  action
) => {
  switch (action.type) {
    case GET_FAILED_VERIFICATIONS_REQUEST:
      return { loading: true };
    case GET_FAILED_VERIFICATIONS_SUCCESS:
      return {
        loading: false,
        success: true,
        verifications: action.payload.data,
      };
    case GET_FAILED_VERIFICATIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const totalCommissionBreakdownReducer = (
  state = { commissions: [] },
  action
) => {
  switch (action.type) {
    case TOTAL_COMMISSION_BREAKDOWN_REQUEST:
      return { loading: true };
    case TOTAL_COMMISSION_BREAKDOWN_SUCCESS:
      return {
        loading: false,
        success: true,
        commissions: action.payload.data,
      };
    case TOTAL_COMMISSION_BREAKDOWN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customerTargetSavingsReducer = (
  state = { savings: [] },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_TARGET_SAVINGS_REQUEST:
      return { loading: true };
    case GET_CUSTOMER_TARGET_SAVINGS_SUCCESS:
      return { loading: false, success: true, savings: action.payload.data };
    case GET_CUSTOMER_TARGET_SAVINGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const balanceMetricsReducer = (state = { metrics: [] }, action) => {
  switch (action.type) {
    case GET_BALANCE_METRICS_REQUEST:
      return { loading: true };
    case GET_BALANCE_METRICS_SUCCESS:
      return { loading: false, success: true, metrics: action.payload.data };
    case GET_BALANCE_METRICS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getRatesReducer = (state = { rates: [] }, action) => {
  switch (action.type) {
    case GET_ALL_RATES_REQUEST:
      return { loading: true };
    case GET_ALL_RATES_SUCCESS:
      return { loading: false, success: true, rates: action.payload.data };
    case GET_ALL_RATES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateRatesReducer = (state = { rates: [] }, action) => {
  switch (action.type) {
    case UPDATE_RATE_REQUEST:
      return { loading: true };
    case UPDATE_RATE_SUCCESS:
      return { loading: false, success: true, rates: action.payload.data };
    case UPDATE_RATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getTotalBalanceReducer = (state = { balance: {} }, action) => {
  switch (action.type) {
    case GET_TOTAL_BALANCE_REQUEST:
      return { loading: true };
    case GET_TOTAL_BALANCE_SUCCESS:
      return {
        loading: false,
        success: true,
        balance: action.payload,
      };
    case GET_TOTAL_BALANCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getBalanceHistoryReducer = (state = { balance: {} }, action) => {
  switch (action.type) {
    case GET_BALANCE_HISTORY_REQUEST:
      return { loading: true };
    case GET_BALANCE_HISTORY_SUCCESS:
      return {
        loading: false,
        success: true,
        balance: action.payload,
      };
    case GET_BALANCE_HISTORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const transactionsExportReducer = (
  state = { transactions: {} },
  action
) => {
  switch (action.type) {
    case TRANSACTIONS_EXPORT_REQUEST:
      return { loading: true };
    case TRANSACTIONS_EXPORT_SUCCESS:
      return { loading: false, success: true, transactions: action.payload };
    case TRANSACTIONS_EXPORT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const fetchBusinessReducer = (state = { businesses: {} }, action) => {
  switch (action.type) {
    case FETCH_BUSINESS_REQUEST:
      return { loading: true };
    case FETCH_BUSINESS_SUCCESS:
      return { loading: false, success: true, businesses: action.payload };
    case FETCH_BUSINESS_FAIL:
      return { loading: false, error: action.payload };
    case FETCH_BUSINESS_RESET:
      return { businesses: [] };
    default:
      return state;
  }
};

export const customeridReducer = (state = { customer: {} }, action) => {
  switch (action.type) {
    case FETCH_CUSTOMERID_REQUEST:
      return { loading: true, customer: [] };
    case FETCH_CUSTOMERID_SUCCESS:
      return { loading: false, success: true, customer: action.payload };
    case FETCH_CUSTOMERID_FAIL:
      return { loading: false, error: action.payload };
    case FETCH_CUSTOMERS_RESET:
      return { customer: [] };
    default:
      return state;
  }
};

export const fetchBanksReducer = (state = { banks: {} }, action) => {
  switch (action.type) {
    case FETCH_BANKS_REQUEST:
      return { loading: true };
    case FETCH_BANKS_SUCCESS:
      return { loading: false, success: true, banks: action.payload };
    case FETCH_BANKS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const assignTerminalReducer = (state = { terminals: {} }, action) => {
  switch (action.type) {
    case ASSIGN_TERMINAL_REQUEST:
      return { loading: true };
    case ASSIGN_TERMINAL_SUCCESS:
      return { loading: false, success: true, terminals: action.payload };
    case ASSIGN_TERMINAL_FAIL:
      return { loading: false, error: action.payload };
    case ASSIGN_TERMINAL_RESET:
      return { terminals: {} };
    default:
      return state;
  }
};

export const addTerminalReducer = (state = { terminal: {} }, action) => {
  switch (action.type) {
    case ADD_TERMINAL_REQUEST:
      return { loading: true };
    case ADD_TERMINAL_SUCCESS:
      return { loading: false, success: true, terminal: action.payload };
    case ADD_TERMINAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const unassignedTerminalsReducer = (
  state = { terminals: {} },
  action
) => {
  switch (action.type) {
    case FETCH_UNASSIGNED_TERMINALS_REQUEST:
      return { loading: true };
    case FETCH_UNASSIGNED_TERMINALS_SUCCESS:
      return {
        loading: false,
        success: true,
        terminals: action.payload,
      };
    case FETCH_UNASSIGNED_TERMINALS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getPayazaBalanceReducer = (state = { balance: {} }, action) => {
  switch (action.type) {
    case GET_PAYAZA_BALANCE_REQUEST:
      return { loading: true };
    case GET_PAYAZA_BALANCE_SUCCESS:
      return {
        loading: false,
        success: true,
        balance: action.payload,
      };
    case GET_PAYAZA_BALANCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getSeerbitBalanceReducer = (state = { balance: {} }, action) => {
  switch (action.type) {
    case GET_SEERBIT_BALANCE_REQUEST:
      return { loading: true };
    case GET_SEERBIT_BALANCE_SUCCESS:
      return {
        loading: false,
        success: true,
        balance: action.payload,
      };
    case GET_SEERBIT_BALANCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getVfdBalanceReducer = (state = { balance: {} }, action) => {
  switch (action.type) {
    case GET_VFD_BALANCE_REQUEST:
      return { loading: true };
    case GET_VFD_BALANCE_SUCCESS:
      return {
        loading: false,
        success: true,
        balance: action.payload,
      };
    case GET_VFD_BALANCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getPaystackBalanceReducer = (state = { balance: {} }, action) => {
  switch (action.type) {
    case GET_PAYSTACK_BALANCE_REQUEST:
      return { loading: true };
    case GET_PAYSTACK_BALANCE_SUCCESS:
      return {
        loading: false,
        success: true,
        balance: action.payload,
      };
    case GET_PAYSTACK_BALANCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getFlwBalanceReducer = (state = { balance: {} }, action) => {
  switch (action.type) {
    case GET_FLW_BALANCE_REQUEST:
      return { loading: true };
    case GET_FLW_BALANCE_SUCCESS:
      return {
        loading: false,
        success: true,
        balance: action.payload,
      };
    case GET_FLW_BALANCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getCustomerBusinessReducer = (
  state = { business: {} },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_BUSINESS_REQUEST:
      return { loading: true };
    case GET_CUSTOMER_BUSINESS_SUCCESS:
      return {
        loading: false,
        success: true,
        business: action.payload.data,
      };
    case GET_CUSTOMER_BUSINESS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialState = {
  wallets: {},
  loading: false,
  error: null,
};

export const getBusinessWalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUSINESS_WALLET_REQUEST:
      return { loading: true };
    case GET_BUSINESS_WALLET_SUCCESS:
      return {
        loading: false,
        success: true,
        wallets: {
          ...state.wallets,
          [action.payload.businessid]: action.payload.wallet,
        },
      };
    case GET_BUSINESS_WALLET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setUserStatus } from "../actions/userActions"
import Loader from "./Loader"
import { setApproveRejectOverDraft } from "../actions/overdraftActions"

const OverdraftModal = ({ setIsOpen, id }) => {
  const dispatch = useDispatch()
  const [content, setContent] = useState("")
  const [interestRate, setInterestRate] = useState(0)
  const [penalRate, setPenalRate] = useState(0)
  const [approvedAmount, setApprovedAmount] = useState(0)

  const approveRejectOverdraft = useSelector(
    (state) => state.approveRejectOverdraft
  )
  const { loading, success, error } = approveRejectOverdraft
  useEffect(() => {
    if (success) {
      setIsOpen(false)
      window.location.reload()
    }
  }, [success, dispatch, setIsOpen])

  const approve = () => {
    const postData = {
      request_id: id,
      is_approved: true,
      approved_amount: approvedAmount,
      interest_rate: interestRate,
      penal_rate: penalRate
    }
    console.log(postData)
    dispatch(setApproveRejectOverDraft(postData))
  }

  const reject = () => {
    const postData = {
      request_id: id,
      is_approved: false,
      approved_amount: approvedAmount,
      interest_rate: interestRate,
      penal_rate: penalRate
    }
    console.log(postData)
    dispatch(setApproveRejectOverDraft(postData))
  }

  return (
    <>
      <div className="darkBG" onClick={() => setIsOpen(false)} />
      <div
        className="centered"
        style={{
          backgroundColor: "grey"
        }}
      >
        <div
          className="modal"
          style={{
            backgroundColor: "grey",
            height: "330px",
            width: "600px"
          }}
        >
          <div className="modalHeader">
            <h5 className="heading">Approve / Reject OverDraft</h5>
          </div>
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            {/* <RiCloseLine style={{ marginBottom: '-3px' }} /> */}
          </button>
          <div className="modalContent">
            <div className="investment-form">
              <form>
                <div className="form-group">
                  <label
                    htmlFor="email"
                    style={{
                      color: "#ffffff",
                      marginRight: "2px",
                      fontSize: "12px",
                      letterSpacing: "1px"
                    }}
                  >
                    Approved Amount
                  </label>
                  <input
                    className="invest-input overdraft-input"
                    type="number"
                    name="Approved Amount"
                    value={approvedAmount}
                    onChange={(e) => setApprovedAmount(e.target.value)}
                    placeholder="Enter Approved Amount"
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="email"
                    style={{
                      color: "#ffffff",
                      marginRight: "2px",
                      fontSize: "12px",
                      letterSpacing: "1px"
                    }}
                  >
                    Interest Rate
                  </label>
                  <input
                    className="invest-input overdraft-input"
                    type="number"
                    name="Approved Amount"
                    value={interestRate}
                    onChange={(e) => setInterestRate(e.target.value)}
                    placeholder="Enter Approved Amount"
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="email"
                    style={{
                      color: "#ffffff",
                      marginRight: "2px",
                      fontSize: "12px",
                      letterSpacing: "1px"
                    }}
                  >
                    Penal Rate
                  </label>
                  <input
                    className="invest-input overdraft-input"
                    type="number"
                    name="Approved Amount"
                    value={penalRate}
                    onChange={(e) => setPenalRate(e.target.value)}
                    placeholder="Enter Approved Amount"
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="modalActions" style={{ marginBottom: 0 }}>
            <div
              className="actionsContainer"
              style={{
                marginTop: "50px"
              }}
            >
              {loading || loading ? (
                <Loader />
              ) : (
                <>
                  <button className="cancelBtn" onClick={approve}>
                    Approve
                  </button>
                  <button className="deleteBtn" onClick={reject}>
                    Reject
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OverdraftModal

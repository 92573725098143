import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  fetchOfficerInfo,
  fetchOfficers,
  fetchSingleOfficerDetails
} from '../actions/adminActions'
import Header from '../components/Header'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import SideNav from '../components/SideNav'
import { avgFloat, format } from '../main'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const SingleOfficerScreen = () => {
  const [currPage, setCurrPage] = React.useState(1)
  const dispatch = useDispatch()
  const { id } = useParams()
  const officerRef = useRef(true)
  const [email, setEmail] = useState('')
  const [tag, setTag] = useState('')
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())

  const singleOfficer = useSelector((state) => state.singleOfficer)
  const { loading, success, singleOfficer: officer, error } = singleOfficer

  const getOfficers = useSelector((state) => state.getOfficers)
  const { officers } = getOfficers

  const singleOfficerInfo = useSelector((state) => state.singleOfficerInfo)
  const { success: singleOfficerSuccess, singleOfficer: officerInfo } =
    singleOfficerInfo

  console.log(officerInfo)
  useEffect(() => {
    if (officerRef.current) {
      dispatch(fetchOfficers())
      dispatch(fetchSingleOfficerDetails(id))
      dispatch(fetchOfficerInfo(id))
      officerRef.current = false
    }
  }, [id, dispatch])

  const afterPageClicked = (page_number) => {
    if (page_number === currPage) {
      setCurrPage(page_number + 1)
    } else {
      setCurrPage(page_number)
    }
  }

  const [styles, setStyles] = useState({
    navContainer: {
      display: 'none'
    }
  })

  const handleClick = () => {
    if (styles.navContainer.display === 'block') {
      setStyles({
        navContainer: {
          display: 'none'
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: 'block'
        }
      })
    }
  }

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: 'none'
      }
    })
    dispatch(fetchSingleOfficerDetails(id))
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: 'none'
      }
    })
    const filterData = {
      email,
      tag
    }
    dispatch(fetchSingleOfficerDetails(id, filterData, currPage))
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Customer | PennyTree - Admin</title>
      </Helmet>
      <section className='page-container'>
        <div className='page-content'>
          <Header />
          <div className='page-body'>
            <h1>{officerInfo?.officer_info?.name} Signups</h1>

            {loading ? (
              <Loader />
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <h3>Personal Account</h3>
                <MDBTable
                  responsive
                  style={{ 'width': '100%', fontSize: '14px' }}
                >
                  <MDBTableHead>
                    <tr>
                      <th scope='col'>S/N</th>
                      <th scope='col'>Value</th>
                      <th scope='col'>Average Float</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {singleOfficerSuccess && (
                      <tr
                        key={
                          officerInfo?.personal_account?.monthly_values[0].id
                        }
                      >
                        <td>1</td>

                        <td>
                          {format(
                            officerInfo?.personal_account?.monthly_values[0]
                              ?.value
                          )}
                        </td>
                        <td>
                          {avgFloat(
                            new Date().getFullYear(),
                            officerInfo?.personal_account?.monthly_values[0]
                              ?.month_name,
                            officerInfo?.personal_account?.monthly_values[0]
                              ?.value
                          )}
                        </td>
                      </tr>
                    )}
                  </MDBTableBody>
                </MDBTable>
                <h3>Merchant Account</h3>
                <MDBTable
                  responsive
                  style={{ 'width': '100%', fontSize: '14px' }}
                >
                  <MDBTableHead>
                    <tr>
                      <th scope='col'>S/N</th>

                      <th scope='col'>Value</th>
                      <th scope='col'>Average Float</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {singleOfficerSuccess && (
                      <tr
                        key={
                          officerInfo?.merchant_account?.monthly_values[0].id
                        }
                      >
                        <td>1</td>

                        <td>
                          {format(
                            officerInfo?.merchant_account?.monthly_values[0]
                              ?.value
                          )}
                        </td>
                        <td>
                          {avgFloat(
                            new Date().getFullYear(),
                            officerInfo?.merchant_account?.monthly_values[0]
                              ?.month_name,
                            officerInfo?.merchant_account?.monthly_values[0]
                              ?.value
                          )}
                        </td>
                      </tr>
                    )}
                  </MDBTableBody>
                </MDBTable>

                <h3>Personal Vault</h3>
                <MDBTable
                  responsive
                  style={{ 'width': '100%', fontSize: '14px' }}
                >
                  <MDBTableHead>
                    <tr>
                      <th scope='col'>S/N</th>

                      <th scope='col'>Value</th>
                      <th scope='col'>Average Float</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {singleOfficerSuccess && (
                      <tr
                        key={
                          officerInfo?.merchant_account?.monthly_values[0].id
                        }
                      >
                        <td>1</td>

                        <td>
                          {format(
                            officerInfo?.merchant_account?.monthly_values[0]
                              ?.value
                          )}
                        </td>
                        <td>
                          {avgFloat(
                            new Date().getFullYear(),
                            officerInfo?.merchant_account?.monthly_values[0]
                              ?.month_name,
                            officerInfo?.merchant_account?.monthly_values[0]
                              ?.value
                          )}
                        </td>
                      </tr>
                    )}
                  </MDBTableBody>
                </MDBTable>
                <div className='filter-box'>
                  <div className='filter'>
                    <>
                      <div className='filter-container'>
                        <p style={{ cursor: 'pointer' }} onClick={handleClick}>
                          Filter
                        </p>
                        <div
                          className='navContainer'
                          style={styles.navContainer}
                        >
                          <div className='date-filter'>
                            <div className='date-title'>Date</div>
                            <div className='date-options'>
                              <div className='custom-filter__item custom-filter__item--pad'>
                                <div className='overview-filter'>
                                  <div className='filter__item'>
                                    <input
                                      id='today_fkc3w9c4e0'
                                      value='Today'
                                      type='radio'
                                      name='dateFilter_fkc3w9c4e0'
                                      className='overview-filter__item__radio'
                                    />
                                    <label
                                      htmlFor='today_fkc3w9c4e0'
                                      className='overview-filter__item__link active'
                                    >
                                      Today
                                    </label>
                                  </div>{' '}
                                  <div className='filter__item'>
                                    <input
                                      id='last7_fkc3w9c4e0'
                                      value='7 days'
                                      type='radio'
                                      name='dateFilter_fkc3w9c4e0'
                                      className='overview-filter__item__radio'
                                    />
                                    <label
                                      htmlFor='last7_fkc3w9c4e0'
                                      className='overview-filter__item__link'
                                    >
                                      Last 7 days
                                    </label>
                                  </div>{' '}
                                  <div className='filter__item'>
                                    <input
                                      id='last30_fkc3w9c4e0'
                                      value='30 days'
                                      type='radio'
                                      name='dateFilter_fkc3w9c4e0'
                                      className='overview-filter__item__radio'
                                    />
                                    <label
                                      htmlFor='last30_fkc3w9c4e0'
                                      className='overview-filter__item__link'
                                    >
                                      30 days
                                    </label>
                                  </div>{' '}
                                  <div className='filter__item'>
                                    <input
                                      id='1year_fkc3w9c4e0'
                                      value='1 year'
                                      type='radio'
                                      name='dateFilter_fkc3w9c4e0'
                                      className='overview-filter__item__radio'
                                    />
                                    <label
                                      htmlFor='1year_fkc3w9c4e0'
                                      className='overview-filter__item__link'
                                    >
                                      1 year
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className='date-content'>
                                <div className='date-items'>
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                  />

                                  <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='status-container'>
                            <div className='date-title'>Customer</div>
                            <div className='checkbox checkbox--flex'>
                              <div
                                className='checkbox__item'
                                style={{
                                  width: '100%'
                                }}
                              >
                                <input
                                  style={{
                                    width: '100%',
                                    borderRadius: '10px',
                                    border: '1px solid grey',
                                    fontSize: '14px',
                                    display: tag ? ' none' : 'block'
                                  }}
                                  type='email'
                                  className='email-input'
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  placeholder='Enter customer Email'
                                />{' '}
                              </div>
                              <div
                                className='checkbox__item'
                                style={{
                                  width: '100%'
                                }}
                              >
                                <input
                                  style={{
                                    width: '100%',
                                    borderRadius: '10px',
                                    border: '1px solid grey',
                                    fontSize: '14px',
                                    display: email ? ' none' : 'block'
                                  }}
                                  type='text'
                                  className='email-input'
                                  value={tag}
                                  onChange={(e) => setTag(e.target.value)}
                                  placeholder='Enter Penntag or merchant Tag'
                                />{' '}
                              </div>
                            </div>
                          </div>
                          <div
                            className='status-container'
                            style={{
                              display: 'flex',
                              justifyContent: 'space-evenly',
                              alignItems: 'center'
                            }}
                          >
                            <button className='button' onClick={handleClear}>
                              <p>Clear</p>
                            </button>
                            <button className='button' onClick={handleFilter}>
                              <p>Filter</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>

                <MDBTable responsive style={{ 'width': '100%' }}>
                  <MDBTableHead>
                    <tr>
                      <th scope='col'>S/N</th>
                      <th scope='col'>Customer Name</th>
                      <th scope='col'>Mobile Number</th>
                      <th scope='col'>Email Address</th>
                      <th scope='col'>Username</th>
                      <th scope='col'>Wallet Balance</th>
                      <th scope='col'>Total Amount Funded</th>
                      <th scope='col'>Actions</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      officer?.items?.map((tran, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>
                            {tran?.first_name} {tran?.last_name}
                          </td>
                          <td>{tran?.mobile_number}</td>
                          <td>{tran?.email_address}</td>
                          <td>{tran?.username}</td>
                          <td>{format(tran?.wallet_balance)}</td>
                          <td>{format(tran?.total_amount_funded)}</td>

                          <td>
                            <button className='withdrawal-btn'>
                              <a href={`/${tran?.id}`}>View More</a>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
                <Paginate
                  totPages={officer?.page?.total_page}
                  currentPage={String(officer?.page?.current_page)}
                  page={String(officer?.page?.current_page)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default SingleOfficerScreen

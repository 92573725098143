import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

const SideNav = () => {
  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const [styles, setStyles] = useState({
    navContainer: {
      display: "none",
      background: "#000",
      color: "grey"
    }
  })

  // const handleClick = () => {
  //   if (styles.navContainer.display === 'block') {
  //     setStyles({
  //       navContainer: {
  //         display: 'none',
  //         background: '#000',
  //         color: 'grey'
  //       }
  //     })
  //   } else {
  //     setStyles({
  //       navContainer: {
  //         display: 'block',
  //         background: '#000',
  //         color: 'grey'
  //       }
  //     })
  //   }
  // }

  return (
    <nav>
      <div className="logo"></div>
      <ul>
        {userInfo?.user?.role === "TREEGAR" && (
          <Link to="/treegar">
            <li>
              <div className="icon"></div>
              <span>Treegar</span>
            </li>
          </Link>
        )}
        {userInfo?.user?.role === "Account Officer" ||
        userInfo?.user?.role === "DSA" ? (
          <>
            <Link to={`/target-savings/officer/${userInfo?.user?.id}`}>
              <li>
                <div className="icon"></div>
                <span>Target Savings Performance</span>
              </li>
            </Link>
            <Link to={`/officer/${userInfo?.user?.id}`}>
              <li>
                <div className="icon"></div>
                <span>All Performance</span>
              </li>
            </Link>
          </>
        ) : userInfo?.user?.role != "TREEGAR" ? (
          <>
            <Link to="/dashboard">
              <li className="active">
                <div className="icon"></div>
                <span>Dashboard</span>
              </li>
            </Link>
            <Link to="/transactions">
              <li>
                <div className="icon"></div>
                <span>Transactions</span>
              </li>
            </Link>

            <Link to="/customers">
              <li>
                <div className="icon"></div>
                <span>Customer Profile</span>
              </li>
            </Link>
            <Link to="/balances">
              <li>
                <div className="icon"></div>
                <span>Balances</span>
              </li>
            </Link>
            <Link to="/balances-metrics">
              <li>
                <div className="icon"></div>
                <span>Balances Metrics</span>
              </li>
            </Link>

            {(userInfo?.user?.role === "Admin" ||
              userInfo?.user?.role === "Operations") && (
              <>
                <Link to="/pending-withdrawal">
                  <li>
                    <div className="icon"></div>
                    <span>Pending Withdrawal</span>
                  </li>
                </Link>
                <Link to="/target-savings/performance">
                  <li>
                    <div className="icon"></div>
                    <span>Target Savings Performance</span>
                  </li>
                </Link>

                <Link to="/merchant-account">
                  <li>
                    <div className="icon"></div>
                    <span>Merchant Account Approval</span>
                  </li>
                </Link>

                <Link to="/overdrafts">
                  <li>
                    <div className="icon"></div>
                    <span>OverDraft</span>
                  </li>
                </Link>
                {/* <Link to='/automation'>
                  <li>
                    <div className='icon'></div>
                    <span>Automate Customer</span>
                  </li>
                </Link> */}
                <Link to="/customer/limits">
                  <li>
                    <div className="icon"></div>
                    <span>Customer Limits</span>
                  </li>
                </Link>

                <Link to="/performance">
                  <li>
                    <div className="icon"></div>
                    <span>Performance</span>
                  </li>
                </Link>

                {/* <Link to='/failed-withdrawal'>
                  <li>
                    <div className='icon'></div>
                    <span>Failed Payouts</span>
                  </li>
                </Link> */}

                <Link to="/verifications">
                  <li>
                    <div className="icon"></div>
                    <span>Customer Verifications</span>
                  </li>
                </Link>

                <Link to="/rates">
                  <li>
                    <div className="icon"></div>
                    <span>Rates</span>
                  </li>
                </Link>
                <Link to="/providers">
                  <li>
                    <div className="icon"></div>
                    <span>Providers</span>
                  </li>
                </Link>
              </>
            )}
            <Link to="/terminals">
              <li>
                <div className="icon"></div>
                <span>Terminals</span>
              </li>
            </Link>

            <Link to="/treegar">
              <li>
                <div className="icon"></div>
                <span>Treegar</span>
              </li>
            </Link>

            <Link to="/investments">
              <li>
                <div className="icon"></div>
                <span> Investments</span>
              </li>
            </Link>

            <Link to="/failed-debits">
              <li>
                <div className="icon"></div>
                <span>Falied Debits</span>
              </li>
            </Link>
          </>
        ) : null}
      </ul>
    </nav>
  )
}

export default SideNav

import React, { useEffect, useRef, useState } from "react"
import SideNav from "../components/SideNav"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
import { format, getMonthName, getYears } from "../main"

import { useDispatch, useSelector } from "react-redux"

import Loader from "../components/Loader"
import { fetchAllWallets } from "../actions/adminActions"

const BalanceMetricsScreen = () => {
  const dispatch = useDispatch()
  const [datedropdown, setDateDropdown] = useState([])
  const [productId, setProductId] = useState("")
  const [year, setYear] = useState()

  const [total, setTotal] = useState(0)
  const dateRef = useRef(true)

  const balanceMetrics = useSelector((state) => state.balanceMetrics)
  const { loading, success, metrics, error } = balanceMetrics

  useEffect(() => {
    if (dateRef.current) {
      dispatch(fetchAllWallets())
      dateRef.current = false
    }
  })

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Admin Dashboard | Investments</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <div className="row">
              {/* <p className='error'>{error && error}</p> */}
              {loading && <Loader />}
              {success &&
                metrics?.length > 0 &&
                metrics?.slice(0, 7).map((d) => (
                  <div class="grid-container">
                    <div class="grid-item">
                      <div className="info-box-count" style={{ color: "grey" }}>
                        {d?.product_name} ({format(d?.transaction_amount)})
                      </div>
                      <p
                        style={{
                          letterSpacing: "1.1px",
                          fontSize: "16px"
                        }}
                      >
                        Revenue Ledger: {format(d?.revenue)}
                      </p>
                    </div>
                    <div class="grid-item">
                      <div className="info-box-count" style={{ color: "grey" }}>
                        {d?.product_name}
                      </div>
                      <p
                        style={{
                          letterSpacing: "1.1px",
                          fontSize: "16px"
                        }}
                      >
                        Expense Ledger: {format(d?.expenses)}
                      </p>
                    </div>
                    <div class="grid-item">
                      <div className="info-box-count" style={{ color: "grey" }}>
                        {d?.product_name}
                      </div>
                      <p
                        style={{
                          letterSpacing: "1.1px",
                          fontSize: "16px"
                        }}
                      >
                        Corresponding Income: {format(d?.income)}
                      </p>
                    </div>
                  </div>
                ))}

              <div class="grid-container">
                <div class="grid-item">
                  <div
                    className="info-box-count"
                    style={{
                      color: "grey"
                    }}
                  >
                    Interest Income({format(0)})
                  </div>
                </div>
                <div class="grid-item">
                  <div
                    className="info-box-count"
                    style={{
                      color: "black",
                      backgroundColor: "grey",
                      padding: "10px",
                      borderRadius: "5px",
                      marginBottom: "5px"
                    }}
                  >
                    Wallet Interest Expenses ({format(0)})
                  </div>
                  <div
                    className="info-box-count"
                    style={{
                      color: "black",
                      backgroundColor: "grey",
                      padding: "10px",
                      borderRadius: "5px",
                      marginBottom: "5px"
                    }}
                  >
                    Personal Vault Interest Expenses ({format(0)})
                  </div>
                  <div
                    className="info-box-count"
                    style={{
                      color: "black",
                      backgroundColor: "grey",
                      padding: "10px",
                      borderRadius: "5px"
                    }}
                  >
                    TS interest Expenses({format(0)})
                  </div>
                </div>
                <div class="grid-item">
                  <div
                    className="info-box-count"
                    style={{
                      color: "grey"
                    }}
                  >
                    Interest Expenses ({format(0)})
                  </div>
                </div>
              </div>

              {success &&
                metrics?.length > 0 &&
                metrics?.slice(7).map((d) => (
                  <div class="grid-container">
                    <div class="grid-item">
                      <div className="info-box-count" style={{ color: "grey" }}>
                        {d?.product_name} ({format(d?.transaction_amount)})
                      </div>
                      <p
                        style={{
                          letterSpacing: "1.1px",
                          fontSize: "16px"
                        }}
                      >
                        Revenue Ledger: {format(d?.revenue)}
                      </p>
                    </div>
                    <div class="grid-item">
                      <div className="info-box-count" style={{ color: "grey" }}>
                        {d?.product_name}
                      </div>
                      <p
                        style={{
                          letterSpacing: "1.1px",
                          fontSize: "16px"
                        }}
                      >
                        Expense Ledger: {format(d?.expenses)}
                      </p>
                    </div>
                    <div class="grid-item">
                      <div className="info-box-count" style={{ color: "grey" }}>
                        {d?.product_name}
                      </div>
                      <p
                        style={{
                          letterSpacing: "1.1px",
                          fontSize: "16px"
                        }}
                      >
                        Corresponding Income: {format(d?.income)}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BalanceMetricsScreen

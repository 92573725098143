import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { format, formatDate } from "../main"
import { CSVLink } from "react-csv"

import {
  fetchBalanceHistory,
  fetchCommisssions,
  fetchCommisssionsBreakdown
} from "../actions/dashboardActions"
import Header from "../components/Header"
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import SideNav from "../components/SideNav"
import { Helmet } from "react-helmet"
import Loader from "../components/Loader"
import moment from "moment"

const BalanceHistoryScreen = () => {
  const commissionsRef = useRef(true)
  const dispatch = useDispatch()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const balanceHistory = useSelector((state) => state.balanceHistory)
  const { loading, success, balance } = balanceHistory

  useEffect(() => {
    if (commissionsRef.current) {
      dispatch(fetchBalanceHistory())
      commissionsRef.current = false
    }
    // if (
    //   (!loading && error?.message === 'Please login again') ||
    //   error?.message === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    // }
  }, [success, dispatch, loading])

  return (
    <>
      <SideNav />
      <Helmet>
        <title>Commissions Breakdown | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Balance History (Last 10 days)</h1>

            {loading ? (
              <Loader />
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                  align="middle"
                >
                  <MDBTableHead>
                    <tr className="font-reduce">
                      <th scope="col">S/N</th>

                      <th scope="col">Individual Wallet Balance</th>
                      <th scope="col">Merchant Wallet Balance</th>
                      <th scope="col">Person Vault Balance</th>
                      <th scope="col">Target Savings Balance</th>
                      <th scope="col">Individual Wallet Funding</th>
                      <th scope="col">Merchant Wallet Funding</th>
                      <th scope="col">Paid Out Target Savings</th>
                      <th scope="col">Paid Out Target savings Interest</th>
                      <th scope="col">Target Savings Liquidation</th>
                      <th scope="col">Individual P2p Sent Money</th>
                      <th scope="col">Merchant P2p Sent Money</th>
                      <th scope="col">Paid Out Personal Vault</th>
                      <th scope="col">Total Successful Transactions</th>
                      <th scope="col">Total Pos Transactions</th>
                      <th scope="col">Total Pos Transaction Count</th>
                      <th scope="col">Total Pos Revenue</th>
                      <th scope="col">Total Pos Expenses</th>
                      <th scope="col">Individual P2B</th>
                      <th scope="col">Merchant P2B</th>
                      <th scope="col">Date Created</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      balance?.data?.map((item, key) => (
                        <tr key={key} className="font-reduce">
                          <td>{key + 1}</td>

                          <td>{format(item?.individual_wallet_balance)}</td>
                          <td>{format(item?.merchant_wallet_balance)}</td>
                          <td>{format(item?.personal_vault_balance)}</td>
                          <td>{format(item?.target_savings_balance)}</td>
                          <td>{format(item?.individual_wallet_funding)}</td>
                          <td>{format(item?.merchant_wallet_funding)}</td>
                          <td>{format(item?.paid_out_target_savings)}</td>
                          <td>
                            {format(item?.paid_out_target_savings_interest)}
                          </td>
                          <td>{format(item?.target_savings_liquidation)}</td>
                          <td>{format(item?.individual_p2_p_sent_money)}</td>
                          <td>{format(item?.merchant_p2_p_sent_money)}</td>
                          <td>{format(item?.paid_out_personal_vault)}</td>
                          <td>{format(item?.total_successful_transactions)}</td>
                          <td>{format(item?.total_pos_transactions)}</td>
                          <td>{format(item?.total_pos_transaction_count)}</td>
                          <td>{format(item?.total_pos_revenue)}</td>
                          <td>{format(item?.total_pos_expenses)}</td>
                          <td>{format(item?.individual_p2_b)}</td>
                          <td>{format(item?.merchant_p2_b)}</td>
                          <td>{moment(item?.date_created).format("LLL")}</td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default BalanceHistoryScreen

import axios from "axios";

import {
  RESEND_OTP_FAIL,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  SINGLE_USER_FAIL,
  SINGLE_USER_REQUEST,
  SINGLE_USER_SUCCESS,
  UPDATE_USER_STATUS_FAIL,
  UPDATE_USER_STATUS_REQUEST,
  UPDATE_USER_STATUS_SUCCESS,
  VERIFY_USER_FAIL,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  ADMIN_2FA_LOGIN_FAIL,
  ADMIN_2FA_LOGIN_REQUEST,
  ADMIN_2FA_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGOUT,
  ASSIGN_OFFICER_FAIL,
  ASSIGN_OFFICER_REQUEST,
  ASSIGN_OFFICER_SUCCESS,
  CUSTOMERS_DETAILS_FAIL,
  CUSTOMERS_DETAILS_REQUEST,
  CUSTOMERS_DETAILS_SUCCESS,
  CUSTOMER_INVESTMENT_FAIL,
  CUSTOMER_INVESTMENT_REQUEST,
  CUSTOMER_INVESTMENT_SUCCESS,
  DEASSIGN_OFFICER_FAIL,
  DEASSIGN_OFFICER_REQUEST,
  DEASSIGN_OFFICER_SUCCESS,
  FETCH_CUSTOMERS_FAIL,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  UPDATE_USER_ROLE_REQUEST,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAIL,
  GET_CUSTOMER_TRANSACTION_REQUEST,
  GET_CUSTOMER_TRANSACTION_SUCCESS,
  GET_CUSTOMER_TRANSACTION_FAIL,
  DEACTIVATE_CUSTOMER_REQUEST,
  DEACTIVATE_CUSTOMER_SUCCESS,
  DEACTIVATE_CUSTOMER_FAIL,
  GET_CUSTOMER_CARDS_REQUEST,
  GET_CUSTOMER_CARDS_SUCCESS,
  GET_CUSTOMER_CARDS_FAIL,
  DELETE_CUSTOMER_CARD_REQUEST,
  DELETE_CUSTOMER_CARD_SUCCESS,
  DELETE_CUSTOMER_CARD_FAIL,
  CUSTOMER_INVESTMENT_EXPIRED_REQUEST,
  CUSTOMER_INVESTMENT_EXPIRED_SUCCESS,
  CUSTOMER_INVESTMENT_EXPIRED_FAIL,
  GET_FAILED_VERIFICATIONS_REQUEST,
  GET_FAILED_VERIFICATIONS_SUCCESS,
  GET_FAILED_VERIFICATIONS_FAIL,
  GET_CUSTOMER_TARGET_SAVINGS_REQUEST,
  GET_CUSTOMER_TARGET_SAVINGS_SUCCESS,
  GET_CUSTOMER_TARGET_SAVINGS_FAIL,
  FETCH_CUSTOMERID_REQUEST,
  FETCH_CUSTOMERID_SUCCESS,
  FETCH_CUSTOMERID_FAIL,
} from "../constants/adminConstants";
import { BASE_API_URL } from "../constants/dropdown";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.post(
      `${BASE_API_URL}/admin/login`,
      { email, password },
      config
    );

    const newData = {
      data: { ...data, email },
    };
    dispatch({
      type: ADMIN_LOGIN_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: ADMIN_LOGIN_FAIL,
      payload: error?.response?.data,
    });
  }
};

export const login2fa = (otp) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_2FA_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {
      adminLogin: { tokenInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();

    const email = tokenInfo?.data?.email;

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.post(
      `${BASE_API_URL}/admin/login/complete`,
      { email, otp },
      config
    );

    localStorage.setItem("userInfo", JSON.stringify(data));

    dispatch({
      type: ADMIN_2FA_LOGIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_2FA_LOGIN_FAIL,
      payload: error?.response?.data,
    });
  }
};

export const otpResend = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESEND_OTP_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {
      adminLogin: { tokenInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();

    const email = tokenInfo?.data?.email;
    const log_in_hash = tokenInfo?.data?.login_hash;

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.post(
      `${BASE_API_URL}/admin/login/resend/otp`,
      { email, log_in_hash },
      config
    );

    dispatch({
      type: RESEND_OTP_SUCCESS,
      payload: data,
    });

    const newData = {
      data: { ...data, email },
    };

    dispatch({
      type: ADMIN_LOGIN_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    dispatch({
      type: RESEND_OTP_FAIL,
      payload: error?.response?.data,
    });
  }
};

export const getCustomers =
  (pageNumber, filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_CUSTOMERS_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (filterData === undefined) {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/customer/all?size=300&page=${pageNumber}`,
          config
        );

        dispatch({
          type: GET_CUSTOMERS_SUCCESS,
          payload: data,
        });
      } else {
        const year = new Date().getFullYear();
        const date = new Date(year, 0, 1);
        const startDate =
          filterData.startDate === undefined ? date : filterData.startDate;

        const {
          endDate,
          email,
          name,
          tag,
          kycLevel,
          newCustomer,
          verifiedCustomer,
          exported,
          isMigrating,
        } = filterData;

        const checkboxArray = [
          {
            name: newCustomer,
            status: "New",
          },

          {
            name: verifiedCustomer,
            status: "Verified",
          },
        ];
        const filtered = checkboxArray.filter((c) => c.name === true);
        const mapped = filtered.map((m) => m.status);
        const statusVal = mapped.toString();

        if (email) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/all?customeremail=${email}&size=300`,
            config
          );

          dispatch({
            type: GET_CUSTOMERS_SUCCESS,
            payload: data,
          });
        } else if (name) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/all?customername=${name}&size=300`,
            config
          );

          dispatch({
            type: GET_CUSTOMERS_SUCCESS,
            payload: data,
          });
        } else if (tag) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/all?customertag=${tag}&size=300`,
            config
          );

          dispatch({
            type: GET_CUSTOMERS_SUCCESS,
            payload: data,
          });
        } else if (kycLevel) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/all?kyclevel=${Number(
              kycLevel
            )}&size=300`,
            config
          );

          console.log(
            `${BASE_API_URL}/admin/customer/all?kyclevel=${Number(
              kycLevel
            )}&size=300`
          );

          dispatch({
            type: GET_CUSTOMERS_SUCCESS,
            payload: data,
          });
        } else if (isMigrating === true) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/all?&customerMigrating=${isMigrating}&size=300`,
            config
          );

          dispatch({
            type: GET_CUSTOMERS_SUCCESS,
            payload: data,
          });
        } else if (filtered.length > 0) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/all?status=${statusVal}&size=300&page=${pageNumber}`,
            config
          );

          dispatch({
            type: GET_CUSTOMERS_SUCCESS,
            payload: data,
          });
        } else if (exported === true) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/all?fromdate=${startDate}&todate=${endDate}&export=${exported}`,
            config
          );

          dispatch({
            type: GET_CUSTOMERS_SUCCESS,
            payload: data,
          });
        } else {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/all?size=300&page=${pageNumber}`,
            config
          );

          dispatch({
            type: GET_CUSTOMERS_SUCCESS,
            payload: data,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: GET_CUSTOMERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message ?? error.response,
      });
    }
  };

export const getCustomerDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CUSTOMERS_DETAILS_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE_API_URL}/admin/customer/detail/${id}`,
      config
    );

    dispatch({
      type: CUSTOMERS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMERS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message ?? error.response,
    });
  }
};

export const getSingleUser = (PennTag) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SINGLE_USER_REQUEST,
    });

    const {
      adminLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}users?PennTag=${PennTag}`,
      config
    );

    dispatch({
      type: SINGLE_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SINGLE_USER_FAIL,
      payload: error?.response?.data,
    });
  }
};

export const getCustomerInvestments =
  (pageNumber, filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CUSTOMER_INVESTMENT_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (filterData === undefined) {
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/investment/customer/all?status=${"Active"}&size=500&page=${pageNumber}&balance=${false}`,
          config
        );

        dispatch({
          type: CUSTOMER_INVESTMENT_SUCCESS,
          payload: data,
        });
      } else {
        const { email, name, accountNumber, tag, byBalance } = filterData;

        if (email) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/investment/customer/all?customeremail=${email}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: CUSTOMER_INVESTMENT_SUCCESS,
            payload: data,
          });
        } else if (tag) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/investment/customer/all?customertag=${tag}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: CUSTOMER_INVESTMENT_SUCCESS,
            payload: data,
          });
        } else if (accountNumber) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/investment/customer/all?accountNumber=${accountNumber}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: CUSTOMER_INVESTMENT_SUCCESS,
            payload: data,
          });
        } else if (name) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/investment/customer/all?customername=${name}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: CUSTOMER_INVESTMENT_SUCCESS,
            payload: data,
          });
        } else {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/investment/customer/all?balance=${byBalance}`,
            config
          );

          dispatch({
            type: CUSTOMER_INVESTMENT_SUCCESS,
            payload: data,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: CUSTOMER_INVESTMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getCustomerInvestments2 =
  (pageNumber, filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CUSTOMER_INVESTMENT_EXPIRED_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (filterData === undefined) {
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/investment/customer/all?status=${
            ("Closed", "Completed")
          }&size=500&page=${pageNumber}&balance=false`,
          config
        );

        dispatch({
          type: CUSTOMER_INVESTMENT_EXPIRED_SUCCESS,
          payload: data,
        });
      } else {
        const { email, name, accountNumber, tag, byBalance } = filterData;

        if (email) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/investment/customer/all?customeremail=${email}&status=${
              ("Closed", "Completed")
            }&balance=${byBalance}`,
            config
          );

          dispatch({
            type: CUSTOMER_INVESTMENT_EXPIRED_SUCCESS,
            payload: data,
          });
        } else if (tag) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/investment/customer/all?customertag=${tag}&status=${
              ("Closed", "Completed")
            }&balance=${byBalance}`,
            config
          );

          dispatch({
            type: CUSTOMER_INVESTMENT_EXPIRED_SUCCESS,
            payload: data,
          });
        } else if (accountNumber) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/investment/customer/all?accountNumber=${accountNumber}&status=${
              ("Closed", "Completed")
            }&balance=${byBalance}`,
            config
          );

          dispatch({
            type: CUSTOMER_INVESTMENT_EXPIRED_SUCCESS,
            payload: data,
          });
        } else if (name) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/investment/customer/all?customername=${name}&status=${
              ("Closed", "Completed")
            }&balance=${byBalance}`,
            config
          );

          dispatch({
            type: CUSTOMER_INVESTMENT_EXPIRED_SUCCESS,
            payload: data,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: CUSTOMER_INVESTMENT_EXPIRED_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("persist:root");
  dispatch({ type: ADMIN_LOGOUT });
};

export const assignOfficerToCustomer =
  (reqData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ASSIGN_OFFICER_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { customer_id, admin_id } = reqData;

      // getting our response as we set it in our userController in the backend
      const { data } = await axios.post(
        `${BASE_API_URL}/admin/customer/management/assign/officer`,
        { customer_id, admin_id },
        config
      );
      dispatch({
        type: ASSIGN_OFFICER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ASSIGN_OFFICER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deassignOfficerFromCustomer =
  (reqData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DEASSIGN_OFFICER_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      // getting our response as we set it in our userController in the backend
      const { data } = await axios.post(
        `${BASE_API_URL}/admin/customer/management/deassign/officer`,
        { reqData },
        config
      );
      dispatch({
        type: DEASSIGN_OFFICER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DEASSIGN_OFFICER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const userVerification = (reqData) => async (dispatch) => {
  try {
    dispatch({
      type: VERIFY_USER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const postData = {
      first_name: reqData.firstName,
      last_name: reqData.lastName,
      mobile_number: reqData.mobile_number,
      password: reqData.password,
      token: reqData.access_token,
    };

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.post(
      `${BASE_API_URL}/admin/user/verify/account`,
      postData,
      config
    );
    dispatch({
      type: VERIFY_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VERIFY_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchCustomers = (filter) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_CUSTOMERS_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    if (!isNaN(filter)) {
      // getting our response as we set it in our userController in the backend
      const { data } = await axios.get(
        `${BASE_API_URL}/admin/customer/all?accountnumber=${filter}`,
        config
      );

      dispatch({
        type: FETCH_CUSTOMERS_SUCCESS,
        payload: data,
      });
    } else {
      const { data } = await axios.get(
        `${BASE_API_URL}/admin/customer/all?customertag=${filter}`,
        config
      );

      dispatch({
        type: FETCH_CUSTOMERS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_CUSTOMERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message ?? error.response,
    });
  }
};

export const setUserStatus = (postData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_USER_STATUS_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { user_id, action } = postData;

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.put(
      `${BASE_API_URL}/admin/user/status/update`,
      { user_id, action },
      config
    );

    dispatch({
      type: UPDATE_USER_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_STATUS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setForgotPassword = (email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${BASE_API_URL}/admin/forgot/password`,
      { email },
      config
    );

    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setResetPassword = (postData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { email_address, otp, new_password } = postData;

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.post(
      `${BASE_API_URL}/admin/reset/password/complete`,
      { email_address, otp, new_password },
      config
    );

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setUserRole = (postData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_USER_ROLE_REQUEST,
    });

    const {
      admin2faLogin: { userInfo },
    } = getState();
    const token = userInfo?.token?.access_token;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { user_id, role_id } = postData;

    console.log(config);

    const { data } = await axios.patch(
      `${BASE_API_URL}/admin/user/role/edit/${user_id}/${role_id}`,
      config
    );

    dispatch({
      type: UPDATE_USER_ROLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_ROLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchAllCustomerTransactions =
  (email, page) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_CUSTOMER_TRANSACTION_REQUEST,
      });
      const {
        admin2faLogin: { userInfo },
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (page === undefined) {
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/transaction/all?size=${20}&penntag=${email}`,
          config
        );
        dispatch({
          type: GET_CUSTOMER_TRANSACTION_SUCCESS,
          payload: data,
        });
      } else {
        const pages = isNaN(page) ? 1 : page;
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/transaction/all?size=${20}&penntag=${email}&page=${pages}`,
          config
        );
        dispatch({
          type: GET_CUSTOMER_TRANSACTION_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CUSTOMER_TRANSACTION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const customerDeactivate = (postData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEACTIVATE_CUSTOMER_REQUEST,
    });
    const {
      admin2faLogin: { userInfo },
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { account_locked_until, customer_id } = postData;

    const { data } = await axios.put(
      `${BASE_API_URL}/admin/customer/deactivate`,
      { account_locked_until, customer_id },
      config
    );
    dispatch({
      type: DEACTIVATE_CUSTOMER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DEACTIVATE_CUSTOMER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// export const fetchAllCustomerTransactions =
//   (email) => async (dispatch, getState) => {
//     try {
//       dispatch({
//         type: GET_CUSTOMER_TRANSACTION_REQUEST
//       })
//       const {
//         admin2faLogin: { userInfo }
//       } = getState()
//       const token = userInfo?.token?.access_token
//       const config = {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`
//         }
//       }

//       const { data } = await axios.get(
//         `${BASE_API_URL}/admin/transaction/all?size=${1000}&customeremail=${email}`,
//         config
//       )
//       dispatch({
//         type: GET_CUSTOMER_TRANSACTION_SUCCESS,
//         payload: data
//       })
//     } catch (error) {
//       dispatch({
//         type: GET_CUSTOMER_TRANSACTION_FAIL,
//         payload:
//           error.response && error.response.data.message
//             ? error.response.data.message
//             : error.message
//       })
//     }
//   }

export const fetchCustomerCards = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_CUSTOMER_CARDS_REQUEST,
    });
    const {
      admin2faLogin: { userInfo },
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE_API_URL}/admin/customer/cards?customerid=${id}`,
      config
    );
    dispatch({
      type: GET_CUSTOMER_CARDS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMER_CARDS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeCustomerCard = (postData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_CUSTOMER_CARD_REQUEST,
    });
    const {
      admin2faLogin: { userInfo },
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { cardid, remove } = postData;

    console.log(postData);

    const { data } = await axios.put(
      `${BASE_API_URL}/admin/customer/card/${cardid}`,
      { cardid, remove },
      config
    );
    dispatch({
      type: DELETE_CUSTOMER_CARD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CUSTOMER_CARD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchCustomerFailedVerfications =
  (pageNumber, filter) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_FAILED_VERIFICATIONS_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const page = isNaN(pageNumber) ? 1 : pageNumber;

      if (filter === undefined) {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/customer/management/verifications`,
          config
        );

        dispatch({
          type: GET_FAILED_VERIFICATIONS_SUCCESS,
          payload: data,
        });
      } else {
        const year = new Date().getFullYear();
        const date = new Date(year, 0, 1);
        const { type, status, customertag, endDate } = filter;

        const startDate =
          filter.startDate === undefined ? date : filter.startDate;

        if (customertag) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/verifications?customertag=${customertag}`,
            config
          );

          dispatch({
            type: GET_FAILED_VERIFICATIONS_SUCCESS,
            payload: data,
          });
        } else {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/verifications?status=${status}&type=${type}&fromdate=${startDate}&todate=${endDate}&page=${page}`,
            config
          );

          console.log(
            `${BASE_API_URL}/admin/customer/management/verifications?status=${status}&type=${type}&fromdate=${startDate}&todate=${endDate}&page=${page}`
          );

          dispatch({
            type: GET_FAILED_VERIFICATIONS_SUCCESS,
            payload: data,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: GET_FAILED_VERIFICATIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message ?? error.response,
      });
    }
  };

export const fetchSingleTargetSavings =
  (filterData, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_CUSTOMER_TARGET_SAVINGS_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (filterData) {
        const { email, tag, statusid } = filterData;

        if (email) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/target-savings?customeremail=${email}`,
            config
          );

          dispatch({
            type: GET_CUSTOMER_TARGET_SAVINGS_SUCCESS,
            payload: data,
          });
        } else if (tag) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/target-savings?customertag=${tag}`,
            config
          );

          dispatch({
            type: GET_CUSTOMER_TARGET_SAVINGS_SUCCESS,
            payload: data,
          });
        } else if (statusid) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/target-savings?statusid=${statusid}`,
            config
          );

          dispatch({
            type: GET_CUSTOMER_TARGET_SAVINGS_SUCCESS,
            payload: data,
          });
        }
      } else {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/customer/management/target-savings/single?customerid=${id}`,
          config
        );

        dispatch({
          type: GET_CUSTOMER_TARGET_SAVINGS_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_CUSTOMER_TARGET_SAVINGS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchCustomerid = (filter) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_CUSTOMERID_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE_API_URL}/admin/customer/all?customertag=${filter}`,
      config
    );

    console.log(data);

    dispatch({
      type: FETCH_CUSTOMERID_SUCCESS,
      payload: data?.items[0]?.id,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CUSTOMERID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message ?? error.response,
    });
  }
};

import React, { useEffect, useState } from 'react'
import SideNav from '../components/SideNav'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { addDays } from 'date-fns'
import { arrayFilter, calcNetInterest, generateInvestmentTag } from '../main'
import {
  createTreegarInvestment,
  fetchTreegarInvestmentConstant
} from '../actions/adminActions'

const CreateTreegarInvestment = ({ constant }) => {
  const dispatch = useDispatch()
  const year = new Date().getFullYear()
  const [investment_tag, setInvestmentTag] = useState('')
  const [status, setStatus] = useState('ACTIVE')
  const [customer_name, setCustomerName] = useState('')
  const [inflow, setInflow] = useState(1000)
  const [effective_date, setEffectiveDate] = useState(new Date())
  const [tenor, setTenor] = useState(0)
  const [interest_expense, setInterestExpense] = useState(0)
  const [gross_interest_payout, setGrossInterestPayout] = useState(0)
  const [wht_deduction, setWhtdeduction] = useState(0)
  const [charges, setCharges] = useState(0)
  const [net_interest_payout, setNetInterestPayout] = useState(0)
  const [withdrawal_amount, setWithdrawalAmount] = useState(0)
  const [is_assest, setIsAssest] = useState(true)
  const [investment_type_name, setInvestmentTypeName] = useState('')

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const treegarInvestmentCreate = useSelector(
    (state) => state.treegarInvestmentCreate
  )
  const { loading, error, success, investment } = treegarInvestmentCreate

  const submitHandler = (e) => {
    e.preventDefault()
    setInvestmentTag(generateInvestmentTag())
    setGrossInterestPayout(calcNetInterest(interest_expense, inflow))
    setWhtdeduction(arrayFilter(constant, 'WHT'))
    setCharges(arrayFilter(constant, 'Charges'))
    // const diffTime = Math.abs(maturity_date - effective_date)
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    const maturity_date = addDays(effective_date, tenor)

    const postData = {
      investment_tag,
      status,
      customer_name,
      inflow,
      effective_date,
      tenor,
      maturity_date,
      interest_expense,
      gross_interest_payout,
      wht_deduction,
      charges,
      is_assest,
      investment_type_name,
      created_by: userInfo?.user?.id
    }
    console.log(postData)
    dispatch(createTreegarInvestment(postData))
  }

  return (
    <div className='investment-form treegar-investment'>
      <form onSubmit={submitHandler}>
        <p
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'red',
            fontSize: '20px',
            letterSpacing: '1.5px',
            marginBottom: '10px'
          }}
        >
          {error && error}
        </p>
        <p
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'green',
            fontSize: '20px',
            letterSpacing: '1.5px'
          }}
        >
          {success && 'Investment Created Successfully'}
        </p>
        <div className='form-group'>
          <label htmlFor='email' style={{ color: '#ffffff' }}>
            Customer Name:
          </label>

          <input
            className='invest-input'
            type='text'
            placeholder='Input Customer Name.'
            value={customer_name}
            onChange={(e) => setCustomerName(e.target.value)}
            min={0}
            max={9}
          />
          <br />
        </div>
        <div className='form-group'>
          <label htmlFor='email' style={{ color: '#ffffff' }}>
            Inflow
          </label>
          <input
            className='invest-input'
            type='number'
            name='investment name'
            value={inflow}
            onChange={(e) => setInflow(e.target.value)}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='email' style={{ color: '#ffffff' }}>
            Effective Date
          </label>

          <DatePicker
            showIcon
            className='invest-input'
            selected={effective_date}
            onChange={(date) => setEffectiveDate(date)}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='email' style={{ color: '#ffffff' }}>
            Tenor
          </label>

          <input
            className='invest-input'
            type='number'
            name='investment name'
            value={tenor}
            onChange={(e) => setTenor(e.target.value)}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='email' style={{ color: '#ffffff' }}>
            Balance Sheet Class
          </label>
          <select
            className='cus-select'
            onChange={(e) => setIsAssest(e.target.value)}
          >
            <option value=''>Select Balance sheet class</option>

            <option value={true}>Asset</option>
            <option value={false}>Liabilities</option>
          </select>
        </div>

        <div className='form-group'>
          <label htmlFor='email' style={{ color: '#ffffff' }}></label>
          <select
            className='cus-select'
            onChange={(e) => setInvestmentTypeName(e.target.value)}
          >
            <option value='1,2,3'>Select Investment Kind</option>

            <option value='Fixed Income'> Fixed Income </option>
            <option value='Mutual Fund'>Mutual Fund</option>
            <option value='Pension Fund Mgt'>Pension Fund Mgt</option>
            <option value='Private Equity'>Private Equity</option>
            <option value='Treasury Bill'>Treasury Bill</option>
            <option value='Real Estate'>Real Estate</option>
          </select>
        </div>

        <div className='form-group'>
          <label htmlFor='email' style={{ color: '#ffffff' }}>
            Interest Expense
          </label>
          <input
            className='invest-input'
            type='number'
            name='investment name'
            value={interest_expense}
            onChange={(e) => setInterestExpense(e.target.value)}
            placeholder='Enter Interest Expense'
          />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <button type='submit' className='login-btn'>
              Proceed
            </button>
          </>
        )}
      </form>
    </div>
  )
}

export default CreateTreegarInvestment

import { faGear, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import Header from "../components/Header"
import MarketPlace from "../components/MarketPlace"
import PersonalVault from "../components/PersonalVault"
import SideNav from "../components/SideNav"
import TargetSavings from "../components/TargetSavings"
import PersonalVaultExpired from "../components/PersonalVaultExpired"

const InvestmentsScreen = () => {
  const navigate = useNavigate()
  const [marketPlaceTab, setMarketplaceTab] = useState(true)
  const [vaultTab, setVaultTab] = useState(false)
  const [vaultTab2, setVaultTab2] = useState(false)
  const [targetSavingsTab, setTargetSavingsTab] = useState(false)

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const setTabs = (param) => {
    if (param === "market") {
      setMarketplaceTab(true)
      setVaultTab(false)
      setTargetSavingsTab(false)
      setVaultTab2(false)
    } else if (param === "vault") {
      setVaultTab(true)
      setTargetSavingsTab(false)
      setMarketplaceTab(false)
      setVaultTab2(false)
    } else if (param === "target") {
      setVaultTab(false)
      setTargetSavingsTab(true)
      setMarketplaceTab(false)
      setVaultTab2(false)
    } else if (param === "vault2") {
      setVaultTab(false)
      setTargetSavingsTab(false)
      setMarketplaceTab(false)
      setVaultTab2(true)
    }
  }

  useEffect(() => {
    if (!userInfo || userInfo === null) {
      navigate("/", { replace: true })
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate("/performance", { replace: true })
    }
  })
  return (
    <>
      <SideNav />
      <Helmet>
        <title> Admin Dashboard | Investments</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            {(userInfo?.user?.role === "Admin" ||
              userInfo?.user?.role === "Operations") && (
              <div
                className="status-container"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center"
                }}
              >
                <div
                  className="button"
                  style={{
                    marginRight: "10px"
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <Link to="/investment-create"> Add Investment </Link>
                </div>
                <div className="button">
                  <FontAwesomeIcon icon={faGear} />
                  <Link to="/investments-settings"> Investment Settings</Link>
                </div>
              </div>
            )}
            <ul
              className="tab-header header-styled"
              style={{
                justifyContent: "flex-start"
              }}
            >
              <li onClick={() => setTabs("market")}>MarketPlace</li>

              <li onClick={() => setTabs("vault")}>Personal Vault(Active)</li>

              <li onClick={() => setTabs("vault2")}>Personal Vault(Expired)</li>

              <li onClick={() => setTabs("target")}>Target Savings</li>
            </ul>

            <div className="tab-body">
              <div
                className="marketPlace-body"
                style={{
                  display: marketPlaceTab ? "block" : "none"
                }}
              >
                <MarketPlace />
              </div>
              <div
                className="vault-body"
                style={{
                  display: vaultTab ? "block" : "none"
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    fontSize: "15px",
                    letterSpacing: "1.2px",
                    color: "#ffffff",
                    fontWeight: "bold",
                    padding: "0.5em 0.5em 0.5em",
                    margin: "0 auto",
                    width: "100%"
                  }}
                  // className='button'
                >
                  Personal Vault(Active)
                </p>
                <PersonalVault />
              </div>

              <div
                className="vault-body2"
                style={{
                  display: vaultTab2 ? "block" : "none"
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    fontSize: "15px",
                    letterSpacing: "1.2px",
                    color: "#ffffff",
                    fontWeight: "bold",
                    padding: "0.5em 0.5em 0.5em",
                    margin: "0 auto",
                    width: "100%"
                  }}
                  // className='button'
                >
                  Personal Vault(Expired)
                </p>
                <PersonalVaultExpired />
              </div>
              <div
                className="targetSavings-body"
                style={{
                  display: targetSavingsTab ? "block" : "none"
                }}
              >
                <TargetSavings />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default InvestmentsScreen

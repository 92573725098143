import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import SideNav from '../components/SideNav'
import { useLocation, useParams } from 'react-router'

import { fetchOfficers } from '../actions/adminActions'

import {
  assignOfficerToCustomer,
  getCustomerDetails
} from '../actions/userActions'

import SavingsLogs from '../components/SavingsLogs'

const TargetSavingsLogs = () => {
  const { id } = useParams()
  const location = useLocation()
  const { state } = location

  console.log(state)

  const dispatch = useDispatch()

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const customerRef = useRef(true)

  useEffect(() => {
    if (customerRef.current) {
      dispatch(getCustomerDetails(id))
      dispatch(fetchOfficers())
      customerRef.current = false
    }
  }, [id, dispatch])

  const setAccountOfficer = (admin) => {
    const reqData = {
      customer_id: id,
      admin_id: admin
    }
    dispatch(assignOfficerToCustomer(reqData))
  }
  return (
    <>
      <SideNav />
      <Helmet>
        <title> Transactions | PennyTree - Admin</title>
      </Helmet>
      <section className='page-container'>
        <div className='page-content'>
          <Header />

          <div className='page-body'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-12'>
                    <h6>{state?.purpose} Logs</h6>
                    <h4>Customer: {state?.name}</h4>
                    <SavingsLogs
                      customerid={state?.customerId}
                      savingsid={state?.savingsId}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TargetSavingsLogs

import React, { useEffect, useRef, useState } from "react"
import SideNav from "../components/SideNav"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
import { format, getMonthName, getYears } from "../main"
import { fetchProductTypes } from "../actions/transactionActions"
import { useDispatch, useSelector } from "react-redux"
import { fetchInterestInfo } from "../actions/interestActions"
import Loader from "../components/Loader"

const InterestInfoScreen = () => {
  const dispatch = useDispatch()
  const [datedropdown, setDateDropdown] = useState([])
  const [productId, setProductId] = useState("")
  const [year, setYear] = useState()
  const [isVault, setIsVault] = useState(false)
  const [total, setTotal] = useState(0)
  const dateRef = useRef(true)

  const productTypes = useSelector((state) => state.productTypes)
  const { success: productTypesSucess, products } = productTypes

  const interestInfo = useSelector((state) => state.interestInfo)
  const { loading, success, interest, error } = interestInfo

  useEffect(() => {
    if (dateRef.current) {
      setDateDropdown(getYears())
      dispatch(fetchProductTypes())
      dateRef.current = false
    }
    if (success) {
      const sumWithInitial = interest?.data.reduce(
        (accumulator, currentValue) => accumulator + currentValue?.total,
        0
      )
      setTotal(sumWithInitial)
    }
  })

  const submitHandler = (e) => {
    e.preventDefault()

    const filterData = {
      productId,
      year,
      isVault
    }
    dispatch(fetchInterestInfo(filterData))
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Admin Dashboard | Investments</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <form
              action=""
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <div className="form-group">
                <label htmlFor="roles" style={{ color: "#ffffff" }}>
                  Select Year to view records
                </label>
                <select
                  style={{
                    background: "#1a1a1a",
                    border: "none",
                    padding: "20px",
                    width: "400px",
                    borderRadius: "10px",
                    marginRight: "20px"
                  }}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option>Select Year</option>
                  {datedropdown[0]?.map((d) => (
                    <option value={d}>{d}</option>
                  ))}
                </select>
              </div>
              {!isVault && (
                <div className="form-group">
                  <label htmlFor="roles" style={{ color: "#ffffff" }}>
                    Select Product to view records
                  </label>
                  <select
                    style={{
                      background: "#1a1a1a",
                      border: "none",
                      padding: "20px",
                      width: "400px",
                      borderRadius: "10px",
                      marginRight: "20px"
                    }}
                    onChange={(e) => setProductId(e.target.value)}
                  >
                    <option>Select Product</option>

                    {productTypesSucess &&
                      products &&
                      products?.data?.map((d) => (
                        <option value={d?.id}>{d?.name}</option>
                      ))}
                  </select>
                </div>
              )}
              <div className="form-group">
                <label class="control">
                  <input
                    type="checkbox"
                    name="checkbox-checked"
                    checked={isVault}
                    onClick={() => setIsVault(!isVault)}
                  />
                  Checkbox for Vault Interest
                </label>
              </div>
            </form>
            <div style={{ width: "40%", display: "block", margin: "0 auto" }}>
              <button
                onClick={submitHandler}
                type="submit"
                className="login-btn"
              >
                Fetch Records
              </button>
            </div>

            <div className="row">
              {/* <p className='error'>{error && error}</p> */}
              {loading && <Loader />}
              {success && interest?.data?.length > 0 ? (
                interest?.data?.map((d) => (
                  <div className="col-lg-3">
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>
                      <div className="info-box-count">{format(d?.total)}</div>
                      <p>{getMonthName(d?.month)}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="error">No records to Display</p>
              )}
            </div>
            <div className="row">
              <div className="col-lg-3" style={{ marginTop: "20px" }}>
                <div className="info-box">
                  <i className="fa fa-users orange-icon"></i>
                  <div
                    className="info-box-count"
                    style={{ marginBottom: "20px" }}
                  >
                    {format(total)}
                  </div>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      letterSpacing: "1.1px"
                    }}
                  >
                    Total
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default InterestInfoScreen

import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Header from "../components/Header"
import Loader from "../components/Loader"
import Paginate from "../components/Paginate"
import SideNav from "../components/SideNav"
import { format, formatDate } from "../main"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { CSVLink } from "react-csv"
import { getCustomerInvestments } from "../actions/userActions"
import { fetchAllInvestments } from "../actions/investmentActions"

const InvestmentProfileScreen = () => {
  const investmentRef = useRef(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [currPage, setCurrPage] = useState(1)
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [name, setName] = useState("")
  const [amount, setAmount] = useState("")
  const [email, setEmail] = useState("")
  const [tag, setTag] = useState("")
  const [accountNumber, setAccountNumber] = useState("")

  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const customerInvestments = useSelector((state) => state.customerInvestments)
  const { loading, success, investments, error } = customerInvestments

  useEffect(() => {
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    if (!userInfo || userInfo === null) {
      navigate("/", { replace: true })
    }
    if (investmentRef.current) {
      dispatch(getCustomerInvestments(currPage))
      investmentRef.current = false
    }
    if (!userInfo) {
      navigate("/", { replace: true })
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate("/performance", { replace: true })
    }
  }, [dispatch, navigate, userInfo, currPage, error, loading])

  const afterPageClicked = (page_number) => {
    if (page_number === currPage) {
      setCurrPage(page_number + 1)
    } else {
      setCurrPage(page_number)
    }

    if (page_number === "undefined1") {
      setCurrPage(1)
    }
    dispatch(fetchAllInvestments(currPage))
  }

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      name,
      email,
      accountNumber,
      tag
    }
    dispatch(getCustomerInvestments(currPage, filterData))
  }

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    setStartDate(new Date())
    setEndDate(new Date())
    setName("")
    setAccountNumber("")
    setTag("")
    setEmail("")
    dispatch(getCustomerInvestments(currPage))
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Customer Investments | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Customer Investments</h1>
            <div className="filter-box">
              <div className="filter">
                <>
                  <div className="filter-container">
                    <p style={{ cursor: "pointer" }} onClick={handleClick}>
                      Filter
                    </p>
                    <div className="navContainer" style={styles.navContainer}>
                      <div className="date-filter">
                        <div className="date-title">Date</div>
                        <div className="date-options">
                          <div className="custom-filter__item custom-filter__item--pad">
                            <div className="overview-filter">
                              <div className="filter__item">
                                <input
                                  id="today_fkc3w9c4e0"
                                  value="Today"
                                  type="radio"
                                  name="dateFilter_fkc3w9c4e0"
                                  className="overview-filter__item__radio"
                                />
                                <label
                                  htmlFor="today_fkc3w9c4e0"
                                  className="overview-filter__item__link active"
                                >
                                  Today
                                </label>
                              </div>{" "}
                              <div className="filter__item">
                                <input
                                  id="last7_fkc3w9c4e0"
                                  value="7 days"
                                  type="radio"
                                  name="dateFilter_fkc3w9c4e0"
                                  className="overview-filter__item__radio"
                                />
                                <label
                                  htmlFor="last7_fkc3w9c4e0"
                                  className="overview-filter__item__link"
                                >
                                  Last 7 days
                                </label>
                              </div>{" "}
                              <div className="filter__item">
                                <input
                                  id="last30_fkc3w9c4e0"
                                  value="30 days"
                                  type="radio"
                                  name="dateFilter_fkc3w9c4e0"
                                  className="overview-filter__item__radio"
                                />
                                <label
                                  htmlFor="last30_fkc3w9c4e0"
                                  className="overview-filter__item__link"
                                >
                                  30 days
                                </label>
                              </div>{" "}
                              <div className="filter__item">
                                <input
                                  id="1year_fkc3w9c4e0"
                                  value="1 year"
                                  type="radio"
                                  name="dateFilter_fkc3w9c4e0"
                                  className="overview-filter__item__radio"
                                />
                                <label
                                  htmlFor="1year_fkc3w9c4e0"
                                  className="overview-filter__item__link"
                                >
                                  1 year
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="date-content">
                            <div className="date-items">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                              />

                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="status-container">
                        <div className="date-title">Customer</div>
                        <div className="checkbox checkbox--flex">
                          <div
                            className="checkbox__item"
                            style={{
                              width: "100%"
                            }}
                          >
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "1px solid grey",
                                fontSize: "14px",
                                display:
                                  accountNumber || tag || email
                                    ? " none"
                                    : "block"
                              }}
                              type="email"
                              className="email-input"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              placeholder="Enter customer Name"
                            />{" "}
                          </div>

                          <div
                            className="checkbox__item"
                            style={{
                              width: "100%"
                            }}
                          >
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "1px solid grey",
                                fontSize: "14px",
                                display:
                                  name || email || accountNumber
                                    ? " none"
                                    : "block"
                              }}
                              type="email"
                              className="email-input"
                              value={tag}
                              onChange={(e) => setTag(e.target.value)}
                              placeholder="Enter PennTag"
                            />{" "}
                          </div>

                          <div
                            className="checkbox__item"
                            style={{
                              width: "100%"
                            }}
                          >
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "1px solid grey",
                                fontSize: "14px",
                                display:
                                  name || tag || accountNumber
                                    ? " none"
                                    : "block"
                              }}
                              type="email"
                              className="email-input"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter Customer Email"
                            />{" "}
                          </div>

                          <div
                            className="checkbox__item"
                            style={{
                              width: "100%"
                            }}
                          >
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "1px solid grey",
                                fontSize: "14px",
                                display:
                                  name || email || tag ? " none" : "block"
                              }}
                              type="email"
                              className="email-input"
                              value={accountNumber}
                              onChange={(e) => setAccountNumber(e.target.value)}
                              placeholder="Enter Account Number"
                            />{" "}
                          </div>
                        </div>
                      </div>
                      <div
                        className="status-container"
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center"
                        }}
                      >
                        <button className="button" onClick={handleClear}>
                          <p>Clear</p>
                        </button>
                        <button className="button" onClick={handleFilter}>
                          <p>Filter</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
              <div className="filter">
                <CSVLink data={data} headers={headers}>
                  Export
                </CSVLink>
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                >
                  <MDBTableHead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col">Customer Id</th>
                      <th scope="col">Full Name</th>
                      <th scope="col">Investment Name</th>
                      <th scope="col">Investment Type</th>
                      <th cope="col">Amount By Slot</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Date Created</th>
                      <th scope="col">Interest Rate</th>
                      <th scope="col">Tenor</th>
                      <th scope="col">Interest Accrued</th>
                      <th scope="col">Value</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      investments?.items?.map((inv, key) => (
                        <tr key={key} className="table-width">
                          <td>{inv?.id}</td>
                          <td>{inv?.customer_id}</td>
                          <td>{inv?.full_name}</td>
                          <td>{inv?.investment_name}</td>
                          <td>{inv?.investment_type}</td>
                          <td>{format(inv?.amount_by_slot)}</td>
                          <td>{format(inv?.amount)}</td>
                          <td>{inv?.date_created.substring(0, 10)}</td>
                          <td>{inv?.interest_rate}</td>
                          <td>{inv?.interest_accured}</td>
                          <td>{inv?.tenor}</td>
                          <td>{inv?.value}</td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
                <Paginate
                  totPages={investments?.page?.total_page}
                  currentPage={String(investments?.page?.currentPage)}
                  page={String(investments?.page?.currentPage)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default InvestmentProfileScreen

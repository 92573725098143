import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Helmet } from "react-helmet"
import moment from "moment"
import Header from "../components/Header"
import SideNav from "../components/SideNav"
import { fetchOfficers } from "../actions/adminActions"
import Loader from "../components/Loader"
import Paginate from "../components/Paginate"
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import { CSVLink } from "react-csv"
import { format, formatDate } from "../main"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useNavigate } from "react-router-dom"
import { getCustomers } from "../actions/userActions"

const CustomerScreen = () => {
  const [currPage, setCurrPage] = React.useState(1)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const allCustomers = useSelector((state) => state.allCustomers)
  const { loading, success, error, customers } = allCustomers
  const customerRef = useRef(true)

  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])

  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [tag, setTag] = useState("")
  const [kycLevel, setKycLevel] = useState("")
  const [newCustomer, setNewCustomer] = useState(false)
  const [verifiedCustomer, setVerifiedCustomer] = useState(false)
  const [isMigrating, setIsMigrating] = useState(false)

  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const getOfficers = useSelector((state) => state.getOfficers)
  const { success: officersSuccess, officers } = getOfficers

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  useEffect(() => {
    // if (!userInfo || userInfo === null) {
    //   navigate('/', { replace: true })
    // }
    if (userInfo?.user?.role === "TREEGAR") {
      navigate("/treegar", { replace: true })
    }
    // if (userInfo?.user?.role === 'Account Officer') {
    //   navigate('/performance', { replace: true })
    // }
    if (customerRef.current) {
      dispatch(getCustomers(currPage))
      dispatch(fetchOfficers())
      customerRef.current = false
    }
    if (success && customers) {
      setData(
        customers?.items?.map((tran) => ({
          customer_name: tran.customer_name,
          mobile_number: tran.mobile_number,
          email_address: tran.email_address,
          gender: tran.gender,
          country: tran.country,
          status: tran.status,
          state: tran.state,
          address: tran.address,
          city: tran.city,
          dob: tran.dob,
          kyc_level_id: tran.kyc_level_id,
          kyc_level: tran.kyc_level,
          customer_type: tran.customer_type,
          wallet_balance: tran.wallet_balance,
          portfolio_balance: tran.portfolio_balance,
          account_number: tran.account_number,
          user_name: tran.user_name,
          date_joined: moment(tran?.date_created).format("LLL")
        }))
      )

      setHeaders([
        { label: "Customer Name", key: "customer_name" },
        { label: "Mobile Number", key: "mobile_number" },
        { label: "Email Address", key: "email_address" },
        { label: "Gender", key: "gender" },
        { label: "Country", key: "country" },
        { label: "Status", key: "status" },
        { label: "State", key: "state" },
        { label: "Address", key: "address" },
        { label: "City", key: "city" },
        { label: "DOB", key: "dob" },
        { label: "Kyc Level Id", key: "kyc_level_id" },
        { label: "Kyc Level", key: "kyc_level" },
        { label: "Customer Type", key: "customer_type" },
        { label: "Wallet Balance", key: "wallet_balance" },
        { label: "Portfolio Balance", key: "portfolio balance" },
        { label: "Account Number", key: "account_number" },
        { label: "User Name", key: "user_name" },
        { label: "Date Joined", key: "date_joined" }
      ])
    }
  }, [
    dispatch,
    currPage,
    customers,
    success,
    error,
    loading,
    navigate,
    userInfo
  ])
  const afterPageClicked = (page_number) => {
    if (page_number === currPage) {
      setCurrPage(page_number + 1)
    } else {
      setCurrPage(page_number)
    }

    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      name,
      tag,
      kycLevel,
      newCustomer,
      verifiedCustomer,
      isMigrating
    }
    dispatch(getCustomers(currPage, filterData))
  }

  //

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      name,
      tag,
      kycLevel,
      newCustomer,
      verifiedCustomer,
      isMigrating
    }

    console.log(filterData)

    dispatch(getCustomers(currPage, filterData))
  }

  const handleClear = () => {
    setEmail("")

    setStyles({
      navContainer: {
        display: "none"
      }
    })

    dispatch(getCustomers(currPage))
  }

  const handleExport = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      name,
      tag,
      kycLevel,
      newCustomer,
      verifiedCustomer,
      exported: true,
      isMigrating
    }

    dispatch(getCustomers(currPage, filterData))
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Customer | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Customers</h1>
            <div className="filter-box">
              <div className="filter">
                <>
                  <div className="filter-container">
                    <p style={{ cursor: "pointer" }} onClick={handleClick}>
                      Filter
                    </p>
                    <div className="navContainer" style={styles.navContainer}>
                      <div className="date-filter">
                        <div className="date-title">Date</div>
                        <div className="date-options">
                          <div className="custom-filter__item custom-filter__item--pad">
                            <div className="overview-filter">
                              <div className="filter__item">
                                <input
                                  id="today_fkc3w9c4e0"
                                  value="Today"
                                  type="radio"
                                  name="dateFilter_fkc3w9c4e0"
                                  className="overview-filter__item__radio"
                                />
                                <label
                                  htmlFor="today_fkc3w9c4e0"
                                  className="overview-filter__item__link active"
                                >
                                  Today
                                </label>
                              </div>{" "}
                              <div className="filter__item">
                                <input
                                  id="last7_fkc3w9c4e0"
                                  value="7 days"
                                  type="radio"
                                  name="dateFilter_fkc3w9c4e0"
                                  className="overview-filter__item__radio"
                                />
                                <label
                                  htmlFor="last7_fkc3w9c4e0"
                                  className="overview-filter__item__link"
                                >
                                  Last 7 days
                                </label>
                              </div>{" "}
                              <div className="filter__item">
                                <input
                                  id="last30_fkc3w9c4e0"
                                  value="30 days"
                                  type="radio"
                                  name="dateFilter_fkc3w9c4e0"
                                  className="overview-filter__item__radio"
                                />
                                <label
                                  htmlFor="last30_fkc3w9c4e0"
                                  className="overview-filter__item__link"
                                >
                                  30 days
                                </label>
                              </div>{" "}
                              <div className="filter__item">
                                <input
                                  id="1year_fkc3w9c4e0"
                                  value="1 year"
                                  type="radio"
                                  name="dateFilter_fkc3w9c4e0"
                                  className="overview-filter__item__radio"
                                />
                                <label
                                  htmlFor="1year_fkc3w9c4e0"
                                  className="overview-filter__item__link"
                                >
                                  1 year
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="date-content">
                            <div className="date-items">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                              />

                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="status-container">
                        <div className="date-title">Status</div>
                        <div className="checkbox checkbox--flex">
                          <div className="checkbox__item">
                            <input
                              id="successful_wz3gl9i2d"
                              type="checkbox"
                              name="successful_wz3gl9i2d"
                              className="checkbox__input"
                              value="successful"
                              checked={newCustomer}
                              onChange={() => setNewCustomer(!newCustomer)}
                            />{" "}
                            <label
                              htmlFor="successful_wz3gl9i2d"
                              className="checkbox__label"
                            >
                              New Customer
                            </label>
                          </div>

                          <div className="checkbox__item">
                            <input
                              id="new_wz3gl9i2d"
                              type="checkbox"
                              name="new_wz3gl9i2d"
                              className="checkbox__input"
                              checked={verifiedCustomer}
                              onChange={() =>
                                setVerifiedCustomer(!verifiedCustomer)
                              }
                            />{" "}
                            <label
                              htmlFor="new_wz3gl9i2d"
                              className="checkbox__label"
                            >
                              Verfied Customer
                            </label>
                          </div>
                          <div className="checkbox__item">
                            <input
                              id="migrating_wz3gl9i2d"
                              type="checkbox"
                              name="migrating_wz3gl9i2d"
                              className="checkbox__input"
                              checked={isMigrating}
                              onChange={() => setIsMigrating(!isMigrating)}
                            />{" "}
                            <label
                              htmlFor="migrating_wz3gl9i2d"
                              className="checkbox__label"
                            >
                              Migrated Customer
                            </label>
                          </div>
                        </div>
                        <div className="date-title">Customer</div>
                        <div className="checkbox checkbox--flex">
                          <div
                            className="checkbox__item"
                            style={{
                              width: "100%"
                            }}
                          >
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "1px solid grey",
                                fontSize: "14px",
                                display:
                                  tag || name || kycLevel ? " none" : "block"
                              }}
                              type="email"
                              className="email-input"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter customer Email"
                            />{" "}
                          </div>
                          <div
                            className="checkbox__item"
                            style={{
                              width: "100%"
                            }}
                          >
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "1px solid grey",
                                fontSize: "14px",
                                display:
                                  tag || kycLevel || email ? " none" : "block"
                              }}
                              type="email"
                              className="email-input"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              placeholder="Enter customer Name"
                            />{" "}
                          </div>
                          <div
                            className="checkbox__item"
                            style={{
                              width: "100%"
                            }}
                          >
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "1px solid grey",
                                fontSize: "14px",
                                display:
                                  kycLevel || name || email ? " none" : "block"
                              }}
                              type="email"
                              className="email-input"
                              value={tag}
                              onChange={(e) => setTag(e.target.value)}
                              placeholder="Enter PennTag"
                            />{" "}
                          </div>
                          <div
                            className="checkbox__item"
                            style={{
                              width: "100%"
                            }}
                          >
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "1px solid grey",
                                fontSize: "14px",
                                display:
                                  tag || name || email ? " none" : "block"
                              }}
                              type="number"
                              className="email-input"
                              value={kycLevel}
                              onChange={(e) => setKycLevel(e.target.value)}
                              placeholder="Enter Kyc Level between 1 to 3"
                              min="1"
                              max="3"
                            />{" "}
                          </div>
                          {/* <div
                            className='checkbox__item'
                            style={{
                              width: '100%'
                            }}
                          >
                            <select>
                              {officersSuccess &&
                                officers.items.map((officer) => (
                                  <option value={officer?.id}>
                                    {officer?.name}
                                  </option>
                                ))}
                            </select>
                          </div> */}
                        </div>
                      </div>
                      <div
                        className="status-container"
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center"
                        }}
                      >
                        <button className="button" onClick={handleClear}>
                          <p>Clear</p>
                        </button>
                        <button className="button" onClick={handleFilter}>
                          <p>Filter</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
              <div className="filter" onClick={handleExport}>
                <CSVLink data={data} headers={headers}>
                  Export
                </CSVLink>
              </div>
            </div>
            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable responsive style={{ "width": "100%" }}>
                  <MDBTableHead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col">Customer ID</th>
                      <th scope="col">Customer Name</th>
                      <th scope="col">Mobile Number</th>
                      <th scope="col">Email Address</th>
                      <th scope="col">Gender</th>
                      <th scope="col">Country</th>
                      <th scope="col">Status</th>
                      <th scope="col">state</th>
                      <th scope="col">Address</th>
                      <th scope="col">City</th>
                      <th scope="col">DOB</th>
                      <th scope="col">Kyc Level Id</th>
                      <th scope="col">Kyc Level</th>
                      <th scope="col">Is Migrating</th>
                      <th scope="col">Transaction Count</th>
                      <th scope="col">Customer Type</th>
                      <th scope="col">Wallet Balance</th>
                      <th scope="col">Portfolio Balance</th>
                      <th scope="col">Individual Account Number</th>
                      <th scope="col">Merchant Account Number</th>
                      <th scope="col">PennTag</th>
                      <th scope="col">Merchant Tag</th>
                      <th scope="col">Date Joined</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      customers?.items?.map((tran, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{tran?.id}</td>
                          <td style={{ "width": "100%" }}>
                            <a href={`/${tran?.id}`}>{tran?.customer_name}</a>
                          </td>
                          <td>{tran?.mobile_number}</td>
                          <td>{tran?.email_address}</td>
                          <td>{tran?.gender}</td>
                          <td>{tran?.country}</td>
                          <td>{tran?.status}</td>
                          <td>{tran?.state}</td>
                          <td>
                            {tran?.address && tran?.address?.substring(0, 30)}
                            ....
                          </td>
                          <td>{tran?.city}</td>
                          <td>{tran?.date_of_birth}</td>
                          <td>{tran?.kyc_level_id}</td>
                          <td>{tran?.kyc_level}</td>
                          <td>{tran?.is_migrating ? "Yes" : "No"}</td>
                          <td>{tran?.transaction_count}</td>
                          <td>{tran?.customer_type}</td>
                          <td>{format(tran?.wallet_balance)}</td>
                          <td>{format(tran?.portfolio_balance)}</td>
                          <td>{tran?.individual_account_number}</td>
                          <td>{tran?.merchant_account_number}</td>
                          <td>{tran?.user_name}</td>
                          <td></td>
                          <td>{moment(tran?.date_created).format("LLL")}</td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
                <Paginate
                  totPages={customers?.page?.total_page}
                  currentPage={String(customers?.page?.current_page)}
                  page={String(customers?.page?.current_page)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default CustomerScreen

import axios from "axios";
import {
  ACCOUNT_OFFICER_DETAILS_FAIL,
  ACCOUNT_OFFICER_DETAILS_REQUEST,
  ACCOUNT_OFFICER_DETAILS_SUCCESS,
  ALL_BALANCES_FAIL,
  ALL_BALANCES_REQUEST,
  ALL_BALANCES_SUCCESS,
  APPROVE_REJECT_INVESTMENT_FAIL,
  APPROVE_REJECT_INVESTMENT_REQUEST,
  APPROVE_REJECT_INVESTMENT_SUCCESS,
  CREATE_MEMBER_FAIL,
  CREATE_MEMBER_REQUEST,
  CREATE_MEMBER_SUCCESS,
  CREATE_TREEGAR_INVESTMENT_FAIL,
  CREATE_TREEGAR_INVESTMENT_REQUEST,
  CREATE_TREEGAR_INVESTMENT_SUCCESS,
  GET_ALL_RATES_FAIL,
  GET_ALL_RATES_REQUEST,
  GET_ALL_RATES_SUCCESS,
  GET_ALL_TARGET_SAVINGS_FAIL,
  GET_ALL_TARGET_SAVINGS_REQUEST,
  GET_ALL_TARGET_SAVINGS_SUCCESS,
  GET_BALANCE_METRICS_FAIL,
  GET_BALANCE_METRICS_REQUEST,
  GET_BALANCE_METRICS_SUCCESS,
  GET_CUSTOMER_LIMIT_FAIL,
  GET_CUSTOMER_LIMIT_REQUEST,
  GET_CUSTOMER_LIMIT_SUCCESS,
  GET_DEPARTMENTS_FAIL,
  GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  GET_OFFICERS_FAIL,
  GET_OFFICERS_REQUEST,
  GET_OFFICERS_SUCCESS,
  GET_PERMISSIONS_FAIL,
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_SUCCESS,
  GET_ROLES_FAIL,
  GET_ROLES_REQUEST,
  GET_ROLES_SUCCESS,
  GET_SINGLE_OFFICER_FAIL,
  GET_SINGLE_OFFICER_INFO_FAIL,
  GET_SINGLE_OFFICER_INFO_REQUEST,
  GET_SINGLE_OFFICER_INFO_SUCCESS,
  GET_SINGLE_OFFICER_REQUEST,
  GET_SINGLE_OFFICER_SUCCESS,
  GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_FAIL,
  GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_REQUEST,
  GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_SUCCESS,
  GET_TARGET_SAVINGS_OFFICER_FAIL,
  GET_TARGET_SAVINGS_OFFICER_REQUEST,
  GET_TARGET_SAVINGS_OFFICER_SUCCESS,
  GET_TERMINALS_FAIL,
  GET_TERMINALS_REQUEST,
  GET_TERMINALS_SUCCESS,
  GET_TREEGAR_INVESTMENT_CONSTANT_FAIL,
  GET_TREEGAR_INVESTMENT_CONSTANT_REQUEST,
  GET_TREEGAR_INVESTMENT_CONSTANT_SUCCESS,
  GET_TREEGAR_INVESTMENT_FAIL,
  GET_TREEGAR_INVESTMENT_LIABILITY_FAIL,
  GET_TREEGAR_INVESTMENT_LIABILITY_REQUEST,
  GET_TREEGAR_INVESTMENT_LIABILITY_SUCCESS,
  GET_TREEGAR_INVESTMENT_REQUEST,
  GET_TREEGAR_INVESTMENT_SUCCESS,
  GET_TREEGAR_PENDING_INVESTMENT_FAIL,
  GET_TREEGAR_PENDING_INVESTMENT_REQUEST,
  GET_TREEGAR_PENDING_INVESTMENT_SUCCESS,
  ROLES_PERMISSIONS_FAIL,
  ROLES_PERMISSIONS_REQUEST,
  ROLES_PERMISSIONS_SUCCESS,
  SET_CUSTOMER_AUTOMATION_FAIL,
  SET_CUSTOMER_AUTOMATION_REQUEST,
  SET_CUSTOMER_AUTOMATION_SUCCESS,
  SET_CUSTOMER_LIMIT_FAIL,
  SET_CUSTOMER_LIMIT_REQUEST,
  SET_CUSTOMER_LIMIT_SUCCESS,
  TEAM_MEMBERS_FAIL,
  TEAM_MEMBERS_REQUEST,
  TEAM_MEMBERS_SUCCESS,
  UPDATE_RATE_FAIL,
  UPDATE_RATE_REQUEST,
  UPDATE_RATE_SUCCESS,
} from "../constants/adminConstants";
import { BASE_API_URL } from "../constants/dropdown";
import { calcNetInterest } from "../main";

export const fetchOfficers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_OFFICERS_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/customer/management/officers/all?size=300`,
      config
    );

    dispatch({
      type: GET_OFFICERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_OFFICERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchAccountOfficerDetails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ACCOUNT_OFFICER_DETAILS_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const id = userInfo?.user?.id;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/customer/management/officer/details/${id}`,
      config
    );

    console.log(data);

    dispatch({
      type: ACCOUNT_OFFICER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ACCOUNT_OFFICER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchTeamMembers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEAM_MEMBERS_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/users/all/paginated?size=300`,
      config
    );

    console.log(data);

    dispatch({
      type: TEAM_MEMBERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: TEAM_MEMBERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchRoles = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ROLES_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(`${BASE_API_URL}/admin/roles/all`, config);
    dispatch({
      type: GET_ROLES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_ROLES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchDepartments = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_DEPARTMENTS_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/departments/all`,
      config
    );
    dispatch({
      type: GET_DEPARTMENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_DEPARTMENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchPermissions = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PERMISSIONS_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/permissions/all`,
      config
    );
    dispatch({
      type: GET_PERMISSIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_PERMISSIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const newMember = (memberData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATE_MEMBER_REQUEST,
    });

    const {
      admin2faLogin: { userInfo },
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    console.log(memberData);
    const { data } = await axios.post(
      `${BASE_API_URL}/admin/user/create`,
      memberData,
      config
    );
    dispatch({
      type: CREATE_MEMBER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: CREATE_MEMBER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchRolePermission = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ROLES_PERMISSIONS_REQUEST,
    });

    const {
      admin2faLogin: { userInfo },
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE_API_URL}/admin/roles/permission/all`,
      config
    );

    dispatch({
      type: ROLES_PERMISSIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ROLES_PERMISSIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message ?? error.response,
    });
  }
};

export const fetchAllBalances =
  (pageNumber, filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ALL_BALANCES_REQUEST,
      });

      const {
        admin2faLogin: { userInfo },
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const page = isNaN(pageNumber) ? 1 : pageNumber;

      if (filterData === undefined) {
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/transaction/all/balances?size=300&page=${page}&balance=false`,
          config
        );

        dispatch({
          type: ALL_BALANCES_SUCCESS,
          payload: data,
        });
      } else {
        const year = new Date().getFullYear();
        const date = new Date(year, 0, 1);
        const { endDate, email, penntag, exported, byBalance } = filterData;

        const startDate =
          filterData.startDate === undefined ? date : filterData.startDate;

        if (email) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all/balances?customeremail=${email}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: ALL_BALANCES_SUCCESS,
            payload: data,
          });
        } else if (penntag) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all/balances?penntag=${penntag}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: ALL_BALANCES_SUCCESS,
            payload: data,
          });
        } else if (exported === true) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all/balances/export?fromdate=${startDate}&todate=${endDate}&export=${exported}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: ALL_BALANCES_SUCCESS,
            payload: data,
          });
        } else {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all/balances?size=300&page=${page}&balance=${byBalance}`,
            config
          );

          console.log("here");

          dispatch({
            type: ALL_BALANCES_SUCCESS,
            payload: data,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: ALL_BALANCES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message ?? error.response,
      });
    }
  };

export const fetchSingleOfficerDetails =
  (id, filterData, page) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_SINGLE_OFFICER_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (filterData) {
        const { email, tag } = filterData;

        if (email) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/officer/single?officerid=${id}&customeremail=${email}`,
            config
          );

          dispatch({
            type: GET_SINGLE_OFFICER_SUCCESS,
            payload: data,
          });
        } else if (tag) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/officer/single/id?officerid=${id}&customertag=${tag}`,
            config
          );

          dispatch({
            type: GET_SINGLE_OFFICER_SUCCESS,
            payload: data,
          });
        }
      } else {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/customer/management/officer/single/id?officerid=${id}&size=200`,
          config
        );

        dispatch({
          type: GET_SINGLE_OFFICER_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_SINGLE_OFFICER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchOfficerInfo = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_SINGLE_OFFICER_INFO_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE_API_URL}/admin/customer/management/officer/details/${id}`,
      config
    );

    console.log(data);

    dispatch({
      type: GET_SINGLE_OFFICER_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_SINGLE_OFFICER_INFO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setCustomerLimit = (reqData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_CUSTOMER_LIMIT_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data: cusData } = await axios.get(
      `${BASE_API_URL}/admin/customer/all?customertag=${reqData.customer_name}`,
      config
    );
    if (cusData?.items[0]?.id === "" || cusData?.items[0]?.id === undefined) {
      dispatch({
        type: SET_CUSTOMER_LIMIT_FAIL,
        payload: "Invalid Customer Tag",
      });
    }
    const postData = {
      customer_id: Number(cusData?.items[0]?.id),
      single_transaction_limit: reqData.single_transaction_limit,
      daily_transaction_limit: reqData.daily_transaction_limit,
      monthly_transaction_limit: reqData.monthly_transaction_limit,
      daily_transaction_frequency: reqData.daily_transaction_frequency,
      monthly_transaction_frequency: reqData.monthly_transaction_frequency,
      single_airtime_and_bill_payment_limit:
        reqData.single_airtime_and_bill_payment_limit,
      daily_airtime_and_bill_payment: reqData.daily_airtime_and_bill_payment,
      reset_limit: reqData.reset_limit || false,
    };

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.post(
      `${BASE_API_URL}/admin/limit/add-or-update`,
      postData,
      config
    );
    dispatch({
      type: SET_CUSTOMER_LIMIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SET_CUSTOMER_LIMIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setCustomerAutomation =
  (reqData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SET_CUSTOMER_AUTOMATION_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data: cusData } = await axios.get(
        `${BASE_API_URL}/admin/customer/all?customertag=${reqData.customer_name}`,
        config
      );
      if (cusData?.items[0]?.id === "" || cusData?.items[0]?.id === undefined) {
        dispatch({
          type: SET_CUSTOMER_AUTOMATION_FAIL,
          payload: "Invalid Customer Tag",
        });
      }
      const postData = {
        customer_id: Number(cusData?.items[0]?.id),
        customer_tag: reqData.customer_name,
      };

      // getting our response as we set it in our userController in the backend
      const { data } = await axios.post(
        `${BASE_API_URL}/admin/limit/automate`,
        postData,
        config
      );
      dispatch({
        type: SET_CUSTOMER_AUTOMATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SET_CUSTOMER_AUTOMATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchCustomerLimit =
  (page, filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_CUSTOMER_LIMIT_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (filterData) {
        const { customerTag, email } = filterData;
        if (customerTag) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/limit/all?customertag=${customerTag}`,
            config
          );

          dispatch({
            type: GET_CUSTOMER_LIMIT_SUCCESS,
            payload: data,
          });
        } else if (email) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/limit/all?customeremail=${email}`,
            config
          );

          dispatch({
            type: GET_CUSTOMER_LIMIT_SUCCESS,
            payload: data,
          });
        }
      } else {
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/limit/all?page=${page}`,
          config
        );

        dispatch({
          type: GET_CUSTOMER_LIMIT_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_CUSTOMER_LIMIT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchTargetSavingsOfficers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_TARGET_SAVINGS_OFFICER_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/customer/management/target-savings/officers/all?size=${40}`,
      config
    );

    dispatch({
      type: GET_TARGET_SAVINGS_OFFICER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_TARGET_SAVINGS_OFFICER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchTargetSavingsOfficerInfo =
  (id, filterData, page) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (filterData) {
        const { email, tag } = filterData;

        if (email) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/target-savings/officer/single?officerid=${id}&customeremail=${email}`,
            config
          );

          dispatch({
            type: GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_SUCCESS,
            payload: data,
          });
        } else if (tag) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/target-savings/officer/single?officerid=${id}&customertag=${tag}`,
            config
          );

          dispatch({
            type: GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_SUCCESS,
            payload: data,
          });
        }
      } else {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/customer/management/target-savings/officer/single?officerid=${id}&size=200`,
          config
        );

        dispatch({
          type: GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchAllTargetSavings =
  (filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ALL_TARGET_SAVINGS_REQUEST,
      });

      console.log("here");

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (filterData) {
        const { email, tag, statusid, customerid, byBalance } = filterData;

        console.log(email);

        if (email) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/target-savings?customeremail=${email}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: GET_ALL_TARGET_SAVINGS_SUCCESS,
            payload: data,
          });
        } else if (customerid) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/target-savings?customerid=${customerid}&statusid=${statusid}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: GET_ALL_TARGET_SAVINGS_SUCCESS,
            payload: data,
          });
        } else if (tag) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/target-savings?customertag=${tag}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: GET_ALL_TARGET_SAVINGS_SUCCESS,
            payload: data,
          });
        } else if (statusid) {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/target-savings?statusid=${statusid}&balance=${byBalance}`,
            config
          );

          dispatch({
            type: GET_ALL_TARGET_SAVINGS_SUCCESS,
            payload: data,
          });
        } else {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/target-savings?balance=${byBalance}`,
            config
          );

          dispatch({
            type: GET_ALL_TARGET_SAVINGS_SUCCESS,
            payload: data,
          });
        }
      } else {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/customer/management/target-savings?size=200&statusid=2&balance=false`,
          config
        );

        dispatch({
          type: GET_ALL_TARGET_SAVINGS_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_ALL_TARGET_SAVINGS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchTreegarInvestmentConstant =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_TREEGAR_INVESTMENT_CONSTANT_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BASE_API_URL}/admin/treegar/constant/get`,
        config
      );

      console.log(data);

      dispatch({
        type: GET_TREEGAR_INVESTMENT_CONSTANT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_TREEGAR_INVESTMENT_CONSTANT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createTreegarInvestment =
  (postData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_TREEGAR_INVESTMENT_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const postReq = {
        investment_tag: postData?.investment_tag,
        status: postData?.status,
        customer_name: postData?.customer_name,
        inflow: postData?.inflow,
        effective_date: postData?.effective_date,
        tenor: postData?.tenor,
        maturity_date: postData?.maturity_date,
        interest_expense: postData?.interest_expense,
        gross_interest_payout: postData?.gross_interest_payout,
        is_asset: postData?.is_assest,
        wht_deduction: calcNetInterest(
          postData?.wht_deduction,
          postData?.gross_interest_payout
        ),
        charges: calcNetInterest(
          postData?.charges,
          postData?.gross_interest_payout
        ),
        investment_type_name: postData?.investment_type_name,
        created_by: postData?.created_by,
      };

      if (
        postData?.investment_tag === "" ||
        postData?.customer_name === "" ||
        postData?.tenor === 0
      ) {
        dispatch({
          type: CREATE_TREEGAR_INVESTMENT_FAIL,
          payload: "Null Values Detected, Please Try agin",
        });
      } else {
        const { data } = await axios.post(
          `${BASE_API_URL}/admin/treegar/investment/add`,
          postReq,
          config
        );

        dispatch({
          type: CREATE_TREEGAR_INVESTMENT_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: CREATE_TREEGAR_INVESTMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchTreegarInvestmentAssest =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_TREEGAR_INVESTMENT_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BASE_API_URL}/admin/treegar/investment/get?isassest=true`,
        config
      );

      console.log(data);

      dispatch({
        type: GET_TREEGAR_INVESTMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_TREEGAR_INVESTMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchTreegarInvestmentLiabilty =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_TREEGAR_INVESTMENT_LIABILITY_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BASE_API_URL}/admin/treegar/investment/get?isassest=false`,
        config
      );

      console.log(data);

      dispatch({
        type: GET_TREEGAR_INVESTMENT_LIABILITY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_TREEGAR_INVESTMENT_LIABILITY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchPendingTreegarInvestmentAssest =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_TREEGAR_PENDING_INVESTMENT_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BASE_API_URL}/admin/treegar/investment/get?statusid=1`,
        config
      );

      console.log(data);

      dispatch({
        type: GET_TREEGAR_PENDING_INVESTMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_TREEGAR_PENDING_INVESTMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const setApproveRejectInvestment =
  (postData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: APPROVE_REJECT_INVESTMENT_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { id, approved_by, approve } = postData;

      // getting our response as we set it in our userController in the backend
      const { data } = await axios.post(
        `${BASE_API_URL}/admin/treegar/investment/approve`,
        { id, approved_by, approve },
        config
      );

      dispatch({
        type: APPROVE_REJECT_INVESTMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: APPROVE_REJECT_INVESTMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchTerminals = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_TERMINALS_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE_API_URL}/admin/terminal/all`,
      config
    );

    dispatch({
      type: GET_TERMINALS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_TERMINALS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchAllWallets = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_BALANCE_METRICS_REQUEST,
    });

    const {
      admin2faLogin: { userInfo },
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE_API_URL}/admin/dashboard/balance/metrics`,
      config
    );

    dispatch({
      type: GET_BALANCE_METRICS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BALANCE_METRICS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message ?? error.response,
    });
  }
};

export const fetchRates = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ALL_RATES_REQUEST,
    });

    const {
      admin2faLogin: { userInfo },
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE_API_URL}/admin/transaction/all/rates`,
      config
    );

    dispatch({
      type: GET_ALL_RATES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_RATES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message ?? error.response,
    });
  }
};

export const setProductRate = (reqBody) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_RATE_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { id, rate } = reqBody;

    const { data } = await axios.put(
      `${BASE_API_URL}/admin/transaction/edit/rate?Name=${id}&value=${rate}`,
      {},
      config
    );

    dispatch({
      type: UPDATE_RATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: UPDATE_RATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

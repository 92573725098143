import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"

import Header from "../components/Header"
import Loader from "../components/Loader"
import SideNav from "../components/SideNav"
import UpdateRateModal from "../components/UpdateRateModal"
import { fetchRates } from "../actions/adminActions"
import UpdateRateAllModal from "../components/UpdateAllRateModal"

const AllRatesScreen = () => {
  const dispatch = useDispatch()
  const ratesRef = useRef(true)
  const [isOpen, setIsOpen] = useState(false)
  const [id, setId] = useState("")
  useEffect(() => {
    if (ratesRef.current) {
      dispatch(fetchRates())
      ratesRef.current = false
    }
    setId(id)
  }, [dispatch, id])

  const getRates = useSelector((state) => state.getRates)
  const { loading, success, error, rates } = getRates

  const revealModal = (id) => {
    setIsOpen(true)
    setId(id)
  }
  return (
    <>
      <SideNav />
      <Helmet>
        <title> PennyTree - Product Rates</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Product Rates</h1>
            {error && <p>{error}</p>}
            <>
              {isOpen && <UpdateRateAllModal setIsOpen={setIsOpen} id={id} />}
              {loading ? (
                <Loader />
              ) : (
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                >
                  <MDBTableHead>
                    <tr className="font-reduce">
                      <th scope="col">S/N</th>
                      <th scope="col"> Name</th>
                      <th scope="col">Value</th>

                      <th scope="col">Action</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      rates &&
                      rates?.map((r, key) => (
                        <tr>
                          <td>{key + 1}</td>
                          <td>{r?.key_name}</td>
                          <td>{r?.key_value}</td>

                          <td>
                            <button
                              className="withdrawal-btn"
                              onClick={() => revealModal(r?.key_name)}
                            >
                              Update
                            </button>
                          </td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
              )}
              {/* <Paginate
                  totPages={investments?.page?.total_page}
                  currentPage={String(investments?.page?.currentPage)}
                  page={String(investments?.page?.currentPage)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
              /> */}
            </>
          </div>
        </div>
      </section>
    </>
  )
}

export default AllRatesScreen

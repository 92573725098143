import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCustomerAutomation } from '../actions/adminActions'
import Header from '../components/Header'
import Loader from '../components/Loader'
import SideNav from '../components/SideNav'
const CustomerAutomationScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [customer_name, setCustomerName] = useState('')

  const customerAutomation = useSelector((state) => state.customerAutomation)
  const { loading, success, error, automation } = customerAutomation

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const submitHandler = (e) => {
    e.preventDefault()
    const reqData = {
      customer_name
    }

    dispatch(setCustomerAutomation(reqData))
  }

  useEffect(() => {
    if (userInfo?.user?.role !== 'Admin') {
      navigate('/performance', { replace: true })
    }
  })
  return (
    <>
      <SideNav />
      <Helmet>
        <title>Limit Creation | PennyTree - Admin</title>
      </Helmet>
      <section className='page-container'>
        <div className='page-content'>
          <Header />
          <div className='page-body'>
            <h1>Automate Customer Transfers</h1>
            <div className='investment-form'>
              <form action='' method='post' onSubmit={submitHandler}>
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'red',
                    fontSize: '20px',
                    letterSpacing: '1.5px'
                  }}
                >
                  {error && error}
                </p>
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'green',
                    fontSize: '20px',
                    letterSpacing: '1.5px'
                  }}
                >
                  {success && automation?.message}
                </p>
                <div className='form-group'>
                  <label htmlFor='email' style={{ color: '#ffffff' }}>
                    Customer Tag:
                  </label>

                  <input
                    className='invest-input'
                    type='text'
                    placeholder='Input Penn Tag or Merchant Tag or Account Number..'
                    value={customer_name}
                    onChange={(e) => setCustomerName(e.target.value)}
                  />
                </div>

                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <button type='submit' className='login-btn'>
                      Publish
                    </button>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CustomerAutomationScreen

import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Header from '../components/Header'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import SideNav from '../components/SideNav'
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit'
import { CSVLink } from 'react-csv'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { format, formatDate } from '../main'
import { fetchFailedTransactions } from '../actions/transactionActions'

const FailedDebitsScreen = () => {
  const navigate = useNavigate()
  const { customeremail } = useParams()
  const [currPage, setCurrPage] = useState(1)
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  // FILTER states starts
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [email, setEmail] = useState('')
  const [transactionReference, setTransactionReference] = useState('')
  const [paymentReference, setPaymentReference] = useState('')
  const [failed, setFailed] = useState(false)
  const [styles, setStyles] = useState({
    navContainer: {
      display: 'none'
    }
  })

  // filter states emd
  const dispatch = useDispatch()
  const transRef = useRef(true)

  const failedDebits = useSelector((state) => state.failedDebits)
  const { loading, success, error, debits } = failedDebits

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  useEffect(() => {
    if (
      (!loading && error === 'Please login again') ||
      error === 'You have previously logged out. Please login again'
    ) {
      localStorage.removeItem('userInfo')
      navigate('/', { replace: true })
    }
    if (transRef.current) {
      dispatch(fetchFailedTransactions(currPage))
      transRef.current = false
    }
    if (userInfo?.user?.role === 'Account Officer') {
      navigate('/performance', { replace: true })
    }
    if (success && debits) {
      setData(
        debits?.items?.map((tran) => ({
          product: tran?.product,
          transactionAmount: tran?.transaction_amount,
          fee: tran?.fee,
          foreign_amount: tran?.foreign_amount,
          transaction_reference: tran?.transaction_reference,
          payment_reference: tran?.payment_reference,
          remarks: tran?.remarks,
          date_created: tran?.date_created,
          status: tran?.status,
          batch_reference: tran?.batch_reference,
          customer_email: tran?.customer_email,
          currency: tran?.currency,
          foreign_currency: tran?.foreign_currency,
          wallet_name: tran?.wallet_name,
          direction: tran?.direction,
          customer_full_name: tran?.customer_full_name,
          user_name: tran?.username
        }))
      )

      setHeaders([
        { label: 'Product', key: 'product' },
        { label: 'Transaction Amount', key: 'transactionAmount' },
        { label: 'Fee', key: 'fee' },
        { label: 'ForeignAmount', key: 'foreign_amount' },
        { label: 'Transaction Reference', key: 'transaction_reference' },
        { label: 'Payment Reference', key: 'payment_reference' },
        { label: 'Remarks', key: 'remarks' },
        { label: 'Date Created', key: 'date_created' },
        { label: 'Status', key: 'status' },
        { label: 'Batch Reference', key: 'batch_reference' },
        { label: 'Customer Email', key: 'customer_email' },
        { label: 'Currency', key: 'currency' },
        { label: 'Foreign Currency', key: 'foreign_currency' },
        { label: 'Wallet Name', key: 'wallet_name' },
        { label: 'Direction', key: 'direction' },
        { label: 'Customer Full Name', key: 'customer_full_name' },
        { label: 'UserName', key: 'user_name' }
      ])
    }
  }, [
    dispatch,
    userInfo,
    navigate,
    currPage,
    success,
    customeremail,
    debits,
    error,
    loading
  ])

  const afterPageClicked = (page_number) => {
    if (page_number === currPage) {
      setCurrPage(page_number + 1)
    } else {
      setCurrPage(page_number)
    }

    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      failed,
      paymentReference,
      transactionReference
    }
    if (page_number === 'undefined1') {
      setCurrPage(1)
    }
    dispatch(fetchFailedTransactions(currPage, filterData))
  }

  // filter data

  const handleClick = () => {
    if (styles.navContainer.display === 'block') {
      setStyles({
        navContainer: {
          display: 'none'
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: 'block'
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: 'none'
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      failed,
      paymentReference,
      transactionReference
    }

    dispatch(fetchFailedTransactions(currPage, filterData))
  }

  const handleClear = () => {
    setEmail('')
    setFailed(false)
    setStyles({
      navContainer: {
        display: 'none'
      }
    })

    dispatch(fetchFailedTransactions(currPage))
  }

  console.log(debits)

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Transactions | PennyTree - Admin</title>
      </Helmet>
      <section className='page-container'>
        <div className='page-content'>
          <Header />
          <div className='page-body'>
            <h1>Failed Debits</h1>
            <div className='filter-box'>
              <div className='filter'>
                <>
                  <div className='filter-container'>
                    <p style={{ cursor: 'pointer' }} onClick={handleClick}>
                      Filter
                    </p>
                    <div className='navContainer' style={styles.navContainer}>
                      <div
                        className='date-filter'
                        style={{
                          display:
                            transactionReference || paymentReference
                              ? 'none'
                              : 'block'
                        }}
                      >
                        <div className='date-title'>Date</div>
                        <div className='date-options'>
                          <div className='date-content'>
                            <div className='date-items'>
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                              />

                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='status-container'
                        style={{
                          display:
                            transactionReference || paymentReference
                              ? 'none'
                              : 'block'
                        }}
                      >
                        <div className='date-title'>Status</div>
                      </div>

                      <div
                        className='status-container'
                        // style={{
                        //   display:
                        //     successful ||
                        //     processing ||
                        //     initiated ||
                        //     reversed ||
                        //     failed ||
                        //     pending
                        //       ? 'none'
                        //       : 'block'
                        // }}
                      >
                        <div className='date-title'>Other Filters</div>
                        <div className='checkbox checkbox--flex'>
                          <div
                            className='checkbox__item'
                            style={{
                              width: '100%'
                            }}
                          >
                            <input
                              style={{
                                width: '100%',
                                borderRadius: '10px',
                                border: '1px solid grey',
                                fontSize: '14px',
                                display:
                                  transactionReference || paymentReference
                                    ? 'none'
                                    : 'block'
                              }}
                              type='email'
                              className='email-input'
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder='Enter customer Email'
                            />{' '}
                          </div>
                          <div
                            className='checkbox__item'
                            style={{
                              width: '100%'
                            }}
                          >
                            <input
                              style={{
                                width: '100%',
                                borderRadius: '10px',
                                border: '1px solid grey',
                                fontSize: '14px',
                                display: paymentReference ? 'none' : 'block'
                              }}
                              type='email'
                              className='email-input'
                              value={transactionReference}
                              onChange={(e) =>
                                setTransactionReference(e.target.value)
                              }
                              placeholder='Enter Transaction Reference'
                            />{' '}
                          </div>
                          <div
                            className='checkbox__item'
                            style={{
                              width: '100%'
                            }}
                          >
                            <input
                              style={{
                                width: '100%',
                                borderRadius: '10px',
                                border: '1px solid grey',
                                fontSize: '14px',
                                display: email ? 'none' : 'block'
                              }}
                              type='email'
                              className='email-input'
                              value={paymentReference}
                              onChange={(e) =>
                                setPaymentReference(e.target.value)
                              }
                              placeholder='Enter Payment Reference'
                            />{' '}
                          </div>
                        </div>
                      </div>
                      <div
                        className='status-container'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center'
                        }}
                      >
                        <button className='button' onClick={handleClear}>
                          <p>Clear</p>
                        </button>
                        <button className='button' onClick={handleFilter}>
                          <p>Filter</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
              <div className='filter'>
                <CSVLink data={data} headers={headers}>
                  Export
                </CSVLink>
              </div>
            </div>
            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ 'width': '100%', fontSize: '14px' }}
                >
                  <MDBTableHead>
                    <tr>
                      <th scope='col'>S/N</th>
                      <th scope='col'>Product</th>
                      <th scope='col'>Transaction Amount</th>
                      <th scope='col'>Fee</th>
                      <th>Foreign Amount</th>
                      <th scope='col'>Transaction Reference</th>
                      <th scope='col'>Payment Reference</th>
                      <th scope='col'>Remarks</th>
                      <th scope='col'>Date Created</th>
                      <th scope='col'>Status</th>
                      <th scope='col'>Batch Reference</th>
                      <th scope='col'>Customer Email</th>
                      <th scope='col'>Currency</th>
                      <th scope='col'>Foreign Currency</th>
                      <th scope='col'>Wallet Name</th>
                      <th scope='col'>Direction</th>
                      <th scope='col'>Customer Full Name</th>
                      <th scope='col'>User Name</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {debits?.items?.map((tran, key) => (
                      <tr key={key}>
                        <td>{tran?.id}</td>
                        <td style={{ 'width': '100%' }}>{tran?.product}</td>
                        <td>{format(tran?.transaction_amount)}</td>
                        <td>{tran?.fee}</td>
                        <td>{tran?.foreign_amount}</td>
                        <td>{tran?.transaction_reference}</td>
                        <td>{tran?.payment_reference}</td>
                        <td>{tran?.remarks}</td>
                        <td>{tran?.date_created.substring(0, 10)}</td>
                        <td>{tran?.status}</td>
                        <td>{tran?.batch_reference}</td>
                        <td>{tran?.customer_email}</td>
                        <td>{tran?.currency}</td>
                        <td>{tran?.foreign_currency}</td>
                        <td>{tran?.wallet_name}</td>
                        <td>{tran?.direction}</td>
                        <td>{tran?.customer_full_name}</td>
                        <td>{tran?.username}</td>
                      </tr>
                    ))}
                  </MDBTableBody>
                </MDBTable>
                <Paginate
                  totPages={debits?.page?.total_page}
                  currentPage={String(debits?.page?.currentPage)}
                  page={String(debits?.page?.currentPage)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default FailedDebitsScreen

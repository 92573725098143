import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { login2fa, otpResend } from "../actions/userActions"
import Loader from "../components/Loader"

const OtpScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [otp, setOtp] = useState("")

  const adminLogin = useSelector((state) => state.adminLogin)
  const { tokenInfo } = adminLogin

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { success, userInfo, error: adminError, loading } = admin2faLogin

  const resendOtp = useSelector((state) => state.resendOtp)
  const { error, otp: otpResent } = resendOtp

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login2fa(otp))
  }

  const resend = () => {
    dispatch(otpResend())
  }

  useEffect(() => {
    if (!tokenInfo) {
      navigate("/", { replace: true })
    } else if (success && userInfo) {
      navigate("/dashboard", { replace: true })
    }
  }, [tokenInfo, success, userInfo, navigate])
  return (
    <>
      <main className="login-bg">
        <div className="login-form">
          <a href="./">
            <img
              src="assets/images/penn-full-logo.png"
              alt=""
              className="admin-logo"
            />
          </a>
          <h5>Enter Otp sent to your email address</h5>
          <form action="" method="post" onSubmit={submitHandler}>
            <div className="err-msg"></div>

            <div className="form-group">
              <label htmlFor="password" style={{ color: "#ffffff" }}>
                One Time Passcode
              </label>
              <input
                id="password"
                type="text"
                name="otp"
                placeholder="Please enter otp to continue"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>

            <p>{!loading && !error && otpResent?.message}</p>

            {loading ? (
              <Loader />
            ) : (
              <button type="submit" className="login-btn">
                Continue
              </button>
            )}
          </form>
          <p
            className="forgot-password"
            style={{ cursor: "pointer" }}
            onClick={resend}
          >
            Click here to Resend Otp
          </p>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "red",
              fontSize: "20px",
              letterSpacing: "1.5px"
            }}
          >
            {error && error}
            {adminError && adminError?.message}
          </p>
        </div>
      </main>
    </>
  )
}

export default OtpScreen

import axios from "axios"

import {
  TRANSACTION_STATUS_FAIL,
  TRANSACTION_STATUS_REQUEST,
  TRANSACTION_STATUS_SUCCESS,
  FAILED_DEBITS_FAIL,
  FAILED_DEBITS_REQUEST,
  FAILED_DEBITS_SUCCESS,
  APPROVE_REJECT_WITHDRAWAL_FAIL,
  APPROVE_REJECT_WITHDRAWAL_REQUEST,
  APPROVE_REJECT_WITHDRAWAL_SUCCESS,
  ALL_TRANSACTIONS_FAIL,
  ALL_TRANSACTIONS_REQUEST,
  ALL_TRANSACTIONS_SUCCESS,
  PENDING_WITHDRAWAL_FAIL,
  PENDING_WITHDRAWAL_REQUEST,
  PENDING_WITHDRAWAL_SUCCESS,
  PRODUCT_TYPES_FAIL,
  PRODUCT_TYPES_REQUEST,
  PRODUCT_TYPES_SUCCESS,
  FAILED_WITHDRAWAL_SUCCESS,
  FAILED_WITHDRAWAL_REQUEST,
  FAILED_WITHDRAWAL_FAIL,
  ALL_PAYOUTS_SUCCESS,
  ALL_PAYOUTS_FAIL,
  ALL_PAYOUTS_REQUEST,
  GET_ALL_BILLS_REQUEST,
  GET_ALL_BILLS_SUCCESS,
  GET_ALL_BILLS_FAIL,
  GET_TRANSACTION_SETTINGS_REQUEST,
  GET_TRANSACTION_SETTINGS_SUCCESS,
  GET_TRANSACTION_SETTINGS_FAIL,
  ENABLE_DISABLE_TRANSACTION_REQUEST,
  ENABLE_DISABLE_TRANSACTION_SUCCESS,
  ENABLE_DISABLE_TRANSACTION_FAIL,
  GET_PROVIDERS_REQUEST,
  GET_PROVIDERS_SUCCESS,
  GET_PROVIDERS_FAIL,
  ENABLE_DISABLE_PROVIDER_REQUEST,
  ENABLE_DISABLE_PROVIDER_SUCCESS,
  ENABLE_DISABLE_PROVIDER_FAIL,
  TRANSACTIONS_EXPORT_REQUEST,
  TRANSACTIONS_EXPORT_SUCCESS,
  TRANSACTIONS_EXPORT_FAIL
} from "../constants/adminConstants"
import { BASE_API_URL, StatusConstants } from "../constants/dropdown"
const PER_PAGE_SIZE = 100

export const fetchAllTransactions =
  (pageNumber, filterdata, status) => async (dispatch, getState) => {
    try {
      console.log(filterdata)
      dispatch({
        type: ALL_TRANSACTIONS_REQUEST
      })
      const {
        admin2faLogin: { userInfo }
      } = getState()
      const token = userInfo?.token?.access_token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      const page = isNaN(pageNumber) ? 1 : pageNumber
      console.log(page)

      if (filterdata === undefined) {
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/transaction/all?size=${PER_PAGE_SIZE}&page=${page}`,
          config
        )
        dispatch({
          type: ALL_TRANSACTIONS_SUCCESS,
          payload: data
        })
      } else {
        const year = new Date().getFullYear()
        const date = new Date(year, 0, 1)
        const {
          endDate,
          email,
          successful,
          pending,
          initiated,
          processing,
          reversed,
          failed,
          transactionReference,
          paymentReference,
          productId,
          internalProviderId,
          exported,
          ismerchant,
          rrn
        } = filterdata

        console.log(filterdata)

        const startDate =
          filterdata.startDate === undefined ? date : filterdata.startDate

        const checkboxArray = [
          {
            name: successful,
            status: status?.data
              ?.filter((s) => s?.name.toLowerCase().includes("succe"))
              .map((s) => s?.name.toLowerCase())
              .toString()
          },
          {
            name: pending,
            status: status?.data
              ?.filter((s) => s?.name.toLowerCase().includes("pendi"))
              .map((s) => s?.name.toLowerCase())
              .toString()
          },
          {
            name: initiated,
            status: status?.data
              ?.filter((s) => s?.name.toLowerCase().includes("initia"))
              .map((s) => s?.name.toLowerCase())
              .toString()
          },
          {
            name: processing,
            status: status?.data
              ?.filter((s) => s?.name.toLowerCase().includes("proces"))
              .map((s) => s?.name.toLowerCase())
              .toString()
          },
          {
            name: reversed,
            status: status?.data
              ?.filter((s) => s?.name.toLowerCase().includes("rever"))
              .map((s) => s?.name.toLowerCase())
              .toString()
          },
          {
            name: failed,
            status: status?.data
              ?.filter((s) => s?.name.toLowerCase().includes("fail"))
              .map((s) => s?.name.toLowerCase())
              .toString()
          }
        ]
        const filtered = checkboxArray.filter((c) => c.name === true)
        const mapped = filtered.map((m) => m.status)
        const statusVal = mapped.toString()

        if (!exported && email) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all?penntag=${email}&size=${PER_PAGE_SIZE}`,
            config
          )

          dispatch({
            type: ALL_TRANSACTIONS_SUCCESS,
            payload: data
          })
        } else if (!exported && paymentReference) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all?paymentreference=${paymentReference}`,
            config
          )
          dispatch({
            type: ALL_TRANSACTIONS_SUCCESS,
            payload: data
          })
        } else if (!exported && transactionReference) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all?transactionreference=${transactionReference}`,
            config
          )
          dispatch({
            type: ALL_TRANSACTIONS_SUCCESS,
            payload: data
          })
        } else if (!exported && filtered.length > 0) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all?status=${statusVal}&size=${PER_PAGE_SIZE}&page=${pageNumber}`,
            config
          )

          dispatch({
            type: ALL_TRANSACTIONS_SUCCESS,
            payload: data
          })
        } else if (!exported && !isNaN(productId) && internalProviderId) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all?internalProvider=${internalProviderId}&page=${pageNumber}&size=${PER_PAGE_SIZE}&fromdate=${startDate}&todate=${endDate}&productid=${productId}`,
            config
          )

          dispatch({
            type: ALL_TRANSACTIONS_SUCCESS,
            payload: data
          })
        } else if (!exported && productId && !isNaN(productId)) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all?productid=${productId}&page=${pageNumber}&size=${PER_PAGE_SIZE}&fromdate=${startDate}&todate=${endDate}`,
            config
          )

          dispatch({
            type: ALL_TRANSACTIONS_SUCCESS,
            payload: data
          })
        } else if (!exported && ismerchant) {
          if (!isNaN(productId) && internalProviderId) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?internalProvider=${internalProviderId}&fromdate=${startDate}&todate=${endDate}&size=${PER_PAGE_SIZE}&productid=${productId}&ismerchant=${ismerchant}`,
              config
            )

            dispatch({
              type: ALL_TRANSACTIONS_SUCCESS,
              payload: data
            })
          } else if (productId && !isNaN(productId)) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?productid=${productId}&fromdate=${startDate}&todate=${endDate}&size=${PER_PAGE_SIZE}&productid=${productId}&ismerchant=${ismerchant}`,
              config
            )

            dispatch({
              type: ALL_TRANSACTIONS_SUCCESS,
              payload: data
            })
          } else {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?page=${page}&fromdate=${startDate}&todate=${endDate}&ismerchant=${ismerchant}&status=${statusVal}&size=${PER_PAGE_SIZE}`,
              config
            )

            dispatch({
              type: ALL_TRANSACTIONS_SUCCESS,
              payload: data
            })
          }
        } else if (exported === true) {
          if (filtered.length > 0) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all/export?status=${statusVal}&fromdate=${startDate}&todate=${endDate}&productid=${productId}&${internalProviderId}`,
              config
            )

            dispatch({
              type: ALL_TRANSACTIONS_SUCCESS,
              payload: data
            })
          } else if (ismerchant) {
            if (!isNaN(productId) && internalProviderId) {
              const { data } = await axios.get(
                `${BASE_API_URL}/admin/transaction/all?internalProvider=${internalProviderId}&fromdate=${startDate}&todate=${endDate}&size=${PER_PAGE_SIZE}&productid=${productId}&ismerchant=${ismerchant}`,
                config
              )

              dispatch({
                type: ALL_TRANSACTIONS_SUCCESS,
                payload: data
              })
            } else if (productId && !isNaN(productId)) {
              const { data } = await axios.get(
                `${BASE_API_URL}/admin/transaction/all?productid=${productId}&fromdate=${startDate}&todate=${endDate}&size=${PER_PAGE_SIZE}&productid=${productId}&ismerchant=${ismerchant}`,
                config
              )

              dispatch({
                type: ALL_TRANSACTIONS_SUCCESS,
                payload: data
              })
            } else {
              const { data } = await axios.get(
                `${BASE_API_URL}/admin/transaction/all?page=${page}&fromdate=${startDate}&todate=${endDate}&ismerchant=${ismerchant}&status=${statusVal}&size=${PER_PAGE_SIZE}`,
                config
              )

              dispatch({
                type: ALL_TRANSACTIONS_SUCCESS,
                payload: data
              })
            }
          } else if (!isNaN(productId) && internalProviderId) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?internalProvider=${internalProviderId}&fromdate=${startDate}&todate=${endDate}&size=${PER_PAGE_SIZE}&productid=${productId}`,
              config
            )

            dispatch({
              type: ALL_TRANSACTIONS_SUCCESS,
              payload: data
            })
          } else if (productId && !isNaN(productId)) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?productid=${productId}&fromdate=${startDate}&todate=${endDate}&size=${PER_PAGE_SIZE}&productid=${productId}`,
              config
            )

            dispatch({
              type: ALL_TRANSACTIONS_SUCCESS,
              payload: data
            })
          } else if (email) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all/export?status=${statusVal}&customeremail=${email}&fromdate=${startDate}&todate=${endDate}`,
              config
            )

            dispatch({
              type: ALL_TRANSACTIONS_SUCCESS,
              payload: data
            })
          } else if (paymentReference) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?paymentreference=${paymentReference}`,
              config
            )
            dispatch({
              type: ALL_TRANSACTIONS_SUCCESS,
              payload: data
            })
          } else if (transactionReference) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?transactionreference=${transactionReference}`,
              config
            )
            dispatch({
              type: ALL_TRANSACTIONS_SUCCESS,
              payload: data
            })
          } else if (rrn) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?rrn=${rrn}`,
              config
            )
            dispatch({
              type: ALL_TRANSACTIONS_SUCCESS,
              payload: data
            })
            console.log("rrn serach")
          } else {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?fromdate=${startDate}&todate=${endDate}`,
              config
            )

            dispatch({
              type: ALL_TRANSACTIONS_SUCCESS,
              payload: data
            })
          }
        } else {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all?page=${page}&fromdate=${startDate}&todate=${endDate}&size=${PER_PAGE_SIZE}&page=${pageNumber}`,
            config
          )
          dispatch({
            type: ALL_TRANSACTIONS_SUCCESS,
            payload: data
          })
        }
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: ALL_TRANSACTIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const fetchFailedTransactions =
  (pageNumber, filterdata) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FAILED_DEBITS_REQUEST
      })
      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      if (filterdata === undefined) {
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/transaction/all?size=${300}&page=${pageNumber}&status=${
            StatusConstants.STATUS_FAILED
          }`,
          config
        )
        dispatch({
          type: FAILED_DEBITS_SUCCESS,
          payload: data
        })
      } else {
        const year = new Date().getFullYear()
        const date = new Date(year, 0, 1)
        const { endDate, email, transactionReference, paymentReference } =
          filterdata

        const startDate =
          filterdata.startDate === undefined ? date : filterdata.startDate
        if (email) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all?status=${StatusConstants.STATUS_FAILED}&customeremail=${email}`,
            config
          )
          console.log(
            `${BASE_API_URL}/admin/transaction/all?status=${StatusConstants.STATUS_FAILED}&customeremail=${email}`
          )
          dispatch({
            type: FAILED_DEBITS_SUCCESS,
            payload: data
          })
        } else if (paymentReference) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all?paymentreference=${paymentReference}&status=${StatusConstants.STATUS_FAILED}`,
            config
          )
          dispatch({
            type: FAILED_DEBITS_SUCCESS,
            payload: data
          })
        } else if (transactionReference) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all?transactionreference=${transactionReference}&status=${StatusConstants.STATUS_FAILED}`,
            config
          )
          dispatch({
            type: FAILED_DEBITS_SUCCESS,
            payload: data
          })
        } else {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all?status=${StatusConstants.STATUS_FAILED}`,
            config
          )
          dispatch({
            type: FAILED_DEBITS_SUCCESS,
            payload: data
          })
        }
      }
    } catch (error) {
      dispatch({
        type: FAILED_DEBITS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const fetchTransactionStatuses = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRANSACTION_STATUS_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/transaction/statuses`,
      config
    )

    console.log(data)

    dispatch({
      type: TRANSACTION_STATUS_SUCCESS,
      payload: data
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: TRANSACTION_STATUS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const setApproveRejectWithdrawal =
  (postData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: APPROVE_REJECT_WITHDRAWAL_REQUEST
      })

      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      const { payout_id, is_approved, failed } = postData

      // getting our response as we set it in our userController in the backend
      const { data } = await axios.post(
        `${BASE_API_URL}/admin/transaction/approve/reject/withdrawal`,
        { payout_id, is_approved, failed },
        config
      )

      dispatch({
        type: APPROVE_REJECT_WITHDRAWAL_SUCCESS,
        payload: data
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: APPROVE_REJECT_WITHDRAWAL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const fetchProductTypes = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_TYPES_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/product/all`,
      config
    )

    console.log(data)

    dispatch({
      type: PRODUCT_TYPES_SUCCESS,
      payload: data
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: PRODUCT_TYPES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const fetchPendingWithdrawals =
  (pageNumber, filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PENDING_WITHDRAWAL_REQUEST
      })

      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      if (filterData === undefined) {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/transaction/pending/withdrawals?size=${PER_PAGE_SIZE}&page=${pageNumber}&status=Pending,Initiated`,
          config
        )

        dispatch({
          type: PENDING_WITHDRAWAL_SUCCESS,
          payload: data
        })
      } else {
        console.log(filterData?.startDate)
        const year = new Date().getFullYear()
        const date = new Date(year, 0, 1)
        const startDate =
          filterData.startDate === undefined ? date : filterData.startDate

        const { endDate, email, transactionReference, status } = filterData

        if (email) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/pending/withdrawals?customeremail=${email}`,
            config
          )

          dispatch({
            type: PENDING_WITHDRAWAL_SUCCESS,
            payload: data
          })
        } else if (transactionReference) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/pending/withdrawals?transactionreference=${transactionReference}`,
            config
          )

          dispatch({
            type: PENDING_WITHDRAWAL_SUCCESS,
            payload: data
          })
        } else {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/pending/withdrawals?transactionreference=${transactionReference}`,
            config
          )

          dispatch({
            type: PENDING_WITHDRAWAL_SUCCESS,
            payload: data
          })
        }
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: PENDING_WITHDRAWAL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const fetchFailedWithdrawals =
  (pageNumber, filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FAILED_WITHDRAWAL_REQUEST
      })

      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      if (filterData === undefined) {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/transaction/pending/withdrawals?size=${PER_PAGE_SIZE}&page=${pageNumber}&status=Failed`,
          config
        )

        dispatch({
          type: FAILED_WITHDRAWAL_SUCCESS,
          payload: data
        })
      } else {
        console.log(filterData?.startDate)
        const year = new Date().getFullYear()
        const date = new Date(year, 0, 1)
        const startDate =
          filterData.startDate === undefined ? date : filterData.startDate

        const { endDate, email, transactionReference, status } = filterData

        if (email) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/pending/withdrawals?customeremail=${email}`,
            config
          )

          dispatch({
            type: FAILED_WITHDRAWAL_SUCCESS,
            payload: data
          })
        } else if (transactionReference) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/pending/withdrawals?transactionreference=${transactionReference}`,
            config
          )

          dispatch({
            type: FAILED_WITHDRAWAL_SUCCESS,
            payload: data
          })
        } else {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/pending/withdrawals?transactionreference=${transactionReference}`,
            config
          )

          dispatch({
            type: FAILED_WITHDRAWAL_SUCCESS,
            payload: data
          })
        }
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: FAILED_WITHDRAWAL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const fetchAllPayouts =
  (pageNumber, filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ALL_PAYOUTS_REQUEST
      })

      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      if (filterData === undefined) {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/transaction/pending/withdrawals?size=${PER_PAGE_SIZE}&page=${pageNumber}&status=Successful`,
          config
        )

        dispatch({
          type: ALL_PAYOUTS_SUCCESS,
          payload: data
        })
      } else {
        console.log(filterData?.startDate)
        const year = new Date().getFullYear()
        const date = new Date(year, 0, 1)
        const startDate =
          filterData.startDate === undefined ? date : filterData.startDate

        const {
          endDate,
          email,
          transactionReference,
          status,
          exported,
          successful,
          pending,
          initiated,
          processing,
          reversed,
          failed
        } = filterData

        const checkboxArray = [
          {
            name: successful,
            status: status?.data
              ?.filter((s) => s?.name.toLowerCase().includes("succe"))
              .map((s) => s?.name.toLowerCase())
              .toString()
          },
          {
            name: pending,
            status: status?.data
              ?.filter((s) => s?.name.toLowerCase().includes("pendi"))
              .map((s) => s?.name.toLowerCase())
              .toString()
          },
          {
            name: initiated,
            status: status?.data
              ?.filter((s) => s?.name.toLowerCase().includes("initia"))
              .map((s) => s?.name.toLowerCase())
              .toString()
          },
          {
            name: processing,
            status: status?.data
              ?.filter((s) => s?.name.toLowerCase().includes("proces"))
              .map((s) => s?.name.toLowerCase())
              .toString()
          },
          {
            name: reversed,
            status: status?.data
              ?.filter((s) => s?.name.toLowerCase().includes("rever"))
              .map((s) => s?.name.toLowerCase())
              .toString()
          },
          {
            name: failed,
            status: status?.data
              ?.filter((s) => s?.name.toLowerCase().includes("fail"))
              .map((s) => s?.name.toLowerCase())
              .toString()
          }
        ]
        const filtered = checkboxArray.filter((c) => c.name === true)
        const mapped = filtered.map((m) => m.status)
        const statusVal = mapped.toString()

        if (email) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/pending/withdrawals?customeremail=${email}}&size=${PER_PAGE_SIZE}&fromdate=${startDate}&todate=${endDate}`,
            config
          )

          dispatch({
            type: ALL_PAYOUTS_SUCCESS,
            payload: data
          })
        } else if (transactionReference) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/pending/withdrawals?transactionreference=${transactionReference}&size=${PER_PAGE_SIZE}&fromdate=${startDate}&todate=${endDate}`,
            config
          )

          dispatch({
            type: ALL_PAYOUTS_SUCCESS,
            payload: data
          })
        } else if (filtered.length > 0) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/pending/withdrawals?status=${statusVal}&size=${PER_PAGE_SIZE}&fromdate=${startDate}&todate=${endDate}`,
            config
          )

          dispatch({
            type: ALL_PAYOUTS_SUCCESS,
            payload: data
          })
        } else {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/pending/withdrawals?transactionreference=${transactionReference}&size=${PER_PAGE_SIZE}&fromdate=${startDate}&todate=${endDate}`,
            config
          )

          dispatch({
            type: ALL_PAYOUTS_SUCCESS,
            payload: data
          })
        }
        if (exported === true) {
          if (email) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/pending/withdrawals?customeremail=${email}&size=${PER_PAGE_SIZE}&fromdate=${startDate}&todate=${endDate}`,
              config
            )

            dispatch({
              type: ALL_PAYOUTS_SUCCESS,
              payload: data
            })
          } else if (transactionReference) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/pending/withdrawals?transactionreference=${transactionReference}&size=${PER_PAGE_SIZE}&fromdate=${startDate}&todate=${endDate}`,
              config
            )

            dispatch({
              type: ALL_PAYOUTS_SUCCESS,
              payload: data
            })
          } else if (filtered.length > 0) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/pending/withdrawals?status=${statusVal}&size=${PER_PAGE_SIZE}&fromdate=${startDate}&todate=${endDate}`,
              config
            )

            dispatch({
              type: ALL_PAYOUTS_SUCCESS,
              payload: data
            })
          } else {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/pending/withdrawals?export=${true}&size=${PER_PAGE_SIZE}&fromdate=${startDate}&todate=${endDate}`,
              config
            )

            dispatch({
              type: ALL_PAYOUTS_SUCCESS,
              payload: data
            })
          }
        }
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: ALL_PAYOUTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const fetchAllBills =
  (pageNumber, filterdata) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ALL_BILLS_REQUEST
      })
      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      if (filterdata === undefined) {
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/transaction/all/bills?size=${300}&page=${pageNumber}`,
          config
        )
        dispatch({
          type: GET_ALL_BILLS_SUCCESS,
          payload: data
        })
      } else {
        const year = new Date().getFullYear()
        const date = new Date(year, 0, 1)
        const { endDate, email, transactionReference, paymentReference } =
          filterdata

        const startDate =
          filterdata.startDate === undefined ? date : filterdata.startDate
        if (email) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all/bills?customeremail=${email}`,
            config
          )
          console.log(
            `${BASE_API_URL}/admin/transaction/all/bills?customeremail=${email}`
          )
          dispatch({
            type: GET_ALL_BILLS_SUCCESS,
            payload: data
          })
        } else if (transactionReference) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all/bills?transactionreference=${transactionReference}`,
            config
          )
          dispatch({
            type: GET_ALL_BILLS_SUCCESS,
            payload: data
          })
        } else {
          // getting our response as we set it in our userController in the backend
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/transaction/all/bills?size=${PER_PAGE_SIZE}&fromdate=${startDate}&todate=${endDate}`,
            config
          )
          dispatch({
            type: GET_ALL_BILLS_SUCCESS,
            payload: data
          })
        }
      }
    } catch (error) {
      dispatch({
        type: GET_ALL_BILLS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const fetchTransactionSettings = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_TRANSACTION_SETTINGS_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.get(
      `${BASE_API_URL}/admin/transaction/transactionsetting`,
      config
    )

    console.log(data)

    dispatch({
      type: GET_TRANSACTION_SETTINGS_SUCCESS,
      payload: data
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: GET_TRANSACTION_SETTINGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const setEnableDisableTransaction =
  (reqBody) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ENABLE_DISABLE_TRANSACTION_REQUEST
      })

      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      const { name, status } = reqBody

      // getting our response as we set it in our userController in the backend
      const { data } = await axios.post(
        `${BASE_API_URL}/admin/transaction/enable_disable`,
        { name, status },
        config
      )

      console.log(data)

      dispatch({
        type: ENABLE_DISABLE_TRANSACTION_SUCCESS,
        payload: data
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: ENABLE_DISABLE_TRANSACTION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const fetchProvders = (text) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PROVIDERS_REQUEST
    })

    const {
      admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState()
    const token = userInfo?.token?.access_token

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    if (text) {
      // getting our response as we set it in our userController in the backend
      const { data } = await axios.get(
        `${BASE_API_URL}/admin/provider/all?SearchText=${text}`,
        config
      )

      dispatch({
        type: GET_PROVIDERS_SUCCESS,
        payload: data
      })
    } else {
      // getting our response as we set it in our userController in the backend
      const { data } = await axios.get(
        `${BASE_API_URL}/admin/provider/all`,
        config
      )

      dispatch({
        type: GET_PROVIDERS_SUCCESS,
        payload: data
      })
    }
  } catch (error) {
    console.log(error)
    dispatch({
      type: GET_PROVIDERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const setEnableDisableProvider =
  (reqBody) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ENABLE_DISABLE_PROVIDER_REQUEST
      })

      const {
        admin2faLogin: { userInfo } // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState()
      const token = userInfo?.token?.access_token

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      console.log(reqBody)

      const { provider_id, status } = reqBody

      // getting our response as we set it in our userController in the backend
      const { data } = await axios.post(
        `${BASE_API_URL}/admin/provider/activate_deactivate`,
        { provider_id, status },
        config
      )

      console.log(data)

      dispatch({
        type: ENABLE_DISABLE_PROVIDER_SUCCESS,
        payload: data
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: ENABLE_DISABLE_PROVIDER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const fetchAllExportedTransactions =
  (filterdata) => async (dispatch, getState) => {
    try {
      console.log(filterdata)
      dispatch({
        type: TRANSACTIONS_EXPORT_REQUEST
      })
      const {
        admin2faLogin: { userInfo }
      } = getState()
      const token = userInfo?.token?.access_token
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }

      if (filterdata === undefined) {
        // const { data } = await axios.get(
        //   `${BASE_API_URL}/admin/transaction/all?size=${1000}`,
        //   config
        // )
        // dispatch({
        //   type: TRANSACTIONS_EXPORT_SUCCESS,
        //   payload: data
        // })
      } else {
        const year = new Date().getFullYear()
        const date = new Date(year, 0, 1)
        const { endDate, ismerchant, internalProviderId, productId } =
          filterdata

        const startDate =
          filterdata.startDate === undefined ? date : filterdata.startDate

        if (filterdata.ismerchant === null) {
          if (!isNaN(productId) && internalProviderId) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?internalProvider=${internalProviderId}&fromdate=${startDate}&todate=${endDate}&productid=${productId}&size=${1000}`,
              config
            )

            console.log("here")

            dispatch({
              type: TRANSACTIONS_EXPORT_SUCCESS,
              payload: data
            })
          } else if (internalProviderId === null && !isNaN(productId)) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?fromdate=${startDate}&todate=${endDate}&productid=${productId}&size=${1000}`,
              config
            )

            dispatch({
              type: TRANSACTIONS_EXPORT_SUCCESS,
              payload: data
            })
          } else {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?size=${1000}&fromdate=${startDate}&todate=${endDate}`,
              config
            )
            dispatch({
              type: TRANSACTIONS_EXPORT_SUCCESS,
              payload: data
            })
          }
        } else {
          if (!isNaN(productId) && internalProviderId) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?internalProvider=${internalProviderId}&fromdate=${startDate}&todate=${endDate}&productid=${productId}&ismerchant=${ismerchant}&size=${1000}`,
              config
            )

            dispatch({
              type: TRANSACTIONS_EXPORT_SUCCESS,
              payload: data
            })
          } else if (internalProviderId === null && !isNaN(productId)) {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?fromdate=${startDate}&todate=${endDate}&productid=${productId}&ismerchant=${ismerchant}&size=${1000}`,
              config
            )

            dispatch({
              type: TRANSACTIONS_EXPORT_SUCCESS,
              payload: data
            })
          } else {
            const { data } = await axios.get(
              `${BASE_API_URL}/admin/transaction/all?size=${1000}&fromdate=${startDate}&todate=${endDate}&ismerchant=${ismerchant}`,
              config
            )
            dispatch({
              type: TRANSACTIONS_EXPORT_SUCCESS,
              payload: data
            })
          }
        }
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: TRANSACTIONS_EXPORT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import React, { useState } from 'react'
import { CSVLink } from 'react-csv'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const TargetSavings = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [currPage, setCurrPage] = useState(1)
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())

  const [styles, setStyles] = useState({
    navContainer: {
      display: 'none'
    }
  })
  const handleClick = () => {
    if (styles.navContainer.display === 'block') {
      setStyles({
        navContainer: {
          display: 'none'
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: 'block'
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: 'none'
      }
    })
  }

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: 'none'
      }
    })
  }
  return (
    <>
      <div className='filter-box'>
        <div className='filter'>
          <>
            <div className='filter-container'>
              <p style={{ cursor: 'pointer' }} onClick={handleClick}>
                Filter
              </p>
              <div className='navContainer' style={styles.navContainer}>
                <div className='date-filter'>
                  <div className='date-title'>Date</div>
                  <div className='date-options'>
                    <div className='custom-filter__item custom-filter__item--pad'>
                      <div className='overview-filter'>
                        <div className='filter__item'>
                          <input
                            id='today_fkc3w9c4e0'
                            value='Today'
                            type='radio'
                            name='dateFilter_fkc3w9c4e0'
                            className='overview-filter__item__radio'
                          />
                          <label
                            htmlFor='today_fkc3w9c4e0'
                            className='overview-filter__item__link active'
                          >
                            Today
                          </label>
                        </div>{' '}
                        <div className='filter__item'>
                          <input
                            id='last7_fkc3w9c4e0'
                            value='7 days'
                            type='radio'
                            name='dateFilter_fkc3w9c4e0'
                            className='overview-filter__item__radio'
                          />
                          <label
                            htmlFor='last7_fkc3w9c4e0'
                            className='overview-filter__item__link'
                          >
                            Last 7 days
                          </label>
                        </div>{' '}
                        <div className='filter__item'>
                          <input
                            id='last30_fkc3w9c4e0'
                            value='30 days'
                            type='radio'
                            name='dateFilter_fkc3w9c4e0'
                            className='overview-filter__item__radio'
                          />
                          <label
                            htmlFor='last30_fkc3w9c4e0'
                            className='overview-filter__item__link'
                          >
                            30 days
                          </label>
                        </div>{' '}
                        <div className='filter__item'>
                          <input
                            id='1year_fkc3w9c4e0'
                            value='1 year'
                            type='radio'
                            name='dateFilter_fkc3w9c4e0'
                            className='overview-filter__item__radio'
                          />
                          <label
                            htmlFor='1year_fkc3w9c4e0'
                            className='overview-filter__item__link'
                          >
                            1 year
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='date-content'>
                      <div className='date-items'>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='status-container'>
                  <div className='date-title'>Customer</div>
                  <div className='checkbox checkbox--flex'></div>
                </div>
                <div
                  className='status-container'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center'
                  }}
                >
                  <button className='button' onClick={handleClear}>
                    <p>Clear</p>
                  </button>
                  <button className='button' onClick={handleFilter}>
                    <p>Filter</p>
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
        <div className='filter'>
          <CSVLink data={data} headers={headers}>
            Export
          </CSVLink>
        </div>
      </div>
      <>
        <MDBTable responsive style={{ 'width': '100%', fontSize: '14px' }}>
          <MDBTableHead>
            <tr className='font-reduce'>
              <th scope='col'>S/N</th>
              <th scope='col'>Savings Name</th>
              <th scope='col'>Target Amount</th>
              <th scope='col'>Amount Saved</th>
              <th cope='col'>Recurring Amount</th>
              <th scope='col'>Interest Rate</th>
              <th scope='col'>Interest Amount</th>
              <th scope='col'>Start Date</th>
              <th scope='col'>End Datet</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody></MDBTableBody>
        </MDBTable>
        {/* <Paginate
          totPages={investments?.page?.total_page}
          currentPage={String(investments?.page?.currentPage)}
          page={String(investments?.page?.currentPage)}
          pageClicked={(ele) => {
            afterPageClicked(ele)
          }}
        /> */}
      </>
    </>
  )
}

export default TargetSavings

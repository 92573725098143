import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Header from "../components/Header"
import Loader from "../components/Loader"
import SideNav from "../components/SideNav"
import { setUpdateOverdraftLimit } from "../actions/overdraftActions"
const OverDraftLimitScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [wallet_tag, setWalletTag] = useState("")
  const [overdraftAmount, setOverdraftAmount] = useState(0)

  const updateOverdraftLimit = useSelector(
    (state) => state.updateOverdraftLimit
  )
  const { loading, success, error, overdraftLimit } = updateOverdraftLimit

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const submitHandler = (e) => {
    e.preventDefault()
    const reqData = {
      wallet_tag,
      overdraftAmount
    }

    dispatch(setUpdateOverdraftLimit(reqData))
  }

  useEffect(() => {
    if (userInfo?.user?.role !== "Admin") {
      navigate("/performance", { replace: true })
    }
  })
  return (
    <>
      <SideNav />
      <Helmet>
        <title>Limit Creation | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Update Customer OverDraft Limit</h1>
            <div className="investment-form">
              <form action="" method="post" onSubmit={submitHandler}>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "red",
                    fontSize: "20px",
                    letterSpacing: "1.5px"
                  }}
                >
                  {error && error}
                </p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "green",
                    fontSize: "20px",
                    letterSpacing: "1.5px"
                  }}
                >
                  {success && overdraftLimit?.message}
                </p>
                <div className="form-group">
                  <label htmlFor="email" style={{ color: "#ffffff" }}>
                    Customer Wallet Tag:
                  </label>

                  <input
                    className="invest-input"
                    type="text"
                    placeholder="Input customer wallet tag"
                    value={wallet_tag}
                    onChange={(e) => setWalletTag(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email" style={{ color: "#ffffff" }}>
                    OverDraft Amount
                  </label>

                  <input
                    className="invest-input"
                    type="number"
                    placeholder="Input customer wallet tag"
                    value={overdraftAmount}
                    onChange={(e) => setOverdraftAmount(e.target.value)}
                  />
                </div>

                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <button type="submit" className="login-btn">
                      Publish
                    </button>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OverDraftLimitScreen

import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { CSVLink } from "react-csv"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { fetchAllBalances } from "../actions/adminActions"
import { format, formatDate } from "../main"
import Loader from "./Loader"
import Paginate from "./Paginate"

const WalletBalance = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [currPage, setCurrPage] = useState(1)
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [email, setEmail] = useState("")
  const [penntag, setPenntag] = useState("")
  const [byBalance, setByBalance] = useState(false)
  const walletRef = useRef(true)

  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const allBalances = useSelector((state) => state.allBalances)
  const { loading, success, balances, error } = allBalances

  useEffect(() => {
    if (walletRef.current) {
      dispatch(fetchAllBalances(currPage))
      walletRef.current = false
    }
    if (success && balances) {
      setData(
        balances?.data?.items?.map((tran) => ({
          wallet_id: tran.wallet_id,
          customer_name: tran.name,
          penntag: tran.username,
          date_created: tran.date_created,
          last_transaction_date: tran.last_transaction_date,
          available_balance: tran.available_balance,
          accrued_interest: tran.accrued_interest
        }))
      )

      setHeaders([
        { label: "Wallet Id", key: "wallet_id" },
        { label: "Customer Name", key: "customer_name" },
        { label: "Penntag", key: "penntag" },
        { label: "Date Created", key: "date_created" },
        { label: "Last Transaction Date", key: "last_transaction_date" },
        { label: "Available Balance", key: "available_balance" },
        { label: "Accrued Interest", key: "accrued_interest" }
      ])
    }
  }, [dispatch, currPage, balances, success])
  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      byBalance,
      penntag
    }

    dispatch(fetchAllBalances(currPage, filterData))
  }

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    setEmail("")
    setPenntag("")
  }

  const afterPageClicked = (page_number) => {
    if (page_number === currPage) {
      setCurrPage(page_number + 1)
    } else {
      setCurrPage(page_number)
    }

    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      byBalance,
      penntag
    }
    if (page_number === "undefined1") {
      setCurrPage(1)
    }
    dispatch(fetchAllBalances(currPage, filterData))
  }

  const handleExport = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      penntag,
      byBalance,
      exported: true
    }

    dispatch(fetchAllBalances(currPage, filterData))
  }
  return (
    <>
      <div className="filter-box">
        <div className="filter">
          <>
            <div className="filter-container">
              <p style={{ cursor: "pointer" }} onClick={handleClick}>
                Filter
              </p>
              <div className="navContainer" style={styles.navContainer}>
                <div className="date-filter">
                  <div className="date-title">Date</div>
                  <div className="date-options">
                    <div className="custom-filter__item custom-filter__item--pad"></div>
                    <div className="date-content">
                      <div className="date-items">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="date-title">Status</div>
                <div className="checkbox checkbox--flex">
                  <div className="checkbox__item">
                    <input
                      id="successful_wz3gl9i2d"
                      type="checkbox"
                      name="succwz3gl9i2d"
                      className="checkbox__input"
                      value="successful"
                      checked={byBalance}
                      onChange={() => setByBalance(!byBalance)}
                    />{" "}
                    <label
                      htmlFor="successful_wz3gl9i2d"
                      className="checkbox__label"
                    >
                      Sort By Balance
                    </label>
                  </div>
                </div>

                <div className="status-container">
                  <div className="date-title">Customer</div>
                  <div className="checkbox checkbox--flex">
                    <div className="checkbox__item full-width">
                      <input
                        type="email"
                        className="email-input checkbox-item-styled"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter customer Email"
                      />{" "}
                    </div>
                    <div className="checkbox__item full-width">
                      <input
                        type="email"
                        className="email-input checkbox-item-styled"
                        value={penntag}
                        onChange={(e) => setPenntag(e.target.value)}
                        placeholder="Enter customer Tag"
                      />{" "}
                    </div>
                  </div>
                </div>
                <div
                  className="status-container"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                  }}
                >
                  <button className="button" onClick={handleClear}>
                    <p>Clear</p>
                  </button>
                  <button className="button" onClick={handleFilter}>
                    <p>Filter</p>
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
        <div className="filter" onClick={handleExport}>
          <CSVLink data={data} headers={headers}>
            Export
          </CSVLink>
        </div>
      </div>
      <p>{error && error}</p>
      <>
        <MDBTable
          responsive
          style={{
            marginTop: "20px"
          }}
        >
          <MDBTableHead>
            <tr className="font-reduce">
              <th scope="col">S/N</th>
              <th scope="col">Wallet ID</th>
              <th scope="col">Name</th>
              <th scope="col">PennTag</th>
              <th cope="col">Balance</th>
              <th scope="col">Accrued Interest</th>
              <th scope="col">Date Added</th>
              <th scope="col">Last Transaction</th>
            </tr>
          </MDBTableHead>
          {loading ? (
            <Loader />
          ) : (
            <MDBTableBody>
              {success &&
                balances?.data?.items?.map((bal, key) => (
                  <tr className="font-reduce">
                    <td>{key + 1}</td>
                    <td>{bal?.id}</td>
                    <td>{bal?.name}</td>
                    <td>{bal?.username}</td>
                    <td>{format(bal?.available_balance)}</td>
                    <td>{format(bal?.accrued_interest)}</td>
                    <td>{moment(bal?.date_created).format("LLL")}</td>
                    <td>
                      {bal?.last_transaction_date != null
                        ? moment(bal?.last_transaction_date).format("LLL")
                        : null}
                    </td>
                  </tr>
                ))}
            </MDBTableBody>
          )}
        </MDBTable>
        <Paginate
          totPages={balances?.data?.page?.total_page}
          currentPage={String(balances?.data?.page?.current_page)}
          page={String(balances?.data?.page?.current_page)}
          pageClicked={(ele) => {
            afterPageClicked(ele)
          }}
        />
      </>
    </>
  )
}

export default WalletBalance

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserStatus } from '../actions/userActions'
import Loader from './Loader'

const DeleteAdminModal = ({ setIsOpen, id }) => {
  const dispatch = useDispatch()
  const [content, setContent] = useState('')

  const userStatus = useSelector((state) => state.userStatus)
  const { loading, success } = userStatus
  useEffect(() => {
    if (success) {
      setIsOpen(false)
      window.location.reload()
    }
  }, [success, dispatch, setIsOpen])

  const reject = () => {
    const postData = {
      user_id: id,
      action: content
    }
    console.log(postData)
    dispatch(setUserStatus(postData))
  }
  return (
    <>
      <div className='darkBG' onClick={() => setIsOpen(false)} />
      <div
        className='centered'
        style={{
          backgroundColor: 'grey'
        }}
      >
        <div
          className='modal'
          style={{
            backgroundColor: 'grey',
            height: '200px'
          }}
        >
          <div className='modalHeader'>
            <h5 className='heading'>Update Admin Status</h5>
          </div>
          <button className='closeBtn' onClick={() => setIsOpen(false)}>
            {/* <RiCloseLine style={{ marginBottom: '-3px' }} /> */}
          </button>
          <div className='modalContent'>
            <select
              style={{
                background: '#1a1a1a',
                border: 'none',
                padding: '20px',
                width: '300px',
                borderRadius: '10px'
              }}
              onChange={(e) => setContent(e.target.value)}
            >
              <option>Select Status</option>

              <option value='activate'>Activate</option>
              <option value='deactivate'>De-Activate</option>
              <option value='flag'>Flag</option>
              <option value='suspend'>Suspend</option>
            </select>
          </div>
          <div className='modalActions'>
            <div className='actionsContainer'>
              {loading || loading ? (
                <Loader />
              ) : (
                <>
                  <button className='deleteBtn' onClick={reject}>
                    Update
                  </button>
                  <button
                    className='cancelBtn'
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteAdminModal

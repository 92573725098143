import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setResetPassword } from '../actions/userActions'
import Loader from '../components/Loader'
import { isEmpty } from '../helper'

const ResetPasswordScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email_address, setEmail] = useState('')
  const [error2, setError] = useState('')
  const [new_password, setPassword] = useState('')
  const [otp, setOtp] = useState('')

  const resetPassword = useSelector((state) => state.resetPassword)
  const { loading, success, error, passwordReset } = resetPassword

  const submitHandler = (e) => {
    e.preventDefault()
    if (!isEmpty(email_address) || !isEmpty(new_password) || !isEmpty(otp)) {
      localStorage.removeItem('userInfo')
      setError('')
      const postData = {
        email_address,
        otp,
        new_password
      }
      dispatch(setResetPassword(postData))
    } else {
      setError('Please fill all required field(s)')
    }
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => navigate('/', { replace: true }), 3000)
    }
  }, [success, navigate])
  return (
    <>
      <main className='login-bg'>
        <div className='login-form'>
          <a href='./'>
            <img
              src='assets/images/PennyTree-Logo.png'
              alt=''
              className='admin-logo'
            />
          </a>
          <h5>
            Hi there, Please enter your email to continue resetting your
            password
          </h5>
          <form onSubmit={submitHandler}>
            <div className='err-msg'></div>

            <div className='form-group'>
              <label htmlFor='password' style={{ color: '#ffffff' }}>
                Email Address
              </label>
              <input
                type='email'
                name='otp'
                placeholder='Enter Email Address '
                value={email_address}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label htmlFor='password' style={{ color: '#ffffff' }}>
                Otp
              </label>
              <input
                type='text'
                name='otp'
                placeholder='Enter Otp Sent to your email '
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label htmlFor='password' style={{ color: '#ffffff' }}>
                New Password
              </label>
              <input
                type='text'
                name='otp'
                placeholder='Enter New Password '
                value={new_password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <p>{!loading && !error && passwordReset?.message}</p>
            {success && (
              <p>
                You will be redirected in 3 seconds if not redirected please{' '}
                <a href='/'>Click Here</a>
              </p>
            )}

            {loading ? (
              <Loader />
            ) : (
              <button type='submit' className='login-btn'>
                Continue
              </button>
            )}
          </form>

          <p
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'red',
              fontSize: '20px',
              letterSpacing: '1.5px'
            }}
          >
            {error && error}
            {error2 && error2}
          </p>
        </div>
      </main>
    </>
  )
}

export default ResetPasswordScreen

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRoles } from '../actions/adminActions'
import { setUserRole, setUserStatus } from '../actions/userActions'
import Loader from './Loader'

const UpdateAdminRoleModal = ({ setIsOpen, id }) => {
  const dispatch = useDispatch()
  const [role_id, setRoleId] = useState('')

  const userRole = useSelector((state) => state.userRole)
  const { loading, success } = userRole
  useEffect(() => {
    dispatch(fetchRoles())
    if (success) {
      setIsOpen(false)
      window.location.reload()
    }
  }, [success, dispatch, setIsOpen])

  const getRoles = useSelector((state) => state.getRoles)
  const { success: roleSuccess, roles } = getRoles

  const reject = () => {
    const postData = {
      user_id: id,
      role_id
    }
    dispatch(setUserRole(postData))
  }
  return (
    <>
      <div className='darkBG' onClick={() => setIsOpen(false)} />
      <div
        className='centered'
        style={{
          backgroundColor: 'grey'
        }}
      >
        <div
          className='modal'
          style={{
            backgroundColor: 'grey',
            height: '200px'
          }}
        >
          <div className='modalHeader'>
            <h5 className='heading'>Update Admin Role</h5>
          </div>
          <button className='closeBtn' onClick={() => setIsOpen(false)}>
            {/* <RiCloseLine style={{ marginBottom: '-3px' }} /> */}
          </button>
          <div className='modalContent'>
            <select
              style={{
                background: '#1a1a1a',
                border: 'none',
                padding: '20px',
                width: '300px',
                borderRadius: '10px'
              }}
              onChange={(e) => setRoleId(e.target.value)}
            >
              <option>Select Roles</option>
              {roleSuccess &&
                roles &&
                roles?.data?.map((d) => (
                  <option value={d?.id}>{d?.name}</option>
                ))}
            </select>
          </div>
          <div className='modalActions'>
            <div className='actionsContainer'>
              {loading || loading ? (
                <Loader />
              ) : (
                <>
                  <button className='deleteBtn' onClick={reject}>
                    Update
                  </button>
                  <button
                    className='cancelBtn'
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateAdminRoleModal

import axios from "axios";
import {
  ALL_INVESTMENTS_FAIL,
  ALL_INVESTMENTS_REQUEST,
  ALL_INVESTMENTS_SUCCESS,
  ALL_TARGET_SAVINGS_LOGS_FAIL,
  ALL_TARGET_SAVINGS_LOGS_REQUEST,
  ALL_TARGET_SAVINGS_LOGS_SUCCESS,
  CREATE_INVESTMENT_FAIL,
  CREATE_INVESTMENT_REQUEST,
  CREATE_INVESTMENT_SUCCESS,
  DELETE_INVESTMENT_FAIL,
  DELETE_INVESTMENT_REQUEST,
  DELETE_INVESTMENT_SUCCESS,
  GET_INVESTMENT_RATES_FAIL,
  GET_INVESTMENT_RATES_REQUEST,
  GET_INVESTMENT_RATES_SUCCESS,
  TARGET_SAVINGS_LOGS_FAIL,
  TARGET_SAVINGS_LOGS_REQUEST,
  TARGET_SAVINGS_LOGS_SUCCESS,
  UPDATE_INVESTMENT_RATES_FAIL,
  UPDATE_INVESTMENT_RATES_REQUEST,
  UPDATE_INVESTMENT_RATES_SUCCESS,
} from "../constants/adminConstants";
import { BASE_API_URL } from "../constants/dropdown";

export const fetchAllInvestments =
  (pageNumber, filterdata) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ALL_INVESTMENTS_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (filterdata === undefined) {
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/investment/all?size=300&page=${pageNumber}`,
          config
        );

        dispatch({
          type: ALL_INVESTMENTS_SUCCESS,
          payload: data,
        });
      } else {
        const year = new Date().getFullYear();
        const date = new Date(year, 0, 1);
        const { name, amount, endDate } = filterdata;

        const startDate =
          filterdata.startDate === undefined ? date : filterdata.startDate;

        if (name) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/investment/all?name=${name}`,
            config
          );

          dispatch({
            type: ALL_INVESTMENTS_SUCCESS,
            payload: data,
          });
        } else if (amount) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/investment/all?amount=${amount}`,
            config
          );

          dispatch({
            type: ALL_INVESTMENTS_SUCCESS,
            payload: data,
          });
        } else {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/investment/all?fromdate=${startDate}&todate=${endDate}`,
            config
          );

          dispatch({
            type: ALL_INVESTMENTS_SUCCESS,
            payload: data,
          });
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: ALL_INVESTMENTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const eraseInvestment = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_INVESTMENT_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.delete(
      `${BASE_API_URL}/admin/investment/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_INVESTMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: DELETE_INVESTMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const newInvestment = (filterData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATE_INVESTMENT_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const createData = {
      name: filterData?.investmentName,
      name_of_issuer: filterData?.issuerName,
      issue_amount: filterData?.issueAmount,
      no_of_subscriptions: filterData?.numberOfSubscription,
      value_of_each_subscription: filterData?.valueOfSubscription,
      tenor: filterData?.tenor,
      start_date: filterData?.startDate,
      end_date: filterData?.endDate,
      interestRate: filterData?.totalInterestRate,
      platform_interest_rate: filterData?.platFormInterestRate,
      spread: filterData?.spread,
    };

    // getting our response as we set it in our userController in the backend
    const { data } = await axios.post(
      `${BASE_API_URL}/admin/investment/create`,
      createData,
      config
    );

    dispatch({
      type: CREATE_INVESTMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: CREATE_INVESTMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchTargetSavingsLogs =
  (customerId, savingsId, filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TARGET_SAVINGS_LOGS_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (filterData === undefined) {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/customer/management/target-savings/single?customerid=${customerId}&savingsid=${savingsId}`,
          config
        );

        dispatch({
          type: TARGET_SAVINGS_LOGS_SUCCESS,
          payload: data,
        });
      } else {
        const year = new Date().getFullYear();
        const date = new Date(year, 0, 1);
        const { endDate } = filterData;

        const startDate =
          filterData.startDate === undefined ? date : filterData.startDate;

        const { data } = await axios.get(
          `${BASE_API_URL}/admin/customer/management/target-savings/single?customerid=${customerId}&savingsid=${savingsId}&fromdate=${startDate}&todate=${endDate}`,
          config
        );

        dispatch({
          type: TARGET_SAVINGS_LOGS_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: TARGET_SAVINGS_LOGS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchInvestmentRates = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_INVESTMENT_RATES_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE_API_URL}/admin/investment/all/rates`,
      config
    );

    dispatch({
      type: GET_INVESTMENT_RATES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_INVESTMENT_RATES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setInvestmentRate = (reqBody) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_INVESTMENT_RATES_REQUEST,
    });

    const {
      admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
    } = getState();
    const token = userInfo?.token?.access_token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { id, rate } = reqBody;

    const { data } = await axios.put(
      `${BASE_API_URL}/admin/investment/rate`,
      { id, rate: Number(rate) },
      config
    );

    dispatch({
      type: UPDATE_INVESTMENT_RATES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: UPDATE_INVESTMENT_RATES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchAllTargetSavingsLogs =
  (filterData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ALL_TARGET_SAVINGS_LOGS_REQUEST,
      });

      const {
        admin2faLogin: { userInfo }, // we are destructuring two levels i.e getState().userLogin.userInfo
      } = getState();
      const token = userInfo?.token?.access_token;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (filterData === undefined) {
        // getting our response as we set it in our userController in the backend
        const { data } = await axios.get(
          `${BASE_API_URL}/admin/customer/management/target-savings/logs?size=${500}`,
          config
        );

        dispatch({
          type: ALL_TARGET_SAVINGS_LOGS_SUCCESS,
          payload: data,
        });
      } else {
        const year = new Date().getFullYear();
        const date = new Date(year, 0, 1);
        const { endDate, email, transactionreference } = filterData;

        const startDate =
          filterData.startDate === undefined ? date : filterData.startDate;

        if (email) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/target-savings/logs?customeremail=${email}&size=${500}`,
            config
          );

          dispatch({
            type: ALL_TARGET_SAVINGS_LOGS_SUCCESS,
            payload: data,
          });
        } else if (transactionreference) {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/target-savings/logs?transactionreference=${transactionreference}&size=${500}`,
            config
          );

          dispatch({
            type: ALL_TARGET_SAVINGS_LOGS_SUCCESS,
            payload: data,
          });
        } else {
          const { data } = await axios.get(
            `${BASE_API_URL}/admin/customer/management/target-savings/logs?fromdate=${startDate}&size=${500}`,
            config
          );

          dispatch({
            type: ALL_TARGET_SAVINGS_LOGS_SUCCESS,
            payload: data,
          });
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: ALL_TARGET_SAVINGS_LOGS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

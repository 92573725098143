import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchAllTargetSavings,
  fetchOfficerInfo,
  fetchTargetSavingsOfficerInfo,
} from "../actions/adminActions";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Paginate from "../components/Paginate";
import SideNav from "../components/SideNav";
import { avgFloat, format, getkeybyvalue } from "../main";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import {
  GET_ALL_TARGET_SAVINGS_RESET,
  INVESTMENT_CONSTANTS,
} from "../constants/adminConstants";

const AllTargetSavingsScreen = () => {
  const [currPage, setCurrPage] = React.useState(1);
  const dispatch = useDispatch();
  const { id } = useParams();
  const officerRef = useRef(true);
  const [email, setEmail] = useState("");
  const [savingsName, setSavingsName] = useState("All");
  const [tag, setTag] = useState("");
  const year = new Date().getFullYear();
  const [startDate, setStartDate] = useState(new Date(year, 0, 1));
  const [endDate, setEndDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [byBalance, setByBalance] = useState(false);

  const allTargetSavings = useSelector((state) => state.allTargetSavings);
  const { loading, success, error, alltargetSavings } = allTargetSavings;

  useEffect(() => {
    dispatch({ type: GET_ALL_TARGET_SAVINGS_RESET });
    if (!id && officerRef.current) {
      dispatch({ type: GET_ALL_TARGET_SAVINGS_RESET });
      dispatch(fetchAllTargetSavings());
      officerRef.current = false;
    } else if (id) {
      dispatch({ type: GET_ALL_TARGET_SAVINGS_RESET });
      const filterData = {
        statusid: id,
        byBalance,
      };
      dispatch(fetchAllTargetSavings(filterData));
      //   setSavingsName(INVESTMENT_CONSTANTS.getKeyByValue(id))
      officerRef.current = false;
    }
    if (success) {
      setData(
        alltargetSavings?.items?.map((tran, key) => ({
          customer_name: tran?.first_name + tran?.last_name,
          email_address: tran?.email_address,
          purpose: tran?.purpose,
          username: tran?.username,
          individual_account_number: tran?.individual_account_number,
          merchant_account_number: tran?.merchant_account_number,
          target_amount: tran?.target_amount,
          date_created: tran?.date_created,
          start_date: tran?.start_date,
          debit_amount: tran?.debit_amount,
          last_collection_date: tran?.last_collection_date,
          next_collection_date: tran?.next_collection_date,
          end_date: tran?.end_date,
          frequency: tran?.frequency,
          no_of_debits: tran?.no_of_debits,
          no_of_failed_debits: tran?.no_of_failed_debits,
          successful_debits_today: tran?.successful_debits_today,
          failed_debits_today: tran?.failed_debits_today,
          total_debits_today: tran?.total_debits_today,
          wallet_balance: tran?.wallet_balance,
          amount_saved: tran?.amount_saved,
          savings_status: tran?.savings_status,
          gateway: tran?.gateway ?? "wallet",
        }))
      );

      setHeaders([
        { label: "Customer Name", key: "customer_name" },
        { label: "Email Address", key: "email_address" },
        { label: "Purpose", key: "purpose" },
        { label: "Username", key: "username" },
        {
          label: "Individual Account Number",
          key: "individual_account_number",
        },
        { label: "Merchant Account Numbe", key: "merchant_account_number" },
        { label: "Target Amount", key: "target_amount" },
        { label: "Date Created", key: "date_created" },
        { label: "Start Date", key: "start_date" },
        { label: "Debit Amount", key: "debit_amount" },
        { label: "Last Collection Date", key: "last_collection_date" },
        { label: "Next Collection Date", key: "next_collection_date" },
        { label: "End Date", key: "end_date" },
        { label: "Frequency", key: "frequency" },
        { label: "No Of Debits", key: "no_of_debits" },
        { label: "No Of Failed Debits", key: "no_of_failed_debits" },
        { label: "Successful Debits Today", key: "successful_debits_today" },
        { label: "Failed Debits Today", key: "failed_debits_today" },
        { label: "Total Debits Today", key: "total_debits_today" },
        { label: "Wallet Balance", key: "wallet_balance" },
        { label: "Amount Saved", key: "amount_saved" },
        { label: "Savings Status", key: "savings_status" },
        { label: "Gateway", key: "gateway" },
      ]);
    }
  }, [id, dispatch]);

  const afterPageClicked = (page_number) => {
    if (page_number === currPage) {
      setCurrPage(page_number + 1);
    } else {
      setCurrPage(page_number);
    }
  };

  const [styles, setStyles] = useState({
    navContainer: {
      display: "none",
    },
  });

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none",
        },
      });
    } else {
      setStyles({
        navContainer: {
          display: "block",
        },
      });
    }
  };

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: "none",
      },
    });
    const filterData = {
      byBalance,
    };
    dispatch(fetchAllTargetSavings(filterData, currPage));
  };

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none",
      },
    });
    const filterData = {
      email,
      tag,
      statusid: id ? id : null,
      byBalance,
    };
    dispatch(fetchAllTargetSavings(filterData, currPage));
  };

  const handleExport = (e, done) => {
    setStyles({
      navContainer: {
        display: "none",
      },
    });

    setData(
      alltargetSavings?.items?.map((tran, key) => ({
        customer_name: tran?.first_name + tran?.last_name,
        email_address: tran?.email_address,
        purpose: tran?.purpose,
        username: tran?.username,
        individual_account_number: tran?.individual_account_number,
        merchant_account_number: tran?.merchant_account_number,
        target_amount: tran?.target_amount,
        date_created: tran?.date_created,
        start_date: tran?.start_date,
        debit_amount: tran?.debit_amount,
        last_collection_date: tran?.last_collection_date,
        next_collection_date: tran?.next_collection_date,
        end_date: tran?.end_date,
        frequency: tran?.frequency,
        no_of_debits: tran?.no_of_debits,
        no_of_failed_debits: tran?.no_of_failed_debits,
        successful_debits_today: tran?.successful_debits_today,
        failed_debits_today: tran?.failed_debits_today,
        total_debits_today: tran?.total_debits_today,
        wallet_balance: tran?.wallet_balance,
        amount_saved: tran?.amount_saved,
        savings_status: tran?.savings_status,
        gateway: tran?.gateway ?? "wallet",
      }))
    );

    setHeaders([
      { label: "Customer Name", key: "customer_name" },
      { label: "Email Address", key: "email_address" },
      { label: "Purpose", key: "purpose" },
      { label: "Username", key: "username" },
      {
        label: "Individual Account Number",
        key: "individual_account_number",
      },
      { label: "Merchant Account Numbe", key: "merchant_account_number" },
      { label: "Target Amount", key: "target_amount" },
      { label: "Date Created", key: "date_created" },
      { label: "Start Date", key: "start_date" },
      { label: "Debit Amount", key: "debit_amount" },
      { label: "Last Collection Date", key: "last_collection_date" },
      { label: "Next Collection Date", key: "next_collection_date" },
      { label: "End Date", key: "end_date" },
      { label: "Frequency", key: "frequency" },
      { label: "No Of Debits", key: "no_of_debits" },
      { label: "No Of Failed Debits", key: "no_of_failed_debits" },
      { label: "Successful Debits Today", key: "successful_debits_today" },
      { label: "Failed Debits Today", key: "failed_debits_today" },
      { label: "Total Debits Today", key: "total_debits_today" },
      { label: "Wallet Balance", key: "wallet_balance" },
      { label: "Amount Saved", key: "amount_saved" },
      { label: "Savings Status", key: "savings_status" },
      { label: "Gateway", key: "gateway" },
    ]);
  };

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Target Savings | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            {loading ? (
              <Loader />
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <div
                  className="status-container"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div className="button">
                    <Link to="/target-savings/failed"> Failed Savings</Link>
                  </div>
                </div>
                <h1>
                  {id ? alltargetSavings?.items?.[0]?.savings_status : "All"}{" "}
                  Target Savings
                </h1>
                <div className="filter-box">
                  <div className="filter">
                    <>
                      <div className="filter-container">
                        <p style={{ cursor: "pointer" }} onClick={handleClick}>
                          Filter
                        </p>
                        <div
                          className="navContainer"
                          style={styles.navContainer}
                        >
                          <div className="date-filter">
                            <div className="date-title">Date</div>
                            <div className="date-options">
                              <div className="custom-filter__item custom-filter__item--pad">
                                <div className="overview-filter">
                                  <div className="filter__item">
                                    <input
                                      id="today_fkc3w9c4e0"
                                      value="Today"
                                      type="radio"
                                      name="dateFilter_fkc3w9c4e0"
                                      className="overview-filter__item__radio"
                                    />
                                    <label
                                      htmlFor="today_fkc3w9c4e0"
                                      className="overview-filter__item__link active"
                                    >
                                      Today
                                    </label>
                                  </div>{" "}
                                  <div className="filter__item">
                                    <input
                                      id="last7_fkc3w9c4e0"
                                      value="7 days"
                                      type="radio"
                                      name="dateFilter_fkc3w9c4e0"
                                      className="overview-filter__item__radio"
                                    />
                                    <label
                                      htmlFor="last7_fkc3w9c4e0"
                                      className="overview-filter__item__link"
                                    >
                                      Last 7 days
                                    </label>
                                  </div>{" "}
                                  <div className="filter__item">
                                    <input
                                      id="last30_fkc3w9c4e0"
                                      value="30 days"
                                      type="radio"
                                      name="dateFilter_fkc3w9c4e0"
                                      className="overview-filter__item__radio"
                                    />
                                    <label
                                      htmlFor="last30_fkc3w9c4e0"
                                      className="overview-filter__item__link"
                                    >
                                      30 days
                                    </label>
                                  </div>{" "}
                                  <div className="filter__item">
                                    <input
                                      id="1year_fkc3w9c4e0"
                                      value="1 year"
                                      type="radio"
                                      name="dateFilter_fkc3w9c4e0"
                                      className="overview-filter__item__radio"
                                    />
                                    <label
                                      htmlFor="1year_fkc3w9c4e0"
                                      className="overview-filter__item__link"
                                    >
                                      1 year
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="date-content">
                                <div className="date-items">
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                  />

                                  <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="status-container">
                            <div className="date-title">Customer</div>
                            <div className="checkbox checkbox--flex">
                              <div
                                className="checkbox__item"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "1px solid grey",
                                    fontSize: "14px",
                                    display: tag ? " none" : "block",
                                  }}
                                  type="email"
                                  className="email-input"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  placeholder="Enter customer Email"
                                />{" "}
                              </div>
                              <div
                                className="checkbox__item"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "1px solid grey",
                                    fontSize: "14px",
                                    display: email ? " none" : "block",
                                  }}
                                  type="text"
                                  className="email-input"
                                  value={tag}
                                  onChange={(e) => setTag(e.target.value)}
                                  placeholder="Enter Penntag or merchant Tag"
                                />{" "}
                              </div>
                            </div>
                            <div className="checkbox checkbox--flex">
                              <div className="checkbox__item">
                                <input
                                  id="successful_wz3gl9i3d"
                                  type="checkbox"
                                  name="succwz3gl9i3d"
                                  className="checkbox__input"
                                  value="successful"
                                  checked={byBalance}
                                  onChange={() => setByBalance(!byBalance)}
                                />{" "}
                                <label
                                  htmlFor="successful_wz3gl9i3d"
                                  className="checkbox__label"
                                >
                                  Sort By Balance
                                </label>
                              </div>
                            </div>
                          </div>
                          <div
                            className="status-container"
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            <button className="button" onClick={handleClear}>
                              <p>Clear</p>
                            </button>
                            <button className="button" onClick={handleFilter}>
                              <p>Filter</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                  <div className="filter">
                    <CSVLink
                      data={data}
                      headers={headers}
                      filename={"transaction.csv"}
                      asyncOnClick={true}
                      onClick={handleExport}
                    >
                      Export
                    </CSVLink>
                  </div>
                </div>

                <MDBTable responsive style={{ width: "100%" }}>
                  <MDBTableHead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col">Customer Name</th>
                      <th scope="col">Email Address</th>
                      <th scope="col">Username</th>
                      <th scope="col">Savings Name</th>
                      <th scope="col">Target Amount</th>
                      <th scope="col">Expected Amount</th>
                      <th scope="col">Recurring Amount</th>
                      <th scope="col">Date Created</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      <th scope="col">Last Debit Date</th>
                      <th scope="col">Next Debit Date</th>
                      <th scope="col">Frequency</th>
                      <th scope="col">No Of Debits</th>
                      <th scope="col">No Of Failed Debits</th>
                      <th scope="col">Successful Debits Today</th>
                      <th scope="col">Failed Debits Today</th>
                      <th scope="col">Total Debits Today</th>
                      <th scope="col">Gateway</th>
                      <th scope="col">Savings Status</th>
                      <th scope="col">Business Name</th>
                      <th scope="col">Merchant Tag</th>
                      <th scope="col">Account Officer</th>
                      <th scope="col">Individual Account Number</th>
                      <th scope="col">Merchant Account Number</th>

                      <th scope="col">Amount Saved</th>
                      <th scope="col">Wallet Balance</th>
                      <th scope="col">Actions</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      alltargetSavings?.items?.map((tran, key) => (
                        <tr key={key}>
                          <td>
                            <Link
                              to={`/target-savings/logs/${tran?.purpose}`}
                              state={{
                                customerId: tran?.id,
                                savingsId: tran?.savings_id,
                                name: tran?.first_name + " " + tran?.last_name,
                                purpose: tran?.purpose,
                              }}
                            >
                              {key + 1}
                            </Link>
                          </td>

                          <td>
                            {tran?.first_name} {tran?.last_name}
                          </td>
                          <td>{tran?.email_address}</td>
                          <td>{tran?.username}</td>

                          <td>
                            <Link
                              to={`/target-savings/logs/${tran?.purpose}`}
                              state={{
                                customerId: tran?.id,
                                savingsId: tran?.savings_id,
                                name: tran?.first_name + " " + tran?.last_name,
                                purpose: tran?.purpose,
                              }}
                            >
                              {tran?.purpose}
                            </Link>
                          </td>

                          <td>{format(tran?.target_amount)}</td>
                          <td>{format(tran?.expected_amount)}</td>
                          <td>{format(tran?.debit_amount)}</td>

                          <td>{moment(tran?.date_created).format("LLL")}</td>
                          <td>{moment(tran?.start_date).format("LLL")}</td>
                          <td>{moment(tran?.end_date).format("LLL")}</td>

                          <td>
                            {moment(tran?.last_collection_date).format("LLL")}
                          </td>
                          <td>
                            {moment(tran?.next_collection_date).format("LLL")}
                          </td>
                          <td>{tran?.frequency}</td>
                          <td>{tran?.no_of_debits}</td>
                          <td>{tran?.no_of_failed_debits}</td>
                          <td>{tran?.successful_debits_today}</td>
                          <td>{tran?.failed_debits_today}</td>
                          <td>{tran?.total_debits_today}</td>
                          <td>{tran?.gateway ?? "wallet"}</td>

                          <td>{tran?.savings_status}</td>
                          <td>{tran?.business_name}</td>
                          <td>{tran?.merchant_tag}</td>
                          <td>{tran?.account_officer}</td>

                          <td>{tran?.individual_account_number}</td>
                          <td>{tran?.merchant_account_number}</td>

                          <td>{format(tran?.amount_saved)}</td>
                          <td>{format(tran?.wallet_balance)}</td>

                          <td>
                            <button className="withdrawal-btn">
                              <a href={`/${tran?.id}`}>View More</a>
                            </button>
                          </td>

                          <td>
                            <button className="withdrawal-btn">
                              <Link
                                to={`/target-savings/logs/${tran?.purpose}`}
                                state={{
                                  customerId: tran?.id,
                                  savingsId: tran?.savings_id,
                                  name:
                                    tran?.first_name + " " + tran?.last_name,
                                  purpose: tran?.purpose,
                                }}
                              >
                                View Logs
                              </Link>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
                <Paginate
                  totPages={alltargetSavings?.page?.total_page}
                  currentPage={String(alltargetSavings?.page?.current_page)}
                  page={String(alltargetSavings?.page?.current_page)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele);
                  }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default AllTargetSavingsScreen;

import {
  GET_FLW_BALANCE_FAIL,
  GET_FLW_BALANCE_REQUEST,
  GET_FLW_BALANCE_SUCCESS,
  GET_PAYAZA_BALANCE_FAIL,
  GET_PAYAZA_BALANCE_REQUEST,
  GET_PAYAZA_BALANCE_SUCCESS,
  GET_PAYSTACK_BALANCE_FAIL,
  GET_PAYSTACK_BALANCE_REQUEST,
  GET_PAYSTACK_BALANCE_SUCCESS,
  GET_SEERBIT_BALANCE_FAIL,
  GET_SEERBIT_BALANCE_REQUEST,
  GET_SEERBIT_BALANCE_SUCCESS,
  GET_VFD_BALANCE_FAIL,
  GET_VFD_BALANCE_REQUEST,
  GET_VFD_BALANCE_SUCCESS
} from "../constants/adminConstants"
import axios from "axios"
import {
  FLW_KEY,
  PAYAZA_AUTH,
  PAYSTACK_SECRET,
  SEERBIT_EMAIL,
  SEERBIT_KEY,
  SEERBIT_PASS,
  VFD_KEY,
  VFD_WALLET_CREDS
} from "../constants/dropdown"

export const fetchPayazaBalance = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PAYAZA_BALANCE_REQUEST
    })

    const config = {
      headers: {
        Authorization: PAYAZA_AUTH,
        "x-TenantID": "live"
      }
    }
    const { data } = await axios.get(
      "https://payaza-account-live.payaza.africa/api/v1/mainaccounts/merchant/enquiry/main",
      config
    )

    console.log(data)

    dispatch({
      type: GET_PAYAZA_BALANCE_SUCCESS,
      payload: data?.data[0]
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: GET_PAYAZA_BALANCE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const fetchSeerbitBalance = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_SEERBIT_BALANCE_REQUEST
    })
    const login = {
      email: SEERBIT_EMAIL,
      password: SEERBIT_PASS
    }
    const { data } = await axios.post(
      `https://seerbitapi.com/pockets/pocket/authenticate`,

      login
    )
    if (data) {
      const token = data?.data.bearerToken
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Public-Key": SEERBIT_KEY
        }
      }

      const { data: balance } = await axios.get(
        `https://seerbitapi.com/pockets/pocket/balance/pocket-id/SBP0000090`,
        config
      )

      dispatch({
        type: GET_SEERBIT_BALANCE_SUCCESS,
        payload: balance
      })
    }
  } catch (error) {
    console.log(error)
    dispatch({
      type: GET_SEERBIT_BALANCE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const fetchVfdBalance = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_VFD_BALANCE_REQUEST
    })
    const config = {
      headers: {
        "signature": "TOBILOBA",
        "Content-Type": "application/json"
      }
    }

    const { data } = await axios.get(
      "http://87.236.167.10:8090/balance-vfd",
      config
    )

    dispatch({
      type: GET_VFD_BALANCE_SUCCESS,
      payload: data
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: GET_VFD_BALANCE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const fetchFlwBalance = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_FLW_BALANCE_REQUEST
    })
    const config = {
      headers: {
        "signature": "TOBILOBA",
        "Content-Type": "application/json"
      }
    }

    const { data } = await axios.get(
      "http://87.236.167.10:8090/balance-flw",
      config
    )

    console.log(data)

    dispatch({
      type: GET_FLW_BALANCE_SUCCESS,
      payload: data
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: GET_FLW_BALANCE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const fetchPaystackBalance = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PAYSTACK_BALANCE_REQUEST
    })
    const config = {
      headers: {
        Authorization: `Bearer ${PAYSTACK_SECRET}`
      }
    }

    const { data } = await axios.get("https://api.paystack.co/balance", config)

    dispatch({
      type: GET_PAYSTACK_BALANCE_SUCCESS,
      payload: data
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: GET_PAYSTACK_BALANCE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

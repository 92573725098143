import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import SideNav from "../components/SideNav";
import { useParams } from "react-router";

import Loader from "../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import {
  fetchAllTargetSavings,
  fetchOfficers,
  setCustomerLimit,
} from "../actions/adminActions";
import { format, formatDate } from "../main";
import {
  assignOfficerToCustomer,
  customerDeactivate,
  fetchCustomerCards,
  getCustomerDetails,
  removeCustomerCard,
} from "../actions/userActions";
import CustomerTransactions from "../components/CustomerTransactions";
import { Link } from "react-router-dom";
import CustomerDeactivateModal from "../components/CustomerDeactivateModal";
import { fetchCustomerBusiness } from "../actions/merchantActions";
import BusinessWallet from "../components/BusinessWallet";

const UserScreen = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const customerDetails = useSelector((state) => state.customerDetails);
  const { loading, error, success, customer } = customerDetails;

  const getOfficers = useSelector((state) => state.getOfficers);
  const { officers } = getOfficers;

  const customerCard = useSelector((state) => state.customerCard);
  const { loading: loadingCard, success: successCard, cards } = customerCard;

  const assignedOfficer = useSelector((state) => state.assignedOfficer);
  const { loading: officerLoading, success: officerSuccess } = assignedOfficer;

  const admin2faLogin = useSelector((state) => state.admin2faLogin);
  const { userInfo } = admin2faLogin;

  const deleteCustomerCard = useSelector((state) => state.deleteCustomerCard);
  const {
    loading: deleteCardLoading,
    success: deleteCardSuccess,
    error: deleteCardError,
  } = deleteCustomerCard;

  const deactivateCustomer = useSelector((state) => state.deactivateCustomer);
  const { loading: deactivateLoading, success: deactivateSuccess } =
    deactivateCustomer;

  const allTargetSavings = useSelector((state) => state.allTargetSavings);
  const {
    loading: savingsLoading,
    success: savingsSuccess,
    alltargetSavings: savings,
  } = allTargetSavings;

  const customerLimit = useSelector((state) => state.customerLimit);
  const {
    success: limitSuccess,
    error: limitError,
    loading: limitLoading,
  } = customerLimit;

  const getCustomerBusiness = useSelector((state) => state.getCustomerBusiness);
  const {
    loading: businessLoading,
    success: businessSuccess,
    business,
  } = getCustomerBusiness;

  const customerRef = useRef(true);

  useEffect(() => {
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location = `${process.env.REACT_APP_BASE_URL}/`
    // }
    if (customerRef.current) {
      const filterData = {
        customerid: id,
        statusid: 2,
        byBalance: true,
      };
      dispatch(getCustomerDetails(id));
      dispatch(fetchCustomerBusiness(id));
      dispatch(fetchOfficers());
      dispatch(fetchCustomerCards(id));
      dispatch(fetchAllTargetSavings(filterData));
      customerRef.current = false;
    }
    if (officerSuccess) {
      window.location.reload();
    }
    if (deleteCardSuccess) {
      window.location.reload();
    }
    if (deactivateSuccess) {
      window.location.reload();
    }
    if (limitSuccess) {
      window.location.reload();
    }
  }, [
    id,
    dispatch,
    loading,
    error,
    officerSuccess,
    deleteCardSuccess,
    deactivateSuccess,
    customer,
    limitSuccess,
  ]);

  const setAccountOfficer = (admin) => {
    const reqData = {
      customer_id: id,
      admin_id: admin,
    };
    dispatch(assignOfficerToCustomer(reqData));
  };

  const deactivateCust = () => {
    setIsOpen(true);
  };

  const activateCustomer = () => {
    const postData = {
      customer_id: id,
      account_locked_until: null,
    };

    dispatch(customerDeactivate(postData));
  };

  const deactivateCard = (remove, id) => {
    const postData = {
      cardid: id,
      remove,
    };
    dispatch(removeCustomerCard(postData));
  };
  const restoreLim = (e) => {
    e.preventDefault();
    const reqData = {
      customer_name: customer?.data?.customer_information?.username,
      reset_limit: true,
    };

    console.log(reqData);

    dispatch(setCustomerLimit(reqData));
  };
  return (
    <>
      <SideNav />
      <Helmet>
        <title> Transactions | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          {loading || officerLoading ? (
            <Loader />
          ) : (
            <div className="page-body">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="profile-box">
                  <div className="profile-content">
                    <FontAwesomeIcon icon={faUserTie} />
                    <span>
                      {customer?.data?.customer_information?.full_name}
                    </span>
                  </div>
                  <div className="profile-content">
                    <FontAwesomeIcon icon={faPhone} />
                    <span>
                      {customer?.data?.customer_information?.mobile_number}
                    </span>
                  </div>
                  <div className="profile-content">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span>
                      {customer?.data?.customer_information?.email_address}
                    </span>
                  </div>
                </div>

                {isOpen && (
                  <CustomerDeactivateModal setIsOpen={setIsOpen} id={id} />
                )}
                {userInfo?.user?.role === "Admin" && (
                  <>
                    <div
                      className="account-officer"
                      style={{ display: "flex" }}
                    >
                      <select
                        style={{
                          background: "#1a1a1a",
                          border: "none",
                          padding: "13px",
                          width: "200px",
                          borderRadius: "10px",
                        }}
                        onChange={(e) => setAccountOfficer(e.target.value)}
                      >
                        <option>
                          {customer?.data?.customer_information
                            ?.account_officer !== null ? (
                            <span>ReAssign Account Officer</span>
                          ) : (
                            <span>Assign Account Officer</span>
                          )}
                        </option>
                        {success &&
                          officers &&
                          officers?.items.map((officer) => (
                            <option value={officer?.id}>{officer?.name}</option>
                          ))}
                      </select>

                      {customer?.data?.customer_information
                        ?.account_locked_until === null ? (
                        <div
                          className="status-container"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            paddingLeft: "10px",
                          }}
                        >
                          <div className="button">
                            <FontAwesomeIcon />
                            <span onClick={deactivateCust}>
                              DeActivate Customer
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="status-container"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            paddingLeft: "10px",
                          }}
                        >
                          <div className="button">
                            <FontAwesomeIcon />
                            {deactivateLoading ? (
                              <Loader />
                            ) : (
                              <span onClick={activateCustomer}>
                                Activate Customer
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              {customer?.data?.customer_information?.account_officer !==
              null ? (
                <p
                  style={{
                    color: "green",
                    letterSpacing: "1.2px",
                    fontSize: "13px",
                  }}
                >
                  Account Officer -{" "}
                  {customer?.data?.customer_information?.account_officer}
                </p>
              ) : null}
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div
                      className="col-12 d-flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="content-box">
                        <div className="content-title">Account Information</div>
                        <div className="content-main">
                          <p>
                            PennTag:{" "}
                            <span>
                              {customer?.data?.customer_information?.username}
                            </span>
                          </p>
                          <p>
                            Merchant ID:{" "}
                            <span>
                              {
                                customer?.data?.customer_information
                                  ?.merchant_id
                              }
                            </span>
                          </p>
                          <p>
                            Individual Account No:{" "}
                            <span>
                              {
                                customer?.data?.customer_information
                                  ?.customer_account_number
                              }
                            </span>
                          </p>
                          <p>
                            Business Account No:{" "}
                            <span>
                              {
                                customer?.data?.customer_information
                                  ?.business_account_number
                              }
                            </span>
                          </p>
                          <p>
                            Account Open Date:{" "}
                            <span>
                              {customer?.data?.customer_information?.open_date?.substring(
                                0,
                                10
                              )}
                            </span>
                          </p>
                          <p>
                            Account Officer:{" "}
                            <span>
                              {
                                customer?.data?.customer_information
                                  ?.account_officer
                              }
                            </span>
                          </p>
                          <p>
                            Last Login Date:{" "}
                            <span>
                              {customer?.data?.customer_information?.last_login_date?.substring(
                                0,
                                10
                              )}
                            </span>
                          </p>
                          <p>
                            Average Monthly Logins:{" "}
                            <span>
                              {
                                customer?.data?.customer_information
                                  ?.monthly_logins
                              }
                            </span>
                          </p>
                          <p>
                            Total Transaction Count:{" "}
                            <span>
                              {
                                customer?.data?.customer_information
                                  ?.transaction_count
                              }
                            </span>
                          </p>
                        </div>
                        <br />
                        {limitLoading ? (
                          <Loader />
                        ) : (
                          <div
                            className="button"
                            style={{ width: "fit-content" }}
                          >
                            <FontAwesomeIcon />
                            <span onClick={restoreLim}>Restore Limit</span>
                          </div>
                        )}
                      </div>

                      <div className="content-box">
                        <div className="content-title">Balances</div>
                        <div className="content-main">
                          <p>
                            Individual Account Balance:{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.individual_wallet_balance
                              )}
                            </span>
                          </p>
                          <p>
                            Merchant Account Balance:{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.business_wallet_balance
                              )}
                            </span>
                          </p>
                          <p>
                            Total Transaction Value Individual (Inflow):{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.individual_inflow_transaction_count
                              )}
                            </span>
                          </p>
                          <p>
                            Total Transaction Value Individual (Outflow):{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.individual_outflow_transaction_count
                              )}
                            </span>
                          </p>
                          <p>
                            Total Transaction Value Merchant (Inflow):{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.merchant_inflow_transaction_count
                              )}
                            </span>
                          </p>
                          <p>
                            Total Transaction Value Merchant (Outflow):{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.merchant_outflow_transaction_count
                              )}
                            </span>
                          </p>
                          <p>
                            Total Transaction Value{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.total_transaction_value
                              )}
                            </span>
                          </p>
                          <p>
                            Active Investment Value{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.active_investment_value
                              )}
                            </span>
                          </p>
                          <p>
                            Last Seven Days Inflow{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.seven_days_inflow
                              )}
                            </span>
                          </p>
                          <p>
                            Last Seven Days Outflow{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.seven_days_outflow
                              )}
                            </span>
                          </p>
                          <p>
                            Last Thirty Days inflow{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.thirty_days_inflow
                              )}
                            </span>
                          </p>
                          <p>
                            Last Thirty Days Outflow{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.thirty_days_outflow
                              )}
                            </span>
                          </p>
                          <p>
                            Inflow Today{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.inflow_today
                              )}
                            </span>
                          </p>
                          <p>
                            Outflow Today
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.outflow_today
                              )}
                            </span>
                          </p>

                          <p>
                            Last P2p Seven Days Inflow{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.p2p_seven_days_inflow
                              )}
                            </span>
                          </p>
                          <p>
                            Last P2p Seven Days Outflow{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.p2p_seven_days_outflow
                              )}
                            </span>
                          </p>
                          <p>
                            Last P2p Thirty Days inflow{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.p2p_thirty_days_inflow
                              )}
                            </span>
                          </p>
                          <p>
                            Last P2p Thirty Days Outflow{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.p2p_thirty_days_outflow
                              )}
                            </span>
                          </p>
                          <p>
                            P2p Inflow Today{" "}
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.p2p_inflow_today
                              )}
                            </span>
                          </p>
                          <p>
                            P2p Outflow Today
                            <span>
                              {format(
                                customer?.data?.customer_information
                                  ?.p2p_outflow_today
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12">
                      <h6>Personal Vault(s)</h6>
                      <div
                        className="content-box"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto auto",
                          gap: "30px",
                        }}
                      >
                        {customer?.data?.personal_vaults?.map((vault, i) => (
                          <div className="content-main" key={i}>
                            <div
                              className="content-title"
                              style={{
                                textTransform: "uppercase",
                                fontWeight: "bold",
                                fontSize: "20px",
                                letterSpacing: "1.2px",
                              }}
                            >
                              {vault?.investment_name}
                            </div>

                            <p>
                              Amount:{" "}
                              <span>{format(vault?.current_amount)}</span>
                            </p>
                            <p>
                              Vault Maturity Date:{" "}
                              <span>{vault?.end_date?.substring(0, 10)}</span>
                            </p>
                            <p>
                              Interest Rate: <span>{vault?.interest}</span>
                            </p>
                            <p>
                              Interest Accrued{" "}
                              <span>{format(vault?.interest_accrued)}</span>
                            </p>
                            <p>
                              Duration: <span>{vault?.duration}</span>
                            </p>
                            <p>
                              Topup Amount:{" "}
                              <span>{format(vault?.topup_amount)}</span>
                            </p>
                            <p>
                              Rollover: <span>{vault?.roll_over}</span>
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Target savings start */}

                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12">
                      <h6>Target Saving(s)</h6>
                      <div
                        className="content-box"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto auto",
                          gap: "30px",
                        }}
                      >
                        {savingsLoading ? (
                          <Loader />
                        ) : (
                          savings?.items?.map((vault, i) => (
                            <div className="content-main" key={i}>
                              <div
                                className="content-title"
                                style={{
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                  fontSize: "20px",
                                  letterSpacing: "1.2px",
                                }}
                              >
                                {vault?.purpose}
                              </div>
                              <p>
                                Target Amount:{" "}
                                <span>{format(vault?.target_amount)}</span>
                              </p>
                              <p>
                                Date Created:{" "}
                                <span>
                                  {vault?.date_created?.substring(0, 10)}
                                </span>
                              </p>
                              <p>
                                Debit Amount:{" "}
                                <span>{format(vault?.debit_amount)}</span>
                              </p>
                              <p>
                                Start Date:{" "}
                                <span>
                                  {vault?.start_date?.substring(0, 10)}
                                </span>
                              </p>
                              <p>
                                Last Debit Date:{" "}
                                <span>
                                  {vault?.last_collection_date?.substring(
                                    0,
                                    10
                                  )}
                                </span>
                              </p>
                              <p>
                                Next Debit Date:{" "}
                                <span>
                                  {vault?.next_collection_date?.substring(
                                    0,
                                    10
                                  )}
                                </span>
                              </p>
                              <p>
                                Amount Saved:{" "}
                                <span>{format(vault?.amount_saved)}</span>
                              </p>
                              <p>
                                Expected Amount:{" "}
                                <span>{format(vault?.expected_amount)}</span>
                              </p>
                              <p>
                                Frequency: <span>{vault?.frequency}</span>
                              </p>
                              <button className="withdrawal-btn">
                                <Link
                                  to={`/target-savings/logs/${vault?.purpose}`}
                                  state={{
                                    customerId: vault?.id,
                                    savingsId: vault?.savings_id,
                                    name:
                                      vault?.first_name +
                                      " " +
                                      vault?.last_name,
                                    purpose: vault?.purpose,
                                  }}
                                >
                                  View Logs
                                </Link>
                              </button>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Target Savings end */}

                {/* Customer Business Start */}
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12">
                      <h6>Business</h6>
                      <div
                        className="content-box"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto auto",
                          gap: "30px",
                        }}
                      >
                        {businessLoading ? (
                          <Loader />
                        ) : (
                          business?.businesses?.map((b, i) => (
                            <div className="content-main" key={i}>
                              <div
                                className="content-title"
                                style={{
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                  fontSize: "20px",
                                  letterSpacing: "1.2px",
                                }}
                              >
                                {b?.business_name}
                              </div>
                              <p>
                                Business Email Address:{" "}
                                <span>{b.support_email_address}</span>
                              </p>
                              <p>
                                Business Phone Number:{" "}
                                <span>{b.support_mobile_number}</span>
                              </p>
                              <p>
                                Business Tag: <span>{b.merchant_tag}</span>
                              </p>
                              <p>
                                Business Legal Name: <span>{b.legal_name}</span>
                              </p>
                              <p>
                                Business Address:{" "}
                                <span>{b.smile_id_address}</span>
                              </p>
                              <p>
                                Business Type:{" "}
                                <span>{b.business_type_other}</span>
                              </p>
                              <p>
                                Business Status: <span>{b.status}</span>
                              </p>

                              <BusinessWallet
                                customerid={id}
                                businessid={b.id}
                              />
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Customer Business End */}
                {userInfo?.user?.role === "Admin" && (
                  <div>
                    <h6>Cards</h6>
                    <div
                      className="col-lg-12"
                      style={{
                        display: "flex",
                        marginBottom: "20px",
                      }}
                    >
                      {!loadingCard &&
                        successCard &&
                        cards?.cards?.map((c) => (
                          <div id="card" class="cc">
                            <div id="main" class="mc">
                              <div id="ctyp" align="left">
                                <font class="hf">PENNYTREE</font>
                              </div>
                            </div>

                            <div class="cno" align="center">
                              <pre>{c?.card_number}</pre>
                            </div>
                            <div class="exp" align="center">
                              EXPIRY {c?.expires}
                            </div>
                            <div class="nv">
                              <div class="name" align="left">
                                {c?.gateway_provider}({c?.card_type})
                              </div>
                            </div>
                            <div class="nv">
                              <div class="name" align="center">
                                {c?.is_active ? "Activated" : "DeActivated"}
                              </div>
                            </div>

                            <select
                              style={{
                                background: "#1a1a1a",
                                border: "none",
                                padding: "13px",
                                width: "350px",
                                borderRadius: "10px",
                              }}
                              onChange={(e) =>
                                deactivateCard(e.target.value, c?.id)
                              }
                            >
                              {deleteCardLoading ? null : (
                                <>
                                  <option>
                                    <span>DeActivate Card</span>
                                  </option>

                                  <option value={"no"}>DeActivate</option>
                                  <option value={"activate"}>Activate</option>
                                  <option value={"yes"}>Remove</option>
                                </>
                              )}
                            </select>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12">
                      <h6>Last 300 Transactions</h6>
                      <CustomerTransactions
                        customeremail={
                          customer?.data?.customer_information?.username
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default UserScreen;

import React, { useEffect, useRef, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit"
import { format } from "../main"
import moment from "moment"
import { fetchAllCustomerTransactions } from "../actions/userActions"
import Loader from "./Loader"
import Paginate from "./Paginate"
import { fetchTargetSavingsLogs } from "../actions/investmentActions"

const SavingsLogs = ({ customerid, savingsid }) => {
  const [currPage, setCurrPage] = useState(1)
  const navigate = useNavigate()

  // FILTER states starts

  // filter states emd
  const dispatch = useDispatch()
  const transRef = useRef(true)

  const targetSavingsLog = useSelector((state) => state.targetSavingsLog)
  const { loading, success, error, logs } = targetSavingsLog

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  useEffect(() => {
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    if (!userInfo || userInfo === null) {
      navigate("/", { replace: true })
    }
    if (transRef.current) {
      dispatch(fetchTargetSavingsLogs(customerid, savingsid))
      transRef.current = false
    }
  }, [
    dispatch,
    userInfo,
    navigate,
    success,
    customerid,
    savingsid,
    error,
    loading
  ])

  const afterPageClicked = (page_number) => {
    const filterData = {}

    if (page_number === currPage) {
      dispatch(fetchTargetSavingsLogs(customerid, savingsid))
    } else {
      dispatch(fetchTargetSavingsLogs(customerid, savingsid))
    }

    if (page_number === "undefined1") {
      dispatch(fetchTargetSavingsLogs(customerid, savingsid))
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <MDBTable responsive style={{ "width": "100%", fontSize: "14px" }}>
            <MDBTableHead>
              <tr className="font-reduce">
                <th scope="col">S/N</th>
                <th scope="col">Target Amount</th>
                <th scope="col">Debit Amount</th>
                {/* <th scope='col'>Start Date</th>
                <th scope='col'>End Date</th> */}
                <th scope="col">Transaction Reference</th>
                <th scope="col">Date Created</th>
                <th scope="col">Remarks</th>
                {/* <th scope='col'>Provider Response Code</th> */}
                <th scope="col">Provider Response Message</th>
                <th scope="col">Amount Charged</th>
                <th scope="col">Amount Settled</th>
                <th scope="col">Fee</th>
                <th scope="col">Provider</th>
                <th scope="col">Debit Type</th>
                <th scope="col">Provider Reference</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {success &&
                logs?.items?.length > 0 &&
                logs?.items?.map((tran, key) => (
                  <tr key={key} className="font-reduce">
                    <td>{key + 1}</td>
                    <td>{format(tran?.target_amount)}</td>
                    <td>{format(tran?.debit_amount)}</td>
                    <td>{tran?.transaction_reference}</td>
                    <td>{moment(tran?.date_created).format("LLL")}</td>
                    <td>{tran?.remarks}</td>
                    <td>{tran?.provider_response_message}</td>
                    <td>{format(tran?.amount_charged)}</td>
                    <td>{format(tran?.amount_settled)}</td>
                    <td>{format(tran?.fee)}</td>
                    <td>{tran?.provider}</td>
                    <td>{tran?.debi_type}</td>
                    <td>{tran?.provider_reference}</td>
                  </tr>
                ))}
            </MDBTableBody>
          </MDBTable>
          <Paginate
            totPages={logs?.page?.total_page}
            currentPage={String(logs?.page?.current_page)}
            page={String(logs?.page?.current_page)}
            pageClicked={(ele) => {
              afterPageClicked(ele)
            }}
          />
        </>
      )}
    </>
  )
}

export default SavingsLogs

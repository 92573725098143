import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import Header from "../components/Header"
import Loader from "../components/Loader"
import SideNav from "../components/SideNav"
import {
  ASSIGN_TERMINAL_RESET,
  FETCH_BUSINESS_RESET,
  FETCH_CUSTOMERS_RESET
} from "../constants/adminConstants"
import { fetchCustomerid, fetchCustomers } from "../actions/userActions"
import {
  assignTerminalToCustomer,
  fetchCustomerBusinesses
} from "../actions/terminalActions"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const AssignTerminalScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const { id } = useParams()
  const [customer_name, setCustomerName] = useState("")
  const [customerBusiness, setCustomerBusiness] = useState({})

  const customerLimit = useSelector((state) => state.customerLimit)
  const { loading, success, error, limit } = customerLimit

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const getCustomerid = useSelector((state) => state.getCustomerid)
  const {
    loading: customersLoading,
    success: customerSuccess,
    customer
  } = getCustomerid

  const fetchBusiness = useSelector((state) => state.fetchBusiness)
  const {
    loading: businessLoading,
    success: businessSuccess,
    businesses,
    error: businessError
  } = fetchBusiness

  const assignTerminal = useSelector((state) => state.assignTerminal)
  const {
    loading: assignLoading,
    success: assignSuccess,
    error: assignError,
    terminals
  } = assignTerminal

  const submitHandler = (e) => {
    e.preventDefault()
    const busName = customerBusiness?.split("=-")[0]
    const busId = customerBusiness?.split("=-")[1]
    const result = window.confirm(
      `You are about to assign this terminal to ${busName}?`
    )
    console.log(result)
    if (result === true) {
      const postData = {
        business_id: Number(busId),
        customer_id: Number(customer),
        terminal_id: Number(id),
        terminal_action: state?.action
      }
      dispatch(assignTerminalToCustomer(postData))

      if (assignError) {
        toast.error(assignError, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true
        })
      }
    }
  }

  useEffect(() => {
    if (userInfo?.user?.role !== "Admin") {
      navigate("/performance", { replace: true })
    }
    if (customerSuccess && !isNaN(customer)) {
      console.log(customer)
      dispatch(fetchCustomerBusinesses(customer))
      if (businessError) {
        toast.error(businessError, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true
        })
      }
    }
    if (assignSuccess) {
      toast.success(terminals?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true
      })
      dispatch({ type: ASSIGN_TERMINAL_RESET })
    }
  }, [
    customer_name,
    userInfo,
    navigate,
    dispatch,
    customer,
    id,
    businessError,
    customerSuccess,
    assignSuccess,
    terminals
  ])

  const handleSearch = () => {
    if (customer_name === "") {
      dispatch({ type: FETCH_CUSTOMERS_RESET })
      dispatch({ type: FETCH_BUSINESS_RESET })
    }
    dispatch(fetchCustomerid(customer_name))
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title>Terminal Creation | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <ToastContainer />
            <h1>
              {state?.action?.toUpperCase()} Terminal {state?.terminal_id}
            </h1>
            <div
              className="status-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <div className="button">
                <FontAwesomeIcon />
                <Link to="/terminal/add"> Add Terminal</Link>
              </div>
            </div>
            {/* <div className='page-flexed'> */}
            <div>
              <div className="investment-form">
                <form action="" method="post" onSubmit={submitHandler}>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                      fontSize: "20px",
                      letterSpacing: "1.5px",
                      marginBottom: "10px"
                    }}
                  >
                    {error && error}
                  </p>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "green",
                      fontSize: "20px",
                      letterSpacing: "1.5px"
                    }}
                  >
                    {success && limit?.message}
                  </p>
                  <div className="form-group">
                    <label htmlFor="email" style={{ color: "#ffffff" }}>
                      Customer Tag:
                    </label>

                    <input
                      className="invest-input"
                      type="text"
                      placeholder="Input Merchant Tag Or Penntag"
                      value={customer_name}
                      onChange={(e) => setCustomerName(e.target.value)}
                      min={0}
                      max={9}
                      style={{ display: "block", width: "400px" }}
                    />
                    <button
                      style={{
                        display: "block",
                        textAlign: "end",
                        padding: "5px",
                        color: "white",
                        border: "1px solid grey",
                        marginLeft: "5px",
                        borderRadius: "5px",
                        textTransform: "uppercase",
                        letterSpacing: "1.2px",
                        fontSize: "11px"
                      }}
                      type="button"
                      onClick={handleSearch}
                    >
                      Fetch Business
                    </button>

                    <br />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email" style={{ color: "#ffffff" }}>
                      Select Customer Business
                    </label>

                    <div className="checkbox checkbox--flex">
                      <div className="checkbox__item">
                        <div className="form-group">
                          {businessLoading ? (
                            <Loader />
                          ) : (
                            <select
                              style={{
                                background: "#1a1a1a",
                                color: "#fff",
                                border: "none",
                                padding: "10px",
                                width: "500px",
                                borderRadius: "10px"
                              }}
                              onChange={(e) =>
                                setCustomerBusiness(e.target.value)
                              }
                            >
                              <option>Select Customer Business</option>

                              {businessSuccess &&
                                businesses &&
                                businesses?.data?.map((d) => (
                                  <option
                                    value={`${
                                      d?.business_name + "=- " + d?.id
                                    }`}
                                  >
                                    {d?.business_name}
                                  </option>
                                ))}
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {assignLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <button type="submit" className="login-btn">
                        Assign Terminal
                      </button>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AssignTerminalScreen

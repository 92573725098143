export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

export const ADMIN_2FA_LOGIN_REQUEST = "ADMIN_2FA_LOGIN_REQUEST";
export const ADMIN_2FA_LOGIN_SUCCESS = "ADMIN_2FA_LOGIN_SUCCESS";
export const ADMIN_2FA_LOGIN_FAIL = "ADMIN_2FA_LOGIN_FAIL";

export const RESEND_OTP_REQUEST = "RESEND_OTP_REQUEST";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAIL = "RESEND_OTP_FAIL";

export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL";

export const CUSTOMERS_DETAILS_REQUEST = "CUSTOMERS_DETAILS_REQUEST";
export const CUSTOMERS_DETAILS_SUCCESS = "CUSTOMERS_DETAILS_SUCCESS";
export const CUSTOMERS_DETAILS_FAIL = "CUSTOMERS_DETAILS_FAIL";

export const SINGLE_USER_REQUEST = "SINGLE_USER_REQUEST";
export const SINGLE_USER_SUCCESS = "SINGLE_USER_SUCCESS";
export const SINGLE_USER_FAIL = "SINGLE_USER_FAIL";

export const ALL_TRANSACTIONS_REQUEST = "ALL_TRANSACTIONS_REQUEST";
export const ALL_TRANSACTIONS_SUCCESS = "ALL_TRANSACTIONS_SUCCESS";
export const ALL_TRANSACTIONS_FAIL = "ALL_TRANSACTIONS_FAIL";

export const ALL_INVESTMENTS_REQUEST = "ALL_INVESTMENTS_REQUEST";
export const ALL_INVESTMENTS_SUCCESS = "ALL_INVESTMENTS_SUCCESS";
export const ALL_INVESTMENTS_FAIL = "ALL_INVESTMENTS_FAIL";

export const CREATE_INVESTMENT_REQUEST = "CREATE_INVESTMENT_REQUEST";
export const CREATE_INVESTMENT_SUCCESS = "CREATE_INVESTMENT_SUCCESS";
export const CREATE_INVESTMENT_FAIL = "CREATE_INVESTMENT_FAIL";

export const CUSTOMER_INVESTMENT_REQUEST = "CUSTOMER_INVESTMENT_REQUEST";
export const CUSTOMER_INVESTMENT_SUCCESS = "CUSTOMER_INVESTMENT_SUCCESS";
export const CUSTOMER_INVESTMENT_FAIL = "CUSTOMER_INVESTMENT_FAIL";

export const CUSTOMER_INVESTMENT_EXPIRED_REQUEST =
  "CUSTOMER_INVESTMENT_EXPIRED_REQUEST";
export const CUSTOMER_INVESTMENT_EXPIRED_SUCCESS =
  "CUSTOMER_INVESTMENT_EXPIRED_SUCCESS";
export const CUSTOMER_INVESTMENT_EXPIRED_FAIL =
  "CUSTOMER_INVESTMENT_EXPIRED_FAIL";

export const USER_CARD_REQUEST = "USER_CARD_REQUEST";
export const USER_CARD_SUCCESS = "USER_CARD_SUCCESS";
export const USER_CARD_FAIL = "USER_CARD_FAIL";

export const USER_ACCOUNT_REQUEST = "USER_ACCOUNT_REQUEST";
export const USER_ACCOUNT_SUCCESS = "USER_ACCOUNT_SUCCESS";
export const USER_ACCOUNT_FAIL = "USER_ACCOUNT_FAIL";

export const USER_SAVINGS_REQUEST = "USER_SAVINGS_REQUEST";
export const USER_SAVINGS_SUCCESS = "USER_SAVINGS_SUCCESS";
export const USER_SAVINGS_FAIL = "USER_SAVINGS_FAIL";

export const USER_VAULTS_REQUEST = "USER_VAULTS_REQUEST";
export const USER_VAULTS_SUCCESS = "USER_VAULTS_SUCCESS";
export const USER_VAULTS_FAIL = "USER_VAULTS_FAIL";

export const FAILED_DEBITS_REQUEST = "FAILED_DEBITS_REQUEST";
export const FAILED_DEBITS_SUCCESS = "FAILED_DEBITS_SUCCESS";
export const FAILED_DEBITS_FAIL = "FAILED_DEBITS_FAIL";

export const USER_COUNT_REQUEST = "USER_COUNT_REQUEST";
export const USER_COUNT_SUCCESS = "USER_COUNT_SUCCESS";
export const USER_COUNT_FAIL = "USER_COUNT_FAIL";

export const INVESTORS_COUNT_REQUEST = "INVESTORS_COUNT_REQUEST";
export const INVESTORS_COUNT_SUCCESS = "INVESTORS_COUNT_SUCCESS";
export const INVESTORS_COUNT_FAIL = "INVESTORS_COUNT_FAIL";

export const LOCKED_FUNDS_REQUEST = "LOCKED_FUNDS_REQUEST";
export const LOCKED_FUNDS_SUCCESS = "LOCKED_FUNDS_SUCCESS";
export const LOCKED_FUNDS_FAIL = "LOCKED_FUNDS_FAIL";

export const TRANSFER_TOTAL_REQUEST = "TRANSFER_TOTAL_REQUEST";
export const TRANSFER_TOTAL_SUCCESS = "TRANSFER_TOTAL_SUCCESS";
export const TRANSFER_TOTAL_FAIL = "TRANSFER_TOTAL_FAIL";

export const TOTAL_COMMISSION_REQUEST = "TOTAL_COMMISSION_REQUEST";
export const TOTAL_COMMISSION_SUCCESS = "TOTAL_COMMISSION_SUCCESS";
export const TOTAL_COMMISSION_FAIL = "TOTAL_COMMISSION_FAIL";

export const BILLS_REQUEST = "BILLS_REQUEST";
export const BILLS_SUCCESS = "BILLS_SUCCESS";
export const BILLS_FAIL = "BILLS_FAIL";

export const BUSINESS_METRICS_REQUEST = "BUSINESS_METRICS_REQUEST";
export const BUSINESS_METRICS_SUCCESS = "BUSINESS_METRICS_SUCCESS";
export const BUSINESS_METRICS_FAIL = "BUSINESS_METRICS_FAIL";

export const PENDING_WITHDRAWAL_REQUEST = "PENDING_WITHDRAWAL_REQUEST";
export const PENDING_WITHDRAWAL_SUCCESS = "PENDING_WITHDRAWAL_SUCCESS";
export const PENDING_WITHDRAWAL_FAIL = "PENDING_WITHDRAWAL_FAIL";

export const GET_OFFICERS_REQUEST = "GET_OFFICERS_REQUEST";
export const GET_OFFICERS_SUCCESS = "GET_OFFICERS_SUCCESS";
export const GET_OFFICERS_FAIL = "GET_OFFICERS_FAIL";

export const ACCOUNT_OFFICER_DETAILS_REQUEST =
  "ACCOUNT_OFFICER_DETAILS_REQUEST";
export const ACCOUNT_OFFICER_DETAILS_SUCCESS =
  "ACCOUNT_OFFICER_DETAILS_SUCCESS";
export const ACCOUNT_OFFICER_DETAILS_FAIL = "ACCOUNT_OFFICER_DETAILS_FAIL";

export const TEAM_MEMBERS_REQUEST = "TEAM_MEMBERS_REQUEST";
export const TEAM_MEMBERS_SUCCESS = "TEAM_MEMBERS_SUCCESS";
export const TEAM_MEMBERS_FAIL = "TEAM_MEMBERS_FAIL";

export const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";

export const GET_DEPARTMENTS_REQUEST = "GET_DEPARTMENTS_REQUEST";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_FAIL = "GET_DEPARTMENTS_FAIL";

export const GET_PERMISSIONS_REQUEST = "GET_PERMISSIONS_REQUEST";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAIL = "GET_PERMISSIONS_FAIL";

export const CREATE_MEMBER_REQUEST = "CREATE_MEMBER_REQUEST";
export const CREATE_MEMBER_SUCCESS = "CREATE_MEMBER_SUCCESS";
export const CREATE_MEMBER_FAIL = "CREATE_MEMBER_FAIL";

export const ASSIGN_OFFICER_REQUEST = "ASSIGN_OFFICER_REQUEST";
export const ASSIGN_OFFICER_SUCCESS = "ASSIGN_OFFICER_SUCCESS";
export const ASSIGN_OFFICER_FAIL = "ASSIGN_OFFICER_FAIL";

export const DEASSIGN_OFFICER_REQUEST = "DEASSIGN_OFFICER_REQUEST";
export const DEASSIGN_OFFICER_SUCCESS = "DEASSIGN_OFFICER_SUCCESS";
export const DEASSIGN_OFFICER_FAIL = "DEASSIGN_OFFICER_FAIL";

export const VERIFY_USER_REQUEST = "VERIFY_USER_REQUEST";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAIL = "VERIFY_USER_FAIL";

export const TRANSACTION_STATUS_REQUEST = "TRANSACTION_STATUS_REQUEST";
export const TRANSACTION_STATUS_SUCCESS = "TRANSACTION_STATUS_SUCCESS";
export const TRANSACTION_STATUS_FAIL = "TRANSACTION_STATUS_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const APPROVE_REJECT_WITHDRAWAL_REQUEST =
  "APPROVE_REJECT_WITHDRAWAL_REQUEST";
export const APPROVE_REJECT_WITHDRAWAL_SUCCESS =
  "APPROVE_REJECT_WITHDRAWAL_SUCCESS";
export const APPROVE_REJECT_WITHDRAWAL_FAIL = "APPROVE_REJECT_WITHDRAWAL_FAIL";

export const PENDING_BUSINESS_REQUEST = "PENDING_BUSINESS_REQUEST";
export const PENDING_BUSINESS_SUCCESS = "PENDING_BUSINESS_SUCCESS";
export const PENDING_BUSINESS_FAIL = "PENDING_BUSINESS_FAIL";

export const BUSINESS_DOCUMENT_REQUEST = "BUSINESS_DOCUMENT_REQUEST";
export const BUSINESS_DOCUMENT_SUCCESS = "BUSINESS_DOCUMENT_SUCCESS";
export const BUSINESS_DOCUMENT_FAIL = "BUSINESS_DOCUMENT_FAIL";

export const TOTAL_INTEREST_REQUEST = "TOTAL_INTEREST_REQUEST";
export const TOTAL_INTEREST_SUCCESS = "TOTAL_INTEREST_SUCCESS";
export const TOTAL_INTEREST_FAIL = "TOTAL_INTEREST_FAIL";

export const APPROVE_BUSINESS_REQUEST = "APPROVE_BUSINESS_REQUEST";
export const APPROVE_BUSINESS_SUCCESS = "APPROVE_BUSINESS_SUCCESS";
export const APPROVE_BUSINESS_FAIL = "APPROVE_BUSINESS_FAIL";

export const PRODUCT_TYPES_REQUEST = "PRODUCT_TYPES_REQUEST";
export const PRODUCT_TYPES_SUCCESS = "PRODUCT_TYPES_SUCCESS";
export const PRODUCT_TYPES_FAIL = "PRODUCT_TYPES_FAIL";

export const FETCH_CUSTOMERS_REQUEST = "FETCH_CUSTOMERS_REQUEST";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_FAIL = "FETCH_CUSTOMERS_FAIL";
export const FETCH_CUSTOMERS_RESET = "FETCH_CUSTOMERS_RESET";

export const VIEW_DOCUMENTS_REQUEST = "VIEW_DOCUMENTS_REQUEST";
export const VIEW_DOCUMENTS_SUCCESS = "VIEW_DOCUMENTS_SUCCESS";
export const VIEW_DOCUMENTS_FAIL = "VIEW_DOCUMENTS_FAIL";

export const ADD_MEMOS_REQUEST = "ADD_MEMOS_REQUEST";
export const ADD_MEMOS_SUCCESS = "ADD_MEMOS_SUCCESS";
export const ADD_MEMOS_FAIL = "ADD_MEMOS_FAIL";

export const GET_MEMOS_REQUEST = "GET_MEMOS_REQUEST";
export const GET_MEMOS_SUCCESS = "GET_MEMOS_SUCCESS";
export const GET_MEMOS_FAIL = "GET_MEMOS_FAIL";

export const ROLES_PERMISSIONS_REQUEST = "ROLES_PERMISSIONS_REQUEST";
export const ROLES_PERMISSIONS_SUCCESS = "ROLES_PERMISSIONS_SUCCESS";
export const ROLES_PERMISSIONS_FAIL = "ROLES_PERMISSIONS_FAIL";

export const ALL_BALANCES_REQUEST = "ALL_BALANCES_REQUEST";
export const ALL_BALANCES_SUCCESS = "ALL_BALANCES_SUCCESS";
export const ALL_BALANCES_FAIL = "ALL_BALANCES_FAIL";

export const MERCHANT_BALANCES_REQUEST = "MERCHANT_BALANCES_REQUEST";
export const MERCHANT_BALANCES_SUCCESS = "MERCHANT_BALANCES_SUCCESS";
export const MERCHANT_BALANCES_FAIL = "MERCHANT_BALANCES_FAIL";

export const DELETE_INVESTMENT_REQUEST = "DELETE_INVESTMENT_REQUEST";
export const DELETE_INVESTMENT_SUCCESS = "DELETE_INVESTMENT_SUCCESS";
export const DELETE_INVESTMENT_FAIL = "DELETE_INVESTMENT_FAIL";

export const UPDATE_USER_STATUS_REQUEST = "UPDATE_USER_STATUS_REQUEST";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_USER_STATUS_FAIL = "UPDATE_USER_STATUS_FAIL";

export const UPDATE_USER_ROLE_REQUEST = "UPDATE_USER_ROLE_REQUEST";
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";
export const UPDATE_USER_ROLE_FAIL = "UPDATE_USER_ROLE_FAIL";

export const GET_CUSTOMER_TRANSACTION_REQUEST =
  "  GET_CUSTOMER_TRANSACTION_REQUEST";
export const GET_CUSTOMER_TRANSACTION_SUCCESS =
  "  GET_CUSTOMER_TRANSACTION_SUCCESS";
export const GET_CUSTOMER_TRANSACTION_FAIL = " GET_CUSTOMER_TRANSACTION_FAIL";

export const GET_SINGLE_OFFICER_REQUEST = "GET_SINGLE_OFFICER_REQUEST";
export const GET_SINGLE_OFFICER_SUCCESS = "GET_SINGLE_OFFICER_SUCCESS";
export const GET_SINGLE_OFFICER_FAIL = "GET_SINGLE_OFFICER_FAIL";

export const GET_SINGLE_OFFICER_INFO_REQUEST =
  "GET_SINGLE_OFFICER_INFO_REQUEST";
export const GET_SINGLE_OFFICER_INFO_SUCCESS =
  "GET_SINGLE_OFFICER_INFO_SUCCESS";
export const GET_SINGLE_OFFICER_INFO_FAIL = "GET_SINGLE_OFFICER_INFO_FAIL";

export const SET_CUSTOMER_LIMIT_REQUEST = "SET_CUSTOMER_LIMIT_REQUEST";
export const SET_CUSTOMER_LIMIT_SUCCESS = "SET_CUSTOMER_LIMIT_SUCCESS";
export const SET_CUSTOMER_LIMIT_FAIL = "SET_CUSTOMER_LIMIT_FAIL";

export const SET_CUSTOMER_AUTOMATION_REQUEST =
  "SET_CUSTOMER_AUTOMATION_REQUEST";
export const SET_CUSTOMER_AUTOMATION_SUCCESS =
  "SET_CUSTOMER_AUTOMATION_SUCCESS";
export const SET_CUSTOMER_AUTOMATION_FAIL = "SET_CUSTOMER_AUTOMATION_FAIL";

export const GET_CUSTOMER_LIMIT_REQUEST = "GET_CUSTOMER_LIMIT_REQUEST";
export const GET_CUSTOMER_LIMIT_SUCCESS = "GET_CUSTOMER_LIMIT_SUCCESS";
export const GET_CUSTOMER_LIMIT_FAIL = "GET_CUSTOMER_LIMIT_FAIL";

export const GET_TARGET_SAVINGS_OFFICER_REQUEST =
  "GET_TARGET_SAVINGS_OFFICER_REQUEST";
export const GET_TARGET_SAVINGS_OFFICER_SUCCESS =
  "GET_TARGET_SAVINGS_OFFICER_SUCCESS";
export const GET_TARGET_SAVINGS_OFFICER_FAIL =
  "GET_TARGET_SAVINGS_OFFICER_FAIL";

export const GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_REQUEST =
  "GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_REQUEST";
export const GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_SUCCESS =
  "GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_SUCCESS";
export const GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_FAIL =
  "GET_SINGLE_TARGET_SAVINGS_OFFICER_INFO_FAIL";

export const FAILED_WITHDRAWAL_REQUEST = "FAILED_WITHDRAWAL_REQUEST";
export const FAILED_WITHDRAWAL_SUCCESS = "FAILED_WITHDRAWAL_SUCCESS";
export const FAILED_WITHDRAWAL_FAIL = "FAILED_WITHDRAWAL_FAIL";

export const ALL_PAYOUTS_REQUEST = "ALL_PAYOUTS_REQUEST";
export const ALL_PAYOUTS_SUCCESS = "ALL_PAYOUTS_SUCCESS";
export const ALL_PAYOUTS_FAIL = "ALL_PAYOUTS_FAIL";

export const GET_ALL_TARGET_SAVINGS_REQUEST = "GET_ALL_TARGET_SAVINGS_REQUEST";
export const GET_ALL_TARGET_SAVINGS_SUCCESS = "GET_ALL_TARGET_SAVINGS_SUCCESS";
export const GET_ALL_TARGET_SAVINGS_FAIL = "GET_ALL_TARGET_SAVINGS_FAIL";
export const GET_ALL_TARGET_SAVINGS_RESET = "GET_ALL_TARGET_SAVINGS_RESET";

export const TARGET_SAVINGS_METRICS_REQUEST = "TARGET_SAVINGS_METRICS_REQUEST";
export const TARGET_SAVINGS_METRICS_SUCCESS = "TARGET_SAVINGS_METRICS_SUCCESS";
export const TARGET_SAVINGS_METRICS_FAIL = "TARGET_SAVINGS_METRICS_FAIL";

export const GET_ALL_BILLS_REQUEST = "GET_ALL_BILLS_REQUEST";
export const GET_ALL_BILLS_SUCCESS = "GET_ALL_BILLS_SUCCESS";
export const GET_ALL_BILLS_FAIL = "GET_ALL_BILLS_FAIL";

export const GET_TRANSACTION_SETTINGS_REQUEST =
  "GET_TRANSACTION_SETTINGS_REQUEST";
export const GET_TRANSACTION_SETTINGS_SUCCESS =
  "GET_TRANSACTION_SETTINGS_SUCCESS";
export const GET_TRANSACTION_SETTINGS_FAIL = "GET_TRANSACTION_SETTINGS_FAIL";

export const ENABLE_DISABLE_TRANSACTION_REQUEST =
  "ENABLE_DISABLE_TRANSACTION_REQUEST";
export const ENABLE_DISABLE_TRANSACTION_SUCCESS =
  "ENABLE_DISABLE_TRANSACTION_SUCCESS";
export const ENABLE_DISABLE_TRANSACTION_FAIL =
  "ENABLE_DISABLE_TRANSACTION_FAIL";

export const GET_PROVIDERS_REQUEST = "GET_PROVIDERS_REQUEST";
export const GET_PROVIDERS_SUCCESS = "GET_PROVIDERS_SUCCESS";
export const GET_PROVIDERS_FAIL = "GET_PROVIDERS_FAIL";

export const ENABLE_DISABLE_PROVIDER_REQUEST =
  "ENABLE_DISABLE_PROVIDER_REQUEST";
export const ENABLE_DISABLE_PROVIDER_SUCCESS =
  "ENABLE_DISABLE_PROVIDER_SUCCESS";
export const ENABLE_DISABLE_PROVIDER_FAIL = "ENABLE_DISABLE_PROVIDER_FAIL";

export const TARGET_SAVINGS_LOGS_REQUEST = "TARGET_SAVINGS_LOGS_REQUEST";
export const TARGET_SAVINGS_LOGS_SUCCESS = "TARGET_SAVINGS_LOGS_SUCCESS";
export const TARGET_SAVINGS_LOGS_FAIL = "TARGET_SAVINGS_LOGS_FAIL";

export const GET_INVESTMENT_RATES_REQUEST = "GET_INVESTMENT_RATES_REQUEST";
export const GET_INVESTMENT_RATES_SUCCESS = "GET_INVESTMENT_RATES_SUCCESS";
export const GET_INVESTMENT_RATES_FAIL = "GET_INVESTMENT_RATES_FAIL";

export const UPDATE_INVESTMENT_RATES_REQUEST =
  "UPDATE_INVESTMENT_RATES_REQUEST";
export const UPDATE_INVESTMENT_RATES_SUCCESS =
  "UPDATE_INVESTMENT_RATES_SUCCESS";
export const UPDATE_INVESTMENT_RATES_FAIL = "UPDATE_INVESTMENT_RATES_FAIL";

export const DEACTIVATE_CUSTOMER_REQUEST = "DEACTIVATE_CUSTOMER_REQUEST";
export const DEACTIVATE_CUSTOMER_SUCCESS = "DEACTIVATE_CUSTOMER_SUCCESS";
export const DEACTIVATE_CUSTOMER_FAIL = "DEACTIVATE_CUSTOMER_FAIL";

export const GET_POS_METRICS_REQUEST = "GET_POS_METRICS_REQUEST";
export const GET_POS_METRICS_SUCCESS = "GET_POS_METRICS_SUCCESS";
export const GET_POS_METRICS_FAIL = "GET_POS_METRICS_FAIL";

export const GET_CUSTOMER_CARDS_REQUEST = "GET_CUSTOMER_CARDS_REQUEST";
export const GET_CUSTOMER_CARDS_SUCCESS = "GET_CUSTOMER_CARDS_SUCCESS";
export const GET_CUSTOMER_CARDS_FAIL = "GET_CUSTOMER_CARDS_FAIL";

export const DELETE_CUSTOMER_CARD_REQUEST = "DELETE_CUSTOMER_CARD_REQUEST";
export const DELETE_CUSTOMER_CARD_SUCCESS = "DELETE_CUSTOMER_CARD_SUCCESS";
export const DELETE_CUSTOMER_CARD_FAIL = "DELETE_CUSTOMER_CARD_FAIL";

export const ALL_TARGET_SAVINGS_LOGS_REQUEST =
  "ALL_TARGET_SAVINGS_LOGS_REQUEST";
export const ALL_TARGET_SAVINGS_LOGS_SUCCESS =
  "ALL_TARGET_SAVINGS_LOGS_SUCCESS";
export const ALL_TARGET_SAVINGS_LOGS_FAIL = "ALL_TARGET_SAVINGS_LOGS_FAIL";

export const GET_INTEREST_INFO_REQUEST = "GET_INTEREST_INFO_REQUEST";
export const GET_INTEREST_INFO_SUCCESS = "GET_INTEREST_INFO_SUCCESS";
export const GET_INTEREST_INFO_FAIL = "GET_INTEREST_INFO_FAIL";

export const GET_INTEREST_REQUEST = "GET_INTEREST_REQUEST";
export const GET_INTEREST_SUCCESS = "GET_INTEREST_SUCCESS";
export const GET_INTEREST_FAIL = "GET_INTEREST_FAIL";

export const GET_TRANSACTION_COUNT_REQUEST = "GET_TRANSACTION_COUNT_REQUEST";
export const GET_TRANSACTION_COUNT_SUCCESS = "GET_TRANSACTION_COUNT_SUCCESS";
export const GET_TRANSACTION_COUNT_FAIL = "GET_TRANSACTION_COUNT_FAIL";

export const CREATE_TREEGAR_INVESTMENT_REQUEST =
  "CREATE_TREEGAR_INVESTMENT_REQUEST";
export const CREATE_TREEGAR_INVESTMENT_SUCCESS =
  "CREATE_TREEGAR_INVESTMENT_SUCCESS";
export const CREATE_TREEGAR_INVESTMENT_FAIL = "CREATE_TREEGAR_INVESTMENT_FAIL";

export const GET_TREEGAR_INVESTMENT_REQUEST = "GET_TREEGAR_INVESTMENT_REQUEST";
export const GET_TREEGAR_INVESTMENT_SUCCESS = "GET_TREEGAR_INVESTMENT_SUCCESS";
export const GET_TREEGAR_INVESTMENT_FAIL = "GET_TREEGAR_INVESTMENT_FAIL";

export const GET_TREEGAR_INVESTMENT_LIABILITY_REQUEST =
  "GET_TREEGAR_INVESTMENT_LIABILITY_REQUEST";
export const GET_TREEGAR_INVESTMENT_LIABILITY_SUCCESS =
  "GET_TREEGAR_INVESTMENT_LIABILITY_SUCCESS";
export const GET_TREEGAR_INVESTMENT_LIABILITY_FAIL =
  "GET_TREEGAR_INVESTMENT_LIABILITY_FAIL";

export const GET_TREEGAR_INVESTMENT_CONSTANT_REQUEST =
  "GET_TREEGAR_INVESTMENT_CONSTANT_REQUEST";
export const GET_TREEGAR_INVESTMENT_CONSTANT_SUCCESS =
  "GET_TREEGAR_INVESTMENT_CONSTANT_SUCCESS";
export const GET_TREEGAR_INVESTMENT_CONSTANT_FAIL =
  "GET_TREEGAR_INVESTMENT_CONSTANT_FAIL";

export const GET_TREEGAR_PENDING_INVESTMENT_REQUEST =
  "GET_TREEGAR_PENDING_INVESTMENT_REQUEST";
export const GET_TREEGAR_PENDING_INVESTMENT_SUCCESS =
  "GET_TREEGAR_PENDING_INVESTMENT_SUCCESS";
export const GET_TREEGAR_PENDING_INVESTMENT_FAIL =
  "GET_TREEGAR_INVESTMENT_CONSTANT_FAIL";

export const APPROVE_REJECT_INVESTMENT_REQUEST =
  "APPROVE_REJECT_INVESTMENT_REQUEST";
export const APPROVE_REJECT_INVESTMENT_SUCCESS =
  "APPROVE_REJECT_INVESTMENT_SUCCESS";
export const APPROVE_REJECT_INVESTMENT_FAIL = "APPROVE_REJECT_INVESTMENT_FAIL";

export const GET_TERMINALS_REQUEST = "GET_TERMINALS_REQUEST";
export const GET_TERMINALS_SUCCESS = "GET_TERMINALS_SUCCESS";
export const GET_TERMINALS_FAIL = "GET_TERMINALS_FAIL";

export const GET_OVERDRAFTS_REQUEST = "GET_OVERDRAFTS_REQUEST";
export const GET_OVERDRAFTS_SUCCESS = "GET_OVERDRAFTS_SUCCESS";
export const GET_OVERDRAFTS_FAIL = "GET_OVERDRAFTS_FAIL";

export const APPROVE_REJECT_OVERDRAFT_REQUEST =
  "APPROVE_REJECT_OVERDRAFT_REQUEST";
export const APPROVE_REJECT_OVERDRAFT_SUCCESS =
  "APPROVE_REJECT_OVERDRAFT_SUCCESS";
export const APPROVE_REJECT_OVERDRAFT_FAIL = "APPROVE_REJECT_OVERDRAFT_FAIL";

export const UPDATE_OVERDRAFT_LIMIT_REQUEST = "UPDATE_OVERDRAFT_LIMIT_REQUEST";
export const UPDATE_OVERDRAFT_LIMIT_SUCCESS = "UPDATE_OVERDRAFT_LIMIT_SUCCESS";
export const UPDATE_OVERDRAFT_LIMIT_FAIL = "UPDATE_OVERDRAFT_LIMIT_FAIL";

export const GET_OVERDRAFT_SCHEDULES_REQUEST =
  "GET_OVERDRAFT_SCHEDULES_REQUEST";
export const GET_OVERDRAFT_SCHEDULES_SUCCESS =
  "GET_OVERDRAFT_SCHEDULES_SUCCESS";
export const GET_OVERDRAFT_SCHEDULES_FAIL = "GET_OVERDRAFT_SCHEDULES_FAIL";

export const GET_FAILED_VERIFICATIONS_REQUEST =
  "GET_FAILED_VERIFICATIONS_REQUEST";
export const GET_FAILED_VERIFICATIONS_SUCCESS =
  "GET_FAILED_VERIFICATIONS_SUCCESS";
export const GET_FAILED_VERIFICATIONS_FAIL = "GET_FAILED_VERIFICATIONS_FAIL";

export const TOTAL_COMMISSION_BREAKDOWN_REQUEST =
  "TOTAL_COMMISSION_BREAKDOWN_REQUEST";
export const TOTAL_COMMISSION_BREAKDOWN_SUCCESS =
  "TOTAL_COMMISSION_BREAKDOWN_SUCCESS";
export const TOTAL_COMMISSION_BREAKDOWN_FAIL =
  "TOTAL_COMMISSION_BREAKDOWN_FAIL";

export const GET_CUSTOMER_TARGET_SAVINGS_REQUEST =
  "GET_CUSTOMER_TARGET_SAVINGS_REQUEST";
export const GET_CUSTOMER_TARGET_SAVINGS_SUCCESS =
  "GET_CUSTOMER_TARGET_SAVINGS_SUCCESS";
export const GET_CUSTOMER_TARGET_SAVINGS_FAIL =
  "GET_CUSTOMER_TARGET_SAVINGS_FAIL";

export const GET_BALANCE_METRICS_REQUEST = "GET_BALANCE_METRICS_REQUEST";
export const GET_BALANCE_METRICS_SUCCESS = "GET_BALANCE_METRICS_SUCCESS";
export const GET_BALANCE_METRICS_FAIL = "GET_BALANCE_METRICS_FAIL";

export const GET_ALL_RATES_REQUEST = "GET_ALL_RATES_REQUEST";
export const GET_ALL_RATES_SUCCESS = "GET_ALL_RATES_SUCCESS";
export const GET_ALL_RATES_FAIL = "GET_ALL_RATES_FAIL";

export const UPDATE_RATE_REQUEST = "UPDATE_RATE_REQUEST";
export const UPDATE_RATE_SUCCESS = "UPDATE_RATE_SUCCESS";
export const UPDATE_RATE_FAIL = "UPDATE_RATE_FAIL";

export const GET_TOTAL_BALANCE_REQUEST = "GET_TOTAL_BALANCE_REQUEST";
export const GET_TOTAL_BALANCE_SUCCESS = "GET_TOTAL_BALANCE_SUCCESS";
export const GET_TOTAL_BALANCE_FAIL = "GET_TOTAL_BALANCE_FAIL";

export const GET_BALANCE_HISTORY_REQUEST = "GET_BALANCE_HISTORY_REQUEST";
export const GET_BALANCE_HISTORY_SUCCESS = "GET_BALANCE_HISTORY_SUCCESS";
export const GET_BALANCE_HISTORY_FAIL = "GET_BALANCE_HISTORY_FAIL";

export const TRANSACTIONS_EXPORT_REQUEST = "TRANSACTIONS_EXPORT_REQUEST";
export const TRANSACTIONS_EXPORT_SUCCESS = "TRANSACTIONS_EXPORT_SUCCESS";
export const TRANSACTIONS_EXPORT_FAIL = "TRANSACTIONS_EXPORT_FAIL";

export const FETCH_BUSINESS_REQUEST = "FETCH_BUSINESS_REQUEST";
export const FETCH_BUSINESS_SUCCESS = "FETCH_BUSINESS_SUCCESS";
export const FETCH_BUSINESS_FAIL = "FETCH_BUSINESS_FAIL";
export const FETCH_BUSINESS_RESET = "FETCH_BUSINESS_RESET";

export const FETCH_CUSTOMERID_REQUEST = "FETCH_CUSTOMERID_REQUEST";
export const FETCH_CUSTOMERID_SUCCESS = "FETCH_CUSTOMERID_SUCCESS";
export const FETCH_CUSTOMERID_FAIL = "FETCH_CUSTOMERID_FAIL";

export const FETCH_BANKS_REQUEST = "FETCH_BANKS_REQUEST";
export const FETCH_BANKS_SUCCESS = "FETCH_BANKS_SUCCESS";
export const FETCH_BANKS_FAIL = "FETCH_BANKS_FAIL";

export const ADD_TERMINAL_REQUEST = "ADD_TERMINAL_REQUEST";
export const ADD_TERMINAL_SUCCESS = "ADD_TERMINAL_SUCCESS";
export const ADD_TERMINAL_FAIL = "ADD_TERMINAL_FAIL";

export const ASSIGN_TERMINAL_REQUEST = "ASSIGN_TERMINAL_REQUEST";
export const ASSIGN_TERMINAL_SUCCESS = "ASSIGN_TERMINAL_SUCCESS";
export const ASSIGN_TERMINAL_FAIL = "ASSIGN_TERMINAL_FAIL";
export const ASSIGN_TERMINAL_RESET = "ASSIGN_TERMINAL_RESET";

export const FETCH_UNASSIGNED_TERMINALS_REQUEST =
  "FETCH_UNASSIGNED_TERMINALS_REQUEST";
export const FETCH_UNASSIGNED_TERMINALS_SUCCESS =
  "FETCH_UNASSIGNED_TERMINALS_SUCCESS";
export const FETCH_UNASSIGNED_TERMINALS_FAIL =
  "FETCH_UNASSIGNED_TERMINALS_FAIL";

export const GET_PAYAZA_BALANCE_REQUEST = "GET_PAYAZA_BALANCE_REQUEST";
export const GET_PAYAZA_BALANCE_SUCCESS = "GET_PAYAZA_BALANCE_SUCCESS";
export const GET_PAYAZA_BALANCE_FAIL = "GET_PAYAZA_BALANCE_FAIL";

export const GET_SEERBIT_BALANCE_REQUEST = "GET_SEERBIT_BALANCE_REQUEST";
export const GET_SEERBIT_BALANCE_SUCCESS = "GET_SEERBIT_BALANCE_SUCCESS";
export const GET_SEERBIT_BALANCE_FAIL = "GET_SEERBIT_BALANCE_FAIL";

export const GET_FLW_BALANCE_REQUEST = "GET_FLW_BALANCE_REQUEST";
export const GET_FLW_BALANCE_SUCCESS = "GET_FLW_BALANCE_SUCCESS";
export const GET_FLW_BALANCE_FAIL = "GET_FLW_BALANCE_FAIL";

export const GET_VFD_BALANCE_REQUEST = "GET_VFD_BALANCE_REQUEST";
export const GET_VFD_BALANCE_SUCCESS = "GET_VFD_BALANCE_SUCCESS";
export const GET_VFD_BALANCE_FAIL = "GET_VFD_BALANCE_FAIL";

export const GET_PAYSTACK_BALANCE_REQUEST = "GET_PAYSTACK_BALANCE_REQUEST";
export const GET_PAYSTACK_BALANCE_SUCCESS = "GET_PAYSTACK_BALANCE_SUCCESS";
export const GET_PAYSTACK_BALANCE_FAIL = "GET_PAYSTACK_BALANCE_FAIL";

export const GET_CUSTOMER_BUSINESS_REQUEST = "GET_CUSTOMER_BUSINESS_REQUEST";
export const GET_CUSTOMER_BUSINESS_SUCCESS = "GET_CUSTOMER_BUSINESS_SUCCESS";
export const GET_CUSTOMER_BUSINESS_FAIL = "GET_CUSTOMER_BUSINESS_FAIL";

export const GET_BUSINESS_WALLET_REQUEST = "GET_BUSINESS_WALLET_REQUEST";
export const GET_BUSINESS_WALLET_SUCCESS = "GET_BUSINESS_WALLET_SUCCESS";
export const GET_BUSINESS_WALLET_FAIL = "GET_BUSINESS_WALLET_FAIL";

export const INVESTMENT_CONSTANTS = {
  Initiated: 1,
  Active: 2,
  Cancelled: 3,
  Completed: 4,
  Closed: 5,
};

import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { format, formatDate } from "../main"
import { CSVLink } from "react-csv"

import {
  fetchCommisssions,
  fetchCommisssionsBreakdown
} from "../actions/dashboardActions"
import Header from "../components/Header"
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import SideNav from "../components/SideNav"
import { Helmet } from "react-helmet"
import Loader from "../components/Loader"
import moment from "moment"

const CommissionsBreakdownScreen = () => {
  const commissionsRef = useRef(true)
  const dispatch = useDispatch()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const totalCommisionBreakdown = useSelector(
    (state) => state.totalCommisionBreakdown
  )
  const { loading, success, error, commissions } = totalCommisionBreakdown

  useEffect(() => {
    if (commissionsRef.current) {
      //   dispatch(fetchCommisssions())
      commissionsRef.current = false
    }
    // if (
    //   (!loading && error?.message === 'Please login again') ||
    //   error?.message === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    // }
    if (success && commissions) {
      setData(
        commissions?.map((c) => ({
          date: moment(c?.date).format("LL"),
          flutterwave_transfer_expenses: c?.flutterwave_transfer_expenses,
          flutterwave_transfers_revenue: c?.flutterwave_transfers_revenue,
          flutterwave_transfers_income: c?.flutterwave_transfers_income,
          airtime_commission: c?.flw_airtime_commission,
          electricity_commission: c?.electricity_commission,
          tv_commission: c?.tv_commission,
          betting_commission: c?.betting_commission,
          paystack_transfer_expenses: c?.paystack_transfer_expenses,
          paystack_transfers_revenue: c?.paystack_transfer_revenue,
          paystack_transfers_income: c?.paystack_transfers_income,
          payaza_transfer_expenses: c?.payaza_transfer_expenses,
          payaza_transfers_revenue: c?.payaza_transfer_revenue,
          payaza_transfers_income: c?.payaza_transfers_income,
          vfd_transfer_expenses: c?.vfd_transfer_expenses,
          vfd_transfers_revenue: c?.vfd_transfers_revenue,
          vfd_transfers_income: c?.vfd_transfers_income,
          seerbit_transfer_expenses: c?.seerbit_transfer_expenses,
          seerbit_transfers_revenue: c?.seerbit_transfer_revenue,
          seerbit_transfers_income: c?.seerbit_transfers_income,
          paystack_target_savings_expenses: c?.paystack_target_savings_expenses,
          seerbit_target_savings_expenses: c?.seerbit_target_savings_expense
        }))
      )
      setHeaders([
        {
          label: "Date",
          key: "date"
        },
        {
          label: "Flutterwave Transfer Expense",
          key: "flutterwave_transfer_expenses"
        },
        {
          label: "Flutterwave Transfers Revenue",
          key: "flutterwave_transfers_revenue"
        },
        {
          label: "Flutterwave Transfers income",
          key: "flutterwave_transfers_income"
        },
        { label: "Airtime Commission", key: "airtime_commission" },
        { label: "Electricity Commission", key: "electricity_commission" },
        { label: "Tv Commission", key: "tv_commission" },
        { label: "Betting Commsio", key: "betting_commission" },
        {
          label: "paystack Transfer Expense",
          key: "paystack_transfer_expenses"
        },
        {
          label: "paystack Transfers Revenue",
          key: "paystack_transfers_revenue"
        },
        {
          label: "paystack Transfers income",
          key: "paystack_transfers_income"
        },
        {
          label: "payaza Transfer Expense",
          key: "payaza_transfer_expenses"
        },
        {
          label: "payaza Transfers Revenue",
          key: "payaza_transfers_revenue"
        },
        {
          label: "payaza Transfers income",
          key: "payaza_transfers_income"
        },
        {
          label: "vfd Transfer Expense",
          key: "vfd_transfer_expenses"
        },
        {
          label: "vfd Transfers Revenue",
          key: "vfd_transfers_revenue"
        },
        {
          label: "vfd Transfers income",
          key: "vfd_transfers_income"
        },
        {
          label: "seerbit Transfer Expense",
          key: "seerbit_transfer_expenses"
        },
        {
          label: "seerbit Transfers Revenue",
          key: "seerbit_transfers_revenue"
        },
        {
          label: "seerbit Transfers income",
          key: "seerbit_transfers_income"
        },
        {
          label: "paystack target savings expenses",
          key: "paystack_target_savings_expenses"
        },
        {
          label: "seerbit target savings expenses",
          key: "seerbit_target_savings_expenses"
        }
      ])
    }
  }, [commissions, success, dispatch, loading, error])

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
    // Calculate the time difference in milliseconds
    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime())

    // Convert milliseconds to days
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))

    if (Number(daysDiff) > 60) {
      alert("You can not fetch records for more than 60 days")
    } else {
      dispatch(fetchCommisssionsBreakdown(filterData))
    }
  }

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    dispatch(fetchCommisssions())
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title>Commissions Breakdown | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Commissions Breakdown</h1>
            <div
              className="status-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: "10px"
              }}
            >
              <div className="filter-box">
                <div className="filter">
                  <>
                    <div className="filter-container">
                      <p style={{ cursor: "pointer" }} onClick={handleClick}>
                        Filter
                      </p>
                      <div className="navContainer" style={styles.navContainer}>
                        <div className="date-filter">
                          <div className="date-title">Date</div>
                          <div className="date-options">
                            <div className="date-content">
                              <div className="date-items">
                                <DatePicker
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                />

                                <DatePicker
                                  selected={endDate}
                                  onChange={(date) => setEndDate(date)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="status-container"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center"
                          }}
                        >
                          <button className="button" onClick={handleClear}>
                            <p>Clear</p>
                          </button>
                          <button className="button" onClick={handleFilter}>
                            <p>Filter</p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
                <div className="filter">
                  <CSVLink data={data} headers={headers}>
                    Export
                  </CSVLink>
                </div>
              </div>
            </div>

            {loading ? (
              <Loader />
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                  align="middle"
                >
                  <MDBTableHead>
                    <tr className="font-reduce">
                      <th scope="col">S/N</th>
                      <th scope="col">Date</th>
                      <th scope="col">Flutterwave Transfer Expenses</th>
                      <th scope="col">Flutterwave Transfer Revenue</th>
                      <th scope="col">Flutterwave Transfer Income</th>
                      <th scope="col">Paystack Transfer Expenses</th>
                      <th scope="col">Paystack Transfer Revenue</th>
                      <th scope="col">Paystack Transfer Income</th>
                      <th scope="col">Payaza Transfer Expenses</th>
                      <th scope="col">Payaza Transfer Revenue</th>
                      <th scope="col">Payaza Transfer Income</th>
                      <th scope="col">VFD Transfer Expenses</th>
                      <th scope="col">VFD Transfer Revenue</th>
                      <th scope="col">VFD Transfer Income</th>
                      <th scope="col">Seerbit Transfer Expenses</th>
                      <th scope="col">Seerbit Transfer Revenue</th>
                      <th scope="col">Seerbit Transfer Income</th>
                      <th scope="col">Paystack Target Savings Expenses</th>
                      <th scope="col">Seerbit Target Savings Expenses</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      commissions?.map((item, key) => (
                        <tr key={key} className="font-reduce">
                          <td>{key}</td>
                          <td>{moment(item?.date).format("LL")}</td>
                          <td>{format(item?.flutterwave_transfer_expenses)}</td>
                          <td>{format(item?.flutterwave_transfers_revenue)}</td>
                          <td>{format(item?.flutterwave_transfers_income)}</td>
                          <td>{format(item?.paystack_transfer_expenses)}</td>
                          <td>{format(item?.paystack_transfer_revenue)}</td>
                          <td>{format(item?.paystack_transfers_income)}</td>
                          <td>{format(item?.payaza_transfer_expenses)}</td>
                          <td>{format(item?.payaza_transfer_revenue)}</td>
                          <td>{format(item?.payaza_transfers_income)}</td>
                          <td>{format(item?.vfd_transfer_expenses)}</td>
                          <td>{format(item?.vfd_transfers_revenue)}</td>
                          <td>{format(item?.vfd_transfers_income)}</td>
                          <td>{format(item?.seerbit_transfer_expenses)}</td>
                          <td>{format(item?.seerbit_transfer_revenue)}</td>
                          <td>{format(item?.seerbit_transfers_income)}</td>
                          <td>
                            {format(item?.paystack_target_savings_expenses)}
                          </td>
                          <td>
                            {format(item?.seerbit_target_savings_expenses)}
                          </td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default CommissionsBreakdownScreen

import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
import SideNav from "../components/SideNav"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { formatDate } from "../main"
import { useDispatch, useSelector } from "react-redux"
import Loader from "../components/Loader"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGear } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { newInvestment } from "../actions/investmentActions"

const InvestmentCreationScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [investmentName, setInvestmentName] = useState("")
  const [issuerName, setIssuerName] = useState("")
  const [issueAmount, setIssueAmount] = useState("")
  const [numberOfSubscription, setNumberOfSubscription] = useState("")
  const [valueOfSubscription, setValueOfSubscription] = useState("")
  const [tenor, setTenor] = useState("")
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [totalInterestRate, setTotalInterestRate] = useState("")
  const [platFormInterestRate, setPlatFormInterestRate] = useState("")
  const [spread, setSpread] = useState("")

  const createInvestment = useSelector((state) => state.createInvestment)
  const { loading, success, investment, error } = createInvestment

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const submitHandler = (e) => {
    e.preventDefault()
    const investmentData = {
      investmentName,
      issuerName,
      issueAmount: Number(issueAmount),
      numberOfSubscription: Number(numberOfSubscription),
      valueOfSubscription: Number(valueOfSubscription),
      tenor: Number(tenor),
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      totalInterestRate: Number(totalInterestRate),
      platFormInterestRate: Number(platFormInterestRate),
      spread: Number(spread)
    }
    dispatch(newInvestment(investmentData))
  }

  useEffect(() => {
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    if (!userInfo || userInfo === null) {
      navigate("/", { replace: true })
    }
    if (
      userInfo?.user?.role !== "Admin" ||
      userInfo?.user?.role !== "Operations"
    ) {
      navigate("/", { replace: true })
    }
    if (success) {
      navigate("/investments", { replace: true })
    }
  }, [error, success, navigate, loading, userInfo])
  return (
    <>
      <SideNav />
      <Helmet>
        <title> Investment Creation | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <div
              className="status-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <div className="button">
                <FontAwesomeIcon icon={faGear} />
                <Link to="/investments-settings"> Investment Settings</Link>
              </div>
            </div>
            <h1>Create Investment</h1>
            <div className="investment-form">
              <form action="" method="post" onSubmit={submitHandler}>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "red",
                    fontSize: "20px",
                    letterSpacing: "1.5px"
                  }}
                >
                  {error && error}
                </p>
                <div className="form-group">
                  <label htmlFor="email" style={{ color: "#ffffff" }}>
                    investment name:
                  </label>
                  <input
                    className="invest-input"
                    type="text"
                    name="investment name"
                    value={investmentName}
                    onChange={(e) => setInvestmentName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" style={{ color: "#ffffff" }}>
                    name of issuer:
                  </label>
                  <input
                    className="invest-input"
                    type="text"
                    name="name of issuer"
                    value={issuerName}
                    onChange={(e) => setIssuerName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" style={{ color: "#ffffff" }}>
                    issue amount:
                  </label>
                  <input
                    className="invest-input"
                    type="number"
                    name="issue amount"
                    value={issueAmount}
                    onChange={(e) => setIssueAmount(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" style={{ color: "#ffffff" }}>
                    number of subscriptions:
                  </label>
                  <input
                    className="invest-input"
                    type="number"
                    name="number of subscriptions"
                    value={numberOfSubscription}
                    onChange={(e) => setNumberOfSubscription(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" style={{ color: "#ffffff" }}>
                    value of each subscription:
                  </label>
                  <input
                    className="invest-input"
                    type="number"
                    name="value of each subscription"
                    value={valueOfSubscription}
                    onChange={(e) => setValueOfSubscription(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" style={{ color: "#ffffff" }}>
                    tenor:
                  </label>
                  <input
                    className="invest-input"
                    type="number"
                    name="tenor"
                    value={tenor}
                    onChange={(e) => setTenor(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" style={{ color: "#ffffff" }}>
                    start date:
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" style={{ color: "#ffffff" }}>
                    close date:
                  </label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" style={{ color: "#ffffff" }}>
                    total interest rate:
                  </label>
                  <input
                    className="invest-input"
                    type="number"
                    name="total interest rate"
                    value={totalInterestRate}
                    onChange={(e) => setTotalInterestRate(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" style={{ color: "#ffffff" }}>
                    platform interest rate:
                  </label>
                  <input
                    className="invest-input"
                    type="number"
                    name="platform interest rate"
                    value={platFormInterestRate}
                    onChange={(e) => setPlatFormInterestRate(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" style={{ color: "#ffffff" }}>
                    Spread:
                  </label>
                  <input
                    className="invest-input"
                    type="number"
                    name="spread"
                    value={spread}
                    onChange={(e) => setSpread(e.target.value)}
                  />
                </div>
                <div className="submit-btns">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <button type="submit">save</button>
                      <button type="submit">publish</button>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default InvestmentCreationScreen

import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
import SideNav from "../components/SideNav"
import moment from "moment"

import Loader from "../components/Loader"

import { format, formatDate } from "../main"

import { fetchAllTargetSavingsLogs } from "../actions/investmentActions"
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import Paginate from "../components/Paginate"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { CSVLink } from "react-csv"

const AllTargetSavingsLogs = () => {
  const [currPage, setCurrPage] = useState(1)
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [email, setEmail] = useState("")
  const [transactionReference, setTransactionReference] = useState("")
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])

  const allTargetSavingsLogs = useSelector(
    (state) => state.allTargetSavingsLogs
  )
  const { loading, success, error, logs } = allTargetSavingsLogs

  const dispatch = useDispatch()

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const customerRef = useRef(true)

  useEffect(() => {
    if (customerRef.current) {
      dispatch(fetchAllTargetSavingsLogs())
      customerRef.current = false
    }

    if (success && logs) {
      setData(
        logs?.items?.map((tran) => ({
          ea: tran?.email_address,
          un: tran?.user_name,
          purpose: tran?.purpose,
          fn: tran?.fullname,
          ta: tran?.target_amount,
          da: tran?.debit_amount,
          tr: tran?.transaction_reference,
          date_created: tran?.date_created,
          remarks: tran?.remarks,
          prm: tran?.provider_response_message,
          ac: tran?.amount_charged,
          as: tran?.amount_settled,
          fee: tran?.fee,
          prv: tran?.provider,
          dt: tran?.debi_type,
          pr: tran?.provider_transaction_reference
        }))
      )

      setHeaders([
        { label: "Email Addresss", key: "ea" },
        { label: "User name", key: "un" },
        { label: "Purpose", key: "purpose" },
        { label: "Full Name", key: "fn" },
        { label: "Target Amount", key: "ta" },
        { label: "Debit Amount", key: "da" },
        { label: "Transaction Reference", key: "tr" },
        { label: "Date Created", key: "date_created" },
        { label: "remarks", key: "remarks" },
        { label: "Provider Response Message", key: "prm" },
        { label: "Amount Charged", key: "ac" },
        { label: "Amount Settled", key: "as" },
        { label: "Fee", key: "fee" },
        { label: "Provider", key: "prv" },
        { label: "Debit Type", key: "dt" },
        { label: "Provider Reference", key: "pr" }
      ])
    }
  }, [dispatch, success, logs])

  const afterPageClicked = (page_number) => {
    const filterData = {}

    if (page_number === currPage) {
      dispatch(fetchAllTargetSavingsLogs(filterData))
    } else {
      dispatch(fetchAllTargetSavingsLogs(filterData))
    }

    if (page_number === "undefined1") {
      dispatch(fetchAllTargetSavingsLogs(filterData))
    }
  }

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      transactionReference
    }

    dispatch(fetchAllTargetSavingsLogs(filterData))
  }

  const handleClear = () => {
    setEmail("")
    setTransactionReference("")

    setStyles({
      navContainer: {
        display: "none"
      }
    })

    dispatch(fetchAllTargetSavingsLogs())
  }

  const handleExport = (e, done) => {
    if (success && logs) {
      setData(
        logs?.items?.map((tran) => ({
          ea: tran?.email_address,
          un: tran?.user_name,
          purpose: tran?.purpose,
          fn: tran?.fullname,
          ta: tran?.target_amount,
          da: tran?.debit_amount,
          tr: tran?.transaction_reference,
          date_created: tran?.date_created,
          remarks: tran?.remarks,
          prm: tran?.provider_response_message,
          ac: tran?.amount_charged,
          as: tran?.amount_settled,
          fee: tran?.fee,
          prv: tran?.provider,
          dt: tran?.debi_type,
          pr: tran?.provider_transaction_reference
        }))
      )

      setHeaders([
        { label: "Email Addresss", key: "ea" },
        { label: "User name", key: "un" },
        { label: "Purpose", key: "purpose" },
        { label: "Full Name", key: "fn" },
        { label: "Target Amount", key: "ta" },
        { label: "Debit Amount", key: "da" },
        { label: "Transaction Reference", key: "tr" },
        { label: "Date Created", key: "date_created" },
        { label: "remarks", key: "remarks" },
        { label: "Provider Response Message", key: "prm" },
        { label: "Amount Charged", key: "ac" },
        { label: "Amount Settled", key: "as" },
        { label: "Fee", key: "fee" },
        { label: "Provider", key: "prv" },
        { label: "Debit Type", key: "dt" },
        { label: "Provider Reference", key: "pr" }
      ])
      done(true)
    }
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Failed Target Savings | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />

          <div className="page-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-12">
                    <div className="filter-box">
                      <div className="filter">
                        <>
                          <div className="filter-container">
                            <p
                              style={{ cursor: "pointer" }}
                              onClick={handleClick}
                            >
                              Filter
                            </p>
                            <div
                              className="navContainer"
                              style={styles.navContainer}
                            >
                              <div className="date-filter">
                                <div className="date-title">Date</div>
                                <div className="date-options">
                                  <div className="date-content">
                                    <div className="date-items">
                                      <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                      />

                                      <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="status-container">
                                <div className="date-title">Filters</div>
                                <div className="checkbox checkbox--flex">
                                  <div
                                    className="checkbox__item"
                                    style={{
                                      width: "100%"
                                    }}
                                  >
                                    <input
                                      style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        border: "1px solid grey",
                                        fontSize: "14px",
                                        display: transactionReference
                                          ? "none"
                                          : "block"
                                      }}
                                      type="email"
                                      className="email-input"
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      placeholder="Enter customer Email"
                                    />{" "}
                                  </div>
                                  <div
                                    className="checkbox__item"
                                    style={{
                                      width: "100%"
                                    }}
                                  >
                                    <input
                                      style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        border: "1px solid grey",
                                        fontSize: "14px",
                                        display: email ? "none" : "block"
                                      }}
                                      type="email"
                                      className="email-input"
                                      value={transactionReference}
                                      onChange={(e) =>
                                        setTransactionReference(e.target.value)
                                      }
                                      placeholder="Enter Transaction Reference"
                                    />{" "}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="status-container"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  alignItems: "center"
                                }}
                              >
                                <button
                                  className="button"
                                  onClick={handleClear}
                                >
                                  <p>Clear</p>
                                </button>
                                <button
                                  className="button"
                                  onClick={handleFilter}
                                >
                                  <p>Filter</p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                      <div className="filter">
                        <CSVLink
                          data={data}
                          headers={headers}
                          filename={"transaction.csv"}
                          asyncOnClick={true}
                          onClick={handleExport}
                        >
                          Export
                        </CSVLink>
                      </div>
                    </div>
                    <h1>Failed Debits Today For Target Savings</h1>
                    <>
                      {loading ? (
                        <Loader />
                      ) : error ? (
                        <p>{error}</p>
                      ) : (
                        <>
                          <MDBTable
                            responsive
                            style={{ "width": "100%", fontSize: "14px" }}
                          >
                            <MDBTableHead>
                              <tr className="font-reduce">
                                <th scope="col">S/N</th>
                                <th scope="col">Email Address</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Purpose</th>
                                <th scope="col">Full Name</th>

                                <th scope="col">Target Amount</th>
                                <th scope="col">Debit Amount</th>

                                <th scope="col">Transaction Reference</th>
                                <th scope="col">Date Created</th>
                                <th scope="col">Remarks</th>
                                {/* <th scope='col'>Provider Response Code</th> */}
                                <th scope="col">Provider Response Message</th>
                                <th scope="col">Amount Charged</th>
                                <th scope="col">Amount Settled</th>
                                <th scope="col">Fee</th>
                                <th scope="col">Provider</th>
                                <th scope="col">Debit Type</th>
                                <th scope="col">Provider Reference</th>
                              </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                              {success &&
                                logs?.items?.length > 0 &&
                                logs?.items?.map((tran, key) => (
                                  <tr key={key} className="font-reduce">
                                    <td>{key + 1}</td>
                                    <td>{tran?.email_address}</td>
                                    <td>{tran?.username}</td>
                                    <td>{tran?.purpose}</td>
                                    <td>{tran?.fullname}</td>
                                    <td>{format(tran?.target_amount)}</td>
                                    <td>{format(tran?.debit_amount)}</td>
                                    <td>{tran?.transaction_reference}</td>
                                    <td>
                                      {moment(tran?.date_created).format("LLL")}
                                    </td>
                                    <td>{tran?.remarks}</td>
                                    <td>
                                      {tran?.provider_response_message?.substring(
                                        0,
                                        350
                                      )}
                                    </td>
                                    <td>{format(tran?.amount_charged)}</td>
                                    <td>{format(tran?.amount_settled)}</td>
                                    <td>{format(tran?.fee)}</td>
                                    <td>{tran?.provider}</td>
                                    <td>{tran?.debi_type}</td>
                                    <td>
                                      {tran?.provider_transaction_reference}
                                    </td>
                                  </tr>
                                ))}
                            </MDBTableBody>
                          </MDBTable>
                          <Paginate
                            totPages={logs?.page?.total_page}
                            currentPage={String(logs?.page?.current_page)}
                            page={String(logs?.page?.current_page)}
                            pageClicked={(ele) => {
                              afterPageClicked(ele)
                            }}
                          />
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AllTargetSavingsLogs

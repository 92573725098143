import { BrowserRouter, Routes, Route } from "react-router-dom"
import AccountCreationScreen from "./screens/AccountCreationScreen"
import AccountOfficerScreen from "./screens/AccountOfficerScreen"
import AdminScreen from "./screens/AdminScreen"
import BalancesScreen from "./screens/BalancesScreen"
import CustomerScreen from "./screens/CustomerScreen"
import FailedDebitsScreen from "./screens/FailedDebitsScreen"
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen"
import HomeScreen from "./screens/HomeScreen"
import InvestmentCreationScreen from "./screens/InvestmentCreationScreen"
import InvestmentProfileScreen from "./screens/InvestmentProfileScreen"
import InvestmentsScreen from "./screens/InvestmentsScreen"
import LoginScreen from "./screens/LoginScreen"
import NoScreen from "./screens/NoScreen"
import OtpScreen from "./screens/OtpScreen"
import PendingWithdrawalScreen from "./screens/PendingWithdrawalScreen"
import PerformanceScreen from "./screens/PerformanceScreen"
import ResetPasswordScreen from "./screens/ResetPasswordScreen"
import TransactionScreen from "./screens/TransactionScreen"
import UserScreen from "./screens/UserScreen"
import MerchantAccountApprovalScreen from "./screens/MerchantAccountApprovalScreen"
import CollectionsScreen from "./screens/CollectionsScreen"
import InvestmentsSettingsScreen from "./screens/InvestmentsSettingsScreen"
import BusinessDocumentScreen from "./screens/BusinessDocumentScreen"
import MerchantAccountScreen from "./screens/MerchantAccountScreen"
import ViewDocScreen from "./screens/ViewDocScreen"
import SingleOfficerScreen from "./screens/SingleOfficerScreen"
import CustomerLimitScreen from "./screens/CustomerLimitScreen"
import CustomerAutomationScreen from "./screens/CustomerAutomationScreen"
import AllCustomerLimitsScreen from "./screens/AllCustomerLimitsScreen"
import TargetSavingsOfficerScreen from "./screens/TargetSavingsOfficerScreen"
import SingleTargetSavingsOfficerScreen from "./screens/SingleTargetSavingsOfficerScreen"
import FailedPayoutsScreen from "./screens/FailedPayoutsScreen"
import PayoutScreen from "./screens/PayoutScreen"
import AllTargetSavingsScreen from "./screens/AllTargetSavingsScreen"
import BillsPaymentScreen from "./screens/BillsPaymentScreen"
import ProvidersScreen from "./screens/ProvidersScreen"
import TargetSavingsLogs from "./screens/TargetSavingsLogsScreen"
import InvestmentRatesScreen from "./screens/InvestmentRatesScreen"
import AllTargetSavingsLogs from "./screens/AllTargetSavingsLogsScreen"
import InterestInfoScreen from "./screens/InterestInfoScreen"
import TreegarScreen from "./screens/TreegarScreen"
import TreegarPendingInvestmentScreen from "./screens/TreegarPendingInvestmentScreen"
import TerminalsScreen from "./screens/TerminalsScreen"
import OverdraftScreen from "./screens/OverdraftScreen"
import OverDraftLimitScreen from "./screens/OverDraftLimitScreen"
import OverdraftSchedulesScreen from "./screens/OverdraftSchedulesScreen"
import FailedVerificationsScreen from "./screens/FailedVerificationsScreen"
import CommissionsBreakdownScreen from "./screens/CommisionsBreakdownScreen"
import BalanceMetricsScreen from "./screens/BalanceMetricsScreen"
import AllRatesScreen from "./screens/AllRatesScreen"
import BalanceHistoryScreen from "./screens/BalanceHistoryScreen"
import TransactionExportScreen from "./screens/TransactionExportScreen"
import AddTerminalScreen from "./screens/AddTerminalScreen"
import PendingTerminalsScreen from "./screens/PendingTerminalsScreen"
import AssignTerminalScreen from "./screens/AssignTerminalScreen"

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginScreen />} exact />
          <Route path="/dashboard" element={<HomeScreen />} exact />
          <Route path="/transactions" element={<TransactionScreen />} exact />
          <Route
            path="/transactions/export"
            element={<TransactionExportScreen />}
            exact
          />
          <Route path="/customers" element={<CustomerScreen />} exact />
          <Route path="/investments" element={<InvestmentsScreen />} exact />
          <Route path="/figures" element={<InterestInfoScreen />} exact />
          <Route
            path="/balances-metrics"
            element={<BalanceMetricsScreen />}
            exact
          />
          <Route path="/treegar" element={<TreegarScreen />} exact />
          <Route path="/terminals" element={<TerminalsScreen />} exact />
          <Route path="/terminal/add" element={<AddTerminalScreen />} exact />
          <Route
            path="/terminal/assign/:id"
            element={<AssignTerminalScreen />}
            exact
          />
          <Route
            path="/terminal/unassigned"
            element={<PendingTerminalsScreen />}
            exact
          />
          <Route
            path="/treegar/pending"
            element={<TreegarPendingInvestmentScreen />}
            exact
          />
          <Route
            path="/investment-create"
            element={<InvestmentCreationScreen />}
            exact
          />
          <Route
            path="/investment-profile"
            element={<InvestmentProfileScreen />}
            exact
          />
          <Route
            path="/investments-settings"
            element={<InvestmentsSettingsScreen />}
            exact
          />

          <Route path="/performance" element={<PerformanceScreen />} exact />
          <Route
            path="/verifications"
            element={<FailedVerificationsScreen />}
            exact
          />
          <Route
            path="/target-savings/performance"
            element={<TargetSavingsOfficerScreen />}
            exact
          />
          <Route
            path="/pending-withdrawal"
            element={<PendingWithdrawalScreen />}
            exact
          />
          <Route
            path="/failed-withdrawal"
            element={<FailedPayoutsScreen />}
            exact
          />
          <Route
            path="/commissions-breakdown"
            element={<CommissionsBreakdownScreen />}
            exact
          />
          <Route path="/payouts" element={<PayoutScreen />} exact />
          <Route
            path="/target-savings/all"
            element={<AllTargetSavingsScreen />}
            exact
          />
          <Route
            path="/target-savings/all/:id"
            element={<AllTargetSavingsScreen />}
            exact
          />
          <Route
            path="/target-savings/logs/:savingsname"
            element={<TargetSavingsLogs />}
            exact
          />
          <Route path="/failed-debits" element={<FailedDebitsScreen />} exact />
          <Route
            path="/target-savings/failed"
            element={<AllTargetSavingsLogs />}
            exact
          />
          {/* <Route
            path='/account-officer'
            element={<AccountOfficerScreen />}
            exact
          /> */}
          <Route
            path="/merchant-account"
            element={<MerchantAccountApprovalScreen />}
            exact
          />
          <Route
            path="/customer/increase-limit"
            element={<CustomerLimitScreen />}
            exact
          />
          <Route
            path="/customer/limits"
            element={<AllCustomerLimitsScreen />}
            exact
          />
          <Route
            path="/automation"
            element={<CustomerAutomationScreen />}
            exact
          />
          <Route path="/history" element={<BalanceHistoryScreen />} exact />
          <Route
            path="/overdraft-limit"
            element={<OverDraftLimitScreen />}
            exact
          />
          <Route path="/overdrafts" element={<OverdraftScreen />} exact />
          <Route
            path="/overdrafts-schedule/:id"
            element={<OverdraftSchedulesScreen />}
            exact
          />
          <Route
            path="/overdrafts-schedule"
            element={<OverdraftSchedulesScreen />}
            exact
          />
          <Route path="/merchants" element={<MerchantAccountScreen />} exact />
          <Route path="/providers" element={<ProvidersScreen />} exact />
          <Route path="/rates" element={<InvestmentRatesScreen />} exact />
          <Route path="/rates/all" element={<AllRatesScreen />} exact />
          <Route
            path="/merchant/doc/:id"
            element={<BusinessDocumentScreen />}
            exact
          />
          <Route
            path="/doc/:field/:fileaccess"
            element={<ViewDocScreen />}
            exact
          />
          {/* <Route path='/collections' element={<CollectionsScreen />} exact /> */}
          <Route path="/admin" element={<AdminScreen />} exact />
          <Route path="/otp" element={<OtpScreen />} exact />
          <Route path="/setup" element={<AccountCreationScreen />} exact />
          <Route path="/bills/all" element={<BillsPaymentScreen />} exact />
          <Route path="/officer/:id" element={<SingleOfficerScreen />} exact />
          <Route
            path="target-savings/officer/:id"
            element={<SingleTargetSavingsOfficerScreen />}
            exact
          />
          <Route
            path="/forgot-password"
            element={<ForgotPasswordScreen />}
            exact
          />
          <Route
            path="/reset-password"
            element={<ResetPasswordScreen />}
            exact
          />
          <Route path="/balances" element={<BalancesScreen />} exact />
          <Route path="/users/:pageNumber" element={<CustomerScreen />} exact />
          <Route path="/:id" element={<UserScreen />} exact />
          <Route path="*" element={<NoScreen />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App

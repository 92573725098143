import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCustomerMemos } from '../actions/merchantActions'

const MemosModal = ({ setIsOpen2, customer_id }) => {
  const dispatch = useDispatch()
  const memosRef = useRef(true)

  const getMemos = useSelector((state) => state.getMemos)
  const { loading, success, doc, error } = getMemos

  useEffect(() => {
    if (memosRef.current) {
      dispatch(fetchCustomerMemos(customer_id))
    }
  }, [])

  return (
    <>
      <div className='darkBG' onClick={() => setIsOpen2(false)} />
      <div className='centered'>
        <div className='modal'>
          <div className='modalHeader'>
            <h5 className='heading'>Memos</h5>
          </div>
          <button
            className='closeBtn'
            onClick={() => setIsOpen2(false)}
          ></button>
          <div className='modalContent'>
            {!loading && success && doc?.data ? (
              <h3 style={{ color: '#1a1a1a' }}>{doc?.data[0]?.remarks}</h3>
            ) : (
              <h3 style={{ color: '#1a1a1a' }}>No Memos</h3>
            )}
          </div>
          <div className='modalActions'>
            <div className='actionsContainer'>
              <button className='cancelBtn' onClick={() => setIsOpen2(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MemosModal

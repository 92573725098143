import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import Loader from '../components/Loader'

import SideNav from '../components/SideNav'

import 'react-datepicker/dist/react-datepicker.css'

import {
  fetchProvders,
  setEnableDisableProvider
} from '../actions/transactionActions'

const ProvidersScreen = () => {
  const dispatch = useDispatch()
  const withdrawalRef = useRef(true)
  const [currPage, setCurrPage] = useState(1)
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const navigate = useNavigate()

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const getProviders = useSelector((state) => state.getProviders)
  const { loading, success, error, providers } = getProviders

  const enableDisableProvider = useSelector(
    (state) => state.enableDisableProvider
  )
  const {
    loading: enableDisableProviderLoading,
    success: enableDisableProviderSuccess,
    error: enableDisableProviderError,
    provider
  } = enableDisableProvider

  useEffect(() => {
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    if (!userInfo || userInfo === null) {
      navigate('/dashboard', { replace: true })
    }
    if (userInfo?.user?.role !== 'Admin') {
      navigate(`/`, { replace: true })
    }
    if (withdrawalRef.current) {
      dispatch(fetchProvders())
      withdrawalRef.current = false
    }
    if (userInfo?.user?.role === 'Account Officer') {
      navigate('/performance', { replace: true })
    }
    if (enableDisableProviderSuccess) {
      window.location.reload()
    }
  }, [
    dispatch,
    loading,
    error,
    navigate,
    userInfo,
    currPage,
    providers,
    success,
    enableDisableProviderSuccess
  ])

  const approve = (id) => {
    const postData = {
      'provider_id': id,
      'status': true
    }
    dispatch(setEnableDisableProvider(postData))
  }

  const reject = (id) => {
    const postData = {
      'provider_id': id,
      'status': false
    }
    dispatch(setEnableDisableProvider(postData))
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Providers | PennyTree - Admin</title>
      </Helmet>
      <section className='page-container'>
        <div className='page-content'>
          <Header />
          <div className='page-body'>
            <h1>Providers</h1>
            <p
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'red',
                fontSize: '20px',
                letterSpacing: '1.5px'
              }}
            >
              {error && error}
            </p>

            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ 'width': '100%', fontSize: '14px' }}
                >
                  <MDBTableHead>
                    <tr className='font-reduce'>
                      <th scope='col'>S/N</th>
                      <th scope='email'>Name</th>
                      <th scope='col'>Service</th>
                      <th scope='col'>Is Active</th>
                      <th scope='col'>Actions</th>
                      <th scope='col'></th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      providers?.map((item, key) => (
                        <tr key={key} className='font-reduce'>
                          <td>{key + 1}</td>
                          <td>{item?.name?.toUpperCase()}</td>
                          <td>{item?.service}</td>
                          <td>{item?.is_active ? 'Active' : 'Not Active'}</td>
                          {(userInfo?.user?.role === 'Admin' ||
                            userInfo?.user?.role === 'Operations') &&
                          enableDisableProviderLoading ? (
                            <Loader />
                          ) : (
                            <>
                              <td>
                                <button
                                  className='withdrawal-btn'
                                  onClick={() => approve(item?.id)}
                                >
                                  Activate
                                </button>
                              </td>
                              <td>
                                <button
                                  className='withdrawal-btn withdrawal-red'
                                  onClick={() => reject(item?.id)}
                                >
                                  {' '}
                                  DeActivate
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
                {/* <Paginate
                  totPages={providers?.page?.total_page}
                  currentPage={providers?.page?.currentPage}
                  page={providers?.page?.currentPage}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                /> */}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default ProvidersScreen

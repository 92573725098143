import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { CSVLink } from 'react-csv'
import { format, formatDate } from '../main'
import Loader from './Loader'
import { fetchBills } from '../actions/dashboardActions'

const Bills = () => {
  const billsRef = useRef(true)
  const dispatch = useDispatch()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())

  const [styles, setStyles] = useState({
    navContainer: {
      display: 'none'
    }
  })
  const totalBills = useSelector((state) => state.totalBills)
  const { loading, success, bills, error } = totalBills

  useEffect(() => {
    if (billsRef.current) {
      dispatch(fetchBills())
      billsRef.current = false
    }
    if (
      (!loading && error?.message === 'Please login again') ||
      error?.message === 'You have previously logged out. Please login again'
    ) {
      localStorage.removeItem('userInfo')
    }
    // if (success && bills) {
    //   setData([
    //     {
    //       airtime_vended: format(bills?.airtel_vended),
    //       mtn_vended: format(bills?.mtn_vended),
    //       airtel_vended: format(bills?.airtel_vended),
    //       nine_mobile_vended: format(bills?.nine_mobile_vended),
    //       tv_subscription: format(bills?.tv_subscription),
    //       electricity: format(bills?.electricity),
    //       betting: format(bills?.betting)
    //     }
    //   ])

    //   setHeaders([
    //     { label: 'Airtime Vended', key: 'airtime_vended' },
    //     { label: 'Mtn Vended', key: 'mtn_vended' },
    //     { label: 'Airtel Vended', key: 'airtel_vended' },
    //     { label: 'Nine Mobile Vended', key: 'nine_mobile_vended' },
    //     { label: 'Tv Subscription', key: 'tv_subscription' },
    //     { label: 'Electricity', key: 'electricity' },
    //     { label: 'Betting', key: 'betting' }
    //   ])
    // }
  }, [bills, success, dispatch, loading, error])

  const handleClick = () => {
    if (styles.navContainer.display === 'block') {
      setStyles({
        navContainer: {
          display: 'none'
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: 'block'
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: 'none'
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
    dispatch(fetchBills(filterData))
  }

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: 'none'
      }
    })
    dispatch(fetchBills())
  }

  return (
    <>
      <div className='filter-box'>
        <div className='filter'>
          <>
            <div className='filter-container'>
              <p style={{ cursor: 'pointer' }} onClick={handleClick}>
                Filter
              </p>
              <div className='navContainer' style={styles.navContainer}>
                <div className='date-filter'>
                  <div className='date-title'>Date</div>
                  <div className='date-options'>
                    <div className='date-content'>
                      <div className='date-items'>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className='status-container'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center'
                  }}
                >
                  <button className='button' onClick={handleClear}>
                    <p>Clear</p>
                  </button>
                  <button className='button' onClick={handleFilter}>
                    <p>Filter</p>
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
      <div className='accordion-item'>
        <h3 className='accordion-header' id='h3'>
          <button
            className='accordion-button'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#bills'
            aria-expanded='true'
            aria-controls='bills'
          >
            Bills Value
          </button>
        </h3>
        <div
          id='bills'
          className='accordion-collapse collapse show'
          aria-labelledby='h3'
        >
          {loading ? (
            <Loader />
          ) : (
            <div className='accordion-body'>
              <div className='row'>
                <div className='col-lg-3'>
                  <div className='info-box'>
                    <i className='fa fa-users blue-icon'></i>
                    <div className='info-box-count'>
                      {format(bills?.airtime_vended)}
                    </div>
                    <p>Airtime Vended</p>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='info-box'>
                    <i className='fa fa-users green-icon'></i>
                    <div className='info-box-count'>
                      {format(bills?.mtn_vended)}
                    </div>
                    <p>Mtn Vended</p>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='info-box'>
                    <i className='fa fa-users orange-icon'></i>
                    <div className='info-box-count'>
                      {format(bills?.airtel_vended)}
                    </div>
                    <p>Airtel Vended</p>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='info-box'>
                    <i className='fa fa-users blue-icon'></i>
                    <div className='info-box-count'>
                      {format(bills?.nine_mobile_vended)}
                    </div>
                    <p>9mobile Vended</p>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='info-box'>
                    <i className='fa fa-users blue-icon'></i>
                    <div className='info-box-count'>
                      {format(bills?.glo_vended)}
                    </div>
                    <p>Glo Vended</p>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='info-box'>
                    <i className='fa fa-users green-icon'></i>
                    <div className='info-box-count'>
                      {format(bills?.tv_subscription)}
                    </div>
                    <p>TV Subscription Vended</p>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='info-box'>
                    <i className='fa fa-users'></i>
                    <div className='info-box-count'>
                      {format(bills?.electricity)}
                    </div>
                    <p>Electricity Vended</p>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='info-box'>
                    <i className='fa fa-users blue-icon'></i>
                    <div className='info-box-count'>
                      {format(bills?.betting)}
                    </div>
                    <p>Betting Vended</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Bills

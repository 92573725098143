import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"

import Header from "../components/Header"
import Loader from "../components/Loader"
import Paginate from "../components/Paginate"
import SideNav from "../components/SideNav"
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit"
import { CSVLink } from "react-csv"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { format, formatDate } from "../main"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  fetchOverDraftRequest,
  fetchOverDraftSchedule
} from "../actions/overdraftActions"
import OverdraftModal from "../components/OverdraftModal"

const OverdraftScreen = () => {
  const navigate = useNavigate()
  const { customeremail } = useParams()
  const location = useLocation()

  const [currPage, setCurrPage] = useState(1)
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  // FILTER states starts
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())

  const [initiated, setInitiated] = useState(false)
  const [approved, setApproved] = useState(false)
  const [rejected, setRejected] = useState(false)

  const [id, setId] = useState("")
  const [isOpen, setIsOpen] = useState(false)

  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  // filter states emd
  const dispatch = useDispatch()
  const transRef = useRef(true)

  const getOverdraftRequest = useSelector((state) => state.getOverdraftRequest)
  const { loading, success, error, overdraftRequest } = getOverdraftRequest

  const approveRejectOverdraft = useSelector(
    (state) => state.approveRejectOverdraft
  )
  const {
    loading: approveRejectLoading,
    success: approveRejectSucess,
    error: approveRejectError
  } = approveRejectOverdraft

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  useEffect(() => {
    if (transRef.current) {
      dispatch(fetchOverDraftRequest())
      dispatch(fetchOverDraftSchedule(56))
      transRef.current = false
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate(`/officer/${userInfo?.user?.id}`, { replace: true })
    }
    if (userInfo?.user?.role === "TREEGAR") {
      navigate("/treegar", { replace: true })
    }
    if (success && overdraftRequest) {
      setData()

      setHeaders([])
    }
  }, [
    dispatch,
    userInfo,
    navigate,
    currPage,

    success,
    customeremail,
    error,
    loading
  ])

  const afterPageClicked = (page_number) => {
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      initiated,
      approved,
      rejected
    }

    if (page_number === currPage) {
      dispatch(fetchOverDraftRequest(page_number + 1, filterData))
    } else {
      dispatch(fetchOverDraftRequest(page_number, filterData))
    }

    if (page_number === "undefined1") {
      dispatch(fetchOverDraftRequest(1, filterData))
    }
  }

  // filter data

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      initiated,
      approved,
      rejected
    }
    dispatch(fetchOverDraftRequest(currPage, filterData))
  }

  const handleClear = () => {
    setApproved(false)
    setInitiated(false)
    setRejected(false)

    setStyles({
      navContainer: {
        display: "none"
      }
    })

    dispatch(fetchOverDraftRequest(currPage))
  }

  const handleExport = (e, done) => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      initiated,
      approved,
      rejected
    }
    dispatch(fetchOverDraftRequest(currPage, filterData))

    if (success && overdraftRequest) {
      setData()

      setHeaders([])
      done(true)
    } else {
      done(false)
    }
  }

  const approve = (id) => {
    setIsOpen(true)
    setId(id)
  }

  const reject = (id) => {
    setIsOpen(true)
    setId(id)
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Transactions | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>OverDraft Requests</h1>
            <div
              className="status-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: "10px"
              }}
            >
              <div className="button">
                <FontAwesomeIcon />
                <Link to="/overdraft-limit"> OverDraft Limit</Link>
              </div>
              &nbsp; &nbsp;
              <div className="button">
                <FontAwesomeIcon />
                <Link to="/overdrafts-schedule"> OverDraft Schedules</Link>
              </div>
            </div>
            <div className="filter-box">
              <div className="filter">
                <>
                  <div className="filter-container">
                    <p style={{ cursor: "pointer" }} onClick={handleClick}>
                      Filter
                    </p>
                    <div className="navContainer" style={styles.navContainer}>
                      <div className="date-filter" style={{}}>
                        <div className="date-title">Date</div>
                        <div className="date-options">
                          <div className="date-content">
                            <div className="date-items">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                              />

                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="status-container" style={{}}>
                        <div className="date-title">Status</div>
                        <div className="checkbox checkbox--flex">
                          <div className="checkbox__item">
                            <input
                              id="failed_wz3gl9i2d"
                              type="checkbox"
                              name="failed_wz3gl9i2d"
                              className="checkbox__input"
                              checked={approved}
                              onChange={() => setApproved(!approved)}
                            />{" "}
                            <label
                              htmlFor="failed_wz3gl9i2d"
                              className="checkbox__label"
                            >
                              Approved
                            </label>
                          </div>
                          <div className="checkbox__item">
                            <input
                              id="reversed_wz3gl9i2d"
                              type="checkbox"
                              name="reversed_wz3gl9i2d"
                              className="checkbox__input"
                              checked={rejected}
                              onChange={() => setRejected(!rejected)}
                            />{" "}
                            <label
                              htmlFor="reversed_wz3gl9i2d"
                              className="checkbox__label"
                            >
                              Rejected
                            </label>
                          </div>
                          <div className="checkbox__item">
                            <input
                              id="initiated_wz3gl9i2d"
                              type="checkbox"
                              name="initiated_wz3gl9i2d"
                              className="checkbox__input"
                              checked={initiated}
                              onChange={() => setInitiated(!initiated)}
                            />{" "}
                            <label
                              htmlFor="initiated_wz3gl9i2d"
                              className="checkbox__label"
                            >
                              Initiated
                            </label>
                          </div>
                        </div>
                      </div>

                      <div
                        className="status-container"
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center"
                        }}
                      >
                        <button className="button" onClick={handleClear}>
                          <p>Clear</p>
                        </button>
                        <button className="button" onClick={handleFilter}>
                          <p>Filter</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
            {isOpen && <OverdraftModal setIsOpen={setIsOpen} id={id} />}
            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                  align="middle"
                >
                  <MDBTableHead>
                    <tr className="font-reduce">
                      <th scope="col">S/N</th>
                      <th scope="col">Merchant Tag</th>
                      <th scope="col">Business Name</th>
                      <th scope="col">Request Amount</th>
                      <th scope="col">Status</th>
                      <th scope="">Actions</th>
                      <th scope="col"></th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      overdraftRequest?.items?.map((r, key) => (
                        <tr key={key} className="font-reduce">
                          <td>{key + 1}</td>
                          <td>{r?.merchant_tag}</td>
                          <td>
                            <Link to={`/overdrafts-schedule/${r?.business_id}`}>
                              {r?.business_name}
                            </Link>
                          </td>

                          <td>{format(r?.request_amount)}</td>
                          <td>
                            {r?.status}{" "}
                            {r?.status === "Approved" && (
                              <p
                                className="withdrawal-btn"
                                style={{
                                  marginTop: "10px",
                                  textAlign: "center"
                                }}
                              >
                                <Link
                                  to={`/overdrafts-schedule/${r?.business_id}`}
                                >
                                  View
                                </Link>
                              </p>
                            )}
                          </td>
                          {(userInfo?.user?.role === "Admin" ||
                            userInfo?.user?.role === "Operations") &&
                          approveRejectLoading ? (
                            <Loader />
                          ) : (
                            r?.status === "Initiated" && (
                              <>
                                <td>
                                  <button
                                    className="withdrawal-btn"
                                    onClick={() => approve(r?.id)}
                                  >
                                    Approve
                                  </button>
                                </td>
                                <td>
                                  <button
                                    className="withdrawal-btn withdrawal-red"
                                    onClick={() => reject(r?.id)}
                                  >
                                    {" "}
                                    Reject
                                  </button>
                                </td>
                              </>
                            )
                          )}
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
                <Paginate
                  totPages={overdraftRequest?.page?.total_page}
                  currentPage={String(overdraftRequest?.page?.current_page)}
                  page={String(overdraftRequest?.page?.current_page)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default OverdraftScreen

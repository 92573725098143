import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { format, formatDate } from "../main"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Loader from "./Loader"
import { CSVLink } from "react-csv"
import {
  fetchBusinessMetrics,
  fetchTotalBalance
} from "../actions/dashboardActions"
import { Link } from "react-router-dom"
import {
  fetchFlwBalance,
  fetchPayazaBalance,
  fetchPaystackBalance,
  fetchSeerbitBalance,
  fetchVfdBalance
} from "../actions/balanceActions"

const Balance = () => {
  const metricsRef = useRef(true)
  const dispatch = useDispatch()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const getFlwBalance = useSelector((state) => state.getFlwBalance)
  const { loading, success, balance, error } = getFlwBalance

  const getPayazaBalance = useSelector((state) => state.getPayazaBalance)
  const {
    loading: payazaLoading,
    success: payazaSuccess,
    balance: payazaBalance
  } = getPayazaBalance

  const getSeerbitBalance = useSelector((state) => state.getSeerbitBalance)
  const {
    loading: seerbitLoading,
    success: seerbitSuccess,
    balance: seerbitBalance
  } = getSeerbitBalance

  const getPaystackBalance = useSelector((state) => state.getPaystackBalance)
  const {
    loading: paystackLoading,
    success: paystackSuccess,
    balance: paystackBalance
  } = getPaystackBalance

  const getVfdBalance = useSelector((state) => state.getVfdBalance)
  const {
    loading: vfdLoading,
    success: vfdSuccess,
    balance: vfdBalance
  } = getVfdBalance

  console.log(balance)

  useEffect(() => {
    if (metricsRef.current) {
      dispatch(fetchFlwBalance())
      dispatch(fetchPaystackBalance())
      dispatch(fetchSeerbitBalance())
      dispatch(fetchPayazaBalance())
      dispatch(fetchVfdBalance())
      metricsRef.current = false
    }
  }, [balance, success, dispatch, error, loading])

  return (
    <>
      <div className="accordion-item">
        <h3 className="accordion-header" id="h1">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#business"
            aria-expanded="true"
            aria-controls="business"
          >
            Webhook Balance
          </button>
        </h3>
        <div
          id="business"
          className="accordion-collapse collapse show"
          aria-labelledby="h1"
        >
          <div className="accordion-body">
            {paystackLoading ? (
              <Loader />
            ) : (
              <div className="row">
                <p className="error">{error && error}</p>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {paystackBalance &&
                      paystackBalance.data &&
                      paystackBalance.data.length > 0
                        ? format(
                            (paystackBalance?.data[0]?.balance / 100).toFixed(2)
                          )
                        : 0}
                    </div>

                    <p>Paystack Balance</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {balance === null
                        ? 0
                        : format(payazaBalance?.accountBalance)}
                    </div>
                    <p>Payaza Balance</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {balance === null
                        ? 0
                        : format(seerbitBalance?.data?.availableBalanceAmount)}
                    </div>
                    <p>Seerbit Balance</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {balance === null
                        ? 0
                        : format(balance?.data?.available_balance)}
                    </div>
                    <p>Flutterwave Balance</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {balance === null
                        ? 0
                        : format(vfdBalance?.data?.accountBalance)}
                    </div>
                    <p>Vfd Balance</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Balance

import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { userVerification } from '../actions/userActions'
import Loader from '../components/Loader'
import { getHashParams, isEmpty, validateTelephone } from '../helper'

const AccountCreationScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const createRef = useRef(true)
  const [accessToken, setAccessToken] = useState()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [password, setPassword] = useState('')
  const [displayPassword, setDisplayPassword] = useState(false)
  const [error2, setError] = useState('')
  const verifyUser = useSelector((state) => state.verifyUser)
  const { loading, error, success } = verifyUser

  useEffect(() => {
    localStorage.removeItem('userInfo')
    if (createRef.current) {
      setAccessToken(getHashParams())
      createRef.current = false
    }
    if (success) {
      navigate('/', { replace: true })
    }
  }, [success, navigate])

  const submitHandler = (e) => {
    e.preventDefault()
    const str = mobileNumber
    const char = str[0]
    const mobile_number = str.replace(char, '+234')
    if (isEmpty(firstName)) {
      setError('First name is required')
    } else if (isEmpty(lastName)) {
      setError('Last name is required')
    } else if (!validateTelephone(mobile_number)) {
      setError('Invalid mobile number provided')
    } else {
      setError('')
      setAccessToken(getHashParams())
      dispatch(
        userVerification({
          firstName,
          lastName,
          mobile_number,
          password,
          access_token: accessToken?.access_token
        })
      )
    }
  }
  const passwordToggler = (e) => {
    e.preventDefault()
    setDisplayPassword(!displayPassword)
  }
  return (
    <>
      <main className='login-bg'>
        <div className='login-form'>
          <a href='./'>
            <img
              src='assets/images/PennyTree-Logo.png'
              alt=''
              className='admin-logo'
            />
          </a>
          <h5>Hi there, Please continue setting up your account</h5>
          <form onSubmit={submitHandler}>
            <div className='err-msg'></div>

            <div className='form-group'>
              <label htmlFor='password' style={{ color: '#ffffff' }}>
                First Name
              </label>
              <input
                type='text'
                name='otp'
                placeholder='Enter First Name '
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label htmlFor='password' style={{ color: '#ffffff' }}>
                Last Name
              </label>
              <input
                type='text'
                name='otp'
                placeholder='Enter last Name'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label htmlFor='password' style={{ color: '#ffffff' }}>
                Mobile Number
              </label>
              <input
                id='number'
                type='text'
                name='otp'
                placeholder='Enter Mobile Number'
                value={mobileNumber}
                max={11}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label htmlFor='password' style={{ color: '#ffffff' }}>
                Password
              </label>
              <input
                id='password'
                type={displayPassword ? 'text' : 'password'}
                name='otp'
                placeholder='Please create a secure password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className='eye-toggler' onClick={passwordToggler}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  id='closed'
                  style={{ display: displayPassword && 'none' }}
                >
                  <path d='M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  id='opened'
                  style={{ display: !displayPassword && 'none' }}
                >
                  <path d='M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z' />
                </svg>
              </div>
            </div>

            {/* <p>{!loading && !error && otpResent?.message}</p> */}

            {loading ? (
              <Loader />
            ) : (
              <button type='submit' className='login-btn'>
                Continue
              </button>
            )}
          </form>

          <p
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'red',
              fontSize: '20px',
              letterSpacing: '1.5px'
            }}
          >
            {error && error}
            {error2 && error2}
          </p>
        </div>
      </main>
    </>
  )
}

export default AccountCreationScreen

import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import Header from "../components/Header"
import Loader from "../components/Loader"
import Paginate from "../components/Paginate"
import SideNav from "../components/SideNav"
import { format, formatDate } from "../main"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import { CSVLink } from "react-csv"

import {
  setApproveRejectWithdrawal,
  fetchAllPayouts,
  fetchAllBills
} from "../actions/transactionActions"
import { fetchTerminals } from "../actions/adminActions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const TerminalsScreen = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const withdrawalRef = useRef(true)
  const [currPage, setCurrPage] = useState(1)

  const navigate = useNavigate()

  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const getTerminals = useSelector((state) => state.getTerminals)
  const { loading, success, error, terminals } = getTerminals

  useEffect(() => {
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    // if (!userInfo || userInfo === null) {
    //   navigate('/', { replace: true })
    // }
    if (error) {
      navigate("/dashboard", { replace: true })
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate(`/officer/${userInfo?.user?.id}`, { replace: true })
    }
    if (withdrawalRef.current) {
      dispatch(fetchTerminals())
      withdrawalRef.current = false
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate("/performance", { replace: true })
    }
    if (success && terminals) {
      setData(
        terminals?.map?.((terminal) => ({
          serial_number: terminal.serialnumber,
          terminal_identity: terminal.terminal_identity,
          commission: terminal.commission,
          email_address: terminal.emailaddress,
          penntag: terminal.penn_tag,
          merchant_tag: terminal.merchanttag,
          date_assigned: moment(terminal?.date_created).format("LLL"),
          business_name: terminal.business_name,
          total_inflow: format(terminal.total_inflow),
          total_fee: format(terminal.fee),
          inflow_today: format(terminal.inflow_today),
          inflow_this_week: format(terminal.weekly_inflow),
          q1_inflow: format(terminal.q1_inflow),
          q2_inflow: format(terminal.q2_inflow),
          q3_inflow: format(terminal.q3_inflow),
          q4_inflow: format(terminal.q4_inflow)
        }))
      )
      setHeaders([
        {
          label: "Serial Number",
          key: "serial_number"
        },
        {
          label: "Terminal Identity",
          key: "terminal_identity"
        },
        {
          label: "Commission",
          key: "commission"
        },
        {
          label: "Email Address",
          key: "email_address"
        },
        {
          label: "PennTag",
          key: "penntag"
        },
        {
          label: "Merchant Tag",
          key: "merchant_tag"
        },
        {
          label: "Business Name",
          key: "business_name"
        },
        {
          label: "Date Assigned",
          key: "date_assigned"
        },
        {
          label: "Total Inflow",
          key: "total_inflow"
        },
        {
          label: "Total Fee",
          key: "total_fee"
        },
        {
          label: "Inflow Today",
          key: "inflow_today"
        },
        {
          label: "Inflow This Week",
          key: "inflow_this_week"
        },
        {
          label: "Q1 inflow",
          key: "q1_inflow"
        },
        {
          label: "Q2 inflow",
          key: "q2_inflow"
        },

        {
          label: "Q2 inflow",
          key: "q2_inflow"
        },

        {
          label: "Q3 inflow",
          key: "q3_inflow"
        },
        {
          label: "Q4 inflow",
          key: "q4_inflow"
        }
      ])
    }
  }, [
    dispatch,
    loading,
    error,
    navigate,
    userInfo,
    currPage,
    terminals,
    success
  ])

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Terminals | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <div className="filter-box">
              <div className="filter">
                <CSVLink data={data} headers={headers}>
                  Export
                </CSVLink>
              </div>
            </div>
            <h1>Terminals</h1>
            <div
              className="status-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <div className="button">
                <FontAwesomeIcon />
                <Link to="/terminal/unassigned"> UnAssigned Terminals</Link>
              </div>
            </div>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "red",
                fontSize: "20px",
                letterSpacing: "1.5px"
              }}
            >
              {error && error}
            </p>

            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                >
                  <MDBTableHead>
                    <tr className="font-reduce">
                      <th scope="col">S/N</th>
                      <th scope="col">Serial Number</th>
                      <th scope="col">Terminal Identity</th>
                      <th scope="col">Commission</th>
                      <th scope="email">Email Address</th>
                      <th scope="col">PennTag</th>
                      <th scope="col">Merchant Tag</th>
                      <th scope="col">Business Name</th>
                      <th scope="col">Date Assigned</th>
                      <th scope="col">Total Inflow</th>
                      <th scope="col">Total Fee</th>
                      <th scope="col">Inflow Today</th>
                      <th scope="col">Inflow This Week</th>
                      <th scope="col">Q1 Inflow </th>
                      <th scope="col">Q2 Inflow</th>
                      <th scope="col">Q3 Inflow</th>
                      <th scope="col">Q4 Inflow</th>
                      <th scope="col"> Actions</th>
                      <th scope="col"></th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      terminals.map((terminal, key) => (
                        <tr>
                          <td>{key + 1}</td>
                          <td>{terminal.serialnumber}</td>
                          <td>{terminal.terminal_identity}</td>
                          <td>{terminal.commission}%</td>
                          <td>{terminal.emailaddress}</td>
                          <td>{terminal.penn_tag}</td>
                          <td>{terminal.merchanttag}</td>
                          <td>{terminal.business_name}</td>
                          <td>
                            {moment(terminal?.date_created).format("LLL")}
                          </td>
                          <td>{format(terminal.total_inflow)}</td>
                          <td>{format(terminal.fee)}</td>
                          <td>{format(terminal.inflow_today)}</td>
                          <td>{format(terminal.weekly_inflow)}</td>
                          <td>{format(terminal.q1_inflow)}</td>
                          <td>{format(terminal.q2_inflow)}</td>
                          <td>{format(terminal.q3_inflow)}</td>
                          <td>{format(terminal.q4_inflow)}</td>
                          <td>
                            <button className="withdrawal-btn">
                              <Link
                                to={`/terminal/assign/${terminal?.terminal_id}`}
                                state={{
                                  terminal_id: terminal?.terminal_identity,
                                  action: "reassign"
                                }}
                              >
                                ReAssign Terminal
                              </Link>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default TerminalsScreen

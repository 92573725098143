import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { format, formatDate } from "../main"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Loader from "./Loader"
import { CSVLink } from "react-csv"
import { fetchPosMetrics } from "../actions/dashboardActions"
import { Link } from "react-router-dom"

const PosMetrics = () => {
  const metricsRef = useRef(true)
  const dispatch = useDispatch()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const posMetrics = useSelector((state) => state.posMetrics)
  const { loading, success, metrics, error } = posMetrics

  useEffect(() => {
    if (metricsRef.current) {
      dispatch(fetchPosMetrics())
      metricsRef.current = false
    }
    if (
      (!loading && error?.message === "Please login again") ||
      error?.message === "You have previously logged out. Please login again"
    ) {
      localStorage.removeItem("userInfo")
    }
  }, [metrics, success, dispatch, error, loading])

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
    dispatch(fetchPosMetrics(filterData))
  }

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    dispatch(fetchPosMetrics())
  }
  return (
    <>
      <div className="filter-box">
        <div className="filter">
          <>
            <div className="filter-container">
              <p style={{ cursor: "pointer" }} onClick={handleClick}>
                Filter
              </p>
              <div className="navContainer" style={styles.navContainer}>
                <div className="date-filter">
                  <div className="date-title">Date</div>
                  <div className="date-options">
                    <div className="date-content">
                      <div className="date-items">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="status-container">
                  <div className="checkbox checkbox--flex"></div>
                </div>
                <div
                  className="status-container"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                  }}
                >
                  <button className="button" onClick={handleClear}>
                    <p>Clear</p>
                  </button>
                  <button className="button" onClick={handleFilter}>
                    <p>Filter</p>
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
      <div className="accordion-item">
        <h3 className="accordion-header" id="h1">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#business"
            aria-expanded="true"
            aria-controls="business"
          >
            POS Metrics
          </button>
        </h3>
        <div
          id="business"
          className="accordion-collapse collapse show"
          aria-labelledby="h1"
        >
          <div className="accordion-body">
            {loading ? (
              <Loader />
            ) : (
              <div className="row">
                <p className="error">{error && error?.message}</p>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : format(metrics?.daily_pos_fees)}
                    </div>
                    <p>Today Pos Fees</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : format(metrics?.daily_pos_transactions)}
                    </div>
                    <p>Today Pos Transactions</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.daily_pos_transaction_count}
                    </div>
                    <p>Today Pos Transaction Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : format(metrics?.weekly_pos_fees)}
                    </div>
                    <p>This Week Pos Fees</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.weekly_pos_transaction_count}
                    </div>
                    <p>This Week Pos Transaction Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <Link to="/target-savings/all/2">
                    <div className="info-box">
                      <i className="fa fa-users orange-icon"></i>

                      <div className="info-box-count">
                        {metrics === null
                          ? 0
                          : format(metrics?.weekly_pos_transactions)}
                      </div>

                      <p>This Week Pos Transaction</p>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : format(metrics?.monthly_pos_fees)}
                    </div>
                    <p>This Month Pos Transaction Fees</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.monthly_pos_transaction_count}
                    </div>
                    <p>This Month Pos Transaction Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : format(metrics?.monthly_pos_transactions)}
                    </div>
                    <p>This Month Pos Transactions</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : format(metrics?.total_pos_transactions)}
                    </div>
                    <p>Total Pos Transactions</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : format(metrics?.total_pos_fees)}
                    </div>
                    <p>Total Pos Fees</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.total_pos_transaction_count}
                    </div>
                    <p>Total Pos Transaction Count</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PosMetrics

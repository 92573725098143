import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import React, { useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Header from "../components/Header"
import Loader from "../components/Loader"
import SideNav from "../components/SideNav"
import moment from "moment"
import { fetchBusinessDocument } from "../actions/merchantActions"

const BusinessDocumentScreen = () => {
  const documentRef = useRef(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const businessDocument = useSelector((state) => state.businessDocument)
  const { loading, success, document, error } = businessDocument

  useEffect(() => {
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    // if (!userInfo || userInfo === null) {
    //   navigate('/', { replace: true })
    // }
    if (error) {
      navigate("/dashboard", { replace: true })
    }
    if (documentRef.current) {
      dispatch(fetchBusinessDocument(id))
      documentRef.current = false
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate("/performance", { replace: true })
    }
  })

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Customer | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <MDBTable responsive style={{ "width": "100%" }}>
              <MDBTableHead>
                <tr>
                  <th scope="col">S/N</th>
                  <th scope="col">Customer Id</th>
                  <th scope="col">Type</th>
                  <th scope="col">Value</th>
                  <th scope="col">Status</th>
                  <th scope="col">Remarks</th>
                  <th scope="col">Verification Reference</th>
                  <th scope="col">Date Created</th>
                  <th scope="col">Action</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {loading ? (
                  <Loader />
                ) : (
                  success &&
                  document &&
                  document.length > 0 &&
                  document.map((doc, key) => (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>
                        <a href={`/${doc?.customer_id}`}>{doc?.customer_id}</a>
                      </td>
                      <td>{doc?.type}</td>
                      <td>{doc?.value}</td>
                      <td>{doc?.status}</td>
                      <td>{doc?.remarks}</td>
                      <td>{doc?.verification_reference}</td>
                      <td>{moment(doc?.date_created).format("LLL")}</td>
                      <td>
                        {doc?.type === "BVN" ||
                        doc?.type === "EMAIL" ||
                        doc?.type === "NIN" ? (
                          <span style={{ color: "red" }}>No View</span>
                        ) : (
                          <a style={{ color: "green" }} href={doc?.value}>
                            <span>View</span>
                          </a>
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </MDBTableBody>
            </MDBTable>
          </div>
        </div>
      </section>
    </>
  )
}

export default BusinessDocumentScreen

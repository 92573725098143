import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setForgotPassword } from '../actions/userActions'
import Loader from '../components/Loader'
import { isEmpty } from '../helper'

const ForgotPasswordScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [error2, setError] = useState('')

  const forgotPassword = useSelector((state) => state.forgotPassword)
  const { loading, success, error, passwordForgot } = forgotPassword

  const submitHandler = (e) => {
    e.preventDefault()
    if (!isEmpty(email)) {
      setError('')
      dispatch(setForgotPassword(email))
    } else {
      setError('Please fill all required field(s)')
    }
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => navigate('/reset-password', { replace: true }), 3000)
    }
  }, [success, navigate])
  return (
    <>
      <main className='login-bg'>
        <div className='login-form'>
          <a href='./'>
            <img
              src='assets/images/PennyTree-Logo.png'
              alt=''
              className='admin-logo'
            />
          </a>
          <h5>
            Hi there, Please enter your email to continue resetting your
            password
          </h5>
          <form onSubmit={submitHandler}>
            <div className='err-msg'></div>

            <div className='form-group'>
              <label htmlFor='password' style={{ color: '#ffffff' }}>
                Email Address
              </label>
              <input
                type='text'
                name='otp'
                placeholder='Enter Email Address '
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <p>{!loading && !error && passwordForgot?.message}</p>
            {success && (
              <p>
                You will be redirected in 3 seconds if not redirected please{' '}
                <a href='/reset-password'>Click Here</a>
              </p>
            )}

            {loading ? (
              <Loader />
            ) : (
              <button type='submit' className='login-btn'>
                Continue
              </button>
            )}
          </form>

          <p
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'red',
              fontSize: '20px',
              letterSpacing: '1.5px'
            }}
          >
            {error && error}
            {error2 && error2}
          </p>
        </div>
      </main>
    </>
  )
}

export default ForgotPasswordScreen

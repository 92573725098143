import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { CSVLink } from "react-csv"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getCustomerInvestments2 } from "../actions/userActions"
import { format, formatDate } from "../main"

const PersonalVaultExpired = ({ status }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [currPage, setCurrPage] = useState(1)
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [tag, setTag] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const customerRef2 = useRef(true)

  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const customerInvestmentsExpired = useSelector(
    (state) => state.customerInvestmentsExpired
  )
  const {
    loading: investmentLoading,
    success: investmentSuccess,
    investments: investmentExpired,
    error: investmentError
  } = customerInvestmentsExpired

  useEffect(() => {
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    if (customerRef2.current) {
      dispatch(getCustomerInvestments2(currPage))
      customerRef2.current = false
    }
    if (investmentSuccess && investmentExpired) {
      setData(
        investmentExpired?.items?.map?.((inv) => ({
          vault_name: inv?.investment_name,
          customer_name: inv?.full_name,
          penntag: inv?.user_name,
          amount: format(inv?.amount),
          duration: inv?.duration,
          interest_rate: inv?.interest_rate,
          interest_amount: format(inv?.interest_accrued),
          status: inv?.status,
          start_date: moment(inv?.start_date).format("LLL"),
          withdrawal_date: moment(inv?.end_date).format("LLL"),
          topup_amount: inv?.topup_amount,
          rollover_duration: inv?.roll_over_duration
        }))
      )
      setHeaders([
        {
          label: "Vault Name",
          key: "vault_name"
        },

        {
          label: "Customer Name",
          key: "customer_name"
        },
        {
          label: "PennTag",
          key: "penntag"
        },
        {
          label: "Amouunt",
          key: "amount"
        },
        {
          label: "Duration",
          key: "duration"
        },
        {
          label: "Interest Rate",
          key: "interest_rate"
        },
        {
          label: "Interest Amount",
          key: "interest_amount"
        },
        {
          label: "Status",
          key: "status"
        },
        {
          label: "Start Date",
          key: "start_date"
        },
        {
          label: "Withdrawal Date",
          key: "withdrawal_date"
        },
        {
          label: "Top up Amount",
          key: "topup_amount"
        },
        {
          label: "Rollover Duration",
          key: "rollover_duration"
        }
      ])
    }
  }, [
    dispatch,
    currPage,
    investmentError,
    investmentLoading,
    investmentSuccess,
    investmentExpired
  ])

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })

    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      email,
      tag,
      name,
      accountNumber
    }

    dispatch(getCustomerInvestments2(currPage, filterData))
  }

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    setEmail("")
    setTag("")
    setName("")
    setAccountNumber("")

    dispatch(getCustomerInvestments2(currPage))
  }
  return (
    <>
      {/* <div className='filter-box'>
        <div className='filter'>
          <>
            <div className='filter-container'>
              <p style={{ cursor: 'pointer' }} onClick={handleClick}>
                Filter
              </p>
              <div className='navContainer' style={styles.navContainer}>
                <div className='date-filter'>
                  <div className='date-title'>Date</div>
                  <div className='date-options'>
                    <div className='custom-filter__item custom-filter__item--pad'>
                      <div className='overview-filter'>
                        <div className='filter__item'>
                          <input
                            id='today_fkc3w9c4e0'
                            value='Today'
                            type='radio'
                            name='dateFilter_fkc3w9c4e0'
                            className='overview-filter__item__radio'
                          />
                          <label
                            htmlFor='today_fkc3w9c4e0'
                            className='overview-filter__item__link active'
                          >
                            Today
                          </label>
                        </div>{' '}
                        <div className='filter__item'>
                          <input
                            id='last7_fkc3w9c4e0'
                            value='7 days'
                            type='radio'
                            name='dateFilter_fkc3w9c4e0'
                            className='overview-filter__item__radio'
                          />
                          <label
                            htmlFor='last7_fkc3w9c4e0'
                            className='overview-filter__item__link'
                          >
                            Last 7 days
                          </label>
                        </div>{' '}
                        <div className='filter__item'>
                          <input
                            id='last30_fkc3w9c4e0'
                            value='30 days'
                            type='radio'
                            name='dateFilter_fkc3w9c4e0'
                            className='overview-filter__item__radio'
                          />
                          <label
                            htmlFor='last30_fkc3w9c4e0'
                            className='overview-filter__item__link'
                          >
                            30 days
                          </label>
                        </div>{' '}
                        <div className='filter__item'>
                          <input
                            id='1year_fkc3w9c4e0'
                            value='1 year'
                            type='radio'
                            name='dateFilter_fkc3w9c4e0'
                            className='overview-filter__item__radio'
                          />
                          <label
                            htmlFor='1year_fkc3w9c4e0'
                            className='overview-filter__item__link'
                          >
                            1 year
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='date-content'>
                      <div className='date-items'>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='status-container'>
                  <div className='date-title'>Customer</div>
                  <div className='checkbox checkbox--flex'>
                    <div className='checkbox__item full-width '>
                      <input
                        type='email'
                        className='email-input checkbox-item-styled'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Enter customer Email'
                      />{' '}
                    </div>
                    <div className='checkbox__item full-width '>
                      <input
                        type='email'
                        className='email-input checkbox-item-styled'
                        value={tag}
                        onChange={(e) => setTag(e.target.value)}
                        placeholder='Enter customer Tag'
                      />{' '}
                    </div>
                    <div className='checkbox__item full-width '>
                      <input
                        type='email'
                        className='email-input checkbox-item-styled'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder='Enter customer Name'
                      />{' '}
                    </div>
                    <div className='checkbox__item full-width '>
                      <input
                        type='email'
                        className='email-input checkbox-item-styled'
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        placeholder='Enter customer Account No'
                      />{' '}
                    </div>
                  </div>
                </div>
                <div
                  className='status-container'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center'
                  }}
                >
                  <button className='button' onClick={handleClear}>
                    <p>Clear</p>
                  </button>
                  <button className='button' onClick={handleFilter}>
                    <p>Filter</p>
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
        <div className='filter'>
          <CSVLink data={data} headers={headers}>
            Export
          </CSVLink>
        </div>
      </div> */}
      <>
        <MDBTable
          responsive
          style={{
            marginTop: "20px"
          }}
        >
          <MDBTableHead>
            <tr className="font-reduce">
              <th scope="col">S/N</th>
              <th scope="col">Vault Name</th>
              <th scope="col">Customer Name</th>
              <th scope="col">PennTag</th>
              <th scope="col">Amount</th>
              <th scope="col">Duration</th>
              <th scope="col">Interest Rate</th>
              <th scope="col">Interest Amount</th>
              <th scope="col">Status</th>
              <th scope="col">Start Date</th>
              <th scope="col">Withdrawal Date</th>
              <th scope="col">Top up Amount</th>
              <th scope="col">Rollover Duration</th>
            </tr>
          </MDBTableHead>

          <MDBTableBody>
            {!investmentLoading &&
              investmentSuccess &&
              investmentExpired?.items?.map((inv, key) => (
                <tr className="font-reduce">
                  <td>{key + 1}</td>
                  <td>{inv?.investment_name}</td>
                  <td>{inv?.full_name}</td>
                  <td>{inv?.user_name}</td>
                  <td>{format(inv?.amount)}</td>
                  <td>{inv?.duration}</td>
                  <td>{inv?.interest_rate}</td>
                  <td>{format(inv?.interest_accrued)}</td>
                  <td>{inv?.status}</td>
                  <td>{moment(inv?.start_date).format("LLL")}</td>
                  <td>{moment(inv?.end_date).format("LLL")}</td>
                  <td>{inv?.topup_amount}</td>
                  <td>{inv?.roll_over_duration}</td>
                </tr>
              ))}
          </MDBTableBody>
        </MDBTable>
        {/* <Paginate
                  totPages={investments?.page?.total_page}
                  currentPage={String(investments?.page?.currentPage)}
                  page={String(investments?.page?.currentPage)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                /> */}
      </>
    </>
  )
}

export default PersonalVaultExpired

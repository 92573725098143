import React, { useEffect, useRef, useState } from 'react'
import SideNav from '../components/SideNav'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import moment from 'moment'

import CreateTreegarInvestment from '../components/CreateTreegarInvestment'
import {
  fetchTreegarInvestmentAssest,
  fetchTreegarInvestmentConstant,
  fetchTreegarInvestmentLiabilty
} from '../actions/adminActions'
import { useDispatch, useSelector } from 'react-redux'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import { format } from '../main'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

const TreegarScreen = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const treegarInvestmentConstant = useSelector(
    (state) => state.treegarInvestmentConstant
  )
  const { loading, error, success, constant } = treegarInvestmentConstant
  const treegarInvestmentGet = useSelector(
    (state) => state.treegarInvestmentGet
  )
  const { success: treegarInvestmentGetSuccess, investment } =
    treegarInvestmentGet
  const treegarInvestmentGetLiability = useSelector(
    (state) => state.treegarInvestmentGetLiability
  )
  const {
    success: treegarInvestmentGetLiabilitySuccess,
    investment: investment2
  } = treegarInvestmentGetLiability
  const ref = useRef(true)
  useEffect(() => {
    if (ref.current) {
      dispatch(fetchTreegarInvestmentConstant())
      dispatch(fetchTreegarInvestmentAssest())
      dispatch(fetchTreegarInvestmentLiabilty())
      ref.current = false
    }
  })
  return (
    <>
      <SideNav />
      <Helmet>
        <title> Treegar | PennyTree - Admin</title>
      </Helmet>
      <section className='page-container'>
        <div className='page-content'>
          <Header />
          <div className='page-body'>
            <div
              className='investment-constants'
              style={{
                marginTop: '10px',
                marginBottom: '19px'
              }}
            >
              {!loading &&
                success &&
                constant?.map((c, key) => (
                  <div key={key}>
                    <p
                      style={{
                        fontWeight: 'bold',
                        letterSpacing: '1.1px',
                        fontSize: '16px'
                      }}
                    >
                      {c.name}: <span>{c.value}%</span>
                    </p>
                  </div>
                ))}
            </div>
            <div
              className='status-container'
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginBottom: '20px'
              }}
            >
              <div className='button'>
                <FontAwesomeIcon />
                <Link to='/treegar/pending'> Pending Investment</Link>
              </div>
            </div>
            <button
              type='submit'
              className='login-btn'
              style={{ width: '30%', background: '#212227' }}
              onClick={(e) => setOpen(!open)}
            >
              {open ? 'Hide' : ' Create Investment'}
            </button>

            {open && <CreateTreegarInvestment constant={constant} />}

            <h3
              style={{
                letterSpacing: '1.2PX',
                fontStyle: 'italic',
                fontSize: '20px'
              }}
            >
              LIABILITIES
            </h3>
            <MDBTable
              responsive
              style={{ 'width': '100%', fontSize: '14px' }}
              align='middle'
            >
              <MDBTableHead>
                <tr className='font-reduce'>
                  <th scope='col'>Investment Id</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Customer Name</th>
                  <th scope='col'>Inflow</th>
                  <th scope='col'>Effective Date</th>
                  <th scope='col'>Tenor(days)</th>
                  <th scope='col'>Maturity Date</th>
                  <th scope='col'>Interest Expense</th>
                  <th scope='col'>Accrued Interest Expense</th>
                  <th scope='col'>Gross Interest Payout At Maturity</th>
                  <th scope='col'>WHT Deduction</th>
                  <th scope='col'>Charges</th>
                  <th scope='col'>Net Interest Payout At Maturity</th>
                  <th scope='col'>Withdrawable Amount At Maturity</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {treegarInvestmentGetSuccess &&
                  investment2?.items?.map((i, key) => (
                    <tr key={key} className='font-reduce'>
                      <td>{i?.investment_tag}</td>
                      <td>{i?.status}</td>
                      <td>{i?.customer_name}</td>
                      <td>{format(i?.inflow)}</td>
                      <td>{moment(i?.effective_date).format('LLL')}</td>
                      <td>{i?.tenor}days</td>
                      <td>{moment(i?.maturity_date).format('LLL')}</td>

                      <td>{i?.interest_expense}%</td>
                      <td>{format(i?.accrued_interest_expense)}</td>
                      <td>{format(i?.gross_interest_payout)}</td>
                      <td>{format(i?.wht_deduction)}</td>
                      <td>{format(i?.charges)}</td>
                      <td>{format(i?.net_interest_payout)}</td>
                      <td>{format(i?.withdrawal_amount)}</td>
                    </tr>
                  ))}
              </MDBTableBody>
            </MDBTable>

            <h3
              style={{
                letterSpacing: '1.2PX',
                fontStyle: 'italic',
                fontSize: '20px'
              }}
            >
              Asset
            </h3>
            <MDBTable
              responsive
              style={{ 'width': '100%', fontSize: '14px' }}
              align='middle'
            >
              <MDBTableHead>
                <tr className='font-reduce'>
                  <th scope='col'>Investment Id</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Customer Name</th>
                  <th scope='col'>Inflow</th>
                  <th scope='col'>Effective Date</th>
                  <th scope='col'>Tenor(days)</th>
                  <th scope='col'>Maturity Date</th>
                  <th scope='col'>Interest Expense</th>
                  <th scope='col'>Accrued Interest Expense</th>
                  <th scope='col'>Gross Interest Payout At Maturity</th>
                  <th scope='col'>WHT Deduction</th>
                  <th scope='col'>Charges</th>
                  <th scope='col'>Net Interest Payout At Maturity</th>
                  <th scope='col'>Withdrawable Amount At Maturity</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {treegarInvestmentGetSuccess &&
                  investment?.items?.map((i, key) => (
                    <tr key={key} className='font-reduce'>
                      <td>{i?.investment_tag}</td>
                      <td>{i?.status}</td>
                      <td>{i?.customer_name}</td>
                      <td>{format(i?.inflow)}</td>
                      <td>{moment(i?.effective_date).format('LLL')}</td>
                      <td>{i?.tenor}days</td>
                      <td>{moment(i?.maturity_date).format('LLL')}</td>
                      <td>{i?.interest_expense}%</td>
                      <td>{format(i?.accrued_interest_expense)}</td>
                      <td>{format(i?.gross_interest_payout)}</td>
                      <td>{format(i?.wht_deduction)}</td>
                      <td>{format(i?.charges)}</td>
                      <td>{format(i?.net_interest_payout)}</td>
                      <td>{format(i?.withdrawal_amount)}</td>
                    </tr>
                  ))}
              </MDBTableBody>
            </MDBTable>
          </div>
        </div>
      </section>
    </>
  )
}

export default TreegarScreen

import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { format, formatDate } from "../main"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Loader from "./Loader"
import { CSVLink } from "react-csv"
import {
  fetchBusinessMetrics,
  fetchTransactionMetrics
} from "../actions/dashboardActions"

const TransactionMetrics = () => {
  const metricsRef = useRef(true)
  const dispatch = useDispatch()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const transactionCount = useSelector((state) => state.transactionCount)
  const { loading, success, metrics, error } = transactionCount

  useEffect(() => {
    if (metricsRef.current) {
      dispatch(fetchTransactionMetrics())
      metricsRef.current = false
    }
    // if (
    //   (!loading && error?.message === 'Please login again') ||
    //   error?.message === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    // }
  }, [metrics, success, dispatch, error, loading])

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
    dispatch(fetchTransactionMetrics(filterData))
  }

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    dispatch(fetchTransactionMetrics())
  }
  return (
    <>
      <div className="filter-box">
        <div className="filter">
          <>
            <div className="filter-container">
              <p style={{ cursor: "pointer" }} onClick={handleClick}>
                Filter
              </p>
              <div className="navContainer" style={styles.navContainer}>
                <div className="date-filter">
                  <div className="date-title">Date</div>
                  <div className="date-options">
                    <div className="date-content">
                      <div className="date-items">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="status-container">
                  <div className="checkbox checkbox--flex"></div>
                </div>
                <div
                  className="status-container"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                  }}
                >
                  <button className="button" onClick={handleClear}>
                    <p>Clear</p>
                  </button>
                  <button className="button" onClick={handleFilter}>
                    <p>Filter</p>
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
        {/* <div className="filter">
          <CSVLink data={data} headers={headers}>
            Export
          </CSVLink>
        </div> */}
      </div>
      <div className="accordion-item">
        <h3 className="accordion-header" id="h1">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#transaction"
            aria-expanded="true"
            aria-controls="transaction"
          >
            Transaction Metrics
          </button>
        </h3>
        <div
          id="transaction"
          className="accordion-collapse collapse show"
          aria-labelledby="h1"
        >
          <div className="accordion-body">
            {loading ? (
              <Loader />
            ) : (
              <div className="row">
                <p className="error">{error && error?.message}</p>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.daily_pos_transaction_count}
                    </div>
                    <p>Today POS Transaction Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.weekly_pos_transaction_count}
                    </div>
                    <p>This Week POS Transaction Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.monthly_pos_transaction_count}
                    </div>
                    <p>This Month POS Transaction Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.total_pos_transaction_count}
                    </div>
                    <p>Total POS Transaction Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.daily_balance_funding_count}
                    </div>
                    <p>Today Balance Funding Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.weekly_balance_funding_count}
                    </div>
                    <p>This Week Balance Funding Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.monthly_balance_funding_count}
                    </div>
                    <p>This Month Balance Funding Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.total_balance_funding_count}
                    </div>
                    <p>Total Balance Funding Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.daily_p2p_sent_money_count}
                    </div>
                    <p>Today P2P sent Money Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.weekly_p2p_sent_money_count}
                    </div>
                    <p>This Week P2P sent Money Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.monthly_p2p_sent_money_count}
                    </div>
                    <p>This Month P2P sent Money Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.total_p2p_sent_money_count}
                    </div>
                    <p>Total P2P sent Money Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.daily_p2p_received_money_count}
                    </div>
                    <p>Today P2P Received Money Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.weekly_p2p_received_money_count}
                    </div>
                    <p>This Week P2P Received Money Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.monthly_p2p_received_money_count}
                    </div>
                    <p>This Month P2P Received Money Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.total_p2p_received_money_count}
                    </div>
                    <p>Total P2P Received Money Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : metrics?.daily_p2_b_count}
                    </div>
                    <p>Today P2B Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : metrics?.weekly_p2_b_count}
                    </div>
                    <p>This Week P2B Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : metrics?.monthly_p2_b_count}
                    </div>
                    <p>This Month P2B Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : metrics?.total_p2_b_count}
                    </div>
                    <p>Total P2B Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.daily_bills_payment_count}
                    </div>
                    <p>Today Bills Payment Count</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.weekly_bills_payment_count}
                    </div>
                    <p>This Week Bills Payment Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.monthly_bills_payment_count}
                    </div>
                    <p>This Month Bills Payment Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null
                        ? 0
                        : metrics?.total_bills_payment_count}
                    </div>
                    <p>Total Bills Payment Count</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : metrics?.active_target_savings}
                    </div>
                    <p>Active Target Savings</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : metrics?.completed_target_savings}
                    </div>
                    <p>Completed Target Savings</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : metrics?.cancelled_target_savings}
                    </div>
                    <p>Cancelled Target Savings</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : metrics?.closed_target_savings}
                    </div>
                    <p>Closed Target Savings</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : metrics?.active_vaults}
                    </div>
                    <p>Active Vaults</p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {metrics === null ? 0 : metrics?.in_active_vaults}
                    </div>
                    <p>InActive Vaults</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TransactionMetrics

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import {
  admin2faLoginReducer,
  adminLoginReducer,
  allCustomersReducer,
  allInvestmentsReducer,
  customersDetailsReducer,
  createInvestmentReducer,
  failedDebitsReducer,
  getTransactionsReducer,
  investorsCountReducer,
  lockedFundsReducer,
  resendOtpReducer,
  singleUserReducer,
  transferTotalReducer,
  userCountReducer,
  customersInvestmentsReducer,
  totalCommissionReducer,
  totalBillsReducer,
  businessMetricsReducer,
  pendingWithdrawalReducer,
  getOfficersReducer,
  getAccountOfficerDetailsReducer,
  getTeamMembersReducer,
  getRolesReducer,
  getDepartmentsReducer,
  getPermissionsReducer,
  createMemberReducer,
  assignOfficerReducer,
  deassignOfficerReducer,
  verifyUserReducer,
  transactionStatusReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  approveRejectWithdrawaldReducer,
  pendingBusinessReducer,
  totalInterestReducer,
  businessDocumentReducer,
  approveBusinessReducer,
  productTypesReducer,
  headerCustomersReducer,
  viewDocumentReducer,
  addMemosReducer,
  getMemosReducer,
  rolesPermissionReducer,
  merchantBalancesReducer,
  allBalancesReducer,
  deleteInvestmentReducer,
  userStatusReducer,
  userRoleReducer,
  getCustomerTransactionsReducer,
  getSingleOfficerDetailsReducer,
  getSingleOfficerInfoReducer,
  customerLimitReducer,
  customerAutomationReducer,
  getcustomerLimitReducer,
  getTargetSavingsOfficerInfoReducer,
  getSingleTargetSavingsOfficerInfoReducer,
  failedWithdrawalReducer,
  allPayoutsReducer,
  getAllTargetSavingsReducer,
  targetSavingsMetricsReducer,
  getAllBillsReducer,
  getTransactionSettingsReducer,
  enableDisableTransactionReducer,
  getProvidersReducer,
  enableDisableProviderReducer,
  targetSavingsLogsReducer,
  investmentRatesReducer,
  updateInvestmentRateReducer,
  deactivateCustomerReducer,
  posMetricsReducer,
  customerCardsReducer,
  deleteCustomerCardReducer,
  allTargetSavingsLogsReducer,
  customersInvestmentsExpiredReducer,
  interestInfoReducer,
  totalinterestReducer,
  transactionCountReducer,
  treegarInvestmentReducer,
  treegarInvestmentGetReducer,
  treegarInvestmentConstantReducer,
  treegarInvestmentGetLiabilityReducer,
  treegarPendingInvestmentReducer,
  approveRejectInvestmentdReducer,
  getTerminalsReducer,
  getOverdraftRequestReducer,
  approveRejectOverdraftReducer,
  updateOverdraftLimitReducer,
  getOverdraftScheduleReducer,
  getFailedVerificationsReducer,
  totalCommissionBreakdownReducer,
  customerTargetSavingsReducer,
  balanceMetricsReducer,
  updateRatesReducer,
  getRatesReducer,
  getTotalBalanceReducer,
  getBalanceHistoryReducer,
  transactionsExportReducer,
  fetchBusinessReducer,
  customeridReducer,
  fetchBanksReducer,
  assignTerminalReducer,
  addTerminalReducer,
  unassignedTerminalsReducer,
  getSeerbitBalanceReducer,
  getVfdBalanceReducer,
  getPayazaBalanceReducer,
  getPaystackBalanceReducer,
  getFlwBalanceReducer,
  getCustomerBusinessReducer,
  getBusinessWalletReducer,
} from "./reducers/adminReducers";

const reducer = combineReducers({
  adminLogin: adminLoginReducer,
  admin2faLogin: admin2faLoginReducer,
  resendOtp: resendOtpReducer,
  userCount: userCountReducer,
  investorsCount: investorsCountReducer,
  lockedFunds: lockedFundsReducer,
  transferTotal: transferTotalReducer,
  getTransactions: getTransactionsReducer,
  allCustomers: allCustomersReducer,
  customerDetails: customersDetailsReducer,
  allInvestments: allInvestmentsReducer,
  createInvestment: createInvestmentReducer,
  customerInvestments: customersInvestmentsReducer,
  customerInvestmentsExpired: customersInvestmentsExpiredReducer,
  totalCommsion: totalCommissionReducer,
  totalBills: totalBillsReducer,
  businessMetrics: businessMetricsReducer,
  pendingWithdrawal: pendingWithdrawalReducer,
  getOfficers: getOfficersReducer,
  getAccountOfficerDetails: getAccountOfficerDetailsReducer,
  failedDebits: failedDebitsReducer,
  singleUser: singleUserReducer,
  teamMembers: getTeamMembersReducer,
  getRoles: getRolesReducer,
  getDepartments: getDepartmentsReducer,
  getPermissions: getPermissionsReducer,
  createMember: createMemberReducer,
  assignedOfficer: assignOfficerReducer,
  deassignedOfficer: deassignOfficerReducer,
  verifyUser: verifyUserReducer,
  transactionStatus: transactionStatusReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  approveRejectWithdrawal: approveRejectWithdrawaldReducer,
  pendingBusiness: pendingBusinessReducer,
  totalInterest: totalInterestReducer,
  businessDocument: businessDocumentReducer,
  approveBusiness: approveBusinessReducer,
  productTypes: productTypesReducer,
  headerCustomers: headerCustomersReducer,
  viewDocument: viewDocumentReducer,
  addMemos: addMemosReducer,
  getMemos: getMemosReducer,
  rolesPermission: rolesPermissionReducer,
  allBalances: allBalancesReducer,
  merchantBalances: merchantBalancesReducer,
  deleteInvestment: deleteInvestmentReducer,
  userStatus: userStatusReducer,
  userRole: userRoleReducer,
  customerTransactions: getCustomerTransactionsReducer,
  singleOfficer: getSingleOfficerDetailsReducer,
  singleOfficerInfo: getSingleOfficerInfoReducer,
  customerLimit: customerLimitReducer,
  customerAutomation: customerAutomationReducer,
  getCustomerLimit: getcustomerLimitReducer,
  getTargetSavingsOfficer: getTargetSavingsOfficerInfoReducer,
  getSingleTargetSavingsOfficerInfo: getSingleTargetSavingsOfficerInfoReducer,
  failedWithdrawal: failedWithdrawalReducer,
  allPayouts: allPayoutsReducer,
  allTargetSavings: getAllTargetSavingsReducer,
  targetSavingsMetrics: targetSavingsMetricsReducer,
  getAllBills: getAllBillsReducer,
  getTransactionSettings: getTransactionSettingsReducer,
  enableDisableTransaction: enableDisableTransactionReducer,
  getProviders: getProvidersReducer,
  enableDisableProvider: enableDisableProviderReducer,
  targetSavingsLog: targetSavingsLogsReducer,
  investmentRates: investmentRatesReducer,
  updateInvestmentRate: updateInvestmentRateReducer,
  deactivateCustomer: deactivateCustomerReducer,
  posMetrics: posMetricsReducer,
  customerCard: customerCardsReducer,
  deleteCustomerCard: deleteCustomerCardReducer,
  allTargetSavingsLogs: allTargetSavingsLogsReducer,
  interestInfo: interestInfoReducer,
  totalInterestExpense: totalinterestReducer,
  transactionCount: transactionCountReducer,
  treegarInvestmentCreate: treegarInvestmentReducer,
  treegarInvestmentGet: treegarInvestmentGetReducer,
  treegarInvestmentGetLiability: treegarInvestmentGetLiabilityReducer,
  treegarInvestmentConstant: treegarInvestmentConstantReducer,
  treegarPendingInvestment: treegarPendingInvestmentReducer,
  approveRejectInvestment: approveRejectInvestmentdReducer,
  getTerminals: getTerminalsReducer,
  getOverdraftRequest: getOverdraftRequestReducer,
  approveRejectOverdraft: approveRejectOverdraftReducer,
  updateOverdraftLimit: updateOverdraftLimitReducer,
  getOverdraftSchedules: getOverdraftScheduleReducer,
  getFailedVerfications: getFailedVerificationsReducer,
  totalCommisionBreakdown: totalCommissionBreakdownReducer,
  customerTargetSavings: customerTargetSavingsReducer,
  balanceMetrics: balanceMetricsReducer,
  getRates: getRatesReducer,
  updateRates: updateRatesReducer,
  getTotalBalance: getTotalBalanceReducer,
  balanceHistory: getBalanceHistoryReducer,
  transactionsExport: transactionsExportReducer,
  fetchBusiness: fetchBusinessReducer,
  getCustomerid: customeridReducer,
  fetchBanks: fetchBanksReducer,
  assignTerminal: assignTerminalReducer,
  addTerminal: addTerminalReducer,
  unassignedTerminals: unassignedTerminalsReducer,
  getSeerbitBalance: getSeerbitBalanceReducer,
  getVfdBalance: getVfdBalanceReducer,
  getPayazaBalance: getPayazaBalanceReducer,
  getPaystackBalance: getPaystackBalanceReducer,
  getFlwBalance: getFlwBalanceReducer,
  getCustomerBusiness: getCustomerBusinessReducer,
  getBusinessWallet: getBusinessWalletReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["admin2faLogin"],
};

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  adminLogin: {},
  admin2faLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// const store = createStore(
//   reducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// )

export default store;

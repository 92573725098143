import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { fetchCustomers, logout } from "../actions/userActions"
import { FETCH_CUSTOMERS_RESET } from "../constants/adminConstants"
import Loader from "./Loader"

const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { loading, userInfo } = admin2faLogin

  const headerCustomers = useSelector((state) => state.headerCustomers)
  const { loading: customersLoading, success, customers } = headerCustomers

  const logoutHandler = () => {
    dispatch(logout())
    window.location.reload()
    navigate("/", { replace: true })
  }

  useEffect(() => {
    if (!userInfo || userInfo === null) {
      navigate("/", { replace: true })
    }
  })

  // setTimeout(() => {
  //   dispatch({ type: FETCH_CUSTOMERS_RESET })
  // }, 4000)

  const handleSearch = (filter) => {
    if (filter === "") {
      dispatch({ type: FETCH_CUSTOMERS_RESET })
    }
    dispatch(fetchCustomers(filter))
  }

  return (
    <div>
      <div className="admin-header">
        <div className="menu-icon">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <br />

        <div className="profile">
          Hi, {userInfo?.user?.first_name ?? ""}{" "}
          {userInfo?.user?.last_name ?? ""}
          <img src="assets/images/admin-icon.png" alt="Admin Icon" />
          <ul className="profile-dropdown">
            <li>
              <a href="/admin" style={{ cursor: "pointer" }}>
                <i className="fa fa-user"></i> My Profile{" "}
              </a>
            </li>

            <li>
              <a href="/forgot-password" style={{ cursor: "pointer" }}>
                <i className="fa fa-user"></i> Reset Password
              </a>
            </li>

            <li onClick={logoutHandler} style={{ cursor: "pointer" }}>
              <i className="fa fa-sign-out-alt"></i> Logout
            </li>
          </ul>
        </div>
      </div>

      {userInfo?.user?.role !== ("Treegar" || "Account Officer") && (
        <div>
          <input
            className="header-input"
            type="text"
            placeholder="Input Penn Tag or Merchant Tag or Account Number.."
            onChange={(e) => handleSearch(e.target.value)}
          />
          {loading ? (
            <Loader />
          ) : success ? (
            <div className="placeholder-dropdown">
              {customersLoading && <Loader />}
              {customers?.items.map((item) => (
                <p>
                  {" "}
                  <a href={`/${item?.id}`}>
                    {item?.customer_name} - {item?.user_name}{" "}
                  </a>
                </p>
              ))}
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default Header

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchSingleDocument } from '../actions/merchantActions'

const ViewDocScreen = () => {
  const dispatch = useDispatch()
  const { field, fileaccess } = useParams()

  useEffect(() => {
    dispatch(fetchSingleDocument(field, fileaccess))
  })
  return (
    <div>
      {' '}
      <img
        src={`https://api.mypennytree.com/api/v2-pilot/admin/customer/documents/verification-documents/download?fileId=${field}&fileaccess=${fileaccess}`}
        alt='Doc'
        width={100}
        height={100}
      />
    </div>
  )
}

export default ViewDocScreen

import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchBusinessMetrics,
  fetchTargetSavingsMetrics
} from "../actions/dashboardActions"
import Loader from "./Loader"
import { format } from "../main"

const TotalBalance = () => {
  const metricsRef = useRef(true)
  const dispatch = useDispatch()
  const [totalAmount, setTotalAmount] = useState(0)
  const targetSavingsMetrics = useSelector(
    (state) => state.targetSavingsMetrics
  )
  const { loading, success, metrics, error } = targetSavingsMetrics

  const businessMetrics = useSelector((state) => state.businessMetrics)
  const {
    loading: metricsLoading,
    success: metricsSuccess,
    metrics: business,
    error: metricsError
  } = businessMetrics
  console.log(totalAmount)
  //   console.log(business)
  useEffect(() => {
    if (metricsRef.current) {
      dispatch(fetchTargetSavingsMetrics())
      dispatch(fetchBusinessMetrics())
      metricsRef.current = false
    }
    if (metrics !== null) {
      setTotalAmount(
        metrics?.active_target_savings_amount +
          business?.individual_balance +
          business?.personal_vault +
          business?.merchant_balance
      )
    }
  }, [metrics, success, dispatch, error, loading, business])
  return (
    <>
      <div className="accordion-item">
        <h3 className="accordion-header" id="h1">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#business"
            aria-expanded="true"
            aria-controls="business"
          >
            Total Active Balance
          </button>
        </h3>
        <div
          id="business"
          className="accordion-collapse collapse show"
          aria-labelledby="h1"
        >
          <div className="accordion-body">
            {loading || metricsLoading ? (
              <Loader />
            ) : (
              <div className="row">
                <p className="error">{error && error}</p>

                <div className="col-lg-12">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div
                      className="info-box-count"
                      style={{ fontSize: "28px" }}
                    >
                      <p>{metrics === null ? 0 : format(totalAmount)}</p>
                    </div>
                    <p>Total Active Balance</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TotalBalance

import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import {
  fetchAllExportedTransactions,
  fetchProductTypes
} from "../actions/transactionActions"
import Header from "../components/Header"
import Loader from "../components/Loader"

import SideNav from "../components/SideNav"
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit"
import { CSVLink } from "react-csv"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { dateCheck, format, formatDate } from "../main"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const TransactionExportScreen = () => {
  const navigate = useNavigate()

  const [currPage, setCurrPage] = useState(1)
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [errorMsg, setErrorMsg] = useState("")
  // FILTER states starts
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())

  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const [customer, setCustomer] = useState(false)
  const [merchant, setMerchant] = useState(false)
  const [productId, setProductId] = useState("")
  const [internalProviderId, setInternalProviderId] = useState("")

  // filter states emd
  const dispatch = useDispatch()
  const transRef = useRef(true)

  const transactionsExport = useSelector((state) => state.transactionsExport)
  const { loading, success, error, transactions } = transactionsExport

  const transactionStatus = useSelector((state) => state.transactionStatus)
  const { status } = transactionStatus

  const productTypes = useSelector((state) => state.productTypes)
  const { success: productTypesSucess, products } = productTypes

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  useEffect(() => {
    if (transRef.current) {
      const filterData = {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        ismerchant: customer ? 1 : merchant ? 2 : null
      }
      //   dispatch(fetchAllExportedTransactions(filterData))
      dispatch(fetchProductTypes())
      transRef.current = false
    }

    if (success && transactions) {
      setData(
        transactions?.items?.map((tran) => ({
          product: tran?.product,
          transactionAmount: tran?.transaction_amount,
          fee: tran?.fee,
          foreign_amount: tran?.foreign_amount,
          transaction_reference: tran?.transaction_reference,
          provider_reference: tran?.provider_reference,
          processor_fee: tran?.processor_fee,
          payment_reference: tran?.payment_reference,
          remarks: tran?.remarks,
          date_created: tran?.date_created,
          status: tran?.status,
          batch_reference: tran?.batch_reference,
          customer_email: tran?.customer_email,
          currency: tran?.currency,
          foreign_currency: tran?.foreign_currency,
          wallet_name: tran?.wallet_name,
          direction: tran?.direction,
          customer_full_name: tran?.customer_full_name,
          user_name: tran?.username,
          balance_before:
            tran?.status === "Initiated"
              ? 0.0
              : tran?.direction === "Credit" && tran?.status === "Successful"
              ? tran?.balance_after - tran?.transaction_amount
              : tran?.balance_after + tran?.transaction_amount,

          balance_after:
            tran?.status === "Initiated" ? 0.0 : tran?.balance_after - tran?.fee
        }))
      )

      setHeaders([
        { label: "Product", key: "product" },
        { label: "Transaction Amount", key: "transactionAmount" },
        { label: "Fee", key: "fee" },
        { label: "ForeignAmount", key: "foreign_amount" },
        { label: "Transaction Reference", key: "transaction_reference" },
        { label: "Provider Reference", key: "provider_reference" },
        { label: "Processor Fee", key: "processor_fee" },
        { label: "Payment Reference", key: "payment_reference" },
        { label: "Remarks", key: "remarks" },
        { label: "Date Created", key: "date_created" },
        { label: "Status", key: "status" },
        { label: "Batch Reference", key: "batch_reference" },
        { label: "Customer Email", key: "customer_email" },
        { label: "Currency", key: "currency" },
        { label: "Foreign Currency", key: "foreign_currency" },
        { label: "Wallet Name", key: "wallet_name" },
        { label: "Direction", key: "direction" },
        { label: "Customer Full Name", key: "customer_full_name" },
        { label: "UserName", key: "user_name" },
        { label: "Balance Before", key: "balance_before" },
        { label: "Balance After", key: "balance_after" }
      ])
    }
  }, [
    dispatch,
    userInfo,
    navigate,
    customer,
    merchant,

    transactions,
    success,

    error,
    loading,

    endDate,

    startDate
  ])

  // filter data

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    console.log(productId, internalProviderId)
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      ismerchant: customer ? 1 : merchant ? 2 : null,
      internalProviderId: productId === "1" ? internalProviderId : null,
      productId,
      exported: true
    }

    console.log(filterData)

    // if (filterData.ismerchant === null) {
    //   setErrorMsg("Please choose a customer Type ( Merchant || Customer )")
    //   setTimeout(() => {
    //     setErrorMsg("")
    //   }, 3000)
    // }

    if (
      // filterData.ismerchant &&
      dateCheck(filterData.startDate, filterData.endDate)
    ) {
      dispatch(fetchAllExportedTransactions(filterData))
    }
    // else if (
    //   !filterData.ismerchant &&
    //   dateCheck(filterData.startDate, filterData.endDate)
    // ) {
    //   setErrorMsg("Please choose a customer Type ( Merchant || Customer )")
    //   setTimeout(() => {
    //     setErrorMsg("")
    //   }, 3000)
    // }
    else {
      setErrorMsg("Difference in date can not be greater than one day")
    }
  }

  const handleClear = () => {
    setCustomer(false)
    setMerchant(false)
    setStyles({
      navContainer: {
        display: "none"
      }
    })

    dispatch(fetchAllExportedTransactions())
  }

  const handleExport = (e, done) => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })

    if (success && transactions) {
      setData(
        transactions?.items?.map((tran) => ({
          product: tran?.product,
          transactionAmount: tran?.transaction_amount,
          fee: tran?.fee,
          foreign_amount: tran?.foreign_amount,
          transaction_reference: tran?.transaction_reference,
          provider_reference: tran?.provider_reference,
          processor_fee: tran?.processor_fee,
          payment_reference: tran?.payment_reference,
          remarks: tran?.remarks,
          date_created: tran?.date_created,
          status: tran?.status,
          batch_reference: tran?.batch_reference,
          customer_email: tran?.customer_email,
          currency: tran?.currency,
          foreign_currency: tran?.foreign_currency,
          wallet_name: tran?.wallet_name,
          direction: tran?.direction,
          customer_full_name: tran?.customer_full_name,
          user_name: tran?.username,
          balance_before:
            tran?.status === "Initiated"
              ? 0.0
              : tran?.direction === "Credit" && tran?.status === "Successful"
              ? tran?.balance_after - tran?.transaction_amount
              : tran?.balance_after + tran?.transaction_amount,

          balance_after:
            tran?.status === "Initiated" ? 0.0 : tran?.balance_after - tran?.fee
        }))
      )

      setHeaders([
        { label: "Product", key: "product" },
        { label: "Transaction Amount", key: "transactionAmount" },
        { label: "Fee", key: "fee" },
        { label: "ForeignAmount", key: "foreign_amount" },
        { label: "Transaction Reference", key: "transaction_reference" },
        { label: "Provider Reference", key: "provider_reference" },
        { label: "Processor Fee", key: "processor_fee" },
        { label: "Payment Reference", key: "payment_reference" },
        { label: "Remarks", key: "remarks" },
        { label: "Date Created", key: "date_created" },
        { label: "Status", key: "status" },
        { label: "Batch Reference", key: "batch_reference" },
        { label: "Customer Email", key: "customer_email" },
        { label: "Currency", key: "currency" },
        { label: "Foreign Currency", key: "foreign_currency" },
        { label: "Wallet Name", key: "wallet_name" },
        { label: "Direction", key: "direction" },
        { label: "Customer Full Name", key: "customer_full_name" },
        { label: "UserName", key: "user_name" },
        { label: "Balance Before", key: "balance_before" },
        { label: "Balance After", key: "balance_after" }
      ])
      done(true)
    } else {
      done(false)
    }
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Transactions | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Transactions Export</h1>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "red",
                fontSize: "20px",
                letterSpacing: "1.5px"
              }}
            >
              {errorMsg}
            </p>

            <div className="filter-box">
              <div className="filter">
                <>
                  <div className="filter-container">
                    <p style={{ cursor: "pointer" }} onClick={handleClick}>
                      Filter
                    </p>
                    <div className="navContainer" style={styles.navContainer}>
                      <div className="date-filter">
                        <div className="date-title">Date</div>
                        <div className="date-options">
                          <div className="date-content">
                            <div className="date-items">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                              />

                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="status-container"></div>

                      <div className="date-title">
                        Customer Type (Leave Unchecked to select both Types)
                      </div>
                      <div className="checkbox checkbox--flex">
                        <div
                          className="checkbox__item"
                          style={{
                            display: merchant ? "none" : "block"
                          }}
                        >
                          <input
                            id="cust_wz3gl9i2d"
                            type="checkbox"
                            name="cust_wz3gl9i2d"
                            className="checkbox__input"
                            value="successful"
                            checked={customer}
                            onChange={() => setCustomer(!customer)}
                          />{" "}
                          <label
                            htmlFor="cust_wz3gl9i2d"
                            className="checkbox__label"
                          >
                            Customer
                          </label>
                        </div>
                        <div
                          className="checkbox__item"
                          style={{
                            display: customer ? "none" : "block"
                          }}
                        >
                          <input
                            id="mer_wz3gl9i2d"
                            type="checkbox"
                            name="mer_wz3gl9i2d"
                            className="checkbox__input"
                            checked={merchant}
                            onChange={() => setMerchant(!merchant)}
                          />{" "}
                          <label
                            htmlFor="mer_wz3gl9i2d"
                            className="checkbox__label"
                          >
                            Merchant
                          </label>
                        </div>
                      </div>

                      <div className="date-title">Product Type</div>
                      <div className="checkbox checkbox--flex">
                        <div className="checkbox__item">
                          <div className="form-group">
                            <select
                              style={{
                                background: "#f4f6f8",
                                color: "#1a1a1a",
                                border: "none",
                                padding: "20px",
                                width: "380px",
                                borderRadius: "10px"
                              }}
                              onChange={(e) => setProductId(e.target.value)}
                            >
                              <option>Select Product</option>

                              {productTypesSucess &&
                                products &&
                                products?.data?.map((d) => (
                                  <option value={d?.id}>{d?.name}</option>
                                ))}
                            </select>
                          </div>

                          {productId === "1" ? (
                            <div className="form-group">
                              <select
                                style={{
                                  background: "#f4f6f8",
                                  color: "#1a1a1a",
                                  border: "none",
                                  padding: "20px",
                                  width: "380px",
                                  borderRadius: "10px"
                                }}
                                onChange={(e) =>
                                  setInternalProviderId(e.target.value)
                                }
                              >
                                <option value="1,2,3">
                                  Select Funding Type
                                </option>

                                <option value="5">Card Funding</option>
                                <option value="6">Providus Funding</option>
                                <option value="3">VFD Funding</option>
                              </select>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div
                        className="status-container"
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center"
                        }}
                      >
                        <button className="button" onClick={handleClear}>
                          <p>Clear</p>
                        </button>
                        <button className="button" onClick={handleFilter}>
                          <p>Filter</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
              <div className="filter">
                <CSVLink
                  data={data}
                  headers={headers}
                  filename={"transaction.csv"}
                  asyncOnClick={true}
                  onClick={handleExport}
                >
                  Export
                </CSVLink>
              </div>
            </div>
            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                  align="middle"
                >
                  <MDBTableHead>
                    <tr className="font-reduce">
                      <th scope="col">S/N</th>
                      <th scope="col">Product</th>
                      <th scope="col">Transaction Amount</th>
                      <th scope="col">Direction</th>
                      <th scope="col">Status</th>
                      <th scope="col">Fee</th>
                      <th scope="col">Recipient</th>
                      <th scope="col">Transaction Reference</th>
                      <th scope="col">Payment Reference</th>
                      <th scope="col">Provider Reference</th>
                      <th scope="col">Processor Fee</th>
                      <th scope="col">Flutterwave Reference</th>
                      <th scope="col">Payaza Reference</th>
                      <th scope="col">Customer Full Name</th>
                      <th scope="col">User Tag</th>
                      <th scope="col">Account number</th>
                      <th scope="col">Is Merchant</th>
                      <th scope="col">Remarks</th>
                      <th scope="col">Date Created</th>
                      <th scope="col">Batch Reference</th>
                      <th scope="col">Customer Email</th>
                      <th scope="col">Balance Before</th>
                      <th scope="col">Balance After</th>
                      <th scope="col">Merchant Tag</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      transactions?.items?.length > 0 &&
                      transactions?.items?.map((tran, key) => (
                        <tr key={key} className="font-reduce">
                          <td>{key + 1 + transactions?.page?.total_skipped}</td>
                          <td style={{ "width": "100%" }}>{tran?.product}</td>
                          <td>{format(tran?.transaction_amount)}</td>
                          <td>{tran?.direction}</td>

                          <td>{tran?.status}</td>

                          <td>{tran?.fee}</td>
                          <td>
                            {tran?.recipient === "0" ? "---" : tran?.recipient}
                          </td>
                          <td>
                            <p className="fw-normal mb-1">
                              {" "}
                              {tran?.transaction_reference}
                            </p>
                          </td>
                          <td>{tran?.payment_reference}</td>
                          <td>{tran?.provider_reference}</td>
                          <td>{format(tran?.processor_fee)}</td>
                          <td>{tran?.flutterwave_reference}</td>
                          <td>{tran?.payaza_reference}</td>
                          <td>
                            {" "}
                            <a
                              href={`/${tran?.customer_id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {tran?.customer_full_name}
                            </a>
                          </td>
                          <td>
                            {" "}
                            <a
                              href={`/${tran?.customer_id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {tran?.username}
                            </a>
                          </td>
                          <td>{tran?.account_number}</td>
                          <td>{tran?.is_merchant ? "YES" : "NO"}</td>
                          <td>{tran?.remarks?.substring(0, 15)}</td>
                          <td>{moment(tran?.date_created).format("LLL")}</td>
                          <td>{tran?.batch_reference}</td>
                          <td>{tran?.customer_email}</td>
                          <td>
                            {format(
                              tran?.status === "Initiated"
                                ? 0.0
                                : tran?.direction === "Credit" &&
                                  tran?.status === "Successful"
                                ? tran?.balance_after - tran?.transaction_amount
                                : tran?.balance_after + tran?.transaction_amount
                            )}
                          </td>
                          <td>
                            {format(
                              tran?.status === "Initiated"
                                ? 0.0
                                : tran?.balance_after - tran?.fee
                            )}
                          </td>
                          <td>{tran?.is_merchant ? "true" : "false"}</td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default TransactionExportScreen

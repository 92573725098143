import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { fetchCustomerLimit, setCustomerLimit } from '../actions/adminActions'
import Header from '../components/Header'
import Loader from '../components/Loader'
import SideNav from '../components/SideNav'
import { SET_CUSTOMER_LIMIT_FAIL } from '../constants/adminConstants'
import { format, formatDate } from '../main'

const CustomerLimitScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [customer_name, setCustomerName] = useState('')
  const [single_transaction_limit, setSingleTransactionLimit] = useState(0)
  const [daily_transaction_limit, setDailyTransactionLimit] = useState(0)
  const [monthly_transaction_limit, setMonthlyTransactionLimit] = useState(0)
  const [daily_transaction_frequency, setDailyTransactionFrequency] =
    useState(0)
  const [monthly_transaction_frequency, setMonthlyTransactionFrequency] =
    useState(0)
  const [
    single_airtime_and_bill_payment_limit,
    setSingleAirtimeAndBillPaymentLimit
  ] = useState(0)
  const [daily_airtime_and_bill_payment, setDailyAirtimeAndBillPayment] =
    useState(0)

  const customerLimit = useSelector((state) => state.customerLimit)
  const { loading, success, error, limit } = customerLimit

  const getCustomerLimit = useSelector((state) => state.getCustomerLimit)
  const {
    loading: limitLoading,
    success: limitSuccess,
    error: errorLimit,
    customLimit
  } = getCustomerLimit

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const submitHandler = (e) => {
    e.preventDefault()
    const reqData = {
      customer_name,
      single_transaction_limit: Number(single_transaction_limit),
      daily_transaction_limit: Number(daily_transaction_limit),
      monthly_transaction_limit: Number(monthly_transaction_limit),
      daily_transaction_frequency: Number(daily_transaction_frequency),
      monthly_transaction_frequency: Number(monthly_transaction_frequency),
      single_airtime_and_bill_payment_limit: Number(
        single_airtime_and_bill_payment_limit
      ),
      daily_airtime_and_bill_payment: Number(daily_airtime_and_bill_payment)
    }

    console.log(reqData)

    dispatch(setCustomerLimit(reqData))
  }

  useEffect(() => {
    if (userInfo?.user?.role !== 'Admin') {
      navigate('/performance', { replace: true })
    }
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    // if (!userInfo || userInfo === null) {
    //   navigate('/', { replace: true })
    // }
  }, [customer_name, userInfo, navigate, dispatch, error, loading])

  // const setCustomer = (e) => {
  //   e.preventDefault()
  //   setCustomerName(e.target.value)

  //   dispatch(fetchCustomerLimit(customer_name))
  // }

  return (
    <>
      <SideNav />
      <Helmet>
        <title>Limit Creation | PennyTree - Admin</title>
      </Helmet>
      <section className='page-container'>
        <div className='page-content'>
          <Header />
          <div className='page-body'>
            <h1>Create Or Update Customer Limit</h1>
            <div
              className='status-container'
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              <div className='button'>
                <FontAwesomeIcon />
                <Link to='/automation'> Automate Customer</Link>
              </div>
            </div>
            {/* <div className='page-flexed'> */}
            <div>
              <div className='investment-form'>
                <form action='' method='post' onSubmit={submitHandler}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'red',
                      fontSize: '20px',
                      letterSpacing: '1.5px',
                      marginBottom: '10px'
                    }}
                  >
                    {error && error}
                  </p>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'green',
                      fontSize: '20px',
                      letterSpacing: '1.5px'
                    }}
                  >
                    {success && limit?.message}
                  </p>
                  <div className='form-group'>
                    <label htmlFor='email' style={{ color: '#ffffff' }}>
                      Customer Tag:
                    </label>

                    <input
                      className='invest-input'
                      type='text'
                      placeholder='Input Penn Tag or Merchant Tag or Account Number..'
                      value={customer_name}
                      onChange={(e) => setCustomerName(e.target.value)}
                      min={0}
                      max={9}
                    />
                    <br />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='email' style={{ color: '#ffffff' }}>
                      Single Transaction Limit:
                    </label>
                    <input
                      className='invest-input'
                      type='number'
                      name='investment name'
                      value={single_transaction_limit}
                      onChange={(e) =>
                        setSingleTransactionLimit(e.target.value)
                      }
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='email' style={{ color: '#ffffff' }}>
                      Daily Transaction Limit::
                    </label>
                    <input
                      className='invest-input'
                      type='number'
                      name='investment name'
                      value={daily_transaction_limit}
                      onChange={(e) => setDailyTransactionLimit(e.target.value)}
                      placeholder='Input PennTag or Merchant Tag'
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='email' style={{ color: '#ffffff' }}>
                      Monthly Transaction Limit:
                    </label>
                    <input
                      className='invest-input'
                      type='number'
                      name='investment name'
                      value={monthly_transaction_limit}
                      onChange={(e) =>
                        setMonthlyTransactionLimit(e.target.value)
                      }
                      placeholder='Input PennTag or Merchant Tag'
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='email' style={{ color: '#ffffff' }}>
                      Daily Transaction Frequency
                    </label>
                    <input
                      className='invest-input'
                      type='number'
                      name='investment name'
                      value={daily_transaction_frequency}
                      onChange={(e) =>
                        setDailyTransactionFrequency(e.target.value)
                      }
                      placeholder='Input PennTag or Merchant Tag'
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='email' style={{ color: '#ffffff' }}>
                      Monthly Transaction Frequency:
                    </label>
                    <input
                      className='invest-input'
                      type='number'
                      name='investment name'
                      value={monthly_transaction_frequency}
                      onChange={(e) =>
                        setMonthlyTransactionFrequency(e.target.value)
                      }
                      placeholder='Input PennTag or Merchant Tag'
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='email' style={{ color: '#ffffff' }}>
                      Single Airtime and Bill Payment Limit:
                    </label>
                    <input
                      className='invest-input'
                      type='number'
                      name='investment name'
                      value={single_airtime_and_bill_payment_limit}
                      onChange={(e) =>
                        setSingleAirtimeAndBillPaymentLimit(e.target.value)
                      }
                      placeholder='Input PennTag or Merchant Tag'
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='email' style={{ color: '#ffffff' }}>
                      Daily Airtime and Bill Payment:
                    </label>
                    <input
                      className='invest-input'
                      type='number'
                      name='investment name'
                      value={daily_airtime_and_bill_payment}
                      onChange={(e) =>
                        setDailyAirtimeAndBillPayment(e.target.value)
                      }
                      placeholder='Input PennTag or Merchant Tag'
                    />
                  </div>

                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <button type='submit' className='login-btn'>
                        Publish
                      </button>
                    </>
                  )}
                </form>
              </div>
            </div>
            {/* <div className='limit-side'>
                {limitLoading ? (
                  <Loader />
                ) : limitSuccess && customLimit?.data?.items ? (
                  <div>
                    <h3>{customLimit?.data?.items[0]?.full_name}</h3>
                    {customLimit?.data?.items[0]?.business_name !== '' && (
                      <h6>{customLimit?.data?.items[0]?.business_name}</h6>
                    )}
                    <p>
                      Single Transaction Limit:{' '}
                      <span>
                        {format(
                          customLimit?.data?.items[0]?.single_transaction_limit
                        )}
                      </span>{' '}
                    </p>{' '}
                    <p>
                      Daily Transaction Limit:
                      <span>
                        {format(
                          customLimit?.data?.items[0]?.daily_transaction_limit
                        )}
                      </span>{' '}
                    </p>{' '}
                    <span></span>
                    <p>
                      Monthly Transaction Limit:{' '}
                      <span>
                        {format(
                          customLimit?.data?.items[0]?.monthly_transaction_limit
                        )}
                      </span>{' '}
                    </p>{' '}
                    <span></span>
                    <p>
                      Daily Transaction Frequency: &nbsp;
                      <span>
                        {
                          customLimit?.data?.items[0]
                            ?.daily_transaction_frequency
                        }
                      </span>{' '}
                    </p>
                    <p>
                      Monthly Transaction Frequency: &nbsp;
                      <span>
                        {
                          customLimit?.data?.items[0]
                            ?.monthly_transaction_frequency
                        }
                      </span>{' '}
                    </p>
                    <p>
                      Single Airtime and Bill Payment Limit:{' '}
                      <span>
                        {format(
                          customLimit?.data?.items[0]
                            ?.single_airtime_and_bill_payment_limit
                        )}{' '}
                      </span>{' '}
                    </p>
                    <p>
                      Daily Airtime and Bill Payment Limit:{' '}
                      <span>
                        {format(
                          customLimit?.data?.items[0]
                            ?.daily_airtime_and_bill_payment
                        )}{' '}
                      </span>
                    </p>
                  </div>
                ) : (
                  <p>Input Customer's Tag to get Details</p>
                )}
              </div> */}
            {/* </div> */}
          </div>
        </div>
      </section>
    </>
  )
}

export default CustomerLimitScreen

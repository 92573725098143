import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { CSVLink } from "react-csv"
import { formatDate } from "../main"
import Loader from "./Loader"
import { countUser } from "../actions/dashboardActions"

const DashboardCounts = () => {
  const countsRef = useRef(true)
  const dispatch = useDispatch()
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const userCount = useSelector((state) => state.userCount)
  const { loading, success, error, count } = userCount

  useEffect(() => {
    if (countsRef.current) {
      dispatch(countUser())
      countsRef.current = false
    }
    // if (
    //   (!loading && error?.message === 'Please login again') ||
    //   error?.message === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    // }
    if (success && count) {
      setData([
        {
          onboarding_customer_sign_ups: count?.onboarding_customer_sign_ups,
          dashboard_customer_sign_ups: count?.dashboard_customer_sign_ups,
          active_login: count?.active_login,
          total_merchants: count?.total_merchants,
          kyc_two_customers: count?.kyc_two_customers,
          kyc_three_customers: count?.kyc_three_customers,
          tier_one_merchant: count?.tier_one_merchant,
          tier_two_merchant: count?.tier_two_merchant,
          investor_count: count?.investor_count,
          investment_count: count?.investment_count,
          personal_vault: count?.personal_vault,
          funded_account: count?.funded_account,
          unfunded_account: count?.unfunded_account,
          active_users: count?.active_users,
          inactive_users: count?.inactive_users
        }
      ])

      setHeaders([
        {
          label: "Onboarding Customer Sign Ups",
          key: "onboarding_customer_sign_ups"
        },
        {
          label: "Dashboard Customer Sign Ups",
          key: "dashboard_customer_sign_ups"
        },
        { label: "Active Login", key: "active_login" },
        { label: "Total Merchants", key: "total_merchants" },
        { label: "Kyc Two Customers", key: "kyc_two_customers" },
        { label: "Kyc Three Customers", key: "kyc_three_customers" },
        { label: "Tier One merchant", key: "tier_one_merchant" },
        { label: "Tier Two Merchant", key: "tier_two_merchant" },
        { label: "Investor Count", key: "investor_count" },
        { label: "Investment Count", key: "investment_count" },
        { label: "Personal Vault", key: "personal_vault" },
        { label: "Funded Account", key: "funded_account" },
        { label: "Unfunded Account", key: "unfunded_account" },
        { label: "Active Users", key: "active_users" },
        { label: "InActive Users", key: "inactive_users" }
      ])
    }
  }, [count, success, dispatch, loading, error])

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
    dispatch(countUser(filterData))
  }

  const handleClear = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    dispatch(countUser())
  }
  return (
    <>
      <div className="filter-box">
        <div className="filter">
          <>
            <div className="filter-container">
              <p style={{ cursor: "pointer" }} onClick={handleClick}>
                Filter
              </p>
              <div className="navContainer" style={styles.navContainer}>
                <div className="date-filter">
                  <div className="date-title">Date</div>
                  <div className="date-options">
                    <div className="date-content">
                      <div className="date-items">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="status-container">
                  <div className="date-title">Status</div>
                </div>

                <div className="status-container">
                  <div className="date-title">Other Filters</div>
                  <div className="checkbox checkbox--flex"></div>
                </div>
                <div
                  className="status-container"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                  }}
                >
                  <button className="button" onClick={handleClear}>
                    <p>Clear</p>
                  </button>
                  <button className="button" onClick={handleFilter}>
                    <p>Filter</p>
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
        <div className="filter">
          <CSVLink data={data} headers={headers}>
            Export
          </CSVLink>
        </div>
      </div>
      <div className="accordion-item">
        <h3 className="accordion-header" id="h2">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#count"
            aria-expanded="true"
            aria-controls="count"
          >
            Counts
          </button>
        </h3>
        <div
          id="count"
          className="accordion-collapse collapse show"
          aria-labelledby="h2"
        >
          {loading ? (
            <Loader />
          ) : (
            <div className="accordion-body">
              <div className="row">
                <p className="error">{error && error}</p>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {count?.onboarding_customer_sign_ups}
                    </div>
                    <p>Customer Sign-ups(onboarding)</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users green-icon"></i>
                    <div className="info-box-count">
                      {" "}
                      {count?.dashboard_customer_sign_ups}
                    </div>
                    <p>Customer Sign-ups(Dashboard)</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count"> {count?.active_login}</div>
                    <p>Active Login</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {Number(count?.active_users) +
                        Number(count?.inactive_users)}
                    </div>
                    <p>Total Users</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users green-icon"></i>
                    <div className="info-box-count">
                      {" "}
                      {count?.total_merchants}
                    </div>
                    <p>Total Number Of Merchants</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {count?.kyc_one_customers}
                    </div>
                    <p>Kyc 1 Cutomers</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users"></i>
                    <div className="info-box-count">
                      {" "}
                      {count?.kyc_two_customers}
                    </div>
                    <p>Kyc 2 Cutomers</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users blue-icon"></i>
                    <div className="info-box-count">
                      {count?.kyc_three_customers}
                    </div>
                    <p>Kyc 3 Cutomers</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {count?.tier_one_merchant}
                    </div>
                    <p>Tier 1 Merchant Count</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {count?.tier_two_merchant}
                    </div>
                    <p>Tier 2 Merchant Count</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {count?.tier_three_merchant}
                    </div>
                    <p>Tier 3 Merchant</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {count?.investor_count}
                    </div>
                    <p>Investor Count</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {count?.investment_count}
                    </div>
                    <p>Investment Count</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {count?.personal_vault}
                    </div>
                    <p>Personal Vault Count</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {count?.funded_account}
                    </div>
                    <p>Funded Account</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {count?.unfunded_account}
                    </div>
                    <p>Unfunded Account</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">{count?.active_users}</div>
                    <p>Active Users (30 days active)</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <i className="fa fa-users orange-icon"></i>
                    <div className="info-box-count">
                      {count?.inactive_users}
                    </div>
                    <p>Inactive Users (30 days inactive)</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DashboardCounts

import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"

import Header from "../components/Header"
import Loader from "../components/Loader"
import Paginate from "../components/Paginate"
import SideNav from "../components/SideNav"
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit"
import { CSVLink } from "react-csv"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { format, formatDate } from "../main"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  fetchOverDraftRequest,
  fetchOverDraftSchedule
} from "../actions/overdraftActions"
import OverdraftModal from "../components/OverdraftModal"

const OverdraftSchedulesScreen = () => {
  const navigate = useNavigate()
  const { customeremail } = useParams()
  const location = useLocation()
  const { id } = useParams()

  const [currPage, setCurrPage] = useState(1)
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  // FILTER states starts
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())

  // filter states emd
  const dispatch = useDispatch()
  const transRef = useRef(true)

  const getOverdraftSchedules = useSelector(
    (state) => state.getOverdraftSchedules
  )
  const { loading, success, error, overdraftSchedules } = getOverdraftSchedules

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  useEffect(() => {
    if (transRef.current) {
      if (id === null) {
        dispatch(fetchOverDraftSchedule())
        transRef.current = false
      } else {
        dispatch(fetchOverDraftSchedule(id))
        transRef.current = false
      }
      transRef.current = false
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate(`/officer/${userInfo?.user?.id}`, { replace: true })
    }
    if (userInfo?.user?.role === "TREEGAR") {
      navigate("/treegar", { replace: true })
    }
  }, [
    dispatch,
    userInfo,
    navigate,
    currPage,
    id,
    success,
    customeremail,
    error,
    loading
  ])

  return (
    <>
      <SideNav />
      <Helmet>
        <title>Overdraft Schedules | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>OverDraft Schedule</h1>
            <div
              className="status-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: "10px"
              }}
            ></div>

            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                  align="middle"
                >
                  <MDBTableHead>
                    <tr className="font-reduce">
                      <th scope="col">S/N</th>
                      <th scope="col">Business Name</th>
                      <th scope="col">Email Address</th>
                      <th scope="col">Approved Amount</th>
                      <th scope="col">Interest Rate</th>
                      <th scope="col">Penal Rate</th>
                      <th scope="col">Interest Accrued</th>
                      <th scope="col">Date Approved</th>
                      <th scope="col">Date Due</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      overdraftSchedules?.items?.map((r, key) => (
                        <tr key={key} className="font-reduce">
                          <td>{key + 1}</td>
                          <td>{r?.business_name}</td>
                          <td>{r?.support_email_address}</td>
                          <td>{format(r?.approved_amount)}</td>
                          <td>{r?.interest_rate}%</td>
                          <td>{r?.penal_rate}%</td>
                          <td>{format(r?.interest)}</td>
                          <td>{moment(r?.date_approved).format("LLL")}</td>
                          <td>{moment(r?.date_due).format("LLL")}</td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default OverdraftSchedulesScreen

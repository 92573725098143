import React, { useEffect, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchInterestMetrics,
  fetchTotalInterest
} from "../actions/dashboardActions"
import { format, formatDate } from "../main"
import Loader from "./Loader"

const ExpenditureAndInterest = () => {
  const dispatch = useDispatch()
  const expenditureRef = useRef(true)
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const totalInterest = useSelector((state) => state.totalInterest)
  const { loading, success, error, interest } = totalInterest

  const totalInterestExpense = useSelector(
    (state) => state.totalInterestExpense
  )
  const { interest: appInterest } = totalInterestExpense

  useEffect(() => {
    if (expenditureRef.current) {
      dispatch(fetchTotalInterest())
      dispatch(fetchInterestMetrics())
      expenditureRef.current = false
    }
  })

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
    dispatch(fetchTotalInterest(filterData))
  }

  const handleClear = () => {}
  return (
    <>
      <div className="filter-box">
        <div className="filter">
          <>
            <div className="filter-container">
              <p style={{ cursor: "pointer" }} onClick={handleClick}>
                Filter
              </p>
              <div className="navContainer" style={styles.navContainer}>
                <div className="date-filter">
                  <div className="date-title">Date</div>
                  <div className="date-options">
                    <div className="date-content">
                      <div className="date-items">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="status-container">
                  <div className="date-title">Status</div>
                </div>

                <div className="status-container">
                  <div className="date-title">Other Filters</div>
                  <div className="checkbox checkbox--flex"></div>
                </div>
                <div
                  className="status-container"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                  }}
                >
                  <button className="button" onClick={handleClear}>
                    <p>Clear</p>
                  </button>
                  <button className="button" onClick={handleFilter}>
                    <p>Filter</p>
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
      {error && <p>{error}</p>}
      <div className="accordion-item">
        <h3 className="accordion-header" id="h3">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#expenditure"
            aria-expanded="true"
            aria-controls="expenditure"
          >
            Expenditure And Interest Expense
          </button>
        </h3>
        <div
          id="expenditure"
          className="accordion-collapse collapse show"
          aria-labelledby="h3"
        >
          {loading ? (
            <Loader />
          ) : (
            success && (
              <div className="accordion-body">
                <div className="row">
                  {/* <div className='col-lg-3'>
                    <div className='info-box'>
                      <i className='fa fa-users blue-icon'></i>
                      <div className='info-box-count'>
                        {format(interest?.data?.wallet_total_interest)}
                      </div>
                      <p>Wallet Total interest Expense</p>
                    </div>
                  </div> */}
                  {/* <div className='col-lg-3'>
                    <div className='info-box'>
                      <i className='fa fa-users blue-icon'></i>
                      <div className='info-box-count'>
                        {' '}
                        {format(interest?.data?.active_wallet_interest)}
                      </div>
                      <p>Wallet Interest Expense Active/Accrued </p>
                    </div>
                  </div> */}
                  <div className="col-lg-3">
                    <div className="info-box">
                      <i className="fa fa-users blue-icon"></i>
                      <div className="info-box-count">
                        {" "}
                        {format(appInterest?.data?.wallet_interest_credit)}
                      </div>
                      <p>Wallet Interest </p>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="info-box">
                      <i className="fa fa-users blue-icon"></i>
                      <div className="info-box-count">
                        {" "}
                        {format(appInterest?.data?.vault_interest)}
                      </div>
                      <p>Vault Interest </p>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="info-box">
                      <i className="fa fa-users blue-icon"></i>
                      <div className="info-box-count">
                        {" "}
                        {format(
                          appInterest?.data?.target_savings_interest_credit
                        )}
                      </div>
                      <p>Target Savings Interest </p>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="info-box">
                      <i className="fa fa-users blue-icon"></i>
                      <div className="info-box-count">
                        {" "}
                        {format(interest?.data?.vault_interest)}
                      </div>
                      <p>Vault Interest Expense</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="info-box">
                      <i className="fa fa-users blue-icon"></i>
                      <div className="info-box-count">
                        {" "}
                        {format(interest?.data?.active_vault_interest)}
                      </div>
                      <p>Vault Interest Expense Active/Accrued </p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="info-box">
                      <i className="fa fa-users blue-icon"></i>
                      <div className="info-box-count">
                        {" "}
                        {format(interest?.data?.disbursed_vault_interest)}
                      </div>
                      <p>Vault Interest Expense Disbursed</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="info-box">
                      <i className="fa fa-users blue-icon"></i>
                      <div className="info-box-count">
                        {" "}
                        {format(interest?.data?.market_place_interest)}
                      </div>
                      <p>Marketplace Total Interest Expense</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="info-box">
                      <i className="fa fa-users blue-icon"></i>
                      <div className="info-box-count">
                        {" "}
                        {format(interest?.data?.active_market_place_interest)}
                      </div>
                      <p>Marketplace Expense Active/Accrued </p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="info-box">
                      <i className="fa fa-users blue-icon"></i>
                      <div className="info-box-count">
                        {" "}
                        {format(
                          interest?.data?.disbursed_market_place_interest
                        )}
                      </div>
                      <p>Marketplace Expense Disbursed</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="info-box">
                      <i className="fa fa-users blue-icon"></i>
                      <div className="info-box-count">
                        {" "}
                        {format(
                          appInterest?.data?.wallet_interest_credit +
                            appInterest?.data?.target_savings_interest_credit +
                            appInterest?.data?.vault_interest
                        )}
                      </div>
                      <p>Total Interest Expense</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="info-box">
                      <i className="fa fa-users blue-icon"></i>
                      <div className="info-box-count">
                        {" "}
                        {format(
                          appInterest?.data?.wallet_interest_credit +
                            appInterest?.data?.target_savings_interest_credit +
                            appInterest?.data?.vault_interest
                        )}
                      </div>
                      <p>Total Interest Expense Active/Accrued </p>
                    </div>
                  </div>
                  {/* <div className="col-lg-3">
                    <div className="info-box">
                      <i className="fa fa-users blue-icon"></i>
                      <div className="info-box-count">
                        {format(
                          appInterest?.data?.wallet_interest_credit +
                            appInterest?.data?.target_savings_interest_credit +
                            appInterest?.data?.vault_interest
                        )}
                      </div>
                      <p>Total Interest Expense Disbursed</p>
                    </div>
                  </div> */}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  )
}

export default ExpenditureAndInterest

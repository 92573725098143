import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Header from '../components/Header'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import SideNav from '../components/SideNav'
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit'
import 'react-datepicker/dist/react-datepicker.css'
import { format, formatDate } from '../main'
import { fetchCustomerLimit } from '../actions/adminActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const AllCustomerLimitsScreen = () => {
  const dispatch = useDispatch()
  const [currPage, setCurrPage] = useState(1)
  const [email, setEmail] = useState('')
  const [customerTag, setCustomerTag] = useState('')
  const [styles, setStyles] = useState({
    navContainer: {
      display: 'none'
    }
  })
  const limitRef = useRef(true)

  const getCustomerLimit = useSelector((state) => state.getCustomerLimit)
  const { loading, success, error, customLimit } = getCustomerLimit

  useEffect(() => {
    if (limitRef.current) {
      dispatch(fetchCustomerLimit(currPage))
      limitRef.current = false
    }
  })

  const handleClick = () => {
    if (styles.navContainer.display === 'block') {
      setStyles({
        navContainer: {
          display: 'none'
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: 'block'
        }
      })
    }
  }
  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: 'none'
      }
    })
    const filterData = {
      email,
      customerTag
    }

    dispatch(fetchCustomerLimit(currPage, filterData))
  }

  const handleClear = () => {
    setEmail('')
    setCustomerTag('')
    setStyles({
      navContainer: {
        display: 'none'
      }
    })
    dispatch(fetchCustomerLimit(currPage))
  }

  const afterPageClicked = (page_number) => {
    if (page_number === currPage) {
      setCurrPage(page_number + 1)
    } else {
      setCurrPage(page_number)
    }

    const filterData = {
      email,
      customerTag
    }
    if (page_number === 'undefined1') {
      setCurrPage(1)
    }
    dispatch(fetchCustomerLimit(currPage, filterData))
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Transactions | PennyTree - Admin</title>
      </Helmet>
      <section className='page-container'>
        <div className='page-content'>
          <Header />
          <div className='page-body'>
            <div
              className='status-container'
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              <div
                className='button'
                style={{
                  marginRight: '10px'
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
                <Link to='/customer/increase-limit'> Add Or Update limit</Link>
              </div>
            </div>
            <h1>Customer Limits</h1>
            <div className='filter-box'>
              <div className='filter'>
                <>
                  <div className='filter-container'>
                    <p style={{ cursor: 'pointer' }} onClick={handleClick}>
                      Filter
                    </p>
                    <div className='navContainer' style={styles.navContainer}>
                      <div className='status-container'>
                        <div className='date-title'>Other Filters</div>
                        <div className='checkbox checkbox--flex'>
                          <div
                            className='checkbox__item'
                            style={{
                              width: '100%'
                            }}
                          >
                            <input
                              style={{
                                width: '100%',
                                borderRadius: '10px',
                                border: '1px solid grey',
                                fontSize: '14px',
                                display: customerTag ? 'none' : 'block'
                              }}
                              type='email'
                              className='email-input'
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder='Enter customer Email'
                            />{' '}
                          </div>
                        </div>
                        <div className='checkbox checkbox--flex'>
                          <div
                            className='checkbox__item'
                            style={{
                              width: '100%'
                            }}
                          >
                            <input
                              style={{
                                width: '100%',
                                borderRadius: '10px',
                                border: '1px solid grey',
                                fontSize: '14px',
                                display: email ? 'none' : 'block'
                              }}
                              type='email'
                              className='email-input'
                              value={customerTag}
                              onChange={(e) => setCustomerTag(e.target.value)}
                              placeholder='Enter PennTag or Merchantb Tag'
                            />{' '}
                          </div>
                        </div>
                      </div>
                      <div
                        className='status-container'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center'
                        }}
                      >
                        <button className='button' onClick={handleClear}>
                          <p>Clear</p>
                        </button>
                        <button className='button' onClick={handleFilter}>
                          <p>Filter</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ 'width': '100%', fontSize: '14px' }}
                >
                  <MDBTableHead>
                    <tr>
                      <th scope='col'>S/N</th>
                      <th scope='col'>Full Name</th>
                      <th scope='col'>PennTag</th>
                      <th scope='col'>Merchant Tag</th>
                      <th scope='col'>Business Name</th>
                      <th scope='col'>Single Transaction Limit</th>
                      <th scope='col'>Daily Transaction Limit</th>
                      <th scope='col'>Monthly Transaction Limit</th>
                      <th scope='col'>Daily Transaction Frequency</th>
                      <th scope='col'>Monthly Transaction Frequency</th>
                      <th scope='col'>Daily Airtime And Bill Payment</th>
                      <th scope='col'>Single Airtime And Bill Payment Limit</th>
                      <th scope='col'>Wallet Balance</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {!loading &&
                      success &&
                      customLimit?.data?.items?.map((c, key) => (
                        <tr>
                          <td>{key + 1}</td>
                          <td>{c?.full_name}</td>
                          <td>{c?.username}</td>

                          <td>{c?.merchant_tag}</td>
                          <td>{c?.business_name}</td>
                          <td>{format(c?.single_transaction_limit)}</td>
                          <td>{format(c?.daily_transaction_limit)}</td>
                          <td>{format(c?.monthly_transaction_limit)}</td>
                          <td>{c?.daily_transaction_frequency}</td>
                          <td>{c?.monthly_transaction_frequency}</td>
                          <td>{format(c?.daily_airtime_and_bill_payment)}</td>
                          <td>
                            {format(c?.single_airtime_and_bill_payment_limit)}
                          </td>
                          <td>{format(c?.wallet_balance)}</td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
                <Paginate
                  totPages={customLimit?.page?.total_page}
                  currentPage={String(customLimit?.page?.current_page)}
                  page={String(customLimit?.page?.current_page)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default AllCustomerLimitsScreen

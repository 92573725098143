import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import Header from "../components/Header"
import InvestmentBalance from "../components/InvestmentBalance"
import MerchantBalance from "../components/MerchantBalance"
import PersonalVaultBalances from "../components/PersonalVaultBalances"
import SideNav from "../components/SideNav"
import WalletBalance from "../components/WalletBalance"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const BalancesScreen = () => {
  const navigate = useNavigate()
  const [walletTab, setWalletTab] = useState(true)
  const [vaultTab, setVaultTab] = useState(false)
  const [merchantTab, setMerchantTab] = useState(false)
  const [investmentTab, setInvestmentTab] = useState(false)

  const setTabs = (param) => {
    if (param === "wallet") {
      setWalletTab(true)
      setVaultTab(false)
      setMerchantTab(false)
      setInvestmentTab(false)
    } else if (param === "vault") {
      setWalletTab(false)
      setVaultTab(true)
      setMerchantTab(false)
      setInvestmentTab(false)
    } else if (param === "merchant") {
      setWalletTab(false)
      setVaultTab(false)
      setMerchantTab(true)
      setInvestmentTab(false)
    } else if (param === "investment") {
      setWalletTab(false)
      setVaultTab(false)
      setMerchantTab(false)
      setInvestmentTab(true)
    }
  }
  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin
  useEffect(() => {
    if (userInfo?.user?.role === "Account Officer") {
      navigate("/performance", { replace: true })
    }
    if (userInfo?.user?.role === "TREEGAR") {
      navigate("/treegar", { replace: true })
    }
  })
  return (
    <>
      <SideNav />
      <Helmet>
        <title> Admin Dashboard | Balances</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <div
              className="status-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: "20px"
              }}
            >
              <div className="button">
                <FontAwesomeIcon />
                <Link to="/figures"> Balances Details</Link>
              </div>
            </div>
            <ul
              className="tab-header header-styled"
              style={{
                justifyContent: "flex-start"
              }}
            >
              <li onClick={() => setTabs("wallet")}>Wallet Balance</li>

              <li onClick={() => setTabs("vault")}>Personal Vault</li>

              <li onClick={() => setTabs("merchant")}>Merchant Wallet</li>

              <li onClick={() => setTabs("investment")}>Investment</li>
            </ul>

            <div className="tab-body">
              <div
                className="wallet-body"
                style={{
                  display: walletTab ? "block" : "none"
                }}
              >
                <WalletBalance />
              </div>
              <div
                className="vault-body"
                style={{
                  display: vaultTab ? "block" : "none"
                }}
              >
                <PersonalVaultBalances />
              </div>
              <div
                className="merchant-body"
                style={{
                  display: merchantTab ? "block" : "none"
                }}
              >
                <MerchantBalance />
              </div>
              <div
                className="investment-body"
                style={{
                  display: investmentTab ? "block" : "none"
                }}
              >
                <InvestmentBalance />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BalancesScreen

import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setInvestmentRate } from "../actions/investmentActions"
import Loader from "./Loader"
import { setProductRate } from "../actions/adminActions"

const UpdateRateAllModal = ({ setIsOpen, id }) => {
  const dispatch = useDispatch()
  const [rate, setRate] = useState("")

  const updateRates = useSelector((state) => state.updateRates)
  const { loading, success } = updateRates
  useEffect(() => {
    if (success) {
      setIsOpen(false)
      window.location.reload()
    }
  }, [success, dispatch, setIsOpen])

  const updateRate = () => {
    const postData = {
      id,
      rate
    }
    console.log(postData)
    dispatch(setProductRate(postData))
  }

  console.log({ setIsOpen, id })
  return (
    <>
      <div className="darkBG" onClick={() => setIsOpen(false)} />
      <div
        className="centered"
        style={{
          backgroundColor: "#101010"
        }}
      >
        <div
          className="modal"
          style={{
            backgroundColor: "#101010",
            height: "200px"
          }}
        >
          <div className="modalHeader">
            <h5 className="heading">Update Rate</h5>
          </div>
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            {/* <RiCloseLine style={{ marginBottom: '-3px' }} /> */}
          </button>
          <div className="modalContent login-form">
            <form>
              <div className="form-group">
                <label
                  htmlFor="text"
                  style={{ color: "#ffffff", letterSpacing: "1.2px" }}
                >
                  RATE:
                </label>
                <input
                  className="invest-input"
                  type="number"
                  name="rate"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                  style={{
                    width: "100%"
                  }}
                />
              </div>
            </form>
          </div>
          <div className="modalActions">
            <div className="actionsContainer">
              {loading || loading ? (
                <Loader />
              ) : (
                <>
                  <button
                    className="deleteBtn"
                    onClick={updateRate}
                    style={{
                      backgroundColor: "green"
                    }}
                  >
                    Update
                  </button>
                  <button
                    className="cancelBtn"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateRateAllModal

import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import Header from "../components/Header"
import Paginate from "../components/Paginate"
import SideNav from "../components/SideNav"
import { formatDate } from "../main"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Loader from "../components/Loader"
import Modal from "../components/Modal"
import MemosModal from "../components/MemosModal"
import {
  fetchPendingBusiness,
  setApproveRejectBusiness
} from "../actions/merchantActions"

const MerchantAccountApprovalScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const businessRef = useRef(true)
  const [currPage, setCurrPage] = useState(1)
  const [customeremail, setCustomeremail] = useState("")
  const [penntag, setPenntag] = useState("")
  const [verified, setVerified] = useState(false)
  const [unverified, setUnverified] = useState(false)
  const year = new Date().getFullYear()
  const [startDate, setStartDate] = useState(new Date(year, 0, 1))
  const [endDate, setEndDate] = useState(new Date())
  const [isOpen, setIsOpen] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [busId, setBusId] = useState("")
  const [customerId, setCustomerId] = useState("")
  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const pendingBusiness = useSelector((state) => state.pendingBusiness)
  const { loading, success, business, error } = pendingBusiness

  const approveBusiness = useSelector((state) => state.approveBusiness)
  const {
    loading: approveRejectLoading,
    success: approveRejectSucess,
    error: approveRejectError
  } = approveBusiness

  useEffect(() => {
    if (approveRejectSucess) {
      window.location.reload()
    }
    // if (
    //   (!loading && error === 'Please login again') ||
    //   error === 'You have previously logged out. Please login again'
    // ) {
    //   localStorage.removeItem('userInfo')
    //   window.location.reload()
    // }
    // if (!userInfo || userInfo === null) {
    //   navigate('/', { replace: true })
    // }
    if (businessRef.current) {
      dispatch(fetchPendingBusiness())
      businessRef.current = false
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate("/performance", { replace: true })
    }
  })

  const afterPageClicked = (page_number) => {
    if (page_number === currPage) {
      setCurrPage(page_number + 1)
    } else {
      setCurrPage(page_number)
    }

    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      customeremail
    }
    if (page_number === "undefined1") {
      setCurrPage(1)
    }
    dispatch(fetchPendingBusiness(currPage, filterData))
  }

  const approve = (id) => {
    const postData = {
      "id": id,
      "is_approved": true
    }
    dispatch(setApproveRejectBusiness(postData))
  }

  const handleClick = () => {
    if (styles.navContainer.display === "block") {
      setStyles({
        navContainer: {
          display: "none"
        }
      })
    } else {
      setStyles({
        navContainer: {
          display: "block"
        }
      })
    }
  }

  const handleFilter = () => {
    setStyles({
      navContainer: {
        display: "none"
      }
    })
    const filterData = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      customeremail,
      penntag,
      verified,
      New: unverified
    }

    console.log(filterData)

    dispatch(fetchPendingBusiness(currPage, filterData))
  }

  const handleClear = () => {
    setCustomeremail("")
    setPenntag("")
    setVerified(false)
    setUnverified(false)

    setStyles({
      navContainer: {
        display: "none"
      }
    })

    dispatch(fetchPendingBusiness())
  }

  const revealModal = (id, cus_id) => {
    setIsOpen(true)
    setBusId(id)
    setCustomerId(cus_id)
  }

  const revealMemosModal = (cus_id) => {
    setIsOpen2(true)
    setCustomerId(cus_id)
  }

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Customer | PennyTree - Merchant Account Approval</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <div
              className="status-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <div className="button">
                <Link to="/merchants"> All Merchants</Link>
              </div>
            </div>
            <h1>Merchant Account Approval</h1>
            <div className="filter-box">
              <div className="filter">
                <>
                  <div className="filter-container">
                    <p style={{ cursor: "pointer" }} onClick={handleClick}>
                      Filter
                    </p>
                    <div className="navContainer" style={styles.navContainer}>
                      <div className="date-filter">
                        <div className="date-title">Date</div>
                        <div className="date-options">
                          <div className="custom-filter__item custom-filter__item--pad">
                            <div className="overview-filter">
                              <div className="filter__item">
                                <input
                                  id="today_fkc3w9c4e0"
                                  value="Today"
                                  type="radio"
                                  name="dateFilter_fkc3w9c4e0"
                                  className="overview-filter__item__radio"
                                />
                                <label
                                  htmlFor="today_fkc3w9c4e0"
                                  className="overview-filter__item__link active"
                                >
                                  Today
                                </label>
                              </div>{" "}
                              <div className="filter__item">
                                <input
                                  id="last7_fkc3w9c4e0"
                                  value="7 days"
                                  type="radio"
                                  name="dateFilter_fkc3w9c4e0"
                                  className="overview-filter__item__radio"
                                />
                                <label
                                  htmlFor="last7_fkc3w9c4e0"
                                  className="overview-filter__item__link"
                                >
                                  Last 7 days
                                </label>
                              </div>{" "}
                              <div className="filter__item">
                                <input
                                  id="last30_fkc3w9c4e0"
                                  value="30 days"
                                  type="radio"
                                  name="dateFilter_fkc3w9c4e0"
                                  className="overview-filter__item__radio"
                                />
                                <label
                                  htmlFor="last30_fkc3w9c4e0"
                                  className="overview-filter__item__link"
                                >
                                  30 days
                                </label>
                              </div>{" "}
                              <div className="filter__item">
                                <input
                                  id="1year_fkc3w9c4e0"
                                  value="1 year"
                                  type="radio"
                                  name="dateFilter_fkc3w9c4e0"
                                  className="overview-filter__item__radio"
                                />
                                <label
                                  htmlFor="1year_fkc3w9c4e0"
                                  className="overview-filter__item__link"
                                >
                                  1 year
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="date-content">
                            <div className="date-items">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                              />

                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="status-container">
                        <div className="date-title">Customer</div>
                        <div className="checkbox checkbox--flex">
                          <div
                            className="checkbox__item"
                            style={{
                              width: "100%"
                            }}
                          >
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "1px solid grey",
                                fontSize: "14px",
                                display: penntag ? " none" : "block"
                              }}
                              type="email"
                              className="email-input"
                              value={customeremail}
                              onChange={(e) => setCustomeremail(e.target.value)}
                              placeholder="Enter customer Email"
                            />{" "}
                          </div>

                          <div
                            className="checkbox__item"
                            style={{
                              width: "100%"
                            }}
                          >
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "1px solid grey",
                                fontSize: "14px",
                                display: customeremail ? " none" : "block"
                              }}
                              type="email"
                              className="email-input"
                              value={penntag}
                              onChange={(e) => setPenntag(e.target.value)}
                              placeholder="Enter PennTag"
                            />{" "}
                          </div>
                        </div>
                      </div>
                      <div
                        className="status-container"
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center"
                        }}
                      >
                        <button className="button" onClick={handleClear}>
                          <p>Clear</p>
                        </button>
                        <button className="button" onClick={handleFilter}>
                          <p>Filter</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
            <p>{approveRejectError && approveRejectError}</p>
            {isOpen && (
              <Modal
                setIsOpen={setIsOpen}
                id={busId}
                customer_id={customerId}
              />
            )}

            {isOpen2 && (
              <MemosModal setIsOpen2={setIsOpen2} customer_id={customerId} />
            )}

            {loading ? (
              <Loader />
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                >
                  <MDBTableHead>
                    <tr className="font-reduce">
                      <th scope="col">S/N</th>
                      <th scope="col">Customer ID</th>
                      <th scope="col">Business Name</th>
                      <th scope="col">Mobile Number</th>
                      <th scope="col">Email Address</th>
                      <th cope="col">Registration Number</th>
                      <th scope="col">Tax Number</th>
                      <th scope="col">No Of Employees</th>
                      <th scope="col">Country</th>
                      <th scope="col">Status</th>
                      <th scope="col">State</th>
                      <th scope="col">Adress</th>
                      <th scope="col">City</th>
                      <th scope="col">Date Uploaded</th>
                      <th scope="col">Lga</th>
                      <th scope="col">Merchant Tag</th>
                      <th scope="col">Business Type</th>
                      <th scope="col">Business Category</th>
                      <th scope="col">Documents</th>
                      <th scope="col">Memos</th>
                      <th scope="col">Action</th>
                      <th scope="col"></th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      business?.data?.items
                        ?.filter(
                          (bus) =>
                            bus?.status === "New" ||
                            bus?.status === "Pending Verification"
                        )
                        ?.map((bus, key) => (
                          <tr key={key} className="font-reduce">
                            <td>{key + 1}</td>
                            <td>{bus?.customer_id}</td>
                            <td>{bus?.business_name}</td>
                            <td>{bus?.support_mobile_number}</td>
                            <td>{bus?.support_email_address}</td>
                            <td>{bus?.registration_number}</td>
                            <td>{bus?.tax_number}</td>
                            <td>{bus?.number_of_employees}</td>
                            <td>{bus?.country}</td>
                            <td>{bus?.status}</td>
                            <td>{bus?.state}</td>
                            <td>{bus?.address}</td>
                            <td>{bus?.city}</td>
                            <td>{moment(bus?.date_updated).format("LLL")}</td>
                            <td>{bus?.lga}</td>
                            <td>
                              {" "}
                              <Link to={`/${bus?.customer_id}`}>
                                {bus?.merchant_tag}
                              </Link>
                            </td>
                            <td>{bus?.business_type}</td>
                            <td>{bus?.business_category}</td>
                            <td
                              style={{
                                color: "yellow",
                                textDecoration: "underline"
                              }}
                            >
                              <Link to={`/merchant/doc/${bus?.customer_id}`}>
                                View Document
                              </Link>
                            </td>
                            <td>
                              <button
                                className="withdrawal-btn "
                                onClick={() =>
                                  revealMemosModal(bus?.customer_id)
                                }
                              >
                                {" "}
                                View Memos
                              </button>
                            </td>
                            {approveRejectLoading ? (
                              <Loader />
                            ) : (
                              <>
                                <td>
                                  <button
                                    className="withdrawal-btn"
                                    onClick={() => approve(bus?.id)}
                                  >
                                    Approve
                                  </button>
                                </td>
                                <td>
                                  <button
                                    className="withdrawal-btn withdrawal-red"
                                    onClick={() =>
                                      revealModal(bus?.id, bus?.customer_id)
                                    }
                                  >
                                    {" "}
                                    Reject
                                  </button>
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                  </MDBTableBody>
                </MDBTable>
                <Paginate
                  totPages={business?.data?.page?.total_page}
                  currentPage={String(business?.data?.page?.currentPage)}
                  page={String(business?.data?.page?.currentPage)}
                  pageClicked={(ele) => {
                    afterPageClicked(ele)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default MerchantAccountApprovalScreen

import $ from "jquery"
import "datatables.net-dt"
import validator from "validator"
import { INVESTMENT_CONSTANTS } from "./constants/adminConstants"

document.addEventListener("DOMContentLoaded", function (e) {
  $(function () {
    function sticky() {
      if ($(window).scrollTop() >= 10) {
        $(".admin-header ").addClass("sticky")
      } else if ($(window).scrollTop() < 10) {
        $(".admin-header ").removeClass("sticky")
      }
    }
    $(window).on("scroll", function () {
      sticky()
    })
    $(window).on("load", sticky())
  })

  // Back to Top Button
  $(".btt").hide()
  $(function () {
    function sticky() {
      let hgt = $(window).innerHeight()
      if ($(window).scrollTop() >= hgt) {
        $(".btt").show()
      } else if ($(window).scrollTop() < hgt) {
        $(".btt").hide()
      }
    }
    $(window).on("scroll", function () {
      sticky()
    })
    $(window).on("load", sticky())
  })

  $(document).on("mouseup", function (e) {
    var fnav = $("nav")
    if (!fnav.is(e.target)) {
      $("nav").removeClass("close")
      $(".logo").removeClass("close")
      $(".page-container").removeClass("close")
      $(".admin-header").removeClass("shadow")
    }
    $(".menu-icon").on("click", function () {
      $("nav").toggleClass("close")
      $(".logo").toggleClass("close")
      $(".page-container").toggleClass("close")
      $(".admin-header").toggleClass("shadow")
    })
  })

  $(document).on("mouseup", function (e) {
    var filter = $(".filter-dropdown")
    if (!filter.is(e.target)) {
      $(".filter-dropdown").hide()
    }
    $(".filter").on("click", function () {
      $(".filter-dropdown").toggle()
    })
  })

  $(document).on("ready", function () {
    $("#example").DataTable()
  })

  $(document).on("mouseup", function (e) {
    var profile = $(".profile-dropdown")
    if (!profile.is(e.target)) {
      $(".profile-dropdown").hide()
    }
    $(".profile").on("click", function () {
      $(".profile-dropdown").toggle()
    })
  })
})

export const formatDate = (startDate) => {
  const today = startDate
  const month =
    today.getMonth() + 1 >= 10
      ? today.getMonth() + 1
      : "0" + (today.getMonth() + 1)
  const date = today.getDate() >= 10 ? today.getDate() : "0" + today.getDate()
  const dateToday = today.getFullYear() + "-" + month + "-" + date

  const dateTime = dateToday
  return dateTime
}

export const isEmpty = (str) => {
  return validator.isEmpty(str, { ignore_whitespace: true })
}

export const { format } = new Intl.NumberFormat("en-us", {
  style: "currency",
  currency: "NGN"
})

export const calculatePercentage = (total, unfunded) => {
  if (unfunded === 0) {
    return 0
  }
  return Math.floor((unfunded / total) * 100)
}

export const avgFloat = (year, month, val) => {
  const monthIndex = [
    { n: "January", value: 1 },
    { n: "Febuary", value: 2 }
  ]
  var newMonth = monthIndex.find((m) => m.n === month)
  console.log(newMonth)
  var days = new Date(year, newMonth?.value, 0).getDate()

  const num = Number(val) / Number(days)
  if (!isNaN(num)) {
    return format(num)
  }
  return format(0)
}

export function getkeybyvalue(object, value) {
  return Object.keys(object).find((key) => object[key] === value)
}

export function getYears() {
  const theDates = []
  for (let year = new Date().getFullYear(); year !== null; ) {
    const row = []
    for (let i = 0; i < 5; i++, year = year > 1901 ? year - 1 : null) {
      row.push(year)
    }
    theDates.push(row)
  }
  return theDates
}

export function getMonthName(monthNumber) {
  const date = new Date()
  date.setMonth(monthNumber - 1)

  return date.toLocaleString("en-US", { month: "long" })
}

export function addDays(date, days) {
  const t = date.setDate(date.getDate() + Number(days))
  console.log(t)

  const targetDate = new Date(t)
  console.log(targetDate)
  var dd = targetDate.getDate()
  var mm = targetDate.getMonth() // 0 is January, so we must add 1
  var yyyy = targetDate.getFullYear()
  const matured = new Date(yyyy, mm, dd)
  console.log(matured)
  return matured
}

export const generateInvestmentTag = () => {
  const firstname = "TRE"
  const PennTag =
    firstname.replace(/ /g, "").substring(0, 3).toUpperCase() +
    Math.floor(new Date().getTime() * Math.random())
      .toString(8)
      .substring(0, 6)
  return PennTag
}

export const calcNetInterest = (percent, amount) => {
  const perc = percent / 100
  const interest = perc * amount
  return interest
}

export const arrayFilter = (array, val) => {
  let newArray = array.filter((item) => item.name == val)
  let value = newArray[0]?.value
  return value
}

export function format_num(id) {
  var number = document.getElementById(id).value
  number += ""
  let x
  let x1
  let x2
  number = number.replace(",", "")
  x = number.split(".")
  x1 = x[0]
  x2 = x.length > 1 ? "." + x[1] : ""
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2")
  }
  document.getElementById(id).value = x1 + x2
}

export function dateCheck(startDate, endDate) {
  const startDateObj = new Date(startDate)
  const endDateObj = new Date(endDate)

  // Check if endDate is not behind startDate
  if (endDateObj < startDateObj) {
    console.log("here")
    return false
  }

  // Calculate the time difference in milliseconds
  const timeDifference = endDateObj - startDateObj

  // Calculate the number of milliseconds in a day
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000

  // Check if the time difference is greater than one day
  if (timeDifference == oneDayInMilliseconds) {
    return true
  } else {
    return false
  }
}

import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import Header from "../components/Header"
import Loader from "../components/Loader"
import Paginate from "../components/Paginate"
import SideNav from "../components/SideNav"
import { format, formatDate } from "../main"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import { CSVLink } from "react-csv"

import {
  setApproveRejectWithdrawal,
  fetchAllPayouts,
  fetchAllBills
} from "../actions/transactionActions"
import { fetchTerminals } from "../actions/adminActions"
import { fetchUnassignedTerminals } from "../actions/terminalActions"

const PendingTerminalsScreen = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const withdrawalRef = useRef(true)
  const [currPage, setCurrPage] = useState(1)

  const navigate = useNavigate()

  const [styles, setStyles] = useState({
    navContainer: {
      display: "none"
    }
  })

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  const unassignedTerminals = useSelector((state) => state.unassignedTerminals)
  const { loading, success, error, terminals } = unassignedTerminals

  useEffect(() => {
    if (error) {
      navigate("/dashboard", { replace: true })
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate(`/officer/${userInfo?.user?.id}`, { replace: true })
    }
    if (withdrawalRef.current) {
      dispatch(fetchUnassignedTerminals(1))
      withdrawalRef.current = false
    }
    if (userInfo?.user?.role === "Account Officer") {
      navigate("/performance", { replace: true })
    }
  }, [
    dispatch,
    loading,
    error,
    navigate,
    userInfo,
    currPage,
    terminals,
    success
  ])

  console.log(terminals)

  return (
    <>
      <SideNav />
      <Helmet>
        <title> Terminals | PennyTree - Admin</title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>UnAssigned Terminals</h1>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "red",
                fontSize: "20px",
                letterSpacing: "1.5px"
              }}
            >
              {error && error}
            </p>

            {loading ? (
              <Loader></Loader>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                <MDBTable
                  responsive
                  style={{ "width": "100%", fontSize: "14px" }}
                >
                  <MDBTableHead>
                    <tr className="font-reduce">
                      <th scope="col">S/N</th>
                      <th scope="col">Serial Number</th>
                      <th scope="col">Terminal Identity</th>
                      <th scope="col">Commission</th>
                      <th scope="col">Bank</th>
                      <th scope="col">Date Created</th>
                      <th scope="col">Action(s)</th>
                      <th scope="col"></th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {success &&
                      terminals?.data?.items?.map((terminal, key) => (
                        <tr>
                          <td>{key + 1}</td>
                          <td>{terminal?.serial_number}</td>
                          <td>{terminal?.terminal_identity}</td>
                          <td>{terminal?.commission}%</td>
                          <td>
                            {terminal?.bank_id === 140
                              ? "PARALLEX BANK"
                              : "VFD MICROFINANCE BANK"}
                          </td>

                          <td>
                            {moment(terminal?.date_created).format("LLL")}
                          </td>
                          <td>
                            <button className="withdrawal-btn">
                              <Link
                                to={`/terminal/assign/${terminal?.id}`}
                                state={{
                                  terminal_id: terminal?.terminal_identity,
                                  action: "assign"
                                }}
                              >
                                Assign to Customer
                              </Link>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default PendingTerminalsScreen

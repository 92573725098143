import React, { useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
import SideNav from "../components/SideNav"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import BusinessMetrics from "../components/BusinessMetrics"
import DashboardCounts from "../components/DashboardCounts"
import Bills from "../components/Bills"
import CommissionReceived from "../components/CommissionReceived"
import ExpenditureAndInterest from "../components/ExpenditureAndInterest"
import TargetSavingsMetrics from "../components/TargetSavingsMetrics"
import PosMetrics from "../components/PosMetrics"
import TransactionMetrics from "../components/TransactionMetrics"
import BalanceMetrics from "../components/BalanceMetrics"
import TotalBalance from "../components/TotalBalance"
import Balance from "../components/Balance"

const HomeScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const refs = useRef(true)

  const admin2faLogin = useSelector((state) => state.admin2faLogin)
  const { userInfo } = admin2faLogin

  useEffect(() => {
    // if (!userInfo || userInfo === null) {
    //   navigate('/', { replace: true })
    // }
    if (
      userInfo?.user?.role === "Account Officer" ||
      userInfo?.user?.role === "DSA"
    ) {
      navigate(`/officer/${userInfo?.user?.id}`, { replace: true })
    } else if (userInfo?.user?.role === "TREEGAR") {
      navigate("/treegar", { replace: true })
    }

    if (refs.current) {
      // dispatch(fundsLocked())
      // dispatch(totalTransfer())

      refs.current = false
    }
  }, [dispatch, userInfo, navigate])

  return (
    <>
      <SideNav />
      <Helmet>
        <title>
          {" "}
          Admin Dashboard | PennyTree - Lifestyle and Finance are not Mutually
          Exclusive
        </title>
      </Helmet>
      <section className="page-container">
        <div className="page-content">
          <Header />
          <div className="page-body">
            <h1>Dashboard Overview</h1>

            <div className="row">
              <div className="col-lg-12">
                <div className="accordion" id="dashboardAccordion">
                  <Balance />
                  <TotalBalance />
                  <TargetSavingsMetrics />
                  <PosMetrics />
                  <BusinessMetrics />
                  <BalanceMetrics />
                  <DashboardCounts />
                  <TransactionMetrics />
                  <Bills />

                  <CommissionReceived />
                  <ExpenditureAndInterest />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HomeScreen
